import { useState, useEffect, createRef } from "react";
import swal from "sweetalert";
import axios from "axios";
import UpdateImageUpload1 from "./customCardUpdateComponents/UpdateImageUpload1";
import UpdateAmount from "./mainCardUpdateComponents/UpdateAmount";
import UpdateCategory from "./mainCardUpdateComponents/UpdateCategory";
import UpdateTerms from "./mainCardUpdateComponents/UpdateTerms";
import UpdateCustomCardReview from "./customCardUpdateComponents/UpdateCustomCardReview";
import UpdateCustomSuccess from "./customCardUpdateComponents/UpdateCustomSuccess";
import UpdatePromotions1 from "./customCardUpdateComponents/updatePromotion1Components/UpdatePromotion1";
import UpdateDescription from "./mainCardUpdateComponents/UpdateDescription";
const UpdateCustomCard = ({
  current1,
  setCurrent1,
  cardItem,
  DoneUpdating,
}) => {
  const [category, setCategory] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [tc, setTc] = useState(null);
  const [amount, setAmount] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [pos, setPos] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [amountTextbox, setAmountTextbox] = useState([createRef()]);
  const [show, toggle] = useState(false);
  const [newLogo, setNewLogo] = useState(null);
  const [newBgImage, setNewBgImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [color, setColor] = useState(null);
  const [color1, setColor1] = useState(null);
  const [current2, setCurrent2] = useState(null);
  const [visible, setVisible] = useState(null);
  // const [promoState, setPromoState]= useState(card?.is_promotion)

  const [posLeftBottom, setLeftBottom] = useState(["bottom", "left"]);
  const [posRightBottom, setRightBottom] = useState(["bottom", "right"]);
  const [posLeftTop, setLeftTop] = useState(["top", "left"]);
  const [posRightTop, setRightTop] = useState(["top", "right"]);

  const [logoLeftCenter, setLeftCenter] = useState(["center", "left"]);
  const [logoRightCenter, setRightCenter] = useState(["center", "right"]);
  const [logoBottomRight, setBottomRight] = useState(["bottom", "right"]);
  const [logoTopRight, setTopRight] = useState(["top", "right"]);

  console.log("vis", cardItem);
  var amountPosition = null;
  if (pos === 1) {
    amountPosition = posRightTop;
  } else if (pos === 2) {
    amountPosition = posLeftTop;
  } else if (pos === 3) {
    amountPosition = posRightBottom;
  } else if (pos === 4) {
    amountPosition = posLeftBottom;
  }

  var logoPosition = null;
  if (current2 === 2) {
    logoPosition = logoLeftCenter;
  } else if (current2 === 3) {
    logoPosition = logoRightCenter;
  } else if (current2 === 4) {
    logoPosition = logoBottomRight;
  } else if (current2 === 5) {
    logoPosition = logoTopRight;
  }

  useEffect(() => {
    const data = {
      logo,
      categoryName,
      title,
      tc,
      amount,
    };
  }, [title, tc, amount, categoryName, logo]);

  const handleValidation = () => {
    // if (amts[0] < 25 || amts[1] < 25) {
    //   swal({
    //     title: "Error",
    //     text: "Minimum Amount is GH¢ 25.00",
    //     icon: "error",
    //     button: "OK",
    //   });
    // } else {
    setCurrent1(2);
    // }
  };
  const handleCatName = (e) => {
    setCategory(e);
    setImage(e.img);
    setCategoryName(e.item.category);
  };

  const company_code = localStorage.getItem("company_code");

  const makeRequest = (data) => {
    var config = {
      method: "put",
      url: "/prime.sika/v1/cards",
      data: data,
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        swal({
          title: "Error",
          text: error.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
      });
  };

  const handleClick = () => {
    switch (reviewData.type) {
      case "giveBack":
        var data = {
          card_id: cardItem?.id,
          company_code: company_code,
          amount: amount ? amount : cardItem?.amount,
          logo: newLogo ? newLogo : cardItem?.logo,
          description: description ? description : cardItem?.description,
          discounted_amount: null,
          percentage_off: null,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title || cardItem?.title,
          card_background: newBgImage ? newBgImage : cardItem?.card_background,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color
              ? color
              : cardItem?.web_design_props?.default_color,
            logo_position: logoPosition
              ? logoPosition
              : cardItem?.web_design_props?.logo_position,
            amount_position: amountPosition
              ? amountPosition
              : cardItem?.web_design_props?.amount_position,
            type: "custom",
            image_placeholder: "",
            egift_color: color1
              ? color1
              : cardItem?.web_design_props?.egift_color,
            show_egift_card: visible
              ? visible
              : cardItem?.web_design_props?.show_egift_card,
            logo_position_number: current2
              ? current2
              : cardItem?.web_design_props?.logo_position_number,
            amount_position_number: pos
              ? pos
              : cardItem?.web_design_props?.amount_position_number,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID
            ? categoryID
            : cardItem?.card_category_id,
          has_coupon_tag: false,
          coupon_tag: null,
          card_category:
            category?.item.category || categoryName || cardItem?.card_category,
          default_color: color ? color : cardItem?.default_color,
          disable_image_upload: true,
          is_promotion: true,
          promotion_title: null,
          promotion_start: reviewData.giveStart,
          promotion_end: reviewData.giveEnd,
          promo_description: null,
          give_back_campaign_id: "",
          who_created_id: 3,
          // card_style: "",
          owner_id: null,
          promo_type: reviewData.type,
          promo_state: null,
          promo_value: reviewData.giveAmount,
          is_feature: false,
          terms_and_conditions: tc ? tc : cardItem?.terms_and_conditions,
          groupings: {
            code: "0f911764b7a1a7a8e25c",
            group_id: 139,
            amounts: [
              {
                card_id: 187,
                amount: "0.0",
              },
            ],
          },
        };
        makeRequest(data);
        break;
      case "deal":
        var dealsData = {
          card_id: cardItem?.id,
          company_code: company_code,
          amount: amount ? amount : cardItem?.amount,
          logo: newLogo ? newLogo : cardItem?.logo,
          description: description ? description : cardItem?.description,
          client_id: 2,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title || cardItem?.title,
          card_background: newBgImage ? newBgImage : cardItem?.card_background,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color
              ? color
              : cardItem?.web_design_props?.default_color,
            logo_position: logoPosition
              ? logoPosition
              : cardItem?.web_design_props?.logo_position,
            amount_position: amountPosition
              ? amountPosition
              : cardItem?.web_design_props?.amount_position,
            type: "custom",
            image_placeholder: "",
            egift_color: color1
              ? color1
              : cardItem?.web_design_props?.egift_color,
            show_egift_card: visible
              ? visible
              : cardItem?.web_design_props?.show_egift_card,
            logo_position_number: current2
              ? current2
              : cardItem?.web_design_props?.logo_position_number,
            amount_position_number: pos
              ? pos
              : cardItem?.web_design_props?.amount_position_number,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID
            ? categoryID
            : cardItem?.card_category_id,
          has_coupon_tag: false,
          coupon_tag: null,
          card_category:
            category?.item.category || categoryName || cardItem?.card_category,
          default_color: color ? color : cardItem?.default_color,
          disable_image_upload: true,
          is_promotion: true,
          promotion_title: reviewData.dealsName,
          promotion_start: reviewData.dealsStart,
          promotion_end: reviewData.dealsEnd,
          promo_description: reviewData.dealsAbout,
          who_created_id: 3,
          promotion_terms: null,
          // card_style: "",
          owner_id: null,
          promo_type: "promotionDiscount",
          promo_state: null,
          promo_value: reviewData.dealsDiscount || reviewData.dealsPercent,
          is_feature: false,
          terms_and_conditions: tc ? tc : cardItem?.terms_and_conditions,
          groupings: {
            group_id: 139,
            amounts: [
              {
                amount: "0.0",
              },
            ],
          },
        };
        makeRequest(dealsData);
        break;
      case "promo":
        var promoData = {
          id: 9,
          logo: newLogo,
          card_background: newBgImage,
          selected: true,
          default_color: color,
          card_category_id: categoryID,
          disable_image_upload: true,
          amount_position: pos,
          is_promotion: true,
          promotion_type: reviewData.type,
          promo_type: null,
          promo_state: null,
          promotion_title: null,
          title: category?.item?.gallery[0].title || title,
          promotion_start: reviewData.promoStart,
          promotion_end: reviewData.promoEnd,
          promo_value: reviewData.promoAmount,
          promotion_description: null,
          created_at: new Date(),
          updated_at: new Date(),
          category: category?.item.category || categoryName,
          show_amount: true,
          give_back_campaign_id: "",
          card_style: "",
          company_code: company_code,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color,
            logo_position: logoPosition,
            amount_position: amountPosition,
            type: "custom",
            image_placeholder: "",
            egift_color: color1,
            show_egift_card: true,
          },
          state: "published",
        };
        makeRequest(promoData);
        break;
      default:
        var defaultData = {
          card_id: cardItem?.id,
          company_code: company_code,
          amount: amount ? amount : cardItem?.amount,
          logo: newLogo ? newLogo : cardItem?.logo,
          description: description ? description : cardItem?.description,
          discounted_amount: null,
          percentage_off: null,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title || cardItem?.title,
          card_background: newBgImage ? newBgImage : cardItem?.card_background,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color
              ? color
              : cardItem?.web_design_props?.default_color,
            logo_position: logoPosition
              ? logoPosition
              : cardItem?.web_design_props?.logo_position,
            amount_position: amountPosition
              ? amountPosition
              : cardItem?.web_design_props?.amount_position,
            type: "custom",
            image_placeholder: "",
            egift_color: color1
              ? color1
              : cardItem?.web_design_props?.egift_color,
            show_egift_card: visible
              ? visible
              : cardItem?.web_design_props?.show_egift_card,
            logo_position_number: current2
              ? current2
              : cardItem?.web_design_props?.logo_position_number,
            amount_position_number: pos
              ? pos
              : cardItem?.web_design_props?.amount_position_number,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID
            ? categoryID
            : cardItem?.card_category_id,
          has_coupon_tag: false,
          coupon_tag: null,
          card_category:
            category?.item.category || categoryName || cardItem?.card_category,
          default_color: color ? color : cardItem?.default_color,
          disable_image_upload: true,
          is_promotion: false,
          promotion_title: null,
          promotion_start: null,
          promo_description: null,
          promotion_terms: null,

          owner_id: null,
          promo_type: null,
          promo_state: null,
          promo_value: null,
          is_feature: false,
          terms_and_conditions: tc ? tc : cardItem?.terms_and_conditions,
          groupings: {
            amounts: [
              {
                amount: "0.0",
              },
            ],
          },
        };
        makeRequest(defaultData);
        break;
    }
  };

  return (
    <>
      <div>
        {current1 === 1 && (
          <form action="">
            <UpdateImageUpload1
              handleNewLogo={(e) => setNewLogo(e)}
              handleBgImage={(e) => setNewBgImage(e)}
              handleVisible={(e) => setVisible(e)}
              visible={
                visible ? visible : cardItem?.web_design_props?.show_egift_card
              }
              handlePos={(e) => setPos(e)}
              title={
                category?.item?.gallery[0].title ||
                title?.length > 0 ||
                title == ""
                  ? title
                  : title || cardItem?.title
              }
              handleTitle={(e) => setTitle(e)}
              handleColor={(e) => setColor(e)}
              handleColor1={(e) => setColor1(e)}
              handleLogo={(e) => setLogo(e)}
              setBackgroundImage={(e) => setBackgroundImage(e)}
              amount={category?.item.gallery[0].amount || amount}
              color={color ? color : cardItem?.default_color}
              color1={color1 ? color1 : cardItem?.web_design_props?.egift_color}
              logo={logo ? logo : cardItem?.logo}
              pos={
                pos ? pos : cardItem?.web_design_props?.amount_position_number
              }
              backgroundImage={
                backgroundImage ? backgroundImage : cardItem?.card_background
              }
              current2={
                current2
                  ? current2
                  : cardItem?.web_design_props?.logo_position_number
              }
              setCurrent2={setCurrent2}
            />
            <UpdateAmount
              handleAmts={(e) => setAmount(e)}
              amount={
                amount?.length > 0 || amount == "" ? amount : cardItem?.amount
              }
              show={show}
            />
            <UpdateCategory
              handleCat={(e) => setCategoryName(e.name)}
              handleCatID={(e) => setCategoryID(e.id)}
              cat={categoryName ? categoryName : cardItem?.card_category}
            />
            <UpdatePromotions1 handleReviewData={(e) => setReviewData(e)} />
            <UpdateTerms
              terms={
                tc?.length > 0 || tc == "" ? tc : cardItem?.terms_and_conditions
              }
              handleText={(e) => setTc(e)}
            />
            <UpdateDescription
              description={
                description?.length > 0 || description == ""
                  ? description
                  : cardItem?.description
              }
              handleDescription={(e) => setDescription(e)}
            />
            <div className="row">
              <div className="col-12">
                <button
                  // disabled={disabled}
                  type="button"
                  className="btn btn-success btn-block review-btn mt-2"
                  onClick={() => handleValidation()}
                >
                  Review
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      {current1 === 2 && (
        <UpdateCustomCardReview
          handleVisible={(e) => setVisible(e)}
          visible={
            visible ? visible : cardItem?.web_design_props?.show_egift_card
          }
          type={reviewData.type ? reviewData.type : cardItem?.promo_type}
          current1={current1}
          current2={
            current2
              ? current2
              : cardItem?.web_design_props?.logo_position_number
          }
          backgroundImage={
            backgroundImage ? backgroundImage : cardItem?.card_background
          }
          setCurrent2={setCurrent2}
          setCurrent1={setCurrent1}
          category={categoryName ? categoryName : cardItem?.card_category}
          terms={tc ? tc : cardItem?.terms_and_conditions}
          handlePublish={handleClick}
          pos={pos ? pos : cardItem?.web_design_props?.amount_position_number}
          amount={amount ? amount : cardItem?.amount}
          handlePos={(e) => setPos(e)}
          title={category?.item?.gallery[0]?.title || title || cardItem?.title}
          description={description ? description : cardItem?.description}
          handleTitle={(e) => setTitle(e)}
          handleColor={(e) => setColor(e)}
          handleColor1={(e) => setColor1(e)}
          handleLogo={(e) => setLogo(e)}
          color={color ? color : cardItem?.default_color}
          color1={color1 ? color1 : cardItem?.web_design_props?.egift_color}
          logo={logo ? logo : cardItem?.logo}
        />
      )}
      {current1 === 3 && (
        <UpdateCustomSuccess
          setCurrent1={setCurrent1}
          current2={
            current2
              ? current2
              : cardItem?.web_design_props?.logo_position_number
          }
          color={color ? color : cardItem?.default_color}
          color1={color1 ? color1 : cardItem?.web_design_props?.egift_color}
          pos={pos ? pos : cardItem?.web_design_props?.amount_position_number}
          amount={amount ? amount : cardItem?.amount}
          logo={logo ? logo : cardItem?.logo}
          visible={
            visible ? visible : cardItem?.web_design_props?.show_egift_card
          }
          DoneUpdating={DoneUpdating}
        />
      )}
    </>
  );
};

export default UpdateCustomCard;
