const GeneralSettingsTab = () => {
  return (
    <div className="row mt-5">
      <div className="col-lg-11">
        <div>
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            {/* <li className="nav-item" role="presentation">
											<Link to="/" className="btn bg-dark-light px-lg-4 px-sm-3 mr-2">Dashboard</Link>
										</li> */}
            <li className="nav-item nbbn" role="presentation">
              <a
                className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2 active"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="TRUE"
              >
                Profile<div className="str-line1"></div>
              </a>
            </li>
            {localStorage?.getItem("client_type") === "franchise" ? (
              <li className="nav-item" role="presentation">
                <a
                  className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2"
                  id="pills-settings-tab"
                  data-toggle="pill"
                  href="#pills-settings"
                  role="tab"
                  aria-controls="pills-settings"
                  aria-selected="false"
                >
                  Managements<div className="str-line2"></div>
                </a>
              </li>
            ) : (
              ""
            )}
            <li className="nav-item" role="presentation">
              <a
                className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2"
                id="pills-payments-tab"
                data-toggle="pill"
                href="#pills-payments"
                role="tab"
                aria-controls="pills-payments"
                aria-selected="false"
              >
                Transactions<div className="str-line3"></div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2"
                id="pills-reports-tab"
                data-toggle="pill"
                href="#pills-reports"
                role="tab"
                aria-controls="pills-reports"
                aria-selected="false"
              >
                Merchant Type<div className="str-line5"></div>
              </a>
            </li>
          </ul>
          <hr className="hr2" />
        </div>
      </div>
    </div>
  );
};


export default GeneralSettingsTab