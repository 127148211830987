import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
const AddNewUserForm = ({
  userInput,
  handleUserInput,
  setlocation1,
  location1,
  saveData,
  setPermissions,
  setReports,
  setManageAccount,
  setManageGiftCards,
  setCreatePromotionsOrDeals,
  setSetUpPaymentAccount,
  setTransactionsAndPayouts,
  reports,
  manageAccount,
  manageGiftCards,
  createPromotionsOrDeals,
  paymentAccount,
  transactionPayout,
  isloading,
  setLoading
}) => {
  // const [permissions, setPermissions] = useState([]);
  // const [reports, setReports] = useState(false);
  // const [manageAccount, setManageAccount] = useState(false);
  // const [manageGiftCards, setManageGiftCards] = useState(false);
  // const [createPromotionsOrDeals, setCreatePromotionsOrDeals] = useState(false);
  // const [paymentAccount, setSetUpPaymentAccount] = useState(false);
  // const [transactionPayout, setTransactionsAndPayouts] = useState(false);
  // const [fullname, setFullname] = useState("");
  // const [email, setEmail] = useState("");
  // const [tel, setTel] = useState("");
  // const [password, setPassword] = useState("");
  // const [userType, setUserType] = useState("");
  // const [location, setLocation] = useState("");



  const [errors, setError] = useState({
    branch: "", location: "", telephone: "", email1: ""
  });


  const handleOnChange = (e) => {
    setError((errors) => ({
      ...errors, [e.target.name]: ""
    }))
    handleUserInput(e)
  }

  const handleValidation = (e) => {
    e.preventDefault();
    if (userInput.merchantname === "" || !userInput.merchantname) {
      setError((errors) => ({
        ...errors,
        merchantname: "Please Enter Merchant's name",
      }));
    }
    else if (location1?.address === "") {
      swal({
        title: "Error",
        text: "Select Merchant Location",
        icon: "error",
        button: "OK",
      });
    }
    else if (userInput.telephone === "" || !userInput.telephone) {
      setError((errors) => ({
        ...errors,
        telephone: "Please Enter Merchant's number",
      }));
    }

    saveData()
  };

  // const handleClick = () => {
  //   const data = {
  //     userType,
  //     fullname,
  //     email,
  //     tel,
  //     password,
  //     persmission: {
  //       "manage account": manageAccount,
  //       "manage gift cards": manageGiftCards,
  //       reports: reports,
  //       promotions: createPromotionsOrDeals,
  //       payment: paymentAccount,
  //       transaction: transactionPayout,
  //     },
  //   };
  //   console.log(data);

  //   // axios
  //   //   .get("/prime.sika/v1/merchant-accounts/permissions/3848")
  //   //   .then((res) => {
  //   //     res.data.data.permissions.forEach((element) => {
  //   //       setPermissions((prev) => [...prev, element.description]);
  //   //     });
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err.response);
  //   //   });
  // };
  return (
    <div
      className="tab-pane fade show active"
      id="pills-add-new-user"
      role="tabpanel"
      aria-labelledby="pills-add-new-user-tab"
    >
      <form className="card card-chart2 card-h11 card-h12-pad card-h11-margin">
        <div>
          <h3>Add New User Information</h3>
          <hr className="hr3" />
        </div>
        <div className="ml-4 mt-5">
          {/* <div className="form-group d-flex align-items-center justify-content-between mt-3">
            <div className="form-name-width">
              <label htmlFor="name">
                <h4 className="my-auto">User Type</h4>
              </label>
            </div>
            <div className="form-box-width">
              <select name="" id="" className="form-control form-fill-textbox1" onChange={(e) => setUserType(e.target.value)}>
                <option>Select UserType</option>
                <option value="manager">Manager</option>
                <option value="front_desk">Front Desk</option>
                <option value="account">Account</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div> */}
          <div className="form-group d-flex align-items-center mt-4">
            <div className="form-name-width">
              <label htmlFor="name">
                <h4 className="my-auto">Full Name</h4>
              </label>
            </div>
            <div className="form-box-width">
              <input
                name="merchantname"
                value={userInput.merchantname}
                onChange={handleOnChange}
                type="text"
                className="form-control form-fill-textbox1"
              />
            </div>
          </div>
          <div className="form-group d-flex align-items-center mt-4">
            <div className="form-name-width">
              <label htmlFor="name">
                <h4 className="my-auto">Email</h4>
              </label>
            </div>
            <div className="form-box-width">
              <input
                name="email"
                value={userInput.email1}
                onChange={handleOnChange}
                type="email"
                className="form-control form-fill-textbox1"
              />
            </div>
          </div>
          <div className="form-group d-flex align-items-center mt-4">
            <div className="form-name-width">
              <label htmlFor="name">
                <h4 className="my-auto">Telephone</h4>
              </label>
            </div>
            <div className="form-box-width">
              <input
                name="telephone1"
                value={userInput.telephone}
                onChange={handleOnChange}
                type="number"
                className="form-control form-fill-textbox1"
              />
            </div>
          </div>
          <div className="form-group d-flex align-items-center mt-4">
            <div className="form-name-width">
              <label htmlFor="name">
                <h4 className="my-auto">Password</h4>
              </label>
            </div>
            <div className="form-box-width">
              <input
                name="password"
                value={userInput.password}
                onChange={handleOnChange}
                type="password"
                className="form-control form-fill-textbox1"
              />
            </div>
          </div>
          <div className="form-group d-flex align-items-center mt-4">
            <div className="form-name-width">

              <label htmlFor="name">
                <h4 className="my-auto">Location</h4>
              </label>


            </div>
            <div className="form-box-width">
              <a data-toggle="modal" href="#location-modal">
                <input
                  value={location1?.address}
                  name="location"
                  type="text"
                  className="form-control form-fill-textbox1"
                  readOnly
                />
                <img
                  src="assets/img/location.png"
                  alt=""
                  className="company-loc-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h3>Privileges</h3>
          <hr className="hr3" />
        </div>
        <div>
          <div className="note-font">
            <h4>Select the options you would like to grant user access</h4>
          </div>
          <div className="ml-4">
            <div className="radio-group1 mt-4 d-flex">
              <div className="">
                <div className="d-flex radio1 d-flex align-items-center">
                  <input
                    checked={
                      //   permissions.includes("merchant account") &&
                      manageAccount
                    }
                    type="checkbox"
                    name="merchant_account"
                    id="merchant_account"
                    className="checkbox-design"
                    value="merchant_account"
                    onChange={() => setManageAccount(!manageAccount)}
                  />
                  <label htmlFor="merchant_account">
                    <h4 className="mt-3 ml-2">Manage Account</h4>
                  </label>
                </div>
                <div className="d-flex radio1 d-flex align-items-center mt-2">
                  <input
                    checked={
                      //   permissions.includes("Manage Gift Cards") &&
                      manageGiftCards
                    }
                    type="checkbox"
                    name="manage_gift_cards"
                    id="manage_gift_cards"
                    className="checkbox-design"
                    value="manage_gift_cards"
                    onChange={() => setManageGiftCards(!manageGiftCards)}
                  />
                  <label htmlFor="manage_gift_cards">
                    <h4 className="mt-3 ml-2">Manage Gift Cards</h4>
                  </label>
                </div>
              </div>
              <div className="ml-4">
                <div className="d-flex radio1 d-flex align-items-center">
                  <input
                    checked={
                      //   permissions.includes("Create Promotions/ Deals") &&
                      createPromotionsOrDeals
                    }
                    type="checkbox"
                    name="create_promotions_or_deals"
                    id="create_promotions_or_deals"
                    className="checkbox-design"
                    value="create_promotions_or_deals"
                    onChange={() =>
                      setCreatePromotionsOrDeals(!createPromotionsOrDeals)
                    }
                  />
                  <label htmlFor="create_promotions_or_deals">
                    <h4 className="mt-3 ml-2">Create Promotions/ Deals</h4>
                  </label>
                </div>
                <div className="d-flex radio1 d-flex align-items-center mt-2">
                  <input
                    checked={
                      //   permissions.includes("Set up Payment Account") &&
                      paymentAccount
                    }
                    type="checkbox"
                    name="set_up_payment_account"
                    id="set_up_payment_account"
                    className="checkbox-design"
                    value="set_up_payment_account"
                    onChange={() => setSetUpPaymentAccount(!paymentAccount)}
                  />
                  <label htmlFor="set_up_payment_account">
                    <h4 className="mt-3 ml-2">Set up Payment Account</h4>
                  </label>
                </div>
              </div>
              <div className="ml-4">
                <div className="d-flex radio1 d-flex align-items-center">
                  <input
                    checked={
                      // permissions.includes("Reports") &&
                      reports
                    }
                    type="checkbox"
                    name="reports"
                    id="reports"
                    className="checkbox-design"
                    value="reports"
                    onChange={() => setReports(!reports)}
                  />
                  <label htmlFor="reports">
                    <h4 className="mt-3 ml-2">Reports</h4>
                  </label>
                </div>
                <div className="d-flex radio1 d-flex align-items-center mt-2">
                  <input
                    checked={
                      //   permissions.includes("Transactions & Payouts") &&
                      transactionPayout
                    }
                    type="checkbox"
                    value="transactions_and_payouts"
                    name="transactions_and_payouts"
                    id="transactions_and_payouts"
                    className="checkbox-design"
                    onChange={() =>
                      setTransactionsAndPayouts(!transactionPayout)
                    }
                  />
                  <label htmlFor="transactions_and_payouts">
                    <h4 className="mt-3 ml-2">Transactions & Payouts</h4>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            {/* <input
              onClick={() => handleClick()}
              type="button"
              className="btn btn-success float-right save-btn-size"
              value="Add"
            /> */}
             {!isloading && <button type="submit" className="btn btn-success send-invite-btn float-right" onClick={handleValidation}>Add</button>}
                {isloading && <button
                  className="btn btn-success send-invite-btn float-right"
                  disabled
                >
                  <i className="fas fa-spinner fa-spin mr-2"></i>Add...
                </button>}
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddNewUserForm;
