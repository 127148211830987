const LiveShopSubscription = () =>{
    return(
<div class="card card-chart z-position card-height6 text-center">
												<div class="row">
													<div class="col-12">
														<div class="mt-3">
															<img src="assets/img/Frame2.png" alt="" />
														</div>
													</div>
												</div>
												
												<div class="row text-left">
													<div class="col-12 d-flex mt-4">
														<h5>LiveShop gives you the opportunity to use the power of <span>live streaming to</span>showcase your
															brand to live customers, who get to know <span>more about your brand.</span> Engaging with your customers on liveShop
															inreases,<span> brand awearness,</span>brand loyalty and bost sales.</h5>
													</div>
													<div class="col-12 mt-3">
														<h5 class="text-success">Benefits</h5>
														<ul class="list-margin-top">
															<li>Launch new products</li>
															<li>Engage customers</li>
															<li>Build brand followers</li>
															<li>Make sales on the live stream</li>
														</ul>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<a href="#" class="btn btn-success mt-3 sub-button-width">Get Subscription</a>
													</div>
												</div>
											</div>
    )
}
export default LiveShopSubscription;