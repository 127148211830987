import $ from "jquery";
import { useEffect, useState } from "react";
import { HuePicker } from "react-color";
import { uploadToBucket } from "../../../UploadImageFolder/uploadToBucket";
const UpdateImageUpload = ({
  title,
  image,
  handleImage,
  handleNewImage,
  handleTitle,
  handleBgImage,
  amount,
  handleColor,
  color,
  handlePos,
  pos,
  setBackgroundImage,
  backgroundImage,
  cardItem,
}) => {
  $(".form-padding1")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
        $(this).css("color", "#000");
      }
    })
    .trigger("change");
  const [img1, setImg1] = useState(null);
  const handleImageUpload = async (e) => {
    let data = new FormData();
    data.append("file", e.target.files[0]);
    let new_image = await uploadToBucket(data);

    handleNewImage(new_image.data.url);
    setImg1(URL.createObjectURL(e.target.files[0]));
    handleImage(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    setImg1(image);
    handleImage(image);
  }, [image]);

  const [defaultCheck, setDefaultCheck] = useState(false);
  useEffect(() => {
    if (cardItem?.web_design_props?.amount_position_number) {
      setDefaultCheck(true);
    }
  });

  const [value, setValue] = useState(title);
  const [img, setImg] = useState(image);

  const [backgroundImg, setBackgroundImg] = useState(null);
  const handleBackground = async (e) => {
    let data = new FormData();
    data.append("file", e.target.files[0]);
    let new_bg_image = await uploadToBucket(data);

    handleBgImage(new_bg_image.data.url);
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
    setBackgroundImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleText = (e) => {
    setValue(e.target.value);
    handleTitle(e.target.value);
  };

  const [current3, setCurrent3] = useState(1);

  return (
    <div className="row justify-content-center align-items-center mb-5">
      <div className="col-lg-6 col-12">
        {" "}
        {img1 ? (
          <>
            <label htmlFor="upload-image">
              <div className={`${img1 ? "" : "upload-btn"}`}>
                <img
                  src={img1 ? img1 : "assets/img/upload-image-icon.png"}
                  alt=""
                  className={`${
                    img1 ? "upload-btn-img" : "upload-image-icon-positiion"
                  }`}
                />
                {img1 ? "" : <h4 className="text-success">Upload Image</h4>}
              </div>
              <input
                type="file"
                id="upload-image"
                onChange={handleImageUpload}
              />
            </label>

            {pos === 1 && (
              <div
                style={{ color: color, transition: "ease all 100ms" }}
                className="card-amount-pos-top-r"
              >
                <h4 className="card-amount-pos-top-r-h4">
                  GH¢ <span>{amount}</span>
                </h4>
              </div>
            )}
            {pos === 2 && (
              <div
                style={{ color: color, transition: "ease all 100ms" }}
                className="card-amount-pos-top-l"
              >
                <h4 className="card-amount-pos-top-l-h4">
                  GH¢ <span>{amount}</span>
                </h4>
              </div>
            )}
            {pos === 3 && (
              <div
                style={{ color: color, transition: "ease all 100ms" }}
                className="card-amount-pos-down-r"
              >
                <h4>
                  GH¢ <span>{amount}</span>
                </h4>
              </div>
            )}
            {pos === 4 && (
              <div
                style={{ color: color, transition: "ease all 100ms" }}
                className="card-amount-pos-down-l"
              >
                <h4>
                  GH¢ <span>{amount}</span>
                </h4>
              </div>
            )}

            <div className="col-position mt-3">
              <div className="row d-flex">
                <div className="col-1">
                  <div
                    className="black-color mr-2"
                    onClick={(color) => {
                      handleColor("#000");
                    }}
                  ></div>
                </div>
                <div className="col-9">
                  <HuePicker
                    color={cardItem?.web_design_props?.default_color}
                    onChangeComplete={(color) => {
                      handleColor(color.hex);
                    }}
                    className="mt-1 ml-3"
                    width="100%"
                  />
                </div>
                <div className="col-1">
                  <div
                    className="white-color ml-2"
                    onClick={(color) => {
                      handleColor("#fff");
                    }}
                  ></div>
                </div>
              </div>
              <h4 className="note-size mt-1">
                <span>
                  Move the picker to change the amount color on the image
                </span>
              </h4>
            </div>
            <div className="">
              <h6 className="text-danger">select a position to place amount</h6>
              <div className="d-block ">
                <div className="d-flex">
                  <label
                    htmlFor="upperRight"
                    className="d-flex cur-pointer mr-5"
                  >
                    <input
                      type="radio"
                      name="amount_position"
                      id="upperRight"
                      defaultChecked={cardItem?.web_design_props?.amount_position_number === 1 ? true : false}
                      onChange={() => handlePos(1)}
                      className="radio__input"
                    />
                    <div className="radio__radio3"></div>
                    <h6 className="my-auto">Upper Right</h6>
                  </label>
                  <label htmlFor="upperLeft" className="d-flex cur-pointer">
                    <input
                      type="radio"
                      name="amount_position"
                      id="upperLeft"
                      defaultChecked={
                        cardItem?.web_design_props?.amount_position_number === 2
                          ? true
                          : false
                      }
                      onChange={() => handlePos(2)}
                      className="radio__input"
                    />
                    <div className="radio__radio3"></div>
                    <h6 className="my-auto">Upper Left</h6>
                  </label>
                </div>
                <div className="d-flex">
                  <label
                    htmlFor="lowerRight"
                    className="d-flex cur-pointer mr-5"
                  >
                    <input
                      type="radio"
                      name="amount_position"
                      id="lowerRight"
                      onChange={() => handlePos(3)}
                      className="radio__input"
                      defaultChecked={
                        cardItem?.web_design_props?.amount_position_number === 3
                          ? true
                          : false
                      }
                    />
                    <div className="radio__radio3"></div>
                    <h6 className="my-auto">Lower Right</h6>
                  </label>
                  <label htmlFor="lowerLeft" className="d-flex cur-pointer">
                    <input
                      type="radio"
                      name="amount_position"
                      id="lowerLeft"
                      onChange={() => handlePos(4)}
                      defaultChecked={
                        cardItem?.web_design_props?.amount_position_number === 4
                          ? true
                          : false
                      }
                      className="radio__input"
                    />
                    <div className="radio__radio3"></div>
                    <h6 className="my-auto">Lower Left</h6>
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <label htmlFor="upload-image">
              <div className={`${img1 ? "upload-butn" : "upload-btn"}`}>
                <img
                  src={img1 ? img1 : "assets/img/upload-image-icon.png"}
                  alt=""
                  className={`${
                    img1 ? "upload-btn-img" : "upload-image-icon-position"
                  }`}
                />
                {img1 ? "" : <h4 className="text-success">Upload Image</h4>}
              </div>
            </label>
            <input type="file" id="upload-image" onChange={handleImageUpload} />
          </>
        )}
      </div>
      <div className="col-lg-6 col-12">
        <div className="row ml-lg-2">
          <div className="col-12">
            <label
              htmlFor="upload-image1"
              className="btn-upload-size btn btn-success pt-2"
            >
              <img
                src="/assets/img/upload-bg-logo.png"
                alt=""
                className="mr-2 my-auto"
              />
              Upload Background Image
            </label>
            <input
              type="file"
              className=" mt-lg-5 mt-3"
              id="upload-image1"
              onChange={handleBackground}
            />
            <h4 className="back-img-size d-flex">
              Image: <span className="ml-2">{backgroundImage}</span>
            </h4>
          </div>
          <div className="col-12">
            <div className="form-group">
              <input
                className="form-control form-padding1 mt-lg-3 mt-3"
                type="text"
                placeholder="Enter card name here"
                value={title}
                onChange={(e) => handleText(e)}
                name="title"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateImageUpload;
