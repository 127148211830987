import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const ResendCodeModal = ({ showResendModal, setShowResendModal, userInput }) => {
  const [isloading, setLoading] = useState(false);
  const [userVerifyInput, setUserVerifyInput] = useState({
    verification_code_destination: "sms",
  });

  const handleUserVerifyInput = (e) => {
    setUserVerifyInput((userVerifyInput) => ({
      ...userVerifyInput,
      [e.target.name]: e.target.value,
    }));
  };
  // const handleResendCode = () => {
  //   setLoading(true);
  //   if (userVerifyInput.verification_code_destination === "sms") {
  //     axios
  //     .post(
  //       `/prime.sika/v1/users/resend-merchant-verification-code?verify_option=sms&user_id=${email}`
  //     )
  //     .then((res) => {
  //       Swal.fire({
  //         title: "Success",
  //         text: "Verification link has been resent",
  //         icon: "success",
  //         button: "OK",
  //       });
  //       setLoading(false);
  //       setShowResendModal(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err.response);
  //     });      
  //   } else if (userVerifyInput.verification_code_destination === "email") {
  //     axios
  //       .post(
  //         `/prime.sika/v1/users/resend-merchant-verification-code?verify_option=email&user_id=${email}`
  //       )
  //       .then((res) => {
  //         Swal.fire({
  //           title: "Success",
  //           text: "Verification link has been resent",
  //           icon: "success",
  //           button: "OK",
  //         });
  //         setLoading(false);
  //         setShowResendModal(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err.response);
  //       });
  //   }
  // };
  return (
    <Modal show={showResendModal} onHide={() => setShowResendModal(false)}>
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4 className="mt-3">Code Resend Options</h4>
          </div>
          <div>
            <button onClick={() => setShowResendModal(false)}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-md-12">
          <div className="font-enter1">
            <h4>Where do you want to receive your verification code?</h4>
          </div>
          <div className="radio-group1 d-flex mt-4">
            <label
              htmlFor="myRadioId2"
              className="radio1 d-flex align-items-center"
            >
              <input
                type="radio"
                name="verification_code_destination"
                value="sms"
                onChange={handleUserVerifyInput}
                id="myRadioId2"
                className="radio__input mr-1"
                defaultChecked
              />
              <div className="radio__radio1"></div>
              <h4 className="my-auto">Phone</h4>
            </label>

            <label
              htmlFor="myRadioId3"
              className="radio1 d-flex align-items-center ml-4"
            >
              <input
                type="radio"
                name="verification_code_destination"
                value="email"
                onChange={handleUserVerifyInput}
                id="myRadioId3"
                className="radio__input mr-1"
              />
              <div className="radio__radio1"></div>
              <h4 className="my-auto">Email</h4>
            </label>
          </div>
          <div>
            <button
              className="btn btn-outline-success my-4 cur-pointer float-right"
              type="button"
              // onClick={handleResendCode}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ResendCodeModal;
