import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import UpdateCustomCard from "./updateEgiftCardComponents/UpdateCustomCard";
import UpdateMainCard from "./updateEgiftCardComponents/UpdateMainCard";

const CouponAssignmentModal = ({
  handleCouponAssignmentModalClose,
  showCouponAssignmentModal,
  cardItem,
}) => {
  const [coupons, setCoupons] = useState([]);
  const [assignLoading, setAssignLoading] = useState(false);

  const [userInput, setUserInput] = useState({
    card_id: "",
    company_code: "",
    coupon_tag_id: "",
  });
  useEffect(() => {
    axios
      .get("/prime.sika/v1/managers/coupons/list")
      .then((res) => {
        setCoupons(res.data.data.coupons);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const AssignCoupon = () => {
    var data = {
      card_id: cardItem?.id,
      company_code: localStorage.getItem("company_code"),
      coupon_tag_id: userInput.coupon_tag_id,
    };
    setAssignLoading(true);
    axios
      .put("/prime.sika/v1/cards", data)
      .then((res) => {
        setAssignLoading(false);
        //Show Sweet Alert Here
        swal({
          title: "Success",
          text: "Card Assigned Successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            handleCouponAssignmentModalClose();
          }
        });
      })
      .catch((err) => {
        console.log(err.response);
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
        setAssignLoading(false);
      });
  };
  return (
    <Modal
      show={showCouponAssignmentModal}
      onHide={handleCouponAssignmentModalClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div>Assign Coupon</div>
          <div>
            <button onClick={handleCouponAssignmentModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <label htmlFor="assign">
            <h4>Select coupon to assign card</h4>
          </label>
        </div>
        <div className="mt-1">
          <select
            className="form-control w-100"
            id="sel2"
            name="coupon_tag_id"
            onChange={handleUserInput}
          >
            <option>Select Coupon</option>
            {coupons.map((ele, index) => (
              <option key={index} value={ele?.tag_id}>
                {ele?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-3">
          {!assignLoading && (
            <button
              className="btn btn-success float-right"
              onClick={AssignCoupon}
            >
              Assign
            </button>
          )}
          {assignLoading && (
            <button className="btn btn-success float-right" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>Assigning...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CouponAssignmentModal;
