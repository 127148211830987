import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";
import PagePagination from "../components/PagePagination";
import Swal from "sweetalert2";

const EGiftCardsModalPage = ({
  setCurrentDashboardScreen,
  handleUpdateEgiftCardModal,
  handleCouponAssignmentModal,
  totalPage,
  setTotalPage,
  currentPage,
  setCurrentPage,
  cards,
  setCards,
  FetchCardDetails,
  isloading,
  setLoading,
}) => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const ChangeCardStatus = (e, i, u) => {
    if (u === "published") {
      e.preventDefault();
      const data = {
        card_id: i,
        status: "draft",
      };

      swal({
        title: "Are you sure you want to unpublish this card?",
        text: "This will make this card unvailable to customers",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((isOkay) => {
        if (isOkay) {
          setLoading(true);
          axios
            .post(
              `/prime.sika/v1/cards/${localStorage.getItem(
                "company_code"
              )}/change_cards_state`,
              data
            )
            .then((res) => {
              Swal.fire({
                title: "Success",
                text: "card status updated successfully",
                icon: "success",
                button: "OK",
                confirmButtonColor: "#00b17d",
              }).then((isOkay) => {
                if (isOkay) {
                  FetchCardDetails();
                }
              });
            })
            .catch((err) => {
              console.log(err.response);
              setLoading(false);
            });
        }
      });
      return false;
    } else {
      e.preventDefault();
      swal({
        title: "Are you sure you want to delete this card?",
        text: "This will remove the card from list of cards",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((isOkay) => {
        if (isOkay) {
          axios
            .delete(
              `/prime.sika/v1/cards/${localStorage.getItem(
                "company_code"
              )}/remove?card_id=${i}`
            )
            .then((res) => {
              Swal.fire({
                title: "Success",
                text: "card deleted successfully",
                icon: "success",
                button: "OK",
                confirmButtonColor: "#00b17d",
              }).then((isOkay) => {
                if (isOkay) {
                  FetchCardDetails();
                }
              });
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      });
      return false;
    }
  };

  var cardList1 = isloading ? (
    cards.length === 0 ? (
      <div className="no-available">
        <h1>No Available Cards</h1>
      </div>
    ) : (
      cards.map((card, i, u) => {
        if (card?.web_design_props.type === "custom") {
          var rightLogo =
            card?.web_design_props?.logo_position[0] === "center" &&
            card?.web_design_props?.logo_position[1] === "right";
          var leftLogo =
            card?.web_design_props?.logo_position[0] === "center" &&
            card?.web_design_props?.logo_position[1] === "left";
          var downLogo =
            card?.web_design_props?.logo_position[0] === "bottom" &&
            card?.web_design_props?.logo_position[1] === "right";
          var topLogo =
            card?.web_design_props?.logo_position[0] === "top" &&
            card?.web_design_props?.logo_position[1] === "right";
          var classProp1;
          if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            rightLogo
          ) {
            classProp1 = "card-e-gift-img-top1-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            rightLogo
          ) {
            classProp1 = "card-e-gift-img-down1-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            leftLogo
          ) {
            classProp1 = "card-e-gift-img-top1-r1";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            leftLogo
          ) {
            classProp1 = "card-e-gift-img-down1-r1";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            downLogo
          ) {
            classProp1 = "card-e-gift-img-top1-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            downLogo
          ) {
            classProp1 = "card-e-gift-img-top1-r";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            topLogo
          ) {
            classProp1 = "card-e-gift-img-down1-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            topLogo
          ) {
            classProp1 = "card-e-gift-img-down1-r";
          }
          return (
            <tr key={[i, u]}>
              <td>
                {rightLogo && (
                  <div
                    className="right-logo"
                    style={{
                      color: card?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "250px",
                      height: "151px",
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div style={{ width: "180px", position: "relative" }}>
                      <img src="http://placeimg.com/180/151/any" alt="" />
                      <div
                        style={{ color: card?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{card?.amount}</span>
                        </h4>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: card?.default_color,
                        width: "70px",
                        position: "relative",
                      }}
                    >
                      <div className="logo-size-prev">
                        <img
                          src={card?.logo}
                          alt="First slide"
                          className="upload-btn-img4"
                        />
                      </div>
                      {card?.web_design_props?.show_egift_card && (
                        <div
                          style={{ color: card?.web_design_props?.egift_color }}
                        >
                          <h4 className="gift-text-size">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {leftLogo && (
                  <div
                    className="left-logo"
                    style={{
                      color: card?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "250px",
                      height: "151px",
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div
                      style={{
                        background: card?.default_color,
                        width: "70px",
                        position: "relative",
                      }}
                    >
                      <div className="logo-size-prev">
                        <img
                          src={card?.logo}
                          alt="First slide"
                          className="upload-btn-img1"
                        />
                      </div>
                      {card?.web_design_props?.show_egift_card && (
                        <div
                          style={{ color: card?.web_design_props?.egift_color }}
                        >
                          <h4 className="gift-text-size">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                    <div>
                      <img src="http://placeimg.com/180/151/any" alt="" />
                      <div
                        style={{ color: card?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{card?.amount}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
                {downLogo && (
                  <div
                    className="down-logo"
                    style={{
                      color: card?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "250px",
                      height: "151px",
                      display: "grid",
                      gridTemplateRows: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div style={{ height: "100px", position: "relative" }}>
                      <img src="http://placeimg.com/250/100/any" alt="" />
                      <div
                        style={{ color: card?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{card?.amount}</span>
                        </h4>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: card?.default_color,
                        position: "relative",
                        height: "51px",
                      }}
                    >
                      <div className="logo-size1-prev">
                        <img
                          src={card?.logo}
                          alt="First slide"
                          className="upload-btn-img-bottom"
                        />
                      </div>
                      {card?.web_design_props?.show_egift_card && (
                        <div
                          style={{
                            color: card?.web_design_props?.egift_color,
                            transition: "ease all 100ms",
                          }}
                        >
                          <h4 className="gift-text-size">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {topLogo && (
                  <div
                    className="up-logo"
                    style={{
                      color: card?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "250px",
                      height: "151px",
                      display: "grid",
                      gridTemplateRows: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div
                      style={{
                        height: "52px",
                        backgroundColor: card?.default_color,
                        position: "relative",
                      }}
                    >
                      <div className="logo-size1-prev">
                        <img
                          src={card?.logo}
                          alt="First slide"
                          className="upload-btn-img-bottom"
                        />
                      </div>
                      {card?.web_design_props?.show_egift_card && (
                        <div
                          style={{
                            color: card?.web_design_props?.egift_color,
                            transition: "ease all 100ms",
                          }}
                        >
                          <h4 className="gift-text-size">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                    <div style={{ height: "100px", position: "relative" }}>
                      <img src="http://placeimg.com/250/100/any" alt="" />
                      <div
                        style={{ color: card?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{card?.amount}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </td>
              <td>
                <div className="d-block card-settings-details">
                  <div>
                    <h4>
                      Card name:<span>{card?.title}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Amount:<span>¢{parseFloat(card?.amount).toFixed(2)}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Category:<span>{card?.card_category}</span>
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div className="d-block card-settings-details">
                  <div>
                    <h4>
                      Tag: <span>{card?.title}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Promotion:{" "}
                      <span>{card?.is_promotion ? "YES" : "NO"}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Status:<span>{capitalize(card?.state)}</span>
                    </h4>
                  </div>
                </div>
              </td>

              <td>
                {/* custom card */}
                <div className="d-flex">
                  <div>
                    <img
                      src="assets/img/message-edit.png"
                      alt=""
                      className="cur-pointer img-fluid"
                      width="40%"
                    />
                  </div>
                  <div>
                    <button
                      onClick={(e) => handleUpdateEgiftCardModal(e, (i = card))}
                    >
                      <img
                        src="assets/img/edit-2.png"
                        alt=""
                        className="cur-pointer img-fluid"
                        width="40%"
                        title="Edit Card"
                      />
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={(e) =>
                        ChangeCardStatus(e, (i = card?.id), (u = card?.state))
                      }
                    >
                      <img
                        src="assets/img/trash.png"
                        alt=""
                        className="cur-pointer img-fluid"
                        width="40%"
                        title="Delete card"
                      />
                    </button>
                  </div>
                </div>
                <div className="mt-4 d-flex card-settings-details">
                  <div>
                    <h4>Created</h4>
                  </div>
                  <div className="line-str"></div>
                  <span>{moment(card?.created_at).format("lll")}</span>
                </div>
              </td>
              {/* <td>
              <div className="d-flex card-settings-details">
                <div className="line-str1"></div>
                <div className="mart">
                  <h4 className="mt-1"> Feature Card</h4>
                </div>
                <div className="mr-3 mart">
                  <label htmlFor={`dd${i}r`} className="switch">
                    <input type="checkbox" id={`dd${i}r`} />
                    <span className="sliderx"></span>
                  </label>
                </div>
              </div>
            </td> */}
            </tr>
          );
        } else {
          var classProp;
          if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "right"
          ) {
            classProp = "card-e-gift-img-top1-r";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "left"
          ) {
            classProp = "card-e-gift-img-top1-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "left"
          ) {
            classProp = "card-e-gift-img-down1-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "right"
          ) {
            classProp = "card-e-gift-img-down1-r";
          }
          return (
            <tr key={i}>
              <td>
                <div className="card-e-gift-img-cover1">
                  <img
                    src={card?.image}
                    alt=""
                    className="card-e-gift-img card-e-gift-img-cover"
                  />
                  <h4
                    style={{ color: card?.default_color }}
                    className={classProp}
                  >
                    GH¢ <span>{parseFloat(card?.amount).toFixed(2)}</span>
                  </h4>
                </div>
              </td>
              <td>
                <div className="d-block card-settings-details">
                  <div>
                    <h4>
                      Card name:<span>{card?.title}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Amount:<span>¢{parseFloat(card?.amount).toFixed(2)}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Category:<span>{card?.card_category}</span>
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div className="d-block card-settings-details">
                  <div>
                    <h4>
                      Tag: <span>{card?.title}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Promotion:{" "}
                      <span>{card?.is_promotion ? "YES" : "NO"}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      Status:<span>{capitalize(card?.state)}</span>
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div className="d-flex card-settings-details">
                  <div>
                    <button
                      onClick={(e) =>
                        handleCouponAssignmentModal(e, (i = card))
                      }
                    >
                      <img
                        src="assets/img/message-edit.png"
                        alt=""
                        className="cur-pointer img-fluid"
                        width="40%"
                      />
                    </button>
                  </div>
                  <div>
                    {/* main card */}
                    <button
                      onClick={(e) => handleUpdateEgiftCardModal(e, (i = card))}
                    >
                      <img
                        src="assets/img/edit-2.png"
                        alt=""
                        className="cur-pointer img-fluid"
                        width="40%"
                        title="Edit card"
                      />
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={(e) =>
                        ChangeCardStatus(e, (i = card?.id), (u = card?.state))
                      }
                    >
                      <img
                        src="assets/img/trash.png"
                        alt=""
                        className="cur-pointer img-fluid"
                        width="40%"
                        title="Delete card"
                      />
                    </button>
                  </div>
                </div>
                <div className="mt-4 d-flex card-settings-details">
                  <div>
                    <h4>Created</h4>
                  </div>
                  <div className="line-str"></div>
                  <span>{moment(card?.created_at).format("lll")}</span>
                </div>
              </td>
              {/* <td>
              <div className="d-flex card-settings-details">
                <div className="line-str1"></div>
                <div className="mart">
                  <h4 className="mt-1"> Feature Card</h4>
                </div>
                <div className="mr-3 mart">
                  <label htmlFor={`dd${i}c`} className="switch" data-toggle="modal" href="#subscribe_modal" >
                    <input type="checkbox" id={`dd${i}c`} />
                    <span className="sliderx"></span>
                  </label>
                </div>
              </div>
            </td> */}
            </tr>
          );
        }
      })
    )
  ) : (
    <div className="no-data1">
      <i className="fas fa-spinner fa-spin mr-2"></i>
    </div>
  );

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <button
            className="back-btn"
            onClick={() => setCurrentDashboardScreen(1)}
          >
            <div className="d-flex">
              <img
                src="/assets/img/previous.png"
                alt=""
                className="img-fluid"
              />
              <h4 className="back-color my-auto ml-2">Back</h4>
            </div>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-table card-tble-height">
            <div className="card-header card-header-margin">
              <h3 className="my-auto">All E-Gift Cards</h3>
            </div>
            <div className="card-body booking_card">
              <div className="table-responsive">
                <table className="datatable table table-stripped table table-hover table-center mb-0">
                  <tbody>{cardList1}</tbody>
                </table>
              </div>
            </div>
            <div className="mr-2">
              <PagePagination
                setTotalPage={setTotalPage}
                totalPage={totalPage}
                setCurrentPage={(e) => setCurrentPage(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EGiftCardsModalPage;
