import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Card from "../dashboardComponents/Card";

const GeneralStats = ({ fund, isLoadingFund }) => {
  const [sum, setSum] = useState(0);
  const [sum1, setSum1] = useState(0);

  useEffect(() => {
    axios
      .get(
        "/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption"
      )
      .then((res) => {
        var sumTemp = res.data.data.transactions.reduce(
          (ps, a) => ps + parseFloat(a.amount),
          0
        );
        setSum(sumTemp);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        "/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_purchase"
      )
      .then((res) => {
        var sumTemp1 = res.data.data.transactions.reduce(
          (ps, a) => ps + parseFloat(a.amount),
          0
        );
        setSum1(sumTemp1);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <>
      <Card
        image="/assets/img/card-tick.png"
        value={fund?.toFixed(2)}
        title="Available Funds"
        subtitle="e-Gift Card"
        background="card board1 fill"
        isLoadingFund={isLoadingFund}
      />
      <Card
        image="/assets/img/wallet.png"
        value={sum.toFixed(2)}
        title="Total Card Redeemed"
        subtitle="e-Gift Card"
        background="card board1 fill2"
        isLoadingFund={isLoadingFund}
      />
      <Card
        image="/assets/img/dollar-circle.png"
        value={sum1.toFixed(2)}
        title="Total Card Purchased"
        subtitle="e-Gift Card"
        background="card board1 fill3"
        isLoadingFund={isLoadingFund}
      />
      <Card
        image="/assets/img/3d-square.png"
        value="100"
        title="Total Deposit"
        subtitle="Marketplace"
        isLoadingFund={isLoadingFund}
        background="card board1 fill4"
      />
    </>
  );
};

export default GeneralStats;
