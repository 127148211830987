import { useState } from "react";

const CreatedCards = ({ cards, loadCards }) => {
  var mainCards = [];
  var customCards = [];
  cards.map((ele, i) => {
    if (ele?.web_design_props?.type?.toLowerCase() === "custom") {
      customCards?.push(ele);
    } else {
      mainCards?.push(ele);
    }
  });

  var classProp;
  mainCards.map((card, i) => {
    if (
      card?.web_design_props?.amount_position[0] === "top" &&
      card?.web_design_props?.amount_position[1] === "right"
    ) {
      classProp = "card-e-gift-img-top1-r";
    } else if (
      card?.web_design_props?.amount_position[0] === "top" &&
      card?.web_design_props?.amount_position[1] === "left"
    ) {
      classProp = "card-e-gift-img-top1-l";
    } else if (
      card?.web_design_props?.amount_position[0] === "bottom" &&
      card?.web_design_props?.amount_position[1] === "left"
    ) {
      classProp = "card-e-gift-img-down1-l";
    } else if (
      card?.web_design_props?.amount_position[0] === "bottom" &&
      card?.web_design_props?.amount_position[1] === "right"
    ) {
      classProp = "card-e-gift-img-down1-r";
    }
  });

  var classProp1;
  var leftLogo;
  var rightLogo;
  var downLogo;
  var topLogo;
  customCards.map((card, i) => {
    rightLogo =
      card?.web_design_props?.logo_position[0] === "center" &&
      card?.web_design_props?.logo_position[1] === "right";
    leftLogo =
      card?.web_design_props?.logo_position[0] === "center" &&
      card?.web_design_props?.logo_position[1] === "left";
    downLogo =
      card?.web_design_props?.logo_position[0] === "bottom" &&
      card?.web_design_props?.logo_position[1] === "right";
    topLogo =
      card?.web_design_props?.logo_position[0] === "top" &&
      card?.web_design_props?.logo_position[1] === "right";
    if (
      card?.web_design_props?.amount_position[0] === "top" &&
      card?.web_design_props?.amount_position[1] === "left" &&
      rightLogo
    ) {
      classProp1 = "card-e-gift-img-top-l";
    } else if (
      card?.web_design_props?.amount_position[0] === "bottom" &&
      card?.web_design_props?.amount_position[1] === "left" &&
      rightLogo
    ) {
      classProp1 = "card-e-gift-img-down-l";
    } else if (
      card?.web_design_props?.amount_position[0] === "top" &&
      card?.web_design_props?.amount_position[1] === "right" &&
      leftLogo
    ) {
      classProp1 = "card-e-gift-img-top-r";
    } else if (
      card?.web_design_props?.amount_position[0] === "bottom" &&
      card?.web_design_props?.amount_position[1] === "right" &&
      leftLogo
    ) {
      classProp1 = "card-e-gift-img-down-r";
    } else if (
      card?.web_design_props?.amount_position[0] === "top" &&
      card?.web_design_props?.amount_position[1] === "left" &&
      downLogo
    ) {
      classProp1 = "card-e-gift-img-top-l";
    } else if (
      card?.web_design_props?.amount_position[0] === "top" &&
      card?.web_design_props?.amount_position[1] === "right" &&
      downLogo
    ) {
      classProp1 = "card-e-gift-img-top-r";
    } else if (
      card?.web_design_props?.amount_position[0] === "bottom" &&
      card?.web_design_props?.amount_position[1] === "left" &&
      topLogo
    ) {
      classProp1 = "card-e-gift-img-down-l";
    } else if (
      card?.web_design_props?.amount_position[0] === "bottom" &&
      card?.web_design_props?.amount_position[1] === "right" &&
      topLogo
    ) {
      classProp1 = "card-e-gift-img-down-r";
    }
  });

  return (
    <>
      <div className="col-xl-3 col-6 col-lg-3 card-mar-b">
        <div className="bg-card2 fill">
          {loadCards ? (
            mainCards.length === 0 ? (
              <div className="no-data">
                <h4>No Main Card</h4>
              </div>
            ) : (
              <>
                <img
                  src={mainCards[0]?.image}
                  alt=""
                  className="img-fluid card-image"
                />
                <h4
                  style={{ color: mainCards[0]?.default_color }}
                  className={classProp}
                >
                  GH¢ <span>{parseFloat(mainCards[0]?.amount).toFixed(2)}</span>
                </h4>
              </>
            )
          ) : (
            <div className="no-data">
              <i className="fas fa-spinner fa-spin mr-2"></i>
            </div>
          )}
        </div>
      </div>
      <div className="col-xl-3 col-6 col-lg-3 card-mar-b">
        <div className="bg-card2 fill2">
          {loadCards ? (
            customCards.length === 0 ? (
              <div className="no-data">
                <h4>No Custom Card</h4>
              </div>
            ) : (
              <>
                {rightLogo && (
                  <div
                    className="right-logo img"
                    style={{
                      color: customCards[0]?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "264px",
                      height: "157px",
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div style={{ width: "194px", position: "relative" }}>
                      <img src="http://placeimg.com/194/157/any" alt="" />
                      <div
                        style={{ color: customCards[0]?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{customCards[0]?.amount}</span>
                        </h4>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: customCards[0]?.default_color,
                        width: "70px",
                        position: "relative",
                      }}
                    >
                      <div className="logo-size-prev">
                        <img
                          src={customCards[0]?.logo}
                          alt="First slide"
                          className="upload-btn-img4"
                        />
                      </div>
                      {customCards[0]?.web_design_props?.show_egift_card && (
                        <div
                          style={{
                            color:
                              customCards[0]?.web_design_props?.egift_color,
                          }}
                        >
                          <h4 className="gift-text-size5">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {leftLogo && (
                  <div
                    className="left-logo img"
                    style={{
                      color: customCards[0]?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "264px",
                      height: "157px",
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div
                      style={{
                        background: customCards[0]?.default_color,
                        width: "70px",
                        position: "relative",
                      }}
                    >
                      <div className="logo-size-prev">
                        <img
                          src={customCards[0]?.logo}
                          alt="First slide"
                          className="upload-btn-img1"
                        />
                      </div>
                      {customCards[0]?.web_design_props?.show_egift_card && (
                        <div
                          style={{
                            color:
                              customCards[0]?.web_design_props?.egift_color,
                          }}
                        >
                          <h4 className="gift-text-size">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                    <div style={{ position: "relative" }}>
                      <img src="http://placeimg.com/194/157/any" alt="" />
                      <div
                        style={{ color: customCards[0]?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{customCards[0]?.amount}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
                {downLogo && (
                  <div
                    className="down-logo img"
                    style={{
                      color: customCards[0]?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "264px",
                      height: "157px",
                      display: "grid",
                      gridTemplateRows: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div style={{ height: "106px", position: "relative" }}>
                      <img src="http://placeimg.com/264/106/any" alt="" />
                      <div
                        style={{ color: customCards[0]?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{customCards[0]?.amount}</span>
                        </h4>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: customCards[0]?.default_color,
                        position: "relative",
                        height: "51px",
                      }}
                    >
                      <div className="logo-size1-prev1">
                        <img
                          src={customCards[0]?.logo}
                          alt="First slide"
                          className="upload-btn-img-bottom"
                        />
                      </div>
                      {customCards[0]?.web_design_props?.show_egift_card && (
                        <div
                          style={{
                            color:
                              customCards[0]?.web_design_props?.egift_color,
                            transition: "ease all 100ms",
                          }}
                        >
                          <h4 className="gift-text-size">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {topLogo && (
                  <div
                    className="up-logo img"
                    style={{
                      color: customCards[0]?.default_color,
                      border: "0.5px solid",
                      borderRadius: "10px",
                      width: "264px",
                      height: "157px",
                      display: "grid",
                      gridTemplateRows: "auto auto",
                      overflow: "hidden",
                      boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                    }}
                  >
                    <div
                      style={{
                        height: "52px",
                        backgroundColor: customCards[0]?.default_color,
                        position: "relative",
                      }}
                    >
                      <div className="logo-size1-prev1">
                        <img
                          src={customCards[0]?.logo}
                          alt="First slide"
                          className="upload-btn-img-bottom"
                        />
                      </div>
                      {customCards[0]?.web_design_props?.show_egift_card && (
                        <div
                          style={{
                            color:
                              customCards[0]?.web_design_props?.egift_color,
                            transition: "ease all 100ms",
                          }}
                        >
                          <h4 className="gift-text-size">e-Gift Card</h4>
                        </div>
                      )}
                    </div>
                    <div style={{ height: "106px", position: "relative" }}>
                      <img src="http://placeimg.com/264/106/any" alt="" />
                      <div
                        style={{ color: customCards[0]?.default_color }}
                        className={classProp1}
                      >
                        <h4>
                          GH¢ <span>{customCards[0]?.amount}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          ) : (
            <div className="no-data">
              <i className="fas fa-spinner fa-spin mr-2"></i>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CreatedCards;
