import { useState, useEffect } from "react";
import axios from "axios";
import { useRef } from "react";
import moment from "moment";
import PagePagination from "../../../components/PagePagination";
import { ExportToExcel } from "../../../components/ExportToExcel";
const CardRedemptions = () => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  const [cardRedemptionTable, setCardRedemptionTable] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [isexporting, setExporting] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState([]);
  const fileName = "Card Redemptions";

  const [sum, setSum] = useState(0);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const fetchRedemption = (e) => {
    setDisabled(false);
    e.preventDefault();
    let url = "";
    if (startDateRef.current.value && endDateRef.current.value) {
      url = `/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption&limit=10&page=${currentPage}&from=${startDateRef.current.value}&to=${endDateRef.current.value}`;
    } else {
      url = `/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption&limit=10&page=${currentPage}`;
    }
    setLoading(false);
    axios
      .get(url)
      .then((res) => {
        setLoading(true);
        setCardRedemptionTable(res.data.data.transactions);
        var temp = res.data.data.transactions;
        var sumTemp = temp.reduce((ps, a) => ps + parseFloat(a.amount), 0);
        setSum(sumTemp);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    let url = "";
    if (startDateRef.current.value && endDateRef.current.value) {
      url = `/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption&limit=10&page=${currentPage}&from=${startDateRef.current.value}&to=${endDateRef.current.value}`;
    } else {
      url = `/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption&limit=10&page=${currentPage}`;
    }
    setLoading(false);
    axios
      .get(url)
      .then((res) => {
        setLoading(true);
        setCardRedemptionTable(res.data.data.transactions);
        setTotalPage(res.data.data.total_pages);
        var temp = res.data.data.transactions;
        var sumTemp = temp.reduce((ps, a) => ps + parseFloat(a.amount), 0);
        setSum(sumTemp);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  }, [currentPage]);

  const clearSearchRedemption = () => {
    setDisabled(true);
    if (startDateRef.current.value && endDateRef.current.value) {
      startDateRef.current.value = "";
      endDateRef.current.value = "";
      let url = `/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption&limit=10&page=${currentPage}`;
      setLoading(false);
      axios
        .get(url)
        .then((res) => {
          setLoading(true);
          setCardRedemptionTable(res.data.data.transactions);
          var temp = res.data.data.transactions;
          var sumTemp = temp.reduce((ps, a) => ps + parseFloat(a.amount), 0);
          setSum(sumTemp);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
        });
    }
  };

  const exportFilteredData = () => {
    setExporting(true);
    axios
      .get(
        `/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption&limit=500000&from=${startDateRef.current.value}&to=${endDateRef.current.value}`
      )
      .then((res) => {
        setTotalRecords(res.data.data.total_records);
        const customHeadings = res.data.data.transactions.map((item) => ({
          "Card ID": item?.card.id,
          Customer: item?.person?.first_name,
          Merchant: item?.merchant,
          "Amount": parseFloat(item?.amount).toFixed(2),
          Date: moment(item?.created_at).format("LLL"),
          status: item?.status?.toLowerCase(),
        }));
        var temp = res.data.data.transactions;
        var sumTemp = temp.reduce((ps, a) => ps + parseFloat(a.amount), 0);
        setSum(sumTemp);
        setData(customHeadings);
        setExporting(false);
      })
      .catch((err) => {
        setExporting(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    exportFilteredData()
  }, []);


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card card-chart2 card-h10 card-h10-pad">
          <div className="d-flex justify-content-between">
            <h3>Total Redemptions</h3>
            <h3 className="mr-3">Search Total Redemptions</h3>
          </div>
          <hr className="hr3" />
          <div className="d-flex justify-content-between mt-d-flex">
            <div className="disbursement-width mt-d-flex1">
              <img
                src="assets/img/disbursement.png"
                alt=""
                className="img-fluid"
              />
              <h3 className="figure-size">¢ {sum.toFixed(2)}</h3>
            </div>
            <form action="" onSubmit={fetchRedemption}>
              <div className="d-block">
                <div className="d-flex justify-content-between mt-2 mb-4">
                  <div>
                    <div className="my-auto">
                      <h4 className="my-auto">From</h4>
                    </div>
                    <div className="form-group my-auto">
                      <input
                        className="form-control"
                        type="date"
                        required
                        ref={startDateRef}
                      />
                    </div>
                  </div>
                  <div className="ml-3">
                    <div className="my-auto">
                      <h4 className="my-auto">To</h4>
                    </div>
                    <div className="form-group my-auto">
                      <input
                        className="form-control"
                        type="date"
                        required
                        ref={endDateRef}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    type="reset"
                    name=""
                    id=""
                    value="Reset"
                    className="btn btn-success float-right ml-4"
                    onClick={clearSearchRedemption}
                  />
                  <input
                    type="submit"
                    name=""
                    id=""
                    value="Search"
                    className="btn btn-outline-success float-right"
                    onClick={exportFilteredData}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="card card-table card-table-height1">
          <div className="card-header card-header-margin d-flex justify-content-between">
            <div>
              <h3 className="my-auto">Card Redemptions Transactions</h3>
            </div>
            <div className="form-group">
              <input
                className="form-control search-textbox22"
                type="text"
                placeholder="Search here..."
                // onChange={(e) => setQuery(e.target.value)}
              />
              <img
                src="assets/img/search-normal1.png"
                alt=""
                className="icon-position-1"
              />
            </div>
            <div>
              <ExportToExcel
                apiData={data}
                fileName={fileName}
                isexporting={isexporting}
                setExporting={setExporting}
                disabled={disabled}
                exportFilteredData={exportFilteredData}
                totalRecords={totalRecords}
              />
            </div>
          </div>
          <div className="card-body booking_card">
            <div className="table-responsive">
              <table className="datatable table table-stripped table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th>Card ID</th>
                    <th>Customer</th>
                    <th>Merchant</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isloading ? (
                    <>
                      {cardRedemptionTable?.length === 0 ? (
                        <div className="no-data">
                          <h1> No Transaction Available</h1>
                        </div>
                      ) : (
                        cardRedemptionTable?.map((ele, i) => {
                          return (
                            <tr>
                              <td>{ele?.card.id}</td>

                              <td>
                                {capitalize(ele?.person?.first_name)
                                  ? ele?.person?.first_name
                                  : "N/A"}
                              </td>
                              <td>{capitalize(ele?.merchant)}</td>
                              <td>¢{parseFloat(ele?.amount).toFixed(2)}</td>
                              <td> {moment(ele?.created_at).format("LLL")}</td>
                              <td>
                                {ele?.status?.toLowerCase() === "paid" && (
                                  <span class="btn btn-sm bg-success-light mr-2">
                                    Paid
                                  </span>
                                )}
                                {ele?.status?.toLowerCase() === "failed" && (
                                  <span class="btn btn-sm bg-danger-light mr-2">
                                    Failed
                                  </span>
                                )}
                                {ele?.status?.toLowerCase() ===
                                  "pending" && (
                                  <span class="btn btn-sm bg-warning-light mr-2">
                                    Pending
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </>
                  ) : (
                    <div className="no-data">
                      <i className="fas fa-spinner fa-spin mr-2"></i>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mr-2">
            <PagePagination
              setTotalPage={setTotalPage}
              totalPage={totalPage}
              setCurrentPage={(e) => setCurrentPage(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardRedemptions;
