import React, { useState } from "react";
import "../slick-1.8.1/slick/slick";
import "../slick-1.8.1/slick/slick-theme.css";
import "../slick-1.8.1/slick/slick.css";
import CustomCard from "./modalPageComponents/CustomCard";
import MainCard from "./modalPageComponents/MainCard";

const ModalPage = () => {
  const [current, setCurrent] = useState(1);
  const [current1, setCurrent1] = useState(1);
  return (
    <>
      <div
        className="modal fade pt-5"
        id="upload_e-gift_card"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Upload E-gift card</h3>
              <img
                src="assets/img/close-btn.png"
                alt=""
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {current === 1 ? (
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="btn btn-outline-success px-lg-4 px-sm-3 mr-3 active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Main
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="btn btn-outline-success px-lg-4 px-sm-3"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Custom
                    </a>
                  </li>
                </ul>
              ) : current1 === 1 ? (
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="btn btn-outline-success px-lg-4 px-sm-3 mr-3"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="false"
                    >
                      Main
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="btn btn-outline-success px-lg-4 px-sm-3 active"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="true"
                    >
                      Custom
                    </a>
                  </li>
                </ul>
              ):(
                <ul
                  className="nav nav-pills"
                  id="pills-tab"
                  role="tablist"
                  style={{
                    pointerEvents: "none !important",
                    cursor: "default",
                  }}
                >
                  <li className="nav-item" role="presentation">
                    <div className="btn btn-outline-success px-lg-4 px-sm-3 mr-3 active">
                      Main
                    </div>
                  </li>
                  <li className="nav-item" role="presentation">
                    <div className="btn btn-outline-success px-lg-4 px-sm-3">
                      Custom
                    </div>
                  </li>
                </ul>
              )}

              <div className="tab-content" id="pills-tabContent">
                <MainCard current={current} setCurrent={setCurrent} />
                <CustomCard current1={current1} setCurrent1={setCurrent1} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalPage;
