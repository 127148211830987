import { useState } from "react";
import { Icon } from "react-icons-kit"
import {eyeOff} from 'react-icons-kit/feather/eyeOff'
import {eye} from 'react-icons-kit/feather/eye'
import axios from "axios";
import swal from 'sweetalert'
const CredentialForm = () => {

  const [type, setType] = useState('password');
  const [type1, setType1] = useState('password');
  const [type2, setType2] = useState('password');
  const [type3, setType3] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [icon1, setIcon1] = useState(eyeOff);
  const [icon2, setIcon2] = useState(eyeOff);
  const [icon3, setIcon3] = useState(eyeOff);

  const handleToggle =()=>{
    if(type==='password'){
      setIcon(eye);
      setType('text');
    }
    else{
      setIcon(eyeOff)
      setType('password')
    }
  }
  const handleToggle1 =()=>{
    if(type1==='password'){
      setIcon1(eye);
      setType1('text');
    }
    else{
      setIcon1(eyeOff)
      setType1('password')
    }
  }
  const handleToggle2 =()=>{
    if(type2==='password'){
      setIcon2(eye);
      setType2('text');
    }
    else{
      setIcon2(eyeOff)
      setType2('password')
    }
  }
  const handleToggle3 =()=>{
    if(type3==='password'){
      setIcon3(eye);
      setType3('text');
    }
    else{
      setIcon3(eyeOff)
      setType3('password')
    }
  }



  const [passwordInput,setPasswordInput]=useState({
    current_password:'',
    new_password:'',
    new_password_confirmation:''
  })

  const handlePasswordChange=(e)=>{
    setPasswordInput(passwordInput=>({
      ...passwordInput,[e.target.name]:e.target.value
    }))
  }
  const [pinInput,setPinInput]=useState({
    password:'',
    new_pin:'',
    new_pin_confirmation:''
  })

  const handlePinChange=(e)=>{
    setPinInput(pinInput=>({
      ...pinInput,[e.target.name]:e.target.value
    }))
  }

  const handlePasswordSubmit=(e)=>{
    e.preventDefault();
    axios.post(`/prime.sika/v1/accounts/merchant/profile/change-password?company_code=${localStorage.getItem('company_code')}`,passwordInput).then(res=>{
      if(res.data.status===201 && res.data.success===true){
        swal({
          title: "Success",
          text: res.data.data.message,
          icon: "success",
          button: "OK",
        });
      }
    }).catch(err=>{
      console.log(err.response);
      if(err.response.data.status===400){
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
      }
    })
  }
  const handlePinSubmit=(e)=>{
    e.preventDefault();
    axios.post(`/prime.sika/v1/accounts/merchant/profile/change-pin?company_code=${localStorage.getItem('company_code')}`,pinInput).then(res=>{
      if(res.data.status===201 && res.data.success===true){
        swal({
          title: "Success",
          text: res.data.data.message,
          icon: "success",
          button: "OK",
        });
      }
    }).catch(err=>{
      console.log(err.response);
      if(err.response.data.status===400){
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
      }
    })
  }


  return (
    <>
    <form className="card card-chart2 card-h11 card-h10-pad" onSubmit={handlePasswordSubmit}>
      <div>
        <h3>Change Password</h3>
        <hr className="hr3" />
      </div>
      <div className="ml-4">
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">Current Password</h4>
            </label>
          </div>
          {/* <CurrentPassword/> */}
          <div className="form-box-width1">
            <input
              type={type}
              className="form-control form-fill-textbox1"
              name="current_password"
              value={passwordInput.current_password}
              onChange={handlePasswordChange}
            />
            <span onClick={handleToggle}><Icon icon={icon} size={18} className="view-password-icon1"/></span>
          </div>
        </div>
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">New Password</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <input
              type={type1}
              className="form-control form-fill-textbox1"
              name="new_password"
              value={passwordInput.new_password}
              onChange={handlePasswordChange}
            />
            <span onClick={handleToggle1}><Icon icon={icon1} size={18} className="view-password-icon1"/></span>
          </div>
        </div>
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">Confirm New Password</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <input
              type={type2}
              className="form-control form-fill-textbox1"
              name="new_password_confirmation"
              value={passwordInput.new_password_confirmation}
              onChange={handlePasswordChange}
            />
            <span onClick={handleToggle2}><Icon icon={icon2} size={18} className="view-password-icon1"/></span>
          </div>
        </div>
        <div className="mt-5">
          <button
            type="submit"
            className="btn btn-success float-right save-btn-size"
            value="Save"
          >
            Save
          </button>
        </div>
      </div>
    </form>

    
    <form className="card card-chart2 card-h11 card-h10-pad" onSubmit={handlePinSubmit}>
      <div className="mt-5">
        <h3>Change Pin</h3>
        <hr className="hr3" />
      </div>
      <div className="ml-4">
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">Current Password</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <input
              type={type3}
              className="form-control form-fill-textbox1"
              name="password"
              value={pinInput.password}
              onChange={handlePinChange}
            />
            <span onClick={handleToggle3}><Icon icon={icon3} size={18} className="view-password-icon1"/></span>
          </div>
        </div>
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">New Pin</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <input
              type="number"
              className="form-control form-fill-textbox1"
              name="new_pin"
              value={pinInput.new_pin}
              onChange={handlePinChange}
            />
          </div>
        </div>
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">Confirm Pin</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <input
              type="number"
              className="form-control form-fill-textbox1"
              name="new_pin_confirmation"
              value={pinInput.new_pin_confirmation}
              onChange={handlePinChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <input
          type="submit"
          className="btn btn-success float-right save-btn-size"
          value="Save"
        />
      </div>
    </form>
    </>
  );
};
export default CredentialForm;
