import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import OtpField from "react-otp-field";
import swal from "sweetalert";

const SettingsAuthModal = ({
  handleSettingsAuthModalClose,
  showSettingsAuthModal,
  setShowSettingsAuthModal,
}) => {
  const [verifycode, setVerifyCode] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    axios
      .get("/prime.sika/v1/managers/merchants/otps")
      .then((res) => {
        setMsg(res.data.data.message);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const ResendCode = () => {
    axios
      .get("/prime.sika/v1/managers/merchants/otps")
      .then((res) => {
        swal({
          title: "Success",
          text: res.data.data.message,
          icon: "success",
          button: "OK",
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const handleValidation = (e) => {
    setSubmitLoading(true);
    if (verifycode.length !== 6) {
      setSubmitLoading(false);
      setErrorMessage("Invalid Verification Code Entered");
    } else {
      setSubmitLoading(true);
      axios
        .post("/prime.sika/v1/managers/merchants/otps", {
          code: verifycode,
        })
        .then((res) => {
          setSubmitLoading(false);
          setShowSettingsAuthModal(false);
        })
        .catch((err) => {
          setSubmitLoading(false);
          console.log(err.response.data);
          setErrorMessage(err.response.data.error_msg);
        });
    }
  };

  return (
    <Modal show={showSettingsAuthModal} onHide={handleSettingsAuthModalClose}>
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4>Two Factor Authentication</h4>
          </div>
          <div>
            <button onClick={handleSettingsAuthModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="mb-3">
            <h4>OTP sent to your number</h4>
          </div>
          <div className="otp-flex">
            <OtpField
              value={verifycode}
              onChange={setVerifyCode}
              numInputs={6}
              onChangeRegex={/^([0-9]{0,})$/}
              autoFocus
              isTypeNumber
              inputProps={{
                className: "form-control number-textbox1",
                disabled: false,
              }}
            />
            <small className={errorMessage ? "text-danger" : "text-success"}>
              {errorMessage ? errorMessage : msg}
            </small>
          </div>
        </div>
        <div className="mt-3">
          <h4 className="text-center">
            Didn't Receive OTP?{" "}
            <span className="text-success cur-pointer" onClick={ResendCode}>
              Resend
            </span>
          </h4>
        </div>
        <div className="mt-3">
          {!submitLoading && (
            <button
              type="button"
              className="btn btn-success w-100"
              onClick={() => handleValidation()}
            >
              Submit
            </button>
          )}
          {submitLoading && (
            <button className="btn btn-success w-100" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>submitting...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SettingsAuthModal;
