import AddNewUserForm from "./managementinfoComponent/AddNewUserForm";
import ListOfUsers from "./managementinfoComponent/ListOfUsers";

const SettingsInfo = ({
  userInput,
  handleUserInput,
  setlocation,
  location,
  saveData,
  setPermissions,
  setReports,
  setManageAccount,
  setManageGiftCards,
  setCreatePromotionsOrDeals,
  setSetUpPaymentAccount,
  setTransactionsAndPayouts,
  reports,
  manageAccount,
  manageGiftCards,
  createPromotionsOrDeals,
  paymentAccount,
  transactionPayout,
  isloading,
  setLoading
}) => {

  return (
    <>
      <div
        className="card card-back tab-pane fade"
        id="pills-settings"
        role="tabpanel"
        aria-labelledby="pills-settings-tab"
      >
        <div className="row">
          <div className="col-lg-3">
            <div className="card card-chart2 z-position card-h15">
              <div className="px-lg-2 px-sm-3 pt-4">
                <h3>Actions</h3>
              </div>
              <ul
                className="nav d-block sidetab-font"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className="btn btn-outline-success tab-btn px-lg-2 px-sm-3 active"
                    id="pills-add-new-user-tab"
                    data-toggle="pill"
                    href="#pills-add-new-user"
                    role="tab"
                    aria-controls="pills-add-new-user"
                    aria-selected="true"
                  >
                    Add New User
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="btn btn-outline-success tab-btn px-lg-2 px-sm-3"
                    id="pills-list-of-users-tab"
                    data-toggle="pill"
                    href="#pills-list-of-users"
                    role="tab"
                    aria-controls="pills-list-of-users"
                    aria-selected="false"
                  >
                    List of Users
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="card card-chart2 card-h12 card-h15 card-h12-pad">
              <div>
                <h3>User Management</h3>
                <hr className="hr3" />
              </div>
              <div>
                <h4>Add users and assign privileges to them</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-management"
                role="tabpanel"
                aria-labelledby="pills-management-tab"
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content" id="pills-tabContent">
                      <AddNewUserForm
                        userInput={userInput}
                        handleUserInput={handleUserInput}
                        location1={location}
                        setlocation1={setlocation}
                        saveData={saveData}
                        setPermissions={setPermissions}
                        setReports={setReports}
                        setManageAccount={setManageAccount}
                        setManageGiftCards={setManageGiftCards}
                        setCreatePromotionsOrDeals={setCreatePromotionsOrDeals}
                        setSetUpPaymentAccount={setSetUpPaymentAccount}
                        setTransactionsAndPayouts={setTransactionsAndPayouts}
                        reports={reports}
                        manageAccount={manageAccount}
                        manageGiftCards={manageGiftCards}
                        createPromotionsOrDeals={createPromotionsOrDeals}
                        paymentAccount={paymentAccount}
                        transactionPayout={transactionPayout}
                        isloading={isloading}
                        setLoading={setLoading}
                      />
                      <ListOfUsers />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SettingsInfo;
