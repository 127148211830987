import axios from "axios";
import React, { useState, useEffect } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import EGiftCards from "./dashboardComponents/EGiftCards";
import EGiftCardsModalPage from "./EGiftCardsModalPage";
import CustomersData from "./merchantComponents/CustomersData";
import GeneralStats from "./merchantComponents/GeneralStats";
import Subscribe from "./Subscribe";
import { Doughnut } from "react-chartjs-2";
import MonthlyTotalCharts from "./dashboardComponents/Charts/MonthlyTotalCharts";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  ArcElement,
} from "chart.js";
import UpdateEgiftCardModal from "./dashboardComponents/UpdateEgiftCardModal";
import CouponAssignmentModal from "./dashboardComponents/CouponAssignmentModal";
import { useLocation } from "react-router-dom";
import OrdersTable from "./dashboardComponents/OrdersTable";
import MarketProductStockTable from "./marketplaceComponents/MarketProductStockTable";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  ArcElement
);
const Dashboard = () => {
  const [currentDashboardScreen, setCurrentDashboardScreen] = useState(1);
  const [showUpdateEgiftCardModal, setShowUpdateEgiftCardModal] =
    useState(false);
  const [showCouponAssignmentModal, setShowCouponAssignmentModal] =
    useState(false);
  const [cardItem, setCardItem] = useState([]);
  const [current1, setCurrent1] = useState(1);
  const [current, setCurrent] = useState(1);

  const [dataChart, setDataChart] = useState(null);
  const location = useLocation();

  const handleUpdateEgiftCardModal = (e, card) => {
    setCardItem(card);
    setShowUpdateEgiftCardModal(true);
  };
  const handleCouponAssignmentModal = (e, card) => {
    setCardItem(card);
    setShowCouponAssignmentModal(true);
  };
  const handleCouponAssignmentModalClose = () => {
    setShowCouponAssignmentModal(false);
  };
  const handleUpdateEgiftCardModalClose = () => {
    setShowUpdateEgiftCardModal(false);
  };

  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [cards, setCards] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [isDoughnutloading, setDoughnutLoading] = useState(false);
  const [isLoadingFund, setLoadingFund] = useState(false);
  const [new_users, setUsers] = useState(0);
  const [total_sales, setSales] = useState(0);
  const [in_store_card_use, setCardUse] = useState(0);
  const [cashed_out, setCashedOut] = useState(0);
  const [stats, setStats] = useState([]);
  const [fund, setFund] = useState(0);
  const [products, setProducts] = useState([]);

  const loadStats = () => {
    setLoadingFund(false);

    axios
      .get("/prime.sika/v1/merchants/dashboard/general-stats")
      .then((res) => {
        setLoadingFund(true);
        setStats(res.data.data);
        setUsers(stats["new_users"]["count"]);
        setSales(stats["total_sales"]["count"]);
        setCardUse(stats["in_store_card_use"]["count"]);
        setCashedOut(stats["cashed_out"]["count"]);
      })
      .catch((err) => {
        setLoadingFund(true);
        console.log(err);
      });
  };
  useEffect(() => {
    setLoading(false);
    axios
      .get(`/market.place/v1/products/list?limit=10`)
      .then((res) => {
        setLoading(true);
        setProducts(res.data.data.products);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  }, []);
  useEffect(() => {
    loadStats();
  }, []);
  const FetchCardDetails = () => {
    setLoading(false);
    axios
      .get(`/prime.sika/v1/cards/list?limit=10&page=${currentPage}`)
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setLoading(true);
          setTotalPage(res.data.data.total_pages);
          setCards(res.data.data.cards);
        }
      })
      .catch((err) => {
        setLoading(true);
        console.log(err.response);
      });
  };
  useEffect(() => {
    FetchCardDetails();
  }, [currentPage]);

  const fetchAvailableFunds = () => {
    setLoadingFund(false);
    axios
      .get("/prime.sika/v1/merchants/dashboard/available-funds")
      .then((res) => {
        setLoadingFund(true);
        if (res.data.status === 200) {
          setFund(res.data.data.available_funds);
        }
      })
      .catch((err) => {
        setLoadingFund(true);
        console.log(err.response);
      });
  };

  useEffect(() => {
    fetchAvailableFunds();
  }, []);

  const DoneUpdating = () => {
    FetchCardDetails();
    setCurrent1(1);
    setCurrent(1);
    handleUpdateEgiftCardModalClose();
  };

  const fetchSalesOverview = () => {
    axios
      .get("/prime.sika/v1/merchants/dashboard/sales-overview/cards")
      .then((res) => {
        if (res.data.status === 200) {
          setDataChart(res.data.data.cards);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  useEffect(() => {
    fetchSalesOverview();
  }, []);

  const data1 = {
    labels: ["Red", "Blue", "Green"],
    datasets: [
      {
        label: "My First Dataset",
        data: [300, 50, 79],
        backgroundColor: ["#338540", "#E8B910", "#E1B088"],
        hoverOffset: 4,
      },
    ],
  };

  const SearchStats = (e) => {
    var searchDate = e;
    const date = new Date(searchDate);
    const month = date
      .toLocaleString("en-US", { month: "short" })
      .toLocaleLowerCase();
    const year = date.getFullYear();
    if (searchDate === "") {
      fetchAvailableFunds();
      fetchSalesOverview();
      fetchOverviewSummary();
      fetchMonthlyTotalsCardPurchase();
    } else {
      setLoadingFund(false);
      axios
        .get(
          `/prime.sika/v1/merchants/dashboard/available-funds?month=${month}&year=${year}`
        )
        .then((res) => {
          setLoadingFund(true);
          if (res.data.status === 200) {
            setFund(res.data.data.available_funds);
          }
        })
        .catch((err) => {
          setLoadingFund(true);
          console.log(err.response);
        });

      setDoughnutLoading(false);
      axios
        .get(
          `/prime.sika/v1/merchants/dashboard/sales-overview/summary?month=${month}&year=${year}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setDoughnutLoading(true);
            setOverview(res.data.data.total_sales);
          }
        })
        .catch((err) => {
          console.log(err.response);
          setDoughnutLoading(true);
        });

      axios
        .get(
          `/prime.sika/v1/merchants/dashboard/sales-overview/cards?month=${month}&year=${year}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setDataChart(res.data.data.cards);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });

      setChatLoading(false);
      axios
        .get(
          `/prime.sika/v1/merchants/dashboard/monthly-totals?for=card_purchase&month=${month}&year=${year}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setChatLoading(true);
            setTotalSales(res.data.data.totals);
          }
        })
        .catch((err) => {
          setChatLoading(false);
          console.log(err.response);
        });
    }
  };

  const options = () => {};

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.font = "bolder 15px sans-serif";
      ctx.fillStyle = "red";
      ctx.textAlign = "center";
      ctx.fillText(
        `Value: ${data.datasets[0].data[0]}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };
  const [overview, setOverview] = useState(0);

  const fetchOverviewSummary = () => {
    setDoughnutLoading(false);
    axios
      .get("/prime.sika/v1/merchants/dashboard/sales-overview/summary")
      .then((res) => {
        if (res.data.status === 200) {
          setDoughnutLoading(true);
          setOverview(res.data.data.total_sales);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setDoughnutLoading(true);
      });
  };
  useEffect(() => {
    fetchOverviewSummary();
  }, []);

  const [totalSales, setTotalSales] = useState([]);
  const [isChatLoading, setChatLoading] = useState(false);

  const fetchMonthlyTotalsCardPurchase = () => {
    setChatLoading(false);
    axios
      .get(
        "/prime.sika/v1/merchants/dashboard/monthly-totals?for=card_purchase"
      )
      .then((res) => {
        if (res.data.status === 200) {
          setChatLoading(true);
          setTotalSales(res.data.data.totals);
        }
      })
      .catch((err) => {
        setChatLoading(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    fetchMonthlyTotalsCardPurchase();
  }, []);

  return (
    <>
      <DashboardLayout>
        {currentDashboardScreen === 1 && (
          <>
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <GeneralStats fund={fund} isLoadingFund={isLoadingFund} />
                  <div className="col-lg-12">
                    <div className="d-flex float-right z-position mb-3">
                      <h4 className="my-auto mr-3">Month/Year</h4>
                      <input
                        type="month"
                        className="form-control search-cover"
                        onChange={(e) => SearchStats(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card card-chart z-position card-height">
                      <MonthlyTotalCharts
                        title="Monthly Total Sales"
                        aspect={2 / 1}
                        totalSales={totalSales}
                        isChatLoading={isChatLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-chart z-position card-height-1 d-flex">
                      {isDoughnutloading ? (
                        <>
                          <div className="d-flex justify-content-between pt-3">
                            <div style={{ width: "250px" }}>
                              <Doughnut
                                data={data1}
                                options={options}
                                plugins={[textCenter]}
                              ></Doughnut>
                            </div>
                            <div className="d-block sales-font">
                              <div className="mr-4">
                                <h5>Total Sales for this year</h5>
                                <h4>{overview?.year?.current}</h4>
                              </div>
                              <div className="mr-4">
                                <h5>Last Year Sales</h5>
                                <h4>{overview?.year?.previous}</h4>
                              </div>
                              <div className="float-right mr-3 mt-5">
                                <h3>
                                  {overview?.year?.comparison_percentage}%
                                </h3>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="chart-h">
                          <div className="no-data">
                            <i className="fas fa-spinner fa-spin mr-2"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <EGiftCards
                      currentDashboardScreen={currentDashboardScreen}
                      setCurrentDashboardScreen={setCurrentDashboardScreen}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-5">
                <CustomersData location={location}/>
              </div>
              <div className="col-md-12 col-lg-7">
                <MarketProductStockTable location={location} products={products} setProducts={setProducts} isloading={isloading}/>
              </div>
            </div>
            <OrdersTable location={location} />
          </>
        )}
        {currentDashboardScreen === 2 && (
          <EGiftCardsModalPage
            handleUpdateEgiftCardModal={handleUpdateEgiftCardModal}
            currentDashboardScreen={currentDashboardScreen}
            setCurrentDashboardScreen={setCurrentDashboardScreen}
            handleCouponAssignmentModal={handleCouponAssignmentModal}
            totalPage={totalPage}
            setTotalPage={setTotalPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            cards={cards}
            setCards={setCards}
            FetchCardDetails={() => FetchCardDetails()}
            isloading={isloading}
            setLoading={setLoading}
          />
        )}
      </DashboardLayout>
      <UpdateEgiftCardModal
        handleUpdateEgiftCardModalClose={handleUpdateEgiftCardModalClose}
        showUpdateEgiftCardModal={showUpdateEgiftCardModal}
        cardItem={cardItem}
        setCurrentDashboardScreen={setCurrentDashboardScreen}
        DoneUpdating={DoneUpdating}
        current1={current1}
        setCurrent1={setCurrent1}
        setCurrent={setCurrent}
        current={current}
      />
      <CouponAssignmentModal
        handleCouponAssignmentModalClose={handleCouponAssignmentModalClose}
        setShowCouponAssignmentModal={setShowCouponAssignmentModal}
        showCouponAssignmentModal={showCouponAssignmentModal}
        cardItem={cardItem}
      />
      <Subscribe />
    </>
  );
};
export default Dashboard;
