import { useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
const NewPasswordForm = ({ userInput, handleUserInput }) => {

    const navigate = useNavigate();

    const [type, setType] = useState("password");
    const [type1, setType1] = useState("password");
    const [icon, setIcon] = useState(eyeOff);
    const [icon1, setIcon1] = useState(eyeOff);

    const handleToggle = () => {
        if (type === "password") {
            setIcon(eye);
            setType("text");
        } else {
            setIcon(eyeOff);
            setType("password");
        }
    };
    const handleToggle1 = () => {
        if (type1 === "password") {
            setIcon1(eye);
            setType1("text");
        } else {
            setIcon1(eyeOff);
            setType1("password");
        }
    };

    const [errors, setError] = useState({
        password: "",
        password_confirmation: ""
    });

    const handleOnChange = (e) => {
        setError((error) => ({
            ...error,
            [e.target.name]: ""
        }));
        handleUserInput(e);
    };

    const [isProcessing, setProcessing] = useState(false);


    const submitForm = (e) => {
        setProcessing(true);
        var password = userInput.password;
        e.preventDefault();
        if (userInput.password !== userInput.password_confirmation) {
            setError((error) => ({
                ...error,
                password: "Passwords do not match"
            }));
            setProcessing(false);
        }
        else {
            Swal.fire({
                title: "Confirm Password Reset",
                text: "Are you sure you want to proceed with Password Reset?",
                icon: "warning",
                showDenyButton: false,
                // showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonColor: "#00b17d",
                denyButtonText: `No`
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const data = {
                        password: userInput.password,
                        token: localStorage.getItem("new_code")
                    };

                    axios
                        .post("prime.sika/v1/users/reset-password", data)
                        .then((res) => {
                            navigate("/login")
                            if (res.data.success === true && res.data.status === 201) {
                                Swal.fire({
                                    title: "success",
                                    text: "Password successfully changed. You can now login with your new password",
                                    icon: "success",
                                    showDenyButton: false,
                                    showCancelButton: false,
                                    confirmButtonText: "Okay",
                                    confirmButtonColor: "#00b17d",
                                    denyButtonText: `No`
                                }).then((result) => {
                                    navigate("/")
                                })
                            }
                        })
                        .catch((err) => {
                            console.log(err.response);
                            if (err.response.status === 400) {
                                Swal.fire({
                                    title: "Error",
                                    text: err.response.data.error_msg,
                                    icon: "error",
                                    button: "OK",
                                    confirmButtonColor: "#00b17d",

                                });
                            }
                        });
                }
            });

        }
    };

    return (
        <>
            <div className="mb-5">
                <Link to="/">
                    <img src="/assets/img/arrow-left.png" alt="" />
                </Link>
            </div>
            <div>
                <h3 className="text-left">New Password Creation</h3>
            </div>
            <div>
                <h4>Enter new password and verify new password</h4>
            </div>
            <form>
                <div className="form-group mt-4">
                    <div className="eye-pos">
                        <input
                            type={type}
                            className="form-control textbx-size1"
                            placeholder="Enter your password"
                            value={userInput.password}
                            name="password"
                            onChange={handleOnChange}
                            required
                        />
                        <span onClick={handleToggle}>
                            <Icon
                                icon={icon}
                                size={18}
                                className="view-password-icon1"
                            />
                        </span>
                    </div>
                    <small className="text-danger">{errors.password}</small>
                </div>
                <div className="form-group mt-4">
                    <div className="eye-pos">
                        <input
                            type={type1}
                            className="form-control textbx-size1"
                            placeholder="Verify Password"
                            value={userInput.password_confirmation}
                            name="password_confirmation"
                            onChange={handleOnChange}
                            required
                        />
                        <span onClick={handleToggle1}>
                            <Icon
                                icon={icon1}
                                size={18}
                                className="view-password-icon1"
                            />
                        </span>
                    </div>
                </div>
                <button
                    type="button"
                    className="btn btn-success log-in-btn-size mt-4"
                    onClick={submitForm}
                >
                    Continue
                </button>
            </form>
        </>
    )
}
export default NewPasswordForm