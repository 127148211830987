import Slider from "react-slick";
import { useEffect, useState } from "react";
import axios from "axios";
const EGiftCards = ({ setCurrentDashboardScreen }) => {
  const settings = {
    dots: true,
    infinite: true,
    variableWidth: true,
    autoplay: true,
    nextArrow: (
      <img
        src="assets/img/arrow.png"
        alt=""
        className="arrow-position1 slick-next"
      />
    ),
  };
  const [cards, setCards] = useState([]);
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    axios
      .get("/prime.sika/v1/cards/list")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCards(res.data.data.cards);
          setLoading(true);
        }
      })
      .catch((err) => {
        setLoading(true);
        console.log(err.response);
      });
  }, []);

  var cardList1 = isloading ? (
    cards.length === 0 ? (
      <h1 className="mt-5 ml-5">No Available Cards</h1>
    ) : (
      cards.map((card) => {
        if (card?.web_design_props.type === "custom") {
          var rightLogo =
            card?.web_design_props?.logo_position[0] === "center" &&
            card?.web_design_props?.logo_position[1] === "right";
          var leftLogo =
            card?.web_design_props?.logo_position[0] === "center" &&
            card?.web_design_props?.logo_position[1] === "left";
          var downLogo =
            card?.web_design_props?.logo_position[0] === "bottom" &&
            card?.web_design_props?.logo_position[1] === "right";
          var topLogo =
            card?.web_design_props?.logo_position[0] === "top" &&
            card?.web_design_props?.logo_position[1] === "right";
          var classProp1;
          if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            rightLogo
          ) {
            classProp1 = "card-e-gift-img-top-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            rightLogo
          ) {
            classProp1 = "card-e-gift-img-down-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            leftLogo
          ) {
            classProp1 = "card-e-gift-img-top-r";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            leftLogo
          ) {
            classProp1 = "card-e-gift-img-down-r";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            downLogo
          ) {
            classProp1 = "card-e-gift-img-top-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            downLogo
          ) {
            classProp1 = "card-e-gift-img-top-r";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "left" &&
            topLogo
          ) {
            classProp1 = "card-e-gift-img-down-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "right" &&
            topLogo
          ) {
            classProp1 = "card-e-gift-img-down-r";
          }
          return (
            <>
              {rightLogo && (
                <div
                  className="right-logo img"
                  style={{
                    color: card?.default_color,
                    border: "0.5px solid",
                    borderRadius: "10px",
                    width: "250px",
                    height: "151px",
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    overflow: "hidden",
                    boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                  }}
                >
                  <div style={{ width: "180px", position: "relative" }}>
                    <img src="http://placeimg.com/180/151/any" alt="" />
                    <div
                      style={{ color: card?.default_color }}
                      className={classProp1}
                    >
                      <h4>
                        GH¢ <span>{card?.amount}</span>
                      </h4>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: card?.default_color,
                      width: "70px",
                      position: "relative",
                    }}
                  >
                    <div className="logo-size-prev">
                      <img
                        src={card?.logo}
                        alt="First slide"
                        className="upload-btn-img4"
                      />
                    </div>
                    {card?.web_design_props?.show_egift_card && (
                      <div
                        style={{ color: card?.web_design_props?.egift_color }}
                      >
                        <h4 className="gift-text-size5">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {leftLogo && (
                <div
                  className="left-logo img"
                  style={{
                    color: card?.default_color,
                    border: "0.5px solid",
                    borderRadius: "10px",
                    width: "250px",
                    height: "151px",
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    overflow: "hidden",
                    boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                  }}
                >
                  <div
                    style={{
                      background: card?.default_color,
                      width: "70px",
                      position: "relative",
                    }}
                  >
                    <div className="logo-size-prev">
                      <img
                        src={card?.logo}
                        alt="First slide"
                        className="upload-btn-img1"
                      />
                    </div>
                    {card?.web_design_props?.show_egift_card && (
                      <div
                        style={{ color: card?.web_design_props?.egift_color }}
                      >
                        <h4 className="gift-text-size">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                  <div style={{ position: "relative" }}>
                    <img src="http://placeimg.com/180/151/any" alt="" />
                    <div
                      style={{ color: card?.default_color }}
                      className={classProp1}
                    >
                      <h4>
                        GH¢ <span>{card?.amount}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {downLogo && (
                <div
                  className="down-logo img"
                  style={{
                    color: card?.default_color,
                    border: "0.5px solid",
                    borderRadius: "10px",
                    width: "250px",
                    height: "151px",
                    display: "grid",
                    gridTemplateRows: "auto auto",
                    overflow: "hidden",
                    boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                  }}
                >
                  <div style={{ height: "100px", position: "relative" }}>
                    <img src="http://placeimg.com/250/100/any" alt="" />
                    <div
                      style={{ color: card?.default_color }}
                      className={classProp1}
                    >
                      <h4>
                        GH¢ <span>{card?.amount}</span>
                      </h4>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: card?.default_color,
                      position: "relative",
                      height: "51px",
                    }}
                  >
                    <div className="logo-size1-prev1">
                      <img
                        src={card?.logo}
                        alt="First slide"
                        className="upload-btn-img-bottom"
                      />
                    </div>
                    {card?.web_design_props?.show_egift_card && (
                      <div
                        style={{
                          color: card?.web_design_props?.egift_color,
                          transition: "ease all 100ms",
                        }}
                      >
                        <h4 className="gift-text-size">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {topLogo && (
                <div
                  className="up-logo img"
                  style={{
                    color: card?.default_color,
                    border: "0.5px solid",
                    borderRadius: "10px",
                    width: "250px",
                    height: "151px",
                    display: "grid",
                    gridTemplateRows: "auto auto",
                    overflow: "hidden",
                    boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                  }}
                >
                  <div
                    style={{
                      height: "52px",
                      backgroundColor: card?.default_color,
                      position: "relative",
                    }}
                  >
                    <div className="logo-size1-prev1">
                      <img
                        src={card?.logo}
                        alt="First slide"
                        className="upload-btn-img-bottom"
                      />
                    </div>
                    {card?.web_design_props?.show_egift_card && (
                      <div
                        style={{
                          color: card?.web_design_props?.egift_color,
                          transition: "ease all 100ms",
                        }}
                      >
                        <h4 className="gift-text-size">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                  <div style={{ height: "100px", position: "relative" }}>
                    <img src="http://placeimg.com/250/100/any" alt="" />
                    <div
                      style={{ color: card?.default_color }}
                      className={classProp1}
                    >
                      <h4>
                        GH¢ <span>{card?.amount}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        } else {
          var classProp;

          if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "right"
          ) {
            classProp = "card-e-gift-img-top-r";
          } else if (
            card?.web_design_props?.amount_position[0] === "top" &&
            card?.web_design_props?.amount_position[1] === "left"
          ) {
            classProp = "card-e-gift-img-top-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "left"
          ) {
            classProp = "card-e-gift-img-down-l";
          } else if (
            card?.web_design_props?.amount_position[0] === "bottom" &&
            card?.web_design_props?.amount_position[1] === "right"
          ) {
            classProp = "card-e-gift-img-down-r";
          }
          return (
            <div className="card-e-gift-img-cover">
              <img src={card?.image} alt="" className="card-e-gift-img" />
              <h4 style={{ color: card?.default_color }} className={classProp}>
                GH¢ <span>{parseFloat(card?.amount).toFixed(2)}</span>
              </h4>
            </div>
          );
        }
      })
    )
  ) : (
    <div className="loading-positon">
      <i className="fas fa-spinner fa-spin mr-2"></i>
    </div>
  );

  return (
    <>
      <div className="card card-chart z-position card-height-1">
        <div className="card-header">
          <h4 className="card-title float-left">E-gift Cards</h4>
          <button
            className="float-right"
            onClick={() => setCurrentDashboardScreen(2)}
          >
            <img src="assets/img/setting-2.png" alt="" />
          </button>
        </div>
        <section className="slider variable card-e-gift">
          <Slider {...settings}>{cardList1}</Slider>
        </section>
      </div>
    </>
  );
};
export default EGiftCards;
