import { Link } from "react-router-dom";
import CredentialForm from "./credentialsComponent/CredentialForm";
const Credentials = () => {
  return (
    <>
        <div
          className="tab-pane fade"
          id="pills-credentials"
          role="tabpanel"
          aria-labelledby="pills-credentials-tab"
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-chart2 card-h10 card-h10-pad">
                <div>
                  <h3>Credentials</h3>
                  <hr className="hr3" />
                </div>
                <div>
                <h4>
                    We do not sell or share your details without your
                    permission. Find out more in our{" "}
                    <Link to="">Privacy Policy.</Link>
                    <span>
                      Your <b>Current password</b> can be
                      updated via{" "}
                      <b className="merch-color">
                        Prime Merchant Change Credentials Settings
                      </b>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <CredentialForm/>
            </div>
          </div>
        </div>
    </>
      
    
  );
};

export default Credentials;
