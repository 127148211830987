import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import $ from "jquery";
import AccountForm from "./accountComponents/AccountForm";

const Account = () => {
  $(".form-fill-textbox1")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "1px solid rgb(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "1px solid rgb(94, 94, 94, 0.8)");
        $(this).css("color", "rgb(94, 94, 94, 0.8)");
      }
    })
    .trigger("change");

  const [showhide, setShowhide] = useState("");

  const handleshowhide = (event) => {
    const getUser = event.target.value;
    setShowhide(getUser);
  };
  const [userDetails, setUserDetails] = useState([]);
  const [userInput, setUserInput] = useState({});

  useEffect(() => {
    axios
      .get("/prime.sika/v1/users/merchant/details")
      .then((res) => {
        setUserDetails(res.data.data.user_details);
        // console.log(res.data.data.user_details);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  useEffect(() => {
    setUserInput((userInput) => ({
      ...userInput,
      userDetails,
    }));
  }, [userDetails]);

  

  const [banks, setBanks] = useState([]);
  const [providers, setProviders] = useState([]);
 

 


  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/bank-accounts/supported-banks")
      .then((res) => {
        if (res.data.status === 200) {
          setBanks(res.data.data.banks);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

 

  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/bank-accounts/supported-banks/branches")
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.data.branches);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);



  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/bank-accounts/supported-momo-providers")
      .then((res) => {
        if (res.data.status === 200) {
          setProviders(res.data.data.providers);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <>
      <div
        className="tab-pane fade"
        id="pills-account"
        role="tabpanel"
        aria-labelledby="pills-account-tab"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-chart2 card-h10 card-h10-pad">
              <div>
                <h3>Account Information</h3>
                <hr className="hr3" />
              </div>
              <div>
                <h4>
                  You have the option to setup disbursement account with your
                  Momo/Bank details.
                  <span>
                    To update your current account details, please authenticate
                    with your verification code
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
          <AccountForm/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
