import $ from "jquery";
const Terms = ({ handleText, terms }) => {
  $(".textarea-size")
    .change(function () {
      if ($(this).val() === "" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
        $(this).css("color", "#000");
      }
    })
    .trigger("change");

  return (
    <div className="row mt-3">
      <div className="col-12">
        <h3>Retailer Gift Card Terms & Conditions</h3>
      </div>
      <div className="col-12 text-color">
        <h4>Add the terms and conditions for your card</h4>
      </div>
      <div className="col-12">
        <textarea
          onChange={(e) => handleText(e.target.value)}
          value={terms}
          name="terms"
          id=""
          placeholder="Type your conditions here"
          className="textarea-size"
        ></textarea>
      </div>
    </div>
  );
};

export default Terms;
