import { useEffect, useState } from "react";
import axios from "axios";

const MomoInfo = ({ handleUserInput, userInput }) => {
  const [providers, setProviders] = useState([]);
  // const [network, setNetwork] = useState(userInput?.bank_name);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/bank-accounts/supported-momo-providers")
      .then((res) => {
        setProviders(res.data.data.providers);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);


  return (
    <div className="momoinfo">
      <div className="form-group d-flex align-items-center mt-4">
        <div className="form-name-width">
          <label htmlFor="name">
            <h4 className="my-auto">Select Network</h4>
          </label>
        </div>
        <div className="form-box-width">
          <select
            className="form-control form-fill-textbox1"
            name="bank_name"
            onChange={handleUserInput}
            value={userInput?.bank_name ? userInput?.bank_name : "Select Network"}
          >
            <option>Select Network</option>

            {providers.map((provider, index) => (
              <option key={index} value={provider?.code}>
                {provider?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group d-flex align-items-center mt-4">
        <div className="form-name-width">
          <label htmlFor="name">
            <h4 className="my-auto">Phone Number</h4>
          </label>
        </div>
        <div className="form-box-width">
          <input
            value={userInput?.number}
            name="number"
            type="text"
            className="form-control form-fill-textbox1"
            onChange={handleUserInput}
          />
        </div>
      </div>
      <div className="form-group d-flex align-items-center mt-4">
        <div className="form-name-width">
          <label htmlFor="name">
            <h4 className="my-auto">Wallet Name</h4>
          </label>
        </div>
        <div className="form-box-width">
          <input
            onChange={handleUserInput}
            name="name"
            value={userInput?.name}
            type="text"
            className="form-control form-fill-textbox1"
          />
        </div>
      </div>
    </div>
  );
};

export default MomoInfo;
