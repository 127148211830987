import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const UpdateMerchantPage = ({
  showUpdateMerchantModal,
  handleUpdateMerchantClose,
  handleUpdateMerchantShow,
  setShowUpdateMerchantModal,
  setMerchantInput,
  merchantInput,
  isUpdateloading,
  setUpdateLoading,
  merchantsTable,
  setMerchantTable,
  location,
  setlocation,
  setCurrent,
  SubMerchants,
  merchantServices,
  onMerchantTypeRemoved,
  onMerchantTypeSelect,
  ChangeCurrentScreenToManageLocation,
}) => {
  const handleUserInput = (e) => {
    setMerchantInput((merchantInput) => ({
      ...merchantInput,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    setlocation((location) => ({
      ...location,
      lat: merchantInput?.cordinates?.lat,
      lng: merchantInput?.cordinates?.long,
      address: merchantInput?.cordinates?.address,
    }));
  }, [merchantInput]);

  const UpdateMerchant = () => {
    const data = {
      id: merchantInput?.id,
      name: merchantInput?.name,
      branch: location?.address,
      telephone: merchantInput?.telephone,
      // merchant_type: "single",
      cordinates: {
        address: location?.address,
        latitude: location?.lat,
        longitude: location?.lng,
      },
      // persmission: {
      //   manage_account: manageAccount,
      //   manage_gift_cards: manageGiftCards,
      //   reports: reports,
      //   promotions: createPromotionsOrDeals,
      //   payment: paymentAccount,
      //   transaction: transactionPayout,
      // },
    };
    setUpdateLoading(true);
    axios
      .put(`prime.sika/v1/managers/merchants/${merchantInput?.id}`, data)
      .then((res) => {
        setUpdateLoading(false);
        ChangeCurrentScreenToManageLocation();
        merchantsTable();
      })
      .catch((err) => {
        if (err.response.data.status === 400) {
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
        }
        setUpdateLoading(false);
      });
  };
  const backToPreviousPage = () => {
    setlocation({
      lng: "",
      lat: "",
      address: "",
    });
    setCurrent(2);
  };
  return (
    <div className="row justify-content-center">
      <div className="col-lg-11 mb-4">
        <button className="back-btn" onClick={() => backToPreviousPage()}>
          <div className="d-flex">
            <img src="/assets/img/previous.png" alt="" className="img-fluid" />
            <h4 className="back-color my-auto ml-2">Back</h4>
          </div>
        </button>
      </div>
      <div className="col-lg-8">
        <div class="card card-tablet">
          <div class="card-header card-header-margin d-flex justify-content-between">
            <h4>Update Merchant</h4>
          </div>
          <div class="card-body booking_card">
            <form className="coupon-form-widt">
              <div className="row">
                <div className="col-lg-12">
                  <label>
                    <h4>Merchant Name</h4>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={merchantInput?.name}
                      onChange={handleUserInput}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  <label>
                    <h4>Location/Branch</h4>
                  </label>
                  <div className="form-group">
                    <a data-toggle="modal" href="#location-modal">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Location"
                        value={location?.address}
                        readOnly
                        name="location"
                      />
                      <img
                        src="assets/img/location.png"
                        alt=""
                        className="company-loc-icon2"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <label>
                    <h4>Merchant Telephone</h4>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="telephone"
                      value={merchantInput?.telephone}
                      onChange={handleUserInput}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div class="form-group">
                    <label>
                      <h4>Merchant Service Type</h4>
                    </label>
                    <Multiselect
                      className="form-control"
                      options={merchantServices}
                      // isObject={true}
                      displayValue="name"
                      selectedValues={merchantInput?.services}
                      onSelect={onMerchantTypeSelect}
                      onRemove={onMerchantTypeRemoved}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mt-4">
                    <h4>Privileges</h4>
                  </div>
                  <hr />
                  <div>
                    <div className="radio-group1 mt-4 d-flex">
                      <div className="">
                        <div className="d-flex radio1 d-flex align-items-center">
                          <input
                            // checked={manageAccount}
                            type="checkbox"
                            name="merchant_account"
                            id="merchant_account"
                            className="checkbox-design"
                            value="merchant_account"
                            // onChange={() => setManageAccount(!manageAccount)}
                          />
                          <label htmlFor="merchant_account">
                            <h4 className="mt-3 ml-2">Manage Account</h4>
                          </label>
                        </div>
                        <div className="d-flex radio1 d-flex align-items-center">
                          <input
                            // checked={manageGiftCards}
                            type="checkbox"
                            name="manage_gift_cards"
                            id="manage_gift_cards"
                            className="checkbox-design"
                            value="manage_gift_cards"
                            // onChange={() => setManageGiftCards(!manageGiftCards)}
                          />
                          <label htmlFor="manage_gift_cards">
                            <h4 className="mt-3 ml-2">Manage Gift Cards</h4>
                          </label>
                        </div>
                      </div>
                      <div className="ml-5">
                        <div className="d-flex radio1 d-flex align-items-center">
                          <input
                            // checked={createPromotionsOrDeals}
                            type="checkbox"
                            name="create_promotions_or_deals"
                            id="create_promotions_or_deals"
                            className="checkbox-design"
                            value="create_promotions_or_deals"
                            // onChange={() =>
                            //   setCreatePromotionsOrDeals(!createPromotionsOrDeals)
                            // }
                          />
                          <label htmlFor="create_promotions_or_deals">
                            <h4 className="mt-3 ml-2">
                              Create Promotions / Deals
                            </h4>
                          </label>
                        </div>
                        <div className="d-flex radio1 d-flex align-items-center">
                          <input
                            // checked={paymentAccount}
                            type="checkbox"
                            name="set_up_payment_account"
                            id="set_up_payment_account"
                            className="checkbox-design"
                            value="set_up_payment_account"
                            // onChange={() => setSetUpPaymentAccount(!paymentAccount)}
                          />
                          <label htmlFor="set_up_payment_account">
                            <h4 className="mt-3 ml-2">
                              Set up Payment Account
                            </h4>
                          </label>
                        </div>
                      </div>
                      <div className="ml-5">
                        <div className="d-flex radio1 d-flex align-items-center">
                          <input
                            // checked={reports}
                            type="checkbox"
                            name="reports"
                            id="reports"
                            className="checkbox-design"
                            value="reports"
                            // onChange={() => setReports(!reports)}
                          />
                          <label htmlFor="reports">
                            <h4 className="mt-3 ml-2">Reports</h4>
                          </label>
                        </div>
                        <div className="d-flex radio1 d-flex align-items-center">
                          <input
                            // checked={transactionPayout}
                            type="checkbox"
                            value="transactions_and_payouts"
                            name="transactions_and_payouts"
                            id="transactions_and_payouts"
                            className="checkbox-design"
                            // onChange={() =>
                            //   setTransactionsAndPayouts(!transactionPayout)
                            // }
                          />
                          <label htmlFor="transactions_and_payouts">
                            <h4 className="mt-3 ml-2">
                              Transactions & Payouts
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mt-4">
                    {!isUpdateloading && (
                      <button
                        className="btn btn-success w-100"
                        onClick={UpdateMerchant}
                      >
                        Update Merchant
                      </button>
                    )}
                    {isUpdateloading && (
                      <button className="btn btn-success w-100" disabled>
                        <i className="fas fa-spinner fa-spin mr-2"></i>
                        Updating...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateMerchantPage;
