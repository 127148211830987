import React from "react";
import Multiselect from "multiselect-react-dropdown";
import Slider from "react-slick";

function RestaurantUpdate({
  resCategories,
  onResCatRemoved,
  onResCatSelect,
  updating,
  handleUserInput,
  items,
  handleSubmit,
  selectedImages,
  sliderRef,
  onSelectFile,
  supportedCurrencies,
  setSelectedImages,
  handleRadioChange,
  isChecked,
  handleCheckboxChange,
  selectedOption,
  setColor,
  productSizes,
  selec,
  onSizeSelect,
  onSizeRemoved,
  customSizes,

  handleSizeChange,
  handleRemoveSize,
  handleCustomSizeAdd,
  isColorChecked,
  categories,
  onCategorySelect,
  onCategoryRemoved,
  selectedCategoryValues,

  handleUpdateSelectedSubCategoriesModal,
  multiSelectRef,
  handleColorCheckboxChange,
  color,
  colorList,
  handleColorRemove,
  handleColorAdd,
  isDiscountChecked,
  handleDiscountChange,
}) {
  const settings = {
    infinite: true,
    autoplay: false,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row mx-3 mt-3">
        <div className="col-lg-6 col-pad">
          <div className="row">
            <div className="col-lg-12 col-pad-left">
              <div className="form-group form-position">
                <input
                  type="text"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Enter Product Name *"
                  name="name"
                  value={items?.name}
                  onChange={handleUserInput}
                  required
                />
              </div>
            </div>
            <div className="col-lg-8 col-pad">
              <section className="variable slider">
                <div
                  className={`${
                    selectedImages.length >= 1
                      ? "upload-img-btn1"
                      : "upload-btn1"
                  }`}
                >
                  <div
                    className={`${
                      selectedImages?.length > 1
                        ? "d-flex justify-content-between arrows-position"
                        : "d-lg-none d-none"
                    }`}
                  >
                    <img
                      src="/assets/img/arrow-left1.png"
                      alt=""
                      onClick={() => sliderRef.current.slickPrev()}
                      className="cur-pointer cur-width"
                    />
                    <img
                      src="/assets/img/arrow-right2.png"
                      alt=""
                      onClick={() => sliderRef.current.slickNext()}
                      className="ms-3 cur-pointer cur-width"
                    />
                  </div>
                  <Slider ref={sliderRef} {...settings}>
                    {selectedImages?.map((image, index) => {
                      return (
                        <div className="img-kk3" key={image}>
                          <img src={image} alt="" className="upload-btn-img2" />
                          <input
                            type="button"
                            onClick={() =>
                              setSelectedImages(
                                selectedImages.filter((e) => e !== image)
                              )
                            }
                            className="btn bg-danger-light delete-img"
                            value="delete"
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </section>
            </div>
            <div className="col-lg-4 col-pad">
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <select
                    name="currency"
                    value={items?.currency}
                    onChange={handleUserInput}
                    className="form-control form-padding-textbx mb-3"
                    required
                  >
                    <option value="">Currency *</option>
                    {supportedCurrencies?.map((supportedCurrency, index) => (
                      <option
                        value={supportedCurrency}
                        key={index}
                        className="text-capitalize"
                      >
                        {supportedCurrency}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/arrow-down.png"
                    alt=""
                    className="category-arrow-pos"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Price *"
                    value={items?.price}
                    name="price"
                    onChange={handleUserInput}
                    required
                  />
                </div>
              </div>
              {/* <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Quantity *"
                    value={items?.quantity}
                    name="quantity"
                    onChange={handleUserInput}
                    required
                  />
                </div>
              </div> */}
            </div>
            <div className="col-lg-12 col-pad-left">
              <div className="col-display-small note-text">
                <label
                  htmlFor="upload-image12"
                  className="btn-upload-size1 btn btn-success pt-2 mt-3"
                >
                  <img
                    src="/assets/img/upload-bg-logo.png"
                    alt=""
                    className="mr-2 my-auto"
                  />
                  Upload Image
                </label>
                <input
                  type="file"
                  id="upload-image12"
                  onChange={(e) => {
                    onSelectFile(e);
                  }}
                  multiple
                  accept="image/"
                  name="product_image"
                />
                <h5 className="mb-3">
                  <span>Note:</span> you can upload more than one image of
                  product
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-pad-right">
          <div className="row">
            <div className="col-lg-12 col-pad-right">
              <div className="form-group form-position">
                <Multiselect
                  required
                  className="form-control form-padding-textbx mb-3"
                  options={resCategories}
                  displayValue="name"
                  placeholder="Category *"
                  onSelect={onResCatSelect}
                  onRemove={onResCatRemoved}
                  selectedValues={selectedCategoryValues}
                  ref={multiSelectRef}
                  selectionLimit={2}
                />
                <img
                  src="/assets/img/arrow-down.png"
                  alt=""
                  className="category-arrow-pos"
                />
              </div>
            </div>
            <div className="col-lg-12 col-pad-right">
              <label htmlFor="check1" className="label-style">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  id="check1"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <h4 className="label-size">Add Product Size</h4>
              </label>
            </div>
            {isChecked && (
              <div className="col-lg-12 col-pad-left">
                <div className="form-group form-position">
                  <Multiselect
                    className="form-control form-padding-textbx mb-3"
                    options={productSizes}
                    isObject={false}
                    placeholder="Product Size"
                    // ref={multiSelectRef}
                    selectionLimit={selec}
                    selectedValues={
                      items?.has_custom_sizes === false
                        ? items?.custom_sizes
                        : []
                    }
                    onSelect={onSizeSelect}
                    onRemove={onSizeRemoved}
                    onChange={handleUserInput}
                  />
                  <img
                    src="/assets/img/arrow-down.png"
                    alt=""
                    className="category-arrow-pos"
                  />
                </div>
              </div>
            )}
            <div className="col-lg-12 col-pad-right">
              <textarea
                placeholder="Enter Product Description *"
                className={
                  isChecked
                    ? "textarea-size10-active11 form-control"
                    : "textarea-size11 form-control"
                }
                value={items?.description}
                name="description"
                onChange={handleUserInput}
                required
              ></textarea>
            </div>
          </div>
        </div>

        <div className="w-100 mb-4">
          {!updating && (
            <input
              type="submit"
              className="btn btn-success w-100"
              value="Update product"
            />
          )}
          {updating && (
            <button className="btn btn-success w-100" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>Updating...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default RestaurantUpdate;
