import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { json, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import MarketplaceStats from "./marketplaceComponents/MarketplaceStats";
import OrdersTable from "./dashboardComponents/OrdersTable";
import MarketPromo from "./marketplaceComponents/MarketPromo";
import MarketProductStockTable from "./marketplaceComponents/MarketProductStockTable";
import UploadProducts from "./marketplaceComponents/UploadProducts";
import UpdateProductModal from "./marketplaceComponents/UpdateProductModal";
import swal from "sweetalert";
import DetailedProductModal from "./marketplaceComponents/DetailedProductModal";
import axios from "axios";
import EnableMarketPlaceModal from "./EnableMarketPlaceModal";
import ProductCategoryModal from "./marketplaceComponents/ProductCategoryModal";
import ViewSelectedSubCategoriesModal from "./marketplaceComponents/ViewSelectedSubCategoriesModal";
import UpdateProductCategoryModal from "./marketplaceComponents/UpdateProductCategoryModal";
import ViewUpdateProductCategoryModal from "./marketplaceComponents/ViewUpdateProductCategoryModal";
import { useRef } from "react";
import DetailedOrderModal from "./marketplaceComponents/DetailedOrderModal";
import ManageMultipleOrderModal from "./marketplaceComponents/ManageMultipleOrderModal";
import Swal from "sweetalert2";

const MarketPlace = () => {
  const [search, setSearch] = useState("");
  const [updateSelectedSubCategories, setUpdateSelectedSubCategories] =
    useState([]);
  const [updateProductCat, setUpdateProductCat] = useState([]);
  const [updateChecked, setUpdateChecked] = useState([]);
  const [updateSubCategories, setUpdateSubCategories] = useState([]);
  const [updateSelectedCat, setUpdateSelectedCat] = useState([]);
  const [showUpdateProductModal, setShowUpdateProductModal] = useState(false);
  const [showUpdateProductCategoryModal, setShowUpdateProductCategoryModal] =
    useState(false);
  const [showSelectedSubCategoriesModal, setShowSelectedSubCategoriesModal] =
    useState(false);
  const [
    showUpdateSelectedSubCategoriesModal,
    setShowUpdateSelectedSubCategoriesModal,
  ] = useState(false);
  const [finalChecked, setFinalChecked] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [resCats, setResCats] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [resProductSizes, setResProductSizes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [resCategories, setResCategories] = useState([]);
  const [showDetailedOrderModal, setShowDetailedOrderModal] = useState(false);
  const [showProductCategoryModal, setShowProductCategoryModal] =
    useState(false);
  const [showDetailedProductModal, setShowDetailedProductModal] =
    useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [items, setItems] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [subCatories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [productCat, setProductCat] = useState([]);
  const [checkedParent, setCheckedParent] = useState([]);
  const [updateCheckedParent, setUpdateCheckedParent] = useState([]);
  const [checked, setChecked] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [activate, setActivate] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isloading, setLoading] = useState(false);
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [selectedParent, setSelectedParent] = useState(new Map());
  const [selectedOldParent, setSelectedOldParent] = useState(new Map());
  const [updatSelectedParent, setUpdateSelectedParent] = useState(new Map());
  const [checkboxDisable, setCheckboxDisable] = useState(false);
  const [parent, setParent] = useState([]);
  const [updateParent, setUpdateParent] = useState([]);
  const [showManageOrderModal, setShowManageOrderModal] = useState(false);
  const [showManageMultipleOrderModal, setShowManageMultipleOrderModal] =
    useState(false);
  const [updateFinalChecked, setUpdateFinalChecked] = useState([]);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [orderTotalPage, setOrderTotalPage] = useState(1);
  const [orderCurrentPage, setOrderCurrentPage] = useState(1);
  const [isorderloading, setOrderLoading] = useState(false);
  const [searchOrder, setsearchOrder] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isColorChecked, setIsColorChecked] = useState(false);
  const [isDiscountChecked, setIsDiscountChecked] = useState(false);
  const [color, setColor] = useState("");
  const [colorList, setColorList] = useState([]);
  const [customSizes, setCustomSizes] = useState([{ size: "" }]);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [userEntry, setUserEntry] = useState({
    product_name: "",
    categories: [],
    productSizes: [],
    product_weight: "",
    product_price: "",
    product_description: "",
    product_color: [],
    product_quantity: "",
    product_discount: "",
    product_currency: "",
    product_return_policy: "",
    product_benefits: "",
    min_product_quantity: "",
    discount_start_date: "",
    discount_end_date: "",
  });

  const [oldSelectedParent, setOldSelectedParent] = useState(new Map());
  const handleUserEntry = (e) => {
    const { name, value } = e.target;
    setUserEntry({ ...userEntry, [name]: value });
  };

  const ActivateButtonLocation = useLocation();
  const location = useLocation();
  useEffect(() => {
    setActivate(ActivateButtonLocation.state);
  }, []);

  const handleUpdateSubCategoriesModal = () => {
    setShowUpdateProductCategoryModal(true);
  };
  const handleUpdateProductCategoryModalClose = () => {
    setShowUpdateProductCategoryModal(false);
  };
  const handleEnableMarketPlaceModalClose = () => {
    setActivate(false);
    navigate("/dashboard");
  };
  const handleUpdateProductClose = (e) => {
    setShowUpdateProductModal(false);
  };

  const handleProductCategoryModal = () => {
    setShowProductCategoryModal(true);
  };
  const handleProductCategoryClose = (e) => {
    setShowProductCategoryModal(false);
  };
  const handleSelectedSubCategoriesModal = () => {
    if (finalChecked.length === 0) {
      setShowSelectedSubCategoriesModal(false);
    } else {
      setShowSelectedSubCategoriesModal(true);
    }
  };
  const handleSelectedSubCategoriesModalClose = (e) => {
    setShowSelectedSubCategoriesModal(false);
  };

  const handleUpdateSelectedSubCategoriesModal = () => {
    if (updateFinalChecked.length === 0) {
      setShowUpdateSelectedSubCategoriesModal(false);
    } else {
      setShowUpdateSelectedSubCategoriesModal(true);
    }
  };
  const handleUpdateSelectedSubCategoriesModalClose = (e) => {
    setShowUpdateSelectedSubCategoriesModal(false);
  };

  var catIds = [];
  var categoryIds = [];
  const handleUpdateProductShow = (e, ele) => {
    ele?.belongs_to_product_types?.map((ele, index) => catIds?.push(ele?.id));

    categories?.map((ele, i) => categoryIds?.push(ele?.id));
    var selectMainCategories = categoryIds?.filter((value) => {
      return catIds?.find((val) => {
        return value === val;
      });
    });

    var ChildCatIds = catIds?.filter((value) => {
      return !selectMainCategories?.find((val) => {
        return value === val;
      });
    });

    var searchMainCategory = [];
    let selectedCategoryValues = [];
    categories.map((ele, i) => {
      searchMainCategory = selectMainCategories.includes(ele.id);
      if (searchMainCategory === true) {
        selectedCategoryValues.push(ele);
      }
    });
    var sle = [];
    selectedCategoryValues?.map((ele, index) => sle.push(ele?.id));

    if (updateFinalChecked?.length === 0) {
      setUpdateFinalChecked(catIds);
      setUpdateSelectedSubCategories(selectedCategoryValues);
    }

    const map = new Map();
    ChildCatIds.map(
      (ele, i) => map.set(ChildCatIds[0], [ChildCatIds[1]]),
      map.set(ChildCatIds[3], [ChildCatIds[2]])
    );
    if (updatSelectedParent?.size === 0) {
      setUpdateSelectedParent(map);
    }
    if (updateChecked?.length === 0) {
      setUpdateChecked(ChildCatIds);
    }
    setSelectedImages(ele?.pictures);
    setItems(ele);
    setShowUpdateProductModal(true);
  };

  const handleDetailedProductClose = (e) => {
    setShowDetailedProductModal(false);
  };
  const handleDetailedOrderClose = (e) => {
    setShowDetailedOrderModal(false);
  };
  const handleDetailedProductShow = (e, ele) => {
    setItems(ele);
    setShowDetailedProductModal(true);
  };
  const handleDetailedOrderShow = (e, ele) => {
    setItems(ele);
    setShowDetailedOrderModal(true);
  };
  const handleManageOrderShow = (e, ele) => {
    setItems(ele);
    setShowManageOrderModal(true);
  };
  const handleManageOrderModalClose = (e) => {
    setShowManageOrderModal(false);
  };

  const handleManageMultipleOrderShow = (e, selectedOrderValues) => {
    setOrderList(selectedOrderValues);
    setShowManageMultipleOrderModal(true);
  };
  const handleManageMultipleOrderModalClose = (e) => {
    setShowManageMultipleOrderModal(false);
  };
  const handleUserInput = (e) => {
    setItems((items) => ({
      ...items,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectedOldParent = () => {
    var temp = localStorage.getItem("oldParent");
    temp = JSON.parse(temp);
    let map = new Map();
    for (let key in temp) {
      map.set(parseInt(key), temp[key]);
    }

    setSelectedParent(map);
  };

  const handleUpdateSelectedOldParent = () => {
    var temp = localStorage.getItem("oldUpdateParent");
    temp = JSON.parse(temp);
    let map = new Map();
    for (let key in temp) {
      map.set(parseInt(key), temp[key]);
    }

    setUpdateSelectedParent(map);
  };

  const onSizeSelect = (selectedList, selectedItem) => {
    var Product_sizes = [];
    selectedList?.map((ele, index) => Product_sizes.push(ele));
    setUserEntry((userEntry) => ({
      ...userEntry,
      productSizes: Product_sizes,
    }));
    setSizes(Product_sizes);
  };

  const onSizeRemoved = (selectedList, selectedItem) => {
    var Product_sizes = [];
    selectedList?.map((ele, index) => Product_sizes.push(ele));
    setUserEntry((userEntry) => ({
      ...userEntry,
      productSizes: Product_sizes,
    }));
    setSizes(Product_sizes);
  };

  const onResCatSelect = (selectedList, selectedItem) => {
    var Product_cats = [];
    selectedList?.map((ele, index) => Product_cats.push(ele));
    setUserEntry((userEntry) => ({
      ...userEntry,
      productCats: Product_cats,
    }));
    setResCats(Product_cats);
  };

  const onResCatRemoved = (selectedList, selectedItem) => {
    var Product_cats = [];
    selectedList?.map((ele, index) => Product_cats.push(ele));
    setUserEntry((userEntry) => ({
      ...userEntry,
      productCats: Product_cats,
    }));
    setResCats(Product_cats);
  };

  const [selectCatIds, setSelectCatids] = useState([]);
  const [updateSelectCatIds, setUpdateSelectCatids] = useState([]);
  const onCategorySelect = (selectedList, selectedItem) => {
    var selectedSubCatIDs = [];
    selectedItem?.sub_categories?.map((ele, i) => {
      selectedSubCatIDs.push(ele?.id);
    });
    setSelectCatids(selectedSubCatIDs);
    var productCategory = [];
    selectedList.map((ele, index) => {
      if (selectedItem?.sub_categories?.length === 0) {
        productCategory?.push(ele?.id);
        setFinalChecked(productCategory);
      } else if (selectedItem?.sub_categories?.length !== 0) {
        handleProductCategoryModal();
        setFinalChecked(productCategory);
        productCategory?.push(ele?.id);
        setSelectedCat(productCategory);
        setSubCategories(selectedItem);
      }
    });
    setUserEntry((userEntry) => ({
      ...userEntry,
      categories: productCategory,
    }));
    var subCatList = [];
    selectedList?.map((ele, i) => {
      subCatList = ele;
    });
    if (
      selectedList?.length === 1 &&
      selectedItem?.sub_categories?.length === 0
    ) {
      setFinalChecked(productCategory);
      setProductCat(productCategory);
      setSelectedCat(productCategory);
      setSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length === 2 &&
      selectedItem?.sub_categories?.length === 0
    ) {
      setFinalChecked([...checkedParent, ...productCategory]);
      setProductCat([...checkedParent, ...productCategory]);
      setSelectedCat([...checkedParent, ...productCategory]);
      setSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length === 1 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setFinalChecked(productCategory);
      setProductCat(productCategory);
      setSelectedCat(productCategory);
      setSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length === 2 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setFinalChecked([...checkedParent, ...productCategory]);
      setProductCat(productCategory);
      setSelectedCat(productCategory);
      setSelectedSubCategories(selectedList);
      setSelectedParent(selectedParent);
    }
  };

  const onCategoryRemoved = (selectedList, selectedItem) => {
    var productCategory = [];
    selectedList.map((ele, index) => {
      if (ele?.sub_categories?.length === 0) {
        productCategory?.push(ele?.id);
        setFinalChecked(productCategory);
      } else if (ele?.sub_categories?.length !== 0) {
        productCategory?.push(ele?.id);
        var handleFinalCheck = [...finalChecked];
        handleFinalCheck.splice(finalChecked.indexOf(selectedItem?.id), 1);
        setFinalChecked(handleFinalCheck);
        setSelectedCat(handleFinalCheck);
      }
    });
    setUserEntry((userEntry) => ({
      ...userEntry,
      categories: productCategory,
    }));
    var subCatList = [];
    selectedList.map((ele, i) => {
      subCatList = ele;
    });

    if (selectedList?.length > 0 && subCatList?.sub_categories?.length === 0) {
      setFinalChecked(productCategory);
      setSelectedCat(productCategory);
      setProductCat(productCategory);
    } else if (
      selectedList?.length === 0 &&
      selectedItem?.sub_categories?.length === 0
    ) {
      setFinalChecked(productCategory);
      setSelectedCat(productCategory);
      setProductCat(productCategory);
    } else if (
      selectedList?.length === 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setFinalChecked(productCategory);
      setSelectedCat(productCategory);
      setProductCat(productCategory);
      setChecked(productCategory);
    }
    if (
      selectedList?.length > 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      var selectedSubList = [];
      subCatList?.sub_categories?.sub_categories?.map((ele, i) =>
        selectedSubList.push(ele?.id)
      );
      var subCategoryList = [...productCategory, ...selectedSubList];
      var listSubCategories = selectedCat?.filter((value) => {
        return subCategoryList?.find((val) => {
          return value === val;
        });
      });
      var temp = selectedParent;
      var selectedSubCatIDs = [];
      selectedItem?.sub_categories?.map((ele, i) => {
        selectedSubCatIDs.push(ele?.id);
      });
      var deleteSelected = deleteParentCat?.filter((value) => {
        return selectedSubCatIDs?.find((val) => {
          return value === val;
        });
      });

      deleteSelected.map((ele) => {
        temp.delete(ele);
      });

      setSelectedParent(temp);

      var tempCat = [];
      for (let [key, value] of temp) {
        tempCat.push(key);

        tempCat.push(...value);
      }

      setFinalChecked([...tempCat, ...productCategory]);
      setSelectedCat([...listSubCategories]);
      setProductCat([...listSubCategories]);
      setCheckedParent(tempCat);
      // setParent({});
    }
    if (
      selectedList?.length > 0 &&
      subCatList?.sub_categories?.length === 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setSelectedParent(new Map());
      setCheckedParent([]);
    }
    if (
      selectedList?.length === 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setSelectedParent(new Map());
      setCheckedParent([]);
    }
  };

  const handleCheck = (e, chk) => {
    var updatedList = [...checked];
    if (chk) {
      updatedList = [...checked, e];
    } else {
      updatedList.splice(checked.indexOf(e), 1);
    }
    setChecked(updatedList);
  };

  const handleChildCheck = (e, chk) => {
    var updatedList = [...checked];
    if (chk) {
      updatedList = [...checked, ...e];
    } else {
      updatedList.splice(checked.indexOf(e[0]), 1);
    }
    setChecked(updatedList);
  };

  const handleUpdateChildCheck = (e, chk) => {
    var updatedList = [...checked];
    if (chk) {
      updatedList = [...checked, ...e];
    } else {
      updatedList.splice(checked.indexOf(e[0]), 1);
    }
    setChecked(updatedList);
  };
  useEffect(() => {
    var temp = new Map(selectedParent);
    setSelectedOldParent(new Map(selectedParent));
  }, []);
  let selectedParentCat = [];
  const handleParentCheck = (id, child) => {
    if (child) {
      var arr = selectedParent.get(id);
      if (arr?.length > 0) {
        var childID = arr.filter((element) => element == child);
        if (childID.length > 0) {
          arr.splice(arr.indexOf(child), 1);
        } else {
          arr.push(child);
        }
        let tempParent = selectedParent;
        tempParent.set(id, arr);
        setOldSelectedParent(tempParent);
        setSelectedParent(tempParent);
      } else {
        let temp = selectedParent;
        temp.set(id, [child]);
        setOldSelectedParent(temp);
        setSelectedParent(temp);
      }
    } else {
      var arr = selectedParent.get(id) ?? [];

      if (arr.length == 0) {
        let temp = selectedParent;
        if (temp.has(id)) {
          temp.delete(id);
        } else {
          temp.set(id, []);
        }
        setOldSelectedParent(temp);
        setSelectedParent(temp);
      } else {
        let temp = selectedParent;
        temp.delete(id);
        setOldSelectedParent(temp);
        setSelectedParent(temp);
      }
    }

    setParent([...parent, id]);

    for (let [key, value] of selectedParent) {
      selectedParentCat.push(key);

      selectedParentCat.push(...value);
    }
    localStorage.setItem(
      "oldParent",
      JSON.stringify(Object.fromEntries(selectedParent))
    );
    setCheckedParent(selectedParentCat);
  };

  const deleteParentCat = parent;

  let updateSelectedParentCat = [];
  const handleUpdateParentCheck = (id, child) => {
    if (child) {
      var arr = updatSelectedParent.get(id);
      if (arr?.length > 0) {
        var childID = arr.filter((element) => element == child);
        if (childID.length > 0) {
          arr.splice(arr.indexOf(child), 1);
        } else {
          arr.push(child);
        }
        let tempParent = updatSelectedParent;
        tempParent.set(id, arr);
        setOldSelectedParent(tempParent);
        setUpdateSelectedParent(tempParent);
      } else {
        let temp = updatSelectedParent;
        temp.set(id, [child]);
        setOldSelectedParent(temp);
        setUpdateSelectedParent(temp);
      }
    } else {
      var arr = updatSelectedParent.get(id) ?? [];

      if (arr.length == 0) {
        let temp = updatSelectedParent;
        if (temp.has(id)) {
          temp.delete(id);
        } else {
          temp.set(id, []);
        }
        setOldSelectedParent(temp);
        setUpdateSelectedParent(temp);
      } else {
        let temp = updatSelectedParent;
        temp.delete(id);
        setOldSelectedParent(temp);
        setUpdateSelectedParent(temp);
      }
    }

    setUpdateParent([...updateParent, id]);

    for (let [key, value] of updatSelectedParent) {
      updateSelectedParentCat.push(key);

      updateSelectedParentCat.push(...value);
    }
    localStorage.setItem(
      "oldUpdateParent",
      JSON.stringify(Object.fromEntries(updatSelectedParent))
    );
    setUpdateCheckedParent(updateSelectedParentCat);
  };
  const deleteUpdateParentCat = updateParent;

  const saveChecked = () => {
    setSelectedCat([...checkedParent, ...productCat]);
    setFinalChecked([...checkedParent, ...productCat]);
    handleProductCategoryClose();
  };
  const handleUpdateCheck = (e, chk) => {
    var updatedList = [...updateChecked];
    if (chk) {
      updatedList = [...updateChecked, e];
    } else {
      updatedList.splice(updateChecked.indexOf(e), 1);
    }
    setUpdateChecked(updatedList);
  };

  const saveUpdateChecked = () => {
    if (updateFinalChecked?.length === 0) {
      setUpdateFinalChecked(catIds);
    }
    setUpdateSelectedCat([...updateCheckedParent, ...updateProductCat]);
    setUpdateFinalChecked([...updateCheckedParent, ...updateProductCat]);
    handleUpdateProductCategoryModalClose();
  };

  const fetchOrders = () => {
    setOrderLoading(false);
    axios
      .get(`/market.place/v1/orders/list?limit=10&page=${orderCurrentPage}`)
      .then((res) => {
        setOrderLoading(true);
        setOrderTotalPage(res.data.data.total_pages);
        setOrders(res.data.data.orders);
      })
      .catch((err) => {
        setOrderLoading(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    fetchOrders();
  }, [orderCurrentPage]);

  const [deliveryStatuses, setDeliveryStatuses] = useState([]);
  useEffect(() => {
    axios
      .get("/market.place/v1/orders/delivery-statuses")
      .then((res) => {
        setDeliveryStatuses(res.data.data.statuses);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/market.place/v1/products/types")
      .then((res) => {
        const nameToRemove = "Restaurant";
        const newArray = res.data.data.categories.filter(
          (item) => item.name !== nameToRemove
        );
        const restauCat = res.data.data.categories.filter(
          (item) => item.name === nameToRemove
        );
        setCategories(newArray);
        setResCategories(restauCat[0]?.sub_categories);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/market.place/v1/products/product-sizes")
      .then((res) => {
        setProductSizes(res.data.data.sizes);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/market.place/v1/products/restaurant/form")
      .then((res) => {
        setResProductSizes(res.data.data.restaurant_form.size);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  const fetchProducts = () => {
    if (search === "") {
      setLoading(false);
      axios
        .get(`/market.place/v1/products/list?limit=10&page=${currentPage}`)
        .then((res) => {
          setLoading(true);
          setTotalPage(res.data.data.total_pages);
          setProducts(res.data.data.products);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
        });
    } else {
      setLoading(false);
      axios
        .get(
          `/market.place/v1/products/list?limit=1000&search_string=${search}`
        )
        .then((res) => {
          setLoading(true);
          setTotalPage(res.data.data.total_pages);
          setProducts(res.data.data.products);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
        });
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [currentPage, search]);

  useEffect(() => {
    axios
      .get("/market.place/v1/products/support-currencies")
      .then((res) => {
        setSupportedCurrencies(res.data.data.support_currencies);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const [productSummaries, setProductSummaries] = useState("");

  const fetchProductSummaries = () => {
    axios
      .get("/market.place/v1/reports/products-summaries")
      .then((res) => {
        setProductSummaries(res.data.data.records);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  useEffect(() => {
    fetchProductSummaries();
  }, []);
  const DeleteProduct = (e, i) => {
    Swal.fire({
      title: "Delete this product?",
      text: "This will make this product unvailable to customers.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#338540",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/market.place/v1/products/${i}/remove`)
          .then((res) => {
            if (res?.data?.success === true && res?.data?.status === 200) {
              Swal.fire({
                title: "Success",
                text: "Product removed successfully",
                icon: "success",
                button: "OK",
                confirmButtonColor: "#00b17d",
              }).then((isOkay) => {
                if (isOkay) {
                  fetchProducts();
                  fetchProductSummaries();
                }
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    });
  };

  const backPage = () => {
    setFinalChecked([]);
    setCurrentScreen(1);
    setCheckedParent([]);
    setSizes([]);
    setSelectedParent(new Map());
    setColor("");
    setColorList([]);
    setFocusedIndex(0);
    setIsChecked(false);
    setIsColorChecked(false);
    setIsDiscountChecked(false);
  };
  const store_type = localStorage.getItem("store_type");
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <PageHeader />
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <MarketplaceStats
                      setProductSummaries={setProductSummaries}
                      productSummaries={productSummaries}
                    />
                  </div>
                </div>
                {currentScreen === 1 && (
                  <>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <MarketProductStockTable
                            setSearch={setSearch}
                            store_type={store_type}
                            search={search}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            isloading={isloading}
                            setLoading={setLoading}
                            setProducts={setProducts}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            products={products}
                            DeleteProduct={DeleteProduct}
                            handleUpdateProductClose={handleUpdateProductClose}
                            handleUpdateProductShow={handleUpdateProductShow}
                            handleDetailedProductClose={
                              handleDetailedProductClose
                            }
                            handleDetailedProductShow={
                              handleDetailedProductShow
                            }
                            currentScreen={currentScreen}
                            setCurrentScreen={setCurrentScreen}
                            location={location}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentScreen === 2 && (
                  <>
                    <div className="col-lg-12">
                      <button
                        className="back-btn mb-3"
                        onClick={() => backPage()}
                      >
                        <div className="d-flex">
                          <img
                            src="/assets/img/previous.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h4 className="back-color my-auto ml-2">Back</h4>
                        </div>
                      </button>
                    </div>

                    <div className="col-lg-8">
                      <UploadProducts
                        sizes={sizes}
                        productSizes={productSizes}
                        resProductSizes={resProductSizes}
                        onResCatSelect={onResCatSelect}
                        onResCatRemoved={onResCatRemoved}
                        onSizeSelect={onSizeSelect}
                        onSizeRemoved={onSizeRemoved}
                        onCategoryRemoved={onCategoryRemoved}
                        onCategorySelect={onCategorySelect}
                        handleProductCategoryModal={handleProductCategoryModal}
                        handleSelectedSubCategoriesModal={
                          handleSelectedSubCategoriesModal
                        }
                        resCats={resCats}
                        setProductSummaries={setProductSummaries}
                        productSummaries={productSummaries}
                        currentScreen={currentScreen}
                        setCurrentScreen={setCurrentScreen}
                        setProducts={setProducts}
                        setSupportedCurrencies={setSupportedCurrencies}
                        supportedCurrencies={supportedCurrencies}
                        categories={categories}
                        setCategories={setCategories}
                        resCategories={resCategories}
                        finalChecked={finalChecked}
                        setFinalChecked={setFinalChecked}
                        handleUserEntry={handleUserEntry}
                        setUserEntry={setUserEntry}
                        userEntry={userEntry}
                        isChecked={isChecked}
                        setIsChecked={(e) => setIsChecked(e)}
                        isColorChecked={isColorChecked}
                        setIsColorChecked={(e) => setIsColorChecked(e)}
                        isDiscountChecked={isDiscountChecked}
                        setIsDiscountChecked={(e) => setIsDiscountChecked(e)}
                        color={color}
                        setColor={(e) => setColor(e)}
                        colorList={colorList}
                        setColorList={(e) => setColorList(e)}
                        customSizes={customSizes}
                        setCustomSizes={(e) => setCustomSizes(e)}
                        focusedIndex={focusedIndex}
                        setFocusedIndex={(e) => setFocusedIndex(e)}
                        store_type={store_type}
                      />
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="justify-content-center">
                            <div
                              className={
                                isColorChecked &&
                                isChecked &&
                                !isDiscountChecked
                                  ? "picture-frame1"
                                  : !isColorChecked &&
                                    isChecked &&
                                    !isDiscountChecked &&
                                    store_type.toLowerCase() !== "restaurant"
                                  ? "picture-frame2"
                                  : isColorChecked &&
                                    !isChecked &&
                                    !isDiscountChecked
                                  ? "picture-frame3"
                                  : isDiscountChecked &&
                                    isColorChecked &&
                                    isChecked
                                  ? "picture-frame4"
                                  : isDiscountChecked &&
                                    !isColorChecked &&
                                    !isChecked
                                  ? "picture-frame5"
                                  : isDiscountChecked &&
                                    !isColorChecked &&
                                    isChecked
                                  ? "picture-frame6"
                                  : isDiscountChecked &&
                                    isColorChecked &&
                                    !isChecked
                                  ? "picture-frame7"
                                  : store_type.toLowerCase() === "restaurant"
                                  ? "picture-frame8"
                                  : "picture-frame"
                              }
                            >
                              <img
                                src="assets/img/gift.png"
                                alt=""
                                className="img-fluid gift-image-size "
                              />
                            </div>
                            <div className="text-position">
                              <h3 className="text-white">
                                Make your loved <span>one's happy</span>
                              </h3>
                            </div>
                            <div>
                              <a
                                href="https://primeegiftcard.com"
                                target="_blank"
                                rel="noreferrer"
                                className="bg-secondary shop-btn-position text-white"
                              >
                                Shop eGift Card
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {currentScreen === 3 && (
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <OrdersTable
                      currentScreen={currentScreen}
                      setCurrentScreen={setCurrentScreen}
                      handleDetailedOrderShow={handleDetailedOrderShow}
                      handleManageOrderShow={handleManageOrderShow}
                      handleManageMultipleOrderShow={
                        handleManageMultipleOrderShow
                      }
                      orders={orders}
                      selectedOrders={selectedOrders}
                      setSelectedOrders={setSelectedOrders}
                      setOrderTotalPage={setOrderTotalPage}
                      setOrderCurrentPage={setOrderCurrentPage}
                      orderTotalPage={orderTotalPage}
                      setOrderLoading={setOrderLoading}
                      isorderloading={isorderloading}
                      location={location}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <EnableMarketPlaceModal
        activate={activate}
        handleEnableMarketPlaceModalClose={handleEnableMarketPlaceModalClose}
      />
      <UpdateProductModal
        setUpdateSelectCatids={(e) => setUpdateSelectCatids(e)}
        setUpdateParent={(e) => setUpdateParent(e)}
        store_type={store_type}
        updateParent={updateParent}
        deleteUpdateParentCat={deleteUpdateParentCat}
        handleUpdateSubCategoriesModal={handleUpdateSubCategoriesModal}
        setUpdateCheckedParent={(e) => setUpdateCheckedParent(e)}
        updateCheckedParent={updateCheckedParent}
        setUpdateSelectedParent={(e) => setUpdateSelectedParent(e)}
        updatSelectedParent={updatSelectedParent}
        categories={categories}
        setCategories={(e) => setCategories(e)}
        sizes={sizes}
        onResCatRemoved={onResCatRemoved}
        onResCatSelect={onResCatSelect}
        resCategories={resCategories}
        productSizes={productSizes}
        setSizes={setSizes}
        handleUpdateSelectedSubCategoriesModal={
          handleUpdateSelectedSubCategoriesModal
        }
        updateSelectedSubCategories={updateSelectedSubCategories}
        setCurrentScreen={setCurrentScreen}
        currentScreen={currentScreen}
        isloading={isloading}
        setLoading={setLoading}
        setProducts={setProducts}
        products={products}
        supportedCurrencies={supportedCurrencies}
        setSupportedCurrencies={setSupportedCurrencies}
        handleUserInput={handleUserInput}
        setSelectedImages={setSelectedImages}
        selectedImages={selectedImages}
        setItems={setItems}
        items={items}
        showUpdateProductModal={showUpdateProductModal}
        setShowUpdateProductModal={setShowUpdateProductModal}
        handleUpdateProductClose={handleUpdateProductClose}
        setUpdateFinalChecked={(e) => setUpdateFinalChecked(e)}
        setUpdateSelectedSubCategories={(e) =>
          setUpdateSelectedSubCategories(e)
        }
        setUpdateProductCat={(e) => setUpdateProductCat(e)}
        setUpdateChecked={(e) => setUpdateChecked(e)}
        setUpdateSubCategories={(e) => setUpdateSubCategories(e)}
        setUpdateSelectedCat={(e) => setUpdateSelectedCat(e)}
        updateChecked={updateChecked}
        updateFinalChecked={updateFinalChecked}
        updateSelectedCat={updateSelectedCat}
        isDiscountChecked={isDiscountChecked}
        setIsDiscountChecked={(e) => setIsDiscountChecked(e)}
      />
      <DetailedProductModal
        setProducts={setProducts}
        products={products}
        setItems={setItems}
        items={items}
        showDetailedProductModal={showDetailedProductModal}
        setShowDetailedProductModal={setShowDetailedProductModal}
        handleDetailedProductClose={handleDetailedProductClose}
        store_type={store_type}
      />
      <ProductCategoryModal
        selectCatIds={selectCatIds}
        deleteParentCat={deleteParentCat}
        handleSelectedOldParent={handleSelectedOldParent}
        setCheckboxDisable={setCheckboxDisable}
        checkboxDisable={checkboxDisable}
        selectedParentCat={selectedParentCat}
        handleParentCheck={handleParentCheck}
        selectedParent={selectedParent}
        setSelectedParent={setSelectedParent}
        setFinalChecked={setFinalChecked}
        productCat={productCat}
        setSelectedCat={(cats) => setSelectedCat(cats)}
        setSubCategories={setSubCategories}
        subCatories={subCatories}
        setShowProductCategoryModal={setShowProductCategoryModal}
        showProductCategoryModal={showProductCategoryModal}
        handleProductCategoryClose={handleProductCategoryClose}
        checked={checked}
        setChecked={setChecked}
        handleCheck={handleCheck}
        handleChildCheck={handleChildCheck}
        saveChecked={saveChecked}
        setCheckedParent={setCheckedParent}
      />
      <ViewSelectedSubCategoriesModal
        handleSelectedOldParent={handleSelectedOldParent}
        oldSelectedParent={oldSelectedParent}
        setCheckedParent={setCheckedParent}
        checkedParent={checkedParent}
        setSelectedParent={setSelectedParent}
        selectedParent={selectedParent}
        finalChecked={finalChecked}
        productCat={productCat}
        checked={checked}
        setChecked={setChecked}
        setFinalChecked={(e) => setFinalChecked(e)}
        setSelectedCat={setSelectedCat}
        selectedCat={selectedCat}
        setSelectedSubCategories={setSelectedSubCategories}
        selectedSubCategories={selectedSubCategories}
        setShowSelectedSubCategoriesModal={setShowSelectedSubCategoriesModal}
        showSelectedSubCategoriesModal={showSelectedSubCategoriesModal}
        handleSelectedSubCategoriesModalClose={
          handleSelectedSubCategoriesModalClose
        }
      />
      <UpdateProductCategoryModal
        updateSelectCatids={updateSelectCatIds}
        handleUpdateProductCategoryModalClose={
          handleUpdateProductCategoryModalClose
        }
        updateSubCategories={updateSubCategories}
        setUpdateChecked={setUpdateChecked}
        handleUpdateCheck={handleUpdateCheck}
        saveUpdateChecked={saveUpdateChecked}
        showUpdateProductCategoryModal={showUpdateProductCategoryModal}
        handleUpdateParentCheck={handleUpdateParentCheck}
        updatSelectedParent={updatSelectedParent}
        handleUpdateChildCheck={handleUpdateChildCheck}
        setUpdateCheckedParent={setUpdateCheckedParent}
        setUpdateFinalChecked={setUpdateFinalChecked}
        deleteUpdateParentCat={deleteUpdateParentCat}
        updateProductCat={updateProductCat}
        setUpdateSelectedParent={setUpdateSelectedParent}
      />
      <ViewUpdateProductCategoryModal
        handleUpdateSelectedOldParent={handleUpdateSelectedOldParent}
        items={items}
        updateProductCat={updateProductCat}
        updateSelectedSubCategories={updateSelectedSubCategories}
        updateSelectedCat={updateSelectedCat}
        setUpdateSelectedCat={setUpdateSelectedCat}
        updateFinalChecked={updateFinalChecked}
        updatSelectedParent={updatSelectedParent}
        setUpdateFinalChecked={setUpdateFinalChecked}
        setUpdateCheckedParent={setUpdateCheckedParent}
        updateCheckedParent={updateCheckedParent}
        setUpdateChecked={setUpdateChecked}
        handleUpdateSelectedSubCategoriesModalClose={
          handleUpdateSelectedSubCategoriesModalClose
        }
        showUpdateSelectedSubCategoriesModal={
          showUpdateSelectedSubCategoriesModal
        }
        setShowUpdateSelectedSubCategoriesModal={
          setShowUpdateSelectedSubCategoriesModal
        }
      />
      <DetailedOrderModal
        showDetailedOrderModal={showDetailedOrderModal}
        handleDetailedOrderClose={handleDetailedOrderClose}
        items={items}
        deliveryStatuses={deliveryStatuses}
        fetchOrders={() => fetchOrders()}
      />
      <ManageMultipleOrderModal
        showManageMultipleOrderModal={showManageMultipleOrderModal}
        handleManageMultipleOrderModalClose={
          handleManageMultipleOrderModalClose
        }
        orderList={orderList}
        setOrderList={setOrderList}
        setOrders={setOrders}
        orderCurrentPage={orderCurrentPage}
        setOrderTotalPage={setOrderTotalPage}
        setSelectedOrders={setSelectedOrders}
        fetchOrders={() => fetchOrders()}
        deliveryStatuses={deliveryStatuses}
      />
    </>
  );
};
export default MarketPlace;
