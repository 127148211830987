import { useState } from "react";
import { Modal } from "react-bootstrap";

const ActivateNotificationModal = ({
  activate,
  handleActivateNotificationModalClose,
}) => {
  return (
    <Modal show={activate} onHide={handleActivateNotificationModalClose}>
      <Modal.Header closeButton>
        <div className="w-100">
          <div className="float-right">
            <button onClick={handleActivateNotificationModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="message-content">
                <h4>
                  You're not eligible to this service. To enjoy this service, please
                  contact our customer care on <span className="text-success">+233548958517 / +233207920300 / +233247117448</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row justify-content-center mt-4">
          <div className="col-lg-6">
            <div className="text-center">
            <button className="btn btn-success w-50" onClick={handleActivateNotificationModalClose}>OK</button>
          </div>
          </div>
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ActivateNotificationModal;
