import { useState } from "react";
import { Link } from "react-router-dom";
import Verification from "./Verification";

const Credentials = ({ userInput, handleUserInput,saveData,setCurrentScreen, currentScreen }) => {
  const [errors, setError] = useState({
    username: "",
    password: "",
    password_confirm: "",
    pin: "",
    pin_confirm: "",
  });

  const handleOnChange = (e) => {
    setError((errors) => ({
      ...errors,
      [e.target.name]: "",
    }));
    handleUserInput(e);
  };

  const handleValidation = (e) => {
    e.preventDefault();
    if (userInput.username === "" || !userInput.username) {
      setError((errors) => ({
        ...errors,
        username: "Please Enter your username",
      }));
    }
    else if (userInput.password === "" || !userInput.password) {
      setError((errors) => ({
        ...errors,
        password: "Please Enter your user password",
      }));
    }
    else if (userInput.password.length < 9) {
      setError((errors) => ({
        ...errors,
        password: "Password length is too short, it should contain more than 9 characters",
      }));
    }
    else if (userInput.password !== userInput.password_confirm) {
      setError((errors) => ({
        ...errors,
        password: "Password Mismatch",
      }));
    }
    else if (userInput.pin ==="" || !userInput.pin) {
      setError((errors) => ({
        ...errors,
        pin: "Please Enter for digit pin",
      }));
    }
    else if (userInput.pin !==userInput.pin_confirm) {
      setError((errors) => ({
        ...errors,
        pin: "Pin code mismatch",
      }));
    }
    else{
      saveData()
      if (userInput.verification_code_destination === "email"){
        setCurrentScreen(7)
      }else{
        setCurrentScreen(6)
      }
    }
  };

  return (
    <div className="">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content">
              <h1 className="text-white">Prime Marketplace</h1>
            </div>
            <div className="left-content">
              <h2 className="text-white">Sell more products</h2>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Subcribe to our online marketplace, list your product or
                <span>
                  services for e-gift card holders to instantly purchase your
                </span>
                products or book appointment to patronize your
                <span>services</span>
              </h4>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-warning learn-more-btn">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img">
            <img src="assets/img/bg6.png" alt="" />
          </div>
        </div>

        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size5 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex float-right log-size">
                    <h4>
                      Already registered?
                      <Link to="/"  className="btn btn-outline-success">
                        Log In
                      </Link>
                    </h4>
                  </div>
                  <div className="mb-4">
                    <img
                      src="assets/img/arrow-left.png"
                      alt=""
                      className="arrow-margin-top btn-prev"
                      onClick={() => setCurrentScreen(currentScreen - 1)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h3>Log in Credentials</h3>
                  </div>
                  <div>
                    <h4>Enter your details below</h4>
                  </div>
                  <div className="d-flex line-grp">
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line"></div>
                  </div>
                  <div className="font-enter">
                    <h4>
                      Last step,{" "}
                      <span>Enter your login authentication details</span>
                    </h4>
                  </div>
                  <div className="form-group mt-1">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="Username"
                      value={userInput.username}
                      name="username"
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.username}</div>
                  </div>
                  <div className="form-group mt-1">
                    <input
                      type="password"
                      className="form-control textbx-size1"
                      placeholder="Password"
                      value={userInput.password}
                      name="password"
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.password}</div>
                  </div>
                  <div className="form-group mt-1">
                    <input
                      type="password"
                      className="form-control textbx-size1"
                      placeholder="confirm password"
                      value={userInput.password_confirm}
                      name="password_confirm"
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.password_confirm}</div>
                  </div>
                  <div className="form-group mt-1">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="Enter four digit pin"
                      value={userInput.pin}
                      name="pin"
                      maxLength={4}
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.pin}</div>
                  </div>
                  <div className="form-group mt-1">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="confirm pin"
                      value={userInput.pin_confirm}
                      name="pin_confirm"
                      maxLength={4}
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.pin_confirm}</div>
                  </div>
                  <div className="d-flex  mt-3 check-text-size1">
                    <input
                      type="checkbox"
                      className="checkbox-design"
                      id="yes"
                      value="yes"
                      name="special_offer"
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="yes" className="label-bottom">
                      <h4 className="ml-2">
                        Yes, I'd like to be first to receive news and special
                        offers from Prime+
                      </h4>
                    </label>
                  </div>
                  <div className="d-flex check-text-size1">
                    <input
                      type="checkbox"
                      className="checkbox-design"
                      id="continue"
                      value="yes"
                      name="policy"
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="continue" className="label-bottom">
                      <h4 className="ml-2">
                        By continuing, you agree to Prime+'s{" "}
                        <span>Privacy Policy</span> and{" "}
                        <span>Terms of Service</span>
                      </h4>
                    </label>
                  </div>
                  <div className="mt-2 continue-btn">
                    <button
                      onClick={handleValidation}
                      className="btn btn-success btn-next"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credentials;
