import { useState, useEffect } from "react";
import axios from "axios";
const ViewMoreRedemption = () => {
    const capitalize = (str) => {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    };
    const [cardRemptionTable, setCardRedemptionTable] = useState([]);
    const [original, setOriginal] = useState([]);
    const [sum, setSum] = useState(0);
    const [cal, setCal] = useState("");
    useEffect(() => {
        var now = new Date();
        var formatDate = `${now.toISOString().split("-")[0]}-${now.toISOString().split("-")[1]
            }`;
        setCal(formatDate);
        axios
            .get(
                "/prime.sika/v1/merchants/reports/transactions?transaction_types[]=card_redemption"
            )
            .then((res) => {
                setOriginal(res.data.data.transactions);
                setCardRedemptionTable(res.data.data.transactions);
                var temp = original.filter((ele) =>
                    ele.created_at.includes(formatDate)
                );

                var sumTemp = temp.reduce((ps, a) => ps + parseFloat(a.amount), 0);
                setSum(sumTemp);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    const handleChange = (e) => {
        setCal(e);
        var temp = original.filter((ele) => ele.created_at.includes(e));
        var sumTemp = temp.reduce((ps, a) => ps + parseFloat(a.amount), 0);
        setCardRedemptionTable(temp);
        setSum(sumTemp);
    };

    const transform1 = (e) => {
        switch (e) {
            case "PAID":
                return <span class="btn btn-sm bg-success-light mr-2">Paid</span>;
                break;
            case "failed":
                return <span class="btn btn-sm bg-danger-light mr-2">Failed</span>;
                break;
            case "pending":
                return <span class="btn btn-sm bg-warning-light mr-2">Pending</span>;
                break;

            default:
                break;
        }
    };
    const [query, setQuery] = useState("")

    return (
        <>
            <div
                className="modal fade"
                id="more_redemptions"
                aria-hidden="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            
                            <img
                                src="assets/img/close-btn.png"
                                alt=""
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card card-chart2 card-h10 card-h10-pad">
                                        <div className="d-flex justify-content-between">
                                            <h3>Total Redemptions this month</h3>
                                            <h3 className="mr-3">Search Total Redemptions by Month</h3>
                                        </div>
                                        <hr className="hr3" />
                                        <div className="d-flex justify-content-between mt-d-flex">
                                            <div className="disbursement-width mt-d-flex1">
                                                <img
                                                    src="assets/img/disbursement.png"
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                                <h3 className="figure-size">¢ {sum.toFixed(2)}</h3>
                                            </div>
                                            <div className="d-flex justify-content-between mb-4 ">
                                                <div className="form-group mt-4">
                                                    <input
                                                        value={cal}
                                                        onChange={(e) => handleChange(e.target.value)}
                                                        className="form-control form-padding-width"
                                                        type="month"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card card-table card-table-height1">
                                        <div className="card-header card-header-margin d-flex justify-content-between">
                                            <div>
                                                <h3 className="my-auto">Card Redemptions Transactions</h3>
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className="form-control search-textbox22"
                                                    type="text"
                                                    placeholder="Search here..."
                                                    onChange={(e) => setQuery(e.target.value)}
                                                />
                                                <img
                                                    src="assets/img/search-normal1.png"
                                                    alt=""
                                                    className="icon-position-1"
                                                />
                                            </div>
                                            <div>
                                                <a href="" className="my-auto btn btn-success">
                                                    <img
                                                        src="assets/img/download.png"
                                                        alt=""
                                                        className="img-fluid mr-2"
                                                    />
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-body booking_card">
                                            <div className="table-responsive">
                                                <table className="datatable table table-stripped table table-hover table-center mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Card ID</th>
                                                            <th>Customer</th>
                                                            <th>Merchant</th>
                                                            <th>Amount</th>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {cardRemptionTable.length === 0 ? (
                                                            <tr className="error-alert-position1 mt-5">
                                                                <td rowSpan={6}> <h1>No Records Found</h1></td>
                                                            </tr>
                                                        ) : (
                                                            cardRemptionTable.filter((ele) => ele.merchant.toLowerCase().includes(query) || ele.person?.first_name?.toLowerCase().includes(query)).map((ele, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{ele.card.id}</td>
                                                                        <td>
                                                                            {capitalize(ele.person.first_name)
                                                                                ? ele.person.first_name
                                                                                : "N/A"}
                                                                        </td>
                                                                        <td>{capitalize(ele.merchant)}</td>
                                                                        <td>¢{parseFloat(ele.amount).toFixed(2)}</td>
                                                                        <td>
                                                                            {" "}
                                                                            {
                                                                                new Date(ele.created_at)
                                                                                    .toLocaleString()
                                                                                    .split(",")[0]
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <div className="actions">
                                                                                {transform1(ele.status)}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewMoreRedemption;