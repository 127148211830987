
const LoginLayout=(props)=>{
    return (
        <>
        <div className="row">
        <div className="col-12">
          <div className="logo-margin">
            <img
              src="/assets/img/logo.png"
              alt=""
              className="img-fluid logo-img-size"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12 pr-0">
          <div className="left-div-content-1 left-div-img mt-3">
            <img src="/assets/img/bg7.png" alt="" className="img-fluid" />
          </div>
          <div className="left-div-content-5">
            <h1>
              We are on a mission
              <span>
                to make <b>e-Gift</b> Card
              </span>
              gifting fun, personalized <span>and simple</span>
            </h1>
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 pl-0">
        {props.children}
        </div>
      </div>
        
        </>
    )
}

export default LoginLayout