import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PagePagination from "../../components/PagePagination";

const MarketProductStockTable = ({
  location,
  setSearch,
  totalPage,
  setTotalPage,
  setCurrentPage,
  isloading,
  products,
  DeleteProduct,
  handleUpdateProductShow,
  handleDetailedProductShow,
  setCurrentScreen,
  store_type,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="card card-table-height-1">
        <div className="card-header card-header-margin">
          <div className="row">
            <div className="col-lg-4">
              <h3 className="my-auto">Product Stocks</h3>
            </div>
            {location?.pathname === "/dashboard" ? (
              ""
            ) : (
              <>
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search product..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => setCurrentScreen(2)}
                      >
                        Upload New Product
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => setCurrentScreen(3)}
                      >
                        Track Orders
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="card-body booking_card">
          <div className="table-responsive">
            <table className="datatable table table-stripped table table-hover table-center mb-0">
              <thead>
                <tr>
                  <th>Product Name</th>
                  {location?.pathname === "/dashboard" ? (
                    ""
                  ) : (
                    <th>Product Image(s)</th>
                  )}

                  <th>Price</th>
                  {(store_type === "undefined" ||
                    store_type?.toLowerCase() === "others") && <th>Quantity</th>}
                  {(store_type === "undefined" ||
                    store_type?.toLowerCase() === "others") && <th>Sold</th>}
                  {(store_type === "undefined" ||
                    store_type?.toLowerCase() === "others") && <th>In stock</th>}
                  {(store_type === "undefined" ||
                    store_type?.toLowerCase() === "others") && <th>Status</th>}
                  {location?.pathname === "/dashboard" ? "" : <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {isloading ? (
                  <>
                    {products?.length === 0 ? (
                      <div className="no-data">
                        <h1> No Product Available</h1>
                      </div>
                    ) : (
                      products &&
                      products?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{ele?.name}</td>
                            {location?.pathname === "/dashboard" ? (
                              ""
                            ) : (
                              <td>
                                <div className="product-img-cover">
                                  <img
                                    src={ele?.pictures[0]}
                                    alt=""
                                    className="product-img-size"
                                  />
                                </div>
                              </td>
                            )}
                            <td>
                              {ele?.currency} {ele?.price}
                            </td>
                            {(store_type === "undefined" ||
                              store_type?.toLowerCase() === "others") && (
                              <td>{ele?.quantity}</td>
                            )}
                            {(store_type === "undefined" ||
                              store_type?.toLowerCase() === "others") && (
                              <td>{ele?.quantity_sold}</td>
                            )}
                            {(store_type === "undefined" ||
                              store_type?.toLowerCase() === "others") && (
                              <td>{ele?.quantity_in_stock}</td>
                            )}
                            {(store_type === "undefined" ||
                              store_type?.toLowerCase() === "others") && (
                              <td>
                                <div>
                                  {ele?.product_quantity_status?.toLowerCase() ===
                                    "high_quantity" && (
                                    <div className="btn btn-sm bg-success-light mr-2">
                                      In stock
                                    </div>
                                  )}
                                  {ele?.product_quantity_status?.toLowerCase() ===
                                    "empty_quantity" && (
                                    <div className="btn btn-sm bg-dark-light mr-2">
                                      Out of stock
                                    </div>
                                  )}
                                  {ele?.product_quantity_status?.toLowerCase() ===
                                    "low_quantity" && (
                                    <div className="btn btn-sm bg-danger-light mr-2">
                                      Low
                                    </div>
                                  )}
                                </div>
                              </td>
                            )}
                            {location?.pathname === "/dashboard" ? (
                              ""
                            ) : (
                              <td>
                                <div className="d-flex">
                                  <div>
                                    <button
                                      onClick={(e) =>
                                        handleUpdateProductShow(e, (i = ele))
                                      }
                                    >
                                      <img
                                        src="/assets/img/edit-2.png"
                                        alt=""
                                        className="cur-pointer img-fluid"
                                        width="40%"
                                        title="update product"
                                      />
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        DeleteProduct(e, (i = ele?.code))
                                      }
                                    >
                                      <img
                                        src="/assets/img/trash.png"
                                        alt=""
                                        className="cur-pointer img-fluid"
                                        width="40%"
                                        title="delete product"
                                      />
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      onClick={(e) =>
                                        handleDetailedProductShow(e, (i = ele))
                                      }
                                    >
                                      <img
                                        src="/assets/img/eye.png"
                                        alt=""
                                        className="cur-pointer"
                                        title="view product in detail"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    )}
                  </>
                ) : (
                  <div className="no-data">
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mr-2">
          {location?.pathname === "/dashboard" ? (
            <>
              {isloading === true ||
                (products?.length > 0 && (
                  <button
                    className="btn btn-success view-more-butn"
                    onClick={() => navigate("/marketplace")}
                  >
                    View more
                  </button>
                ))}
            </>
          ) : (
            <PagePagination
              setTotalPage={setTotalPage}
              totalPage={totalPage}
              setCurrentPage={(e) => setCurrentPage(e)}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default MarketProductStockTable;
