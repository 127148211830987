import axios from "axios";
import { useEffect } from "react";
const MerchantDashboard = ({ PreviewVideoScreen, videos, setVideos }) => {
  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/merchant-resources?page=1&limit=10")
      .then((res) => {
        let vids = res.data.data.resources.filter(
          (ele) => ele?.type.toLowerCase() === "video"
        );

        setVideos(vids);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [videos]);
  return (
    <div
      className="card card-h7 tab-pane fade show active"
      id="pills-merchant1"
      role="tabpanel"
      aria-labelledby="pills-merchant-tab1"
    >
      <div className="row">
        {videos.length > 0 ? (
          videos.map((ele, i) => (
            <div key={i} className="col-lg-4">
              <div>
                <div
                  className="videos-cover mb-3 cursor-pointer"
                  onClick={(e) => PreviewVideoScreen(e, ele)}
                >
                  <video className="play">
                    <source src={ele?.url} type="video/mp4" />
                  </video>
                </div>
                <div className="videos-names">
                  <h3>{ele.title}</h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="error-alert-position col-12">
            <h1>No Videos Found</h1>
          </div>
        )}
      </div>
    </div>
  );
};
export default MerchantDashboard;
