import $ from "jquery";
import { useRef, createRef, useEffect } from "react";
import { useState } from "react";
const UpdateAmount = ({
  amount,
  handleAmount,
  count,
  handleAmts,
  amts,
 }) => {
  $(".form-padding2")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
      }
    })
    .trigger("change");

  $(".form-padding3")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
      }
    })
    .trigger("change");


  return (
    <div className="row mt-3">
      <div className="col-12">
        <h3>Enter Card Amounts in Ghana Cedis</h3>
      </div>
      <div className="col-lg-12">
        <input
          type="number"
          value={amount}
          className="form-control form-padding2 text-center"
          name="amount"
          onChange={(e) => handleAmts(e.target.value)}
        />
      </div>
    </div>
  );
};

export default UpdateAmount;
