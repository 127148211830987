import MerchantDashboardDocuments from "./MerchantDashboardDocuments";

const Documents = ({ PreviewScreen, documents, setDocuments }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-0">
          <div className="">
            <h3 className="text-success">Documents</h3>
          </div>
        </div>
        <div className="col-lg-11">
          <div className="">
            <hr className="hr1 mr-5" />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-2">
          <div className="card card-chart z-position card-h6">
            <div className="px-lg-2 px-sm-3 pt-3">
              <h3>Topics</h3>
            </div>
            <ul
              className="nav d-block sidetab-font"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="btn btn-outline-success tab-btn px-lg-2 px-sm-3 active"
                  id="pills-merchant-tab"
                  data-toggle="pill"
                  href="#pills-merchant"
                  role="tab"
                  aria-controls="pills-merchant"
                  aria-selected="true"
                >
                  Merchant Dashboard
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-10">
          <div className="tab-content" id="pills-tabContent">
            <MerchantDashboardDocuments
              PreviewScreen={PreviewScreen}
              documents={documents}
              setDocuments={(e) => setDocuments(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Documents;
