import { useEffect, useRef,  useState } from "react";
import { Modal } from "react-bootstrap";

const ViewSelectedSubCategoriesModal = ({
  handleSelectedSubCategoriesModalClose,
  showSelectedSubCategoriesModal,
  selectedSubCategories,
  selectedCat,
  setSelectedCat,
  finalChecked,
  setFinalChecked,
  setChecked,
  setCheckedParent,
  selectedParent,
  handleSelectedOldParent,
  checked,
  productCat,
  checkedParent,
  oldSelectedParent
}) => {


const previousValue = useRef(null);
  
useEffect(() => {
  previousValue.current = selectedParent;
}, [selectedParent]);


  const handleChildCheck = (e, chk) => {
    var updatedList = [...selectedCat];
    if (chk) {
      updatedList = [...selectedCat, ...e];
    } else {
      updatedList.splice(selectedCat.indexOf(e[0]), 1);
    }
    setChecked(updatedList);
  };

  const [viewSelectedParent, setViewSelectedParent] = useState(new Map());
  const view = useRef(selectedParent)


  let selectedParentCat = [];
  const handleParentCheck = (id, child) => {
    if (child) {
      var arr = selectedParent.get(id);
      if (arr?.length > 0) {
        var childID = arr.filter((element) => element == child);
        if (childID.length > 0) {
          arr.splice(arr.indexOf(child), 1);
        } else {
          arr.push(child);
        }
        let tempParent = selectedParent;
        tempParent.set(id, arr);

        setViewSelectedParent(tempParent);
      } else {
        let temp = selectedParent;
        temp.set(id, [child]);
        setViewSelectedParent(temp);
      }
    } else {
      var arr = selectedParent.get(id) ?? [];

      if (arr.length == 0) {
        let temp = selectedParent;
        if (temp.has(id)) {
          temp.delete(id);
        } else {
          temp.set(id, []);
        }
        setViewSelectedParent(temp);
      } else {
        let temp = selectedParent;
        temp.delete(id);

        setViewSelectedParent(temp);
      }
    }

    for (let [key, value] of selectedParent) {
      selectedParentCat.push(key);

      selectedParentCat.push(...value);
    }
    setCheckedParent(selectedParentCat);
  };
 

  const handleCheck = (e, chk) => {
    var updatedList = [...selectedCat];
    if (chk) {
      updatedList = [...selectedCat, e];
    } else {
      updatedList.splice(updatedList.indexOf(e), 1);
    }
    setSelectedCat(updatedList);
  };
  const saveReviewChecked = () => {
    setFinalChecked([...checkedParent, ...productCat]);
    setChecked(selectedCat);
    handleSelectedSubCategoriesModalClose();
  };
  const handleSkip = () => {
    handleSelectedOldParent()
    setSelectedCat(finalChecked);
    handleSelectedSubCategoriesModalClose();
  };


  return (
    <Modal show={showSelectedSubCategoriesModal} onHide={handleSkip}>
      <Modal.Header closeButton>
        {/* <Modal.Title> */}
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4 className="mt-3">Product Sub-categories</h4>
          </div>
        </div>
        {/* </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="product-list-font">
          <h4>
            These consist of selected categories for your product. You can edit
            these before product is being uploaded
          </h4>
        </div>
        <ul className="pl-0 mt-4">
          {selectedSubCategories?.map((list, index) => (
            <li key={index}>
              <div className="d-flex  check-text-size">
                <h4 className="ml-2">{list?.name}</h4>
              </div>
              <ul>
                {list?.sub_categories?.map((sublist, i) => (
                  <li key={i}>
                    <div className="d-flex  check-text-size">
                      <input
                        type="checkbox"
                        value={sublist?.id}
                        checked={
                          selectedParent.has(parseInt(sublist?.id))
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleCheck(
                            parseInt(e.target.value),
                            e.target.checked
                          );
                          handleParentCheck(parseInt(sublist?.id), null);
                        }}
                        className="checkbox-design radio_check"
                      />
                      <h4 className="ml-2">{sublist?.name}</h4>
                    </div>
                    <ul>
                      {sublist?.sub_categories?.map((item, indx) => (
                        <li key={indx}>
                          <div className="d-flex  check-text-size">
                            <input
                              type="checkbox"
                              value={item?.id}
                              checked={
                                selectedParent
                                  .get(sublist?.id)
                                  ?.includes(item?.id)
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                handleChildCheck(
                                  [
                                    parseInt(e.target.value),
                                    parseInt(sublist?.id),
                                  ],
                                  e.target.checked
                                );
                                handleParentCheck(
                                  parseInt(sublist?.id),
                                  parseInt(item?.id)
                                );
                              }}
                              className="checkbox-design radio_check"
                            />
                            <h4 className="ml-2 sublist-font">{item?.name}</h4>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <div className="d-flex justify-content-between">
          <div className="">
            <button className="btn btn-danger" onClick={handleSkip}>
              Cancel
            </button>
          </div>
          <div className="">
            <button className="btn btn-success" onClick={saveReviewChecked}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ViewSelectedSubCategoriesModal;
