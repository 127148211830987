const MarketPlaceCards = ({image,value,title,background}) => {
    return (
      <div className="col-xl-3 col-sm-6 col-6">
        <div className={background}>
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="card-body z-position">
            <div className="row">
              <div className="col-2">
                <div className="icon-background">
                  <img src={image} alt="" width="20px" />
                </div>
              </div>
              <div className="col-9">
                <h3 className="card_widget_header">{value}</h3>
              </div>
              <div className="col-12">
                <h6 className="text-mute1 mt-2">{title}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default MarketPlaceCards;
  