import axios from "axios";
import { useState, useEffect } from "react";
import AccountType from "./AccountType";
import BankInfo from "./BankInfo";
import MomoInfo from "./MomoInfo";
import swal from "sweetalert";

const AccountForm = () => {
  const [isAccountloading, setAccountLoading] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [userInput, setUserInput] = useState({
    account_type: "bank",
    bank_name: "",
    name: "",
    number: "",
    bank: "",
    branch: "",
  });

  const Account = () => {
    setLoadingAccount(false);
    axios
      .get("/prime.sika/v1/merchants/bank-accounts/current")
      .then((res) => {
        setLoadingAccount(true);
        setUserInput({
          account_type: res.data.data.bank_account.account_type,
          bank_name: res.data.data.bank_account.bank,
          name: res.data.data.bank_account.name,
          number: res.data.data.bank_account.number,
          branch: res.data.data.bank_account.branch,
          bank: res.data.data.bank_account.bank,
        });
      })
      .catch((err) => {
        setLoadingAccount(false);
        console.log(err.response);
      });
  };

  useEffect(() => {
    Account();
  }, []);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAccount = () => {
    if (userInput?.account_type === "bank") {
      var data = {
        account_type: userInput?.account_type,
        bank: userInput?.bank,
        branch: userInput?.branch,
        name: userInput?.name,
        number: userInput?.number,
      };
    } else {
      var data = {
        account_type: userInput?.account_type,
        bank: userInput?.bank_name,
        name: userInput?.name,
        number: userInput?.number,
      };
    }

    setAccountLoading(true);
    axios
      .post("/prime.sika/v1/merchants/bank-accounts/current", data)
      .then((res) => {
        setAccountLoading(false);
        swal({
          title: "Success",
          text: "Account Saved Successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            Account();
          }
        });
      })
      .catch((err) => {
        setAccountLoading(false);
        console.log(err.response);
      });
  };

  return (
    <form className="card card-chart2 card-h11 card-h10-pad">
      <div>
        <h3>Account Information</h3>
        <hr className="hr3" />
      </div>
      {loadingAccount ? (
        <>
          <div className="ml-4">
            <AccountType
              handleUserInput={handleUserInput}
              accountType={userInput?.account_type}
            />
            {userInput?.account_type === "bank" && (
              <BankInfo
                handleUserInput={handleUserInput}
                userInput={userInput}
              />
            )}
            {userInput?.account_type === "momo" && (
              <MomoInfo
                handleUserInput={handleUserInput}
                userInput={userInput}
              />
            )}

            <div className="mt-5">
              {!isAccountloading && (
                <button
                  type="button"
                  className="btn btn-success float-right save-btn-size"
                  onClick={handleAccount}
                >
                  Save
                </button>
              )}
              {isAccountloading && (
                <button
                  className="btn btn-success float-right save-btn-size"
                  disabled
                >
                  <i className="fas fa-spinner fa-spin mr-2"></i>Saving...
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="no-data-div p-20">
          <div className="no-data">
            <i className="fas fa-spinner fa-spin mr-2"></i>
          </div>
        </div>
      )}
    </form>
  );
};

export default AccountForm;
