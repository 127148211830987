import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BusinessDescription = ({
  setCurrentScreen,
  currentScreen,
  next,
  userInput,
  handleUserInput,
}) => {
  const [storeType, setStoreType] = useState([]);

  useEffect(() => {
    axios
      .get("/market.place/v1/stores/types")
      .then((res) => {
        console.log("res", res.data.data);
        setStoreType(res.data.data);
      })
      .catch((err) => {
        console.log(err?.response);
      });
  }, []);
  const [errors, setError] = useState({
    business_description: "",
  });

  const handleValidation = (e) => {
    e.preventDefault();
    if (
      userInput.business_description === "" ||
      !userInput.business_description
    ) {
      setError((errors) => ({
        ...errors,
        business_description: "Please Enter Business Description",
      }));
    } else {
      setCurrentScreen(4);
    }
  };

  return (
    <div className="">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content">
              <h1 className="text-white">Merchant Dashboard</h1>
            </div>
            <div className="left-content">
              <h2 className="text-white">Easy to Navigate</h2>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Here's where your business has access to all the
                <span>tools you'll need to create, sell, track and</span>
                promote your eGift cards.
              </h4>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-warning learn-more-btn">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img">
            <img src="assets/img/bg6.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size1 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex float-right log-size">
                    <h4>
                      Already registered?
                      <Link to="/" className="btn btn-outline-success">
                        Log In
                      </Link>
                    </h4>
                  </div>
                  <div className="mb-4">
                    <img
                      src="assets/img/arrow-left.png"
                      alt=""
                      className="arrow-margin-top btn-prev"
                      onClick={() => setCurrentScreen(currentScreen - 1)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h3>Sign up to Prime Merchant</h3>
                  </div>
                  <div>
                    <h4>Enter your details below</h4>
                  </div>
                  <div className="d-flex line-grp">
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                  <div className="font-enter">
                    <h4>Brief business description</h4>
                  </div>

                  <div className="form-group mt-4">
                    <textarea
                      name="business_description"
                      value={userInput.business_description}
                      onChange={handleUserInput}
                      id=""
                      required
                      className="textarea-size1"
                      placeholder="Enter brief description about the business and your services, this will be visible to customers on Prime e-gift app, Marketplace and Live Shop"
                    ></textarea>
                    <div className="text-danger">
                      {errors.business_description}
                    </div>
                  </div>
                  <div className="font-enter">
                    <h4>Store Type</h4>
                  </div>
                  <div>
                    <div className="form-group form-group-width mt-2">
                      <select
                        id="store_type_id"
                        className="form-control textbx-size1"
                        name="store_type_id"
                        value={userInput?.store_type_id}
                        onChange={handleUserInput}
                      >
                        <option value="">-- Select --</option>
                        {storeType?.store_types?.map((storetype, i) => (
                          <option value={storetype?.id}>{storetype?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="d-flex  mt-3 check-text-size">
                    <input
                      type="radio"
                      className="checkbox-design radio_check"
                      id="single"
                      value="single"
                      name="merchant_type"
                      onChange={handleUserInput}
                      defaultChecked
                    />
                    <label htmlFor="single">
                      <h4 className="ml-2">
                        <span>Single Merchant</span> (Managing single business
                        location)
                      </h4>
                    </label>
                  </div>
                  <div className="d-flex  mt-2 check-text-size">
                    <input
                      type="radio"
                      className="checkbox-design radio_check"
                      id="franchise"
                      value="franchise"
                      name="merchant_type"
                      onChange={handleUserInput}
                    />
                    <label htmlFor="franchise">
                      <h4 className="ml-2">
                        <span>Franchise Merchant</span> (Managing multiple
                        business locations)
                      </h4>
                    </label>
                  </div>
                  <div className="mt-2 continue-btn">
                    <button
                      className="btn btn-success btn-next"
                      type="button"
                      onClick={handleValidation}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDescription;
