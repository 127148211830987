import { useState, useEffect } from "react";
import axios from "axios";
const LogoUpload = ({
  company,
  updateLogo,
  logoimage,
  setLogoimage,
  onImageChange,
  setProfileLoading,
  isProfileloading,
}) => {
  useEffect(() => {
    axios
      .get("/prime.sika/v1/users/merchant/details")
      .then((res) => {
        setLogoimage(company?.logo_url === null ? "/assets/img/default.png" : company?.logo_url);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [company?.logo_url]);
  return (
    <form action="">
      <div className="card-h13-cover">
        <label htmlFor="update-logo">
          <div className="card card-h13 cur-pointer">
            <img src={logoimage} alt="" />
            <div className="overlay">
              <div className="conttent">
                <img src="/assets/img/camera.png" alt="" width="10%" />
              </div>
            </div>
          </div>
        </label>
        <input
          type="file"
          id="update-logo"
          name="productimage"
          onChange={onImageChange}
        />
        <div>
          {!isProfileloading && (
            <input
              type="submit"
              className="btn btn-outline-success logo-update-btn"
              onClick={updateLogo}
              value="Update logo"
            />
          )}
          {isProfileloading && (
            <button
              className="btn btn-outline-success logo-update-btn"
              disabled
            >
              <i className="fas fa-spinner fa-spin mr-2"></i>Updating logo
            </button>
          )}
        </div>
      </div>
    </form>
  );
};
export default LogoUpload;
