import { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { uploadBulkImages } from "../UploadImageFolder/uploadBulkImages";
import Swal from "sweetalert2";
import GroceriesUpdate from "./updateproductsComponents/GroceriesUpdate";
import RestaurantUpdate from "./updateproductsComponents/RestaurantUpdate";

const UpdateProductModal = ({
  setProducts,
  supportedCurrencies,
  showUpdateProductModal,
  handleUpdateSubCategoriesModal,
  handleUpdateSelectedSubCategoriesModal,
  handleUpdateProductClose,
  setItems,
  items,
  handleUserInput,
  setLoading,
  selectedImages,
  setSelectedImages,
  sizes,
  setSizes,
  productSizes,
  categories,
  setUpdateFinalChecked,
  setUpdateSelectedSubCategories,
  setUpdateProductCat,
  setUpdateChecked,
  setUpdateSubCategories,
  setUpdateSelectedCat,
  updateChecked,
  updateFinalChecked,
  updateSelectedCat,
  setUpdateCheckedParent,
  setUpdateSelectedParent,
  updateCheckedParent,
  setUpdateProductCatID,
  setUpdateParent,
  updatSelectedParent,
  updateParent,
  deleteUpdateParentCat,
  updateSelectedSubCategories,
  setUpdateSelectCatids,
  isDiscountChecked,
  setIsDiscountChecked,
  store_type,
  onResCatRemoved,
  onResCatSelect,
  resCategories
}) => {
  const [updating, setUpdating] = useState(false);
  const [color, setColor] = useState("");
  const [colorList, setColorList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("non_numeric");
  const [isChecked, setIsChecked] = useState(false);
  const [customSizes, setCustomSizes] = useState([{ size: "" }]);
  const [isColorChecked, setIsColorChecked] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [called, setCalled] = useState(false);

  const colorObjects = items?.colors?.map((color) => ({ color }));
  const customSizeObjects = items?.custom_sizes?.map((size) => ({ size }));
  console.log("items", items);
  console.log("colors", colorObjects);
  console.log("selectedOption", selectedOption);
  useEffect(() => {
    if (items.code && !called) {
      setCalled(true);
      if (items?.discount === 0) {
        setIsDiscountChecked(false);
      } else {
        setIsDiscountChecked(true);
      }
      if (items?.custom_sizes?.length > 0) {
        setIsChecked(true);
        if (
          items?.custom_sizes?.length >= 0 &&
          items?.has_custom_sizes === false
        ) {
          setCustomSizes([{ size: "" }]);
        } else if (
          items?.custom_sizes?.length >= 0 &&
          items?.has_custom_sizes === true
        ) {
          setCustomSizes(customSizeObjects);
        }
      } else {
        setIsChecked(false);
      }

      if (items?.has_custom_sizes === true) {
        setSelectedOption("numeric");
      } else {
        setSelectedOption("non_numeric");
      }
    }
  }, [items, called]);
  useEffect(() => {
    if (items.colors && items?.colors.length >= 1) {
      setColorList(colorObjects);
      setIsColorChecked(true);
    }
  }, [items]);
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.id);
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };
  const handleColorCheckboxChange = () => {
    setIsColorChecked((prev) => !prev);
  };
  const handleDiscountChange = () => {
    setIsDiscountChecked((prev) => !prev);
  };

  const handleColorAdd = () => {
    if (colorList.length > 1) {
      const prevItemValue = colorList[colorList.length - 2].color;
      const currentItemValue = colorList[colorList.length - 1].color;

      if (currentItemValue === prevItemValue) {
        Swal.fire({
          title: "Duplicated color",
          icon: "info",
          // confirmButtonColor: "#338540",
          confirmButtonText: "Okay",
        });
      } else {
        setColorList([...colorList, { color: "" }]);
        setFocusedIndex(colorList.length);
      }
    } else {
      setColorList([...colorList, { color: "" }]);
      setFocusedIndex(colorList.length);
    }
  };
  useEffect(() => {
    if (!isColorChecked) {
      setColor("");
      setColorList([]);
      setFocusedIndex(0);
    } else {
      setColor("#000");
      if (items?.colors === null) {
        setColorList([{ color: "" }]);
      } else {
        setColorList(colorObjects);
      }
    }
  }, [isColorChecked]);
  useEffect(() => {
    if (isChecked) {
      setIsChecked(true);
    }
  }, [isChecked]);

  useEffect(() => {
    if (isColorChecked && colorList && colorList[focusedIndex]) {
      const updatedColorList = [...colorList];
      updatedColorList[focusedIndex] = { color };
      setColorList(updatedColorList);
    }
  }, [color, focusedIndex, isColorChecked]);

  const handleColorRemove = (e, index) => {
    const list = [...colorList];
    Swal.fire({
      title: "Are you sure you want to remove this color?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#338540",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        list.splice(index, 1);
        setColorList(list);
      }
    });
  };

  const handleCustomSizeAdd = () => {
    if (
      customSizes.length > 0 &&
      customSizes[customSizes.length - 1].size === ""
    ) {
      Swal.fire({
        title: "Field can't be empty",
        icon: "info",
        confirmButtonText: "Okay",
      });
    } else if (customSizes.length > 1) {
      const currentValue = customSizes[customSizes.length - 1].size;
      const prevValue = customSizes[customSizes.length - 2].size;

      if (currentValue === prevValue) {
        Swal.fire({
          title: "Duplicated sizes",
          icon: "info",
          // confirmButtonColor: "#338540",
          confirmButtonText: "Okay",
        });
      } else {
        setCustomSizes([...customSizes, { size: "" }]);
      }
    } else {
      setCustomSizes([...customSizes, { size: "" }]);
    }
  };

  const handleSizeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...customSizes];
    list[index][name] = value;
    setCustomSizes(list);
  };
  const handleRemoveSize = (e, index) => {
    const list = [...customSizes];
    list.splice(index, 1);
    setCustomSizes(list);
  };

  const sliderRef = useRef(null);
  let multiSelectRef = useRef();

  let data = new FormData();
  const onSelectFile = async (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      data.append("files[]", file);
      return URL.createObjectURL(file);
    });
    let image = await uploadBulkImages(data);
    image.data.files.map((ele) => {
      setSelectedImages((previousImages) => previousImages.concat(ele?.url));
    });
  };

  // UpdateselectedMainCategories
  var catIds = [];
  var categoryIds = [];
  items?.belongs_to_product_types?.map((ele, index) => catIds?.push(ele?.id));

  categories?.map((ele, i) => categoryIds?.push(ele?.id));
  var selectMainCategories = categoryIds?.filter((value) => {
    return catIds?.find((val) => {
      return value === val;
    });
  });

  var searchMainCategory = [];
  let selectedCategoryValues = [];
  console.log("eer",categories)
  categories.map((ele, i) => {
    searchMainCategory = selectMainCategories.includes(ele.id);
    if (searchMainCategory === true) {
      selectedCategoryValues.push(ele);
    }
  });
  var sle = [];
  selectedCategoryValues?.map((ele, index) => sle.push(ele?.id));

  if (updateFinalChecked?.length === 0) {
    updateFinalChecked = catIds;
  }
  if (updateSelectedSubCategories?.length === 0) {
    updateSelectedSubCategories = selectedCategoryValues;
  }

  let selec;
  if (sizes[0] === "Not Applicable") {
    selec = 1;
  }

  const onSizeSelect = (selectedList, selectedItem) => {
    var Product_sizes = [];
    selectedList?.map((ele, index) => Product_sizes.push(ele));
    setItems((items) => ({
      ...items,
      productSizes: Product_sizes,
    }));
    setSizes(Product_sizes);
  };

  const onSizeRemoved = (selectedList, selectedItem) => {
    var Product_sizes = [];
    selectedList?.map((ele, index) => Product_sizes.push(ele));
    setItems((items) => ({
      ...items,
      productSizes: Product_sizes,
    }));
    setSizes(Product_sizes);
  };

  const onCategorySelect = (selectedList, selectedItem) => {
    var selectedSubCatIDs = [];
    selectedItem?.sub_categories?.map((ele, i) => {
      selectedSubCatIDs.push(ele?.id);
    });
    setUpdateSelectCatids(selectedSubCatIDs);
    var productCategory = [];
    selectedList.map((ele, index) => {
      if (selectedItem?.sub_categories?.length === 0) {
        productCategory?.push(ele?.id);
        setUpdateFinalChecked(productCategory);
      } else if (selectedItem?.sub_categories?.length !== 0) {
        handleUpdateSubCategoriesModal();
        setUpdateFinalChecked(productCategory);
        productCategory?.push(ele?.id);
        setUpdateSelectedCat(productCategory);
        setUpdateSubCategories(selectedItem);
      }
    });
    setItems((items) => ({
      ...items,
      categories: productCategory,
    }));
    var subCatList = [];
    selectedList?.map((ele, i) => {
      subCatList = ele;
    });
    if (
      selectedList?.length === 1 &&
      selectedItem?.sub_categories?.length === 0
    ) {
      setUpdateFinalChecked(productCategory);
      setUpdateProductCat(productCategory);
      setUpdateSelectedCat(productCategory);
      setUpdateSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length === 2 &&
      selectedItem?.sub_categories?.length === 0
    ) {
      setUpdateFinalChecked([...updateCheckedParent, ...productCategory]);
      setUpdateProductCat([...updateCheckedParent, ...productCategory]);
      setUpdateSelectedCat([...updateCheckedParent, ...productCategory]);
      setUpdateSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length === 1 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setUpdateFinalChecked(productCategory);
      setUpdateProductCat(productCategory);
      setUpdateSelectedCat(productCategory);
      setUpdateSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length === 2 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setUpdateFinalChecked([...updateCheckedParent, ...productCategory]);
      setUpdateProductCat(productCategory);
      setUpdateSelectedCat(productCategory);
      setUpdateSelectedSubCategories(selectedList);
      setUpdateSelectedParent(updatSelectedParent);
    }
  };

  const onCategoryRemoved = (selectedList, selectedItem) => {
    var productCategory = [];
    selectedList.map((ele, index) => {
      if (ele?.sub_categories?.length === 0) {
        productCategory?.push(ele?.id);
        setUpdateFinalChecked(productCategory);
      } else if (ele?.sub_categories?.length !== 0) {
        productCategory?.push(ele?.id);
        var handleFinalCheck = [...updateFinalChecked];
        handleFinalCheck.splice(
          updateFinalChecked.indexOf(selectedItem?.id),
          1
        );
        setUpdateFinalChecked(handleFinalCheck);
        setUpdateSelectedCat(handleFinalCheck);
      }
    });
    setItems((items) => ({
      ...items,
      categories: productCategory,
    }));
    var subCatList = [];
    selectedList.map((ele, i) => {
      subCatList = ele;
    });

    if (selectedList?.length > 0 && subCatList?.sub_categories?.length === 0) {
      setUpdateFinalChecked(productCategory);
      setUpdateSelectedCat(productCategory);
      setUpdateProductCat(productCategory);
      setUpdateSelectedSubCategories(selectedList);
    } else if (
      selectedList?.length === 0 &&
      selectedItem?.sub_categories?.length === 0
    ) {
      setUpdateFinalChecked(productCategory);
      setUpdateSelectedCat(productCategory);
      setUpdateProductCat(productCategory);
      setUpdateSelectedSubCategories(selectedList);
    } else if (
      selectedList?.length === 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setUpdateFinalChecked(productCategory);
      setUpdateSelectedCat(productCategory);
      setUpdateProductCat(productCategory);
      setUpdateChecked(productCategory);
      setUpdateSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length > 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      var selectedSubList = [];
      subCatList?.sub_categories?.sub_categories?.map((ele, i) =>
        selectedSubList.push(ele?.id)
      );
      var subCategoryList = [...productCategory, ...selectedSubList];
      var listSubCategories = updateSelectedCat?.filter((value) => {
        return subCategoryList?.find((val) => {
          return value === val;
        });
      });
      var temp = updatSelectedParent;
      var selectedSubCatIDs = [];
      selectedItem?.sub_categories?.map((ele, i) => {
        selectedSubCatIDs.push(ele?.id);
      });
      var deleteUpdateSelected = deleteUpdateParentCat?.filter((value) => {
        return selectedSubCatIDs?.find((val) => {
          return value === val;
        });
      });

      deleteUpdateSelected.map((ele) => {
        temp.delete(ele);
      });

      setUpdateSelectedParent(temp);

      var tempCat = [];
      for (let [key, value] of temp) {
        tempCat.push(key);

        tempCat.push(...value);
      }
      setUpdateFinalChecked([...tempCat, ...productCategory]);
      setUpdateSelectedCat([...listSubCategories]);
      setUpdateProductCat([...listSubCategories]);
      setUpdateCheckedParent(tempCat);
      setUpdateSelectedSubCategories(selectedList);

      // setUpdateParent({});
    }
    if (
      selectedList?.length > 0 &&
      subCatList?.sub_categories?.length === 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setUpdateSelectedParent(new Map());
      setUpdateCheckedParent([]);
      setUpdateSelectedSubCategories(selectedList);
    }
    if (
      selectedList?.length === 0 &&
      selectedItem?.sub_categories?.length !== 0
    ) {
      setUpdateSelectedParent(new Map());
      setUpdateCheckedParent([]);
      setUpdateSelectedSubCategories(selectedList);
    }
  };

  // console.log("product", productSizes)
  const handleSubmit = (e) => {
    e.preventDefault();
    setUpdating(true);

    const hasDuplicate = colorList.some((item, index) =>
      colorList
        .slice(index + 1)
        .some((otherItem) => item.color === otherItem.color)
    );
    const hasDuplicateSizes = customSizes.some((item, index) =>
      customSizes
        .slice(index + 1)
        .some((otherItem) => item.size === otherItem.size)
    );

    const data = new FormData();
    data.append("name", items.name);
    updateFinalChecked?.map((ele) => {
      data.append("category_ids[]", ele);
    });

    if (selectedOption === "non_numeric") {
      sizes?.map((ele) => {
        data.append("custom_sizes[]", ele);
      });
      data.append("has_custom_sizes", false);
    } else if (selectedOption === "numeric") {
      customSizes?.map((ele) => {
        data.append("custom_sizes[]", ele.size);
      });
      data.append("has_custom_sizes", true);
    }
    data.append("weight", items.weight);
    data.append("price", items.price);
    data.append("description", items.description);
    colorList?.map((ele) => {
      data.append("colors[]", ele.color);
    });
    data.append("product_code", items?.code);
    data.append("quantity", items.quantity);
    data.append("discount", isDiscountChecked === false ? 0 : items.discount);

    if (isDiscountChecked && items.discount_start_date !== "") {
      data.append("discount_start_date", items.discount_start_date);
    }
    if (isDiscountChecked && items.discount_end_date !== "") {
      data.append("discount_end_date", items.discount_end_date);
    }

    data.append("currency", items.currency);
    data.append("return_policy", items.return_policy);
    data.append("benefits", items?.benefits);
    selectedImages.map((ele) => {
      data.append("pictures[]", ele);
    });

    if (selectedImages.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please upload product image(s)",
        icon: "error",
        button: "OK",
      });
      setUpdating(false);
    } else if (updateFinalChecked?.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Product category is required",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else if (
      items.discount_end_date != "" &&
      items.discount_start_date === ""
    ) {
      Swal.fire({
        title: "Error",
        text: "Discount start date is required",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else if (hasDuplicate) {
      Swal.fire({
        title: "Error",
        text: "There is duplication in product color",
        icon: "error",
        button: "OK",
      });
      setUpdating(false);
    } else if (hasDuplicateSizes) {
      Swal.fire({
        title: "Error",
        text: "There is duplication in product size",
        icon: "error",
        button: "OK",
      });
      setUpdating(false);
    } else {
      axios
        .put("/market.place/v1/products/update", data)
        .then((res) => {
          setUpdating(false);
          Swal.fire({
            title: "Success",
            text: "Product Updated Successfully",
            icon: "success",
            button: "OK",
          }).then((isOkay) => {
            if (isOkay) {
              setUpdateFinalChecked([]);
              setSizes([]);
              setUpdateSelectedCat([]);
              setUpdateSelectedParent(new Map());
              setUpdateCheckedParent([]);
              localStorage.removeItem("oldUpdateParent");
              axios
                .get("/market.place/v1/products/list")
                .then((res) => {
                  setLoading(true);

                  setProducts(res.data.data.products);
                  handleUpdateProductClose();
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err.response);
                });
            }
          });
        })
        .catch((err) => {
          setUpdating(false);
          console.log(err.response);
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
        });
    }
  };

  const CloseUpdateProductModal = () => {
    setUpdateFinalChecked([]);
    setUpdateSelectedCat([]);
    setUpdateCheckedParent([]);
    setUpdateChecked([]);
    setUpdateSelectedParent(new Map());
    handleUpdateProductClose();
  };

  return (
    <Modal
      show={showUpdateProductModal}
      onHide={CloseUpdateProductModal}
      dialogClassName="update-modal"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title> */}
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4 className="mt-3">Update Product</h4>
          </div>
          <div>
            <button onClick={CloseUpdateProductModal}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
        {/* </Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="card card-chart z-position card-height1">
          {(store_type === "undefined" ||
            store_type.toLowerCase() === "others") && (
            <GroceriesUpdate
              updating={updating}
              handleUserInput={handleUserInput}
              items={items}
              handleSubmit={handleSubmit}
              selectedImages={selectedImages}
              sliderRef={sliderRef}
              onSelectFile={onSelectFile}
              setSelectedImages={(e) => setSelectedImages(e)}
              supportedCurrencies={supportedCurrencies}
              isChecked={isChecked}
              handleCheckboxChange={handleCheckboxChange}
              selectedOption={selectedOption}
              setColor={setColor}
              handleRadioChange={handleRadioChange}
              productSizes={productSizes}
              selec={selec}
              onSizeSelect={onSizeSelect}
              onSizeRemoved={onSizeRemoved}
              customSizes={customSizes}
              handleSizeChange={handleSizeChange}
              handleRemoveSize={handleRemoveSize}
              handleCustomSizeAdd={handleCustomSizeAdd}
              isColorChecked={isColorChecked}
              categories={categories}
              onCategorySelect={onCategorySelect}
              onCategoryRemoved={onCategoryRemoved}
              selectedCategoryValues={selectedCategoryValues}
              handleUpdateSelectedSubCategoriesModal={
                handleUpdateSelectedSubCategoriesModal
              }
              multiSelectRef={multiSelectRef}
              handleColorCheckboxChange={handleColorCheckboxChange}
              color={color}
              colorList={colorList}
              handleColorRemove={handleColorRemove}
              handleColorAdd={handleColorAdd}
              isDiscountChecked={isDiscountChecked}
              handleDiscountChange={handleDiscountChange}
            />
          )}
          {store_type !== "undefined" &&
            store_type.toLowerCase() === "restaurant" && (
              <RestaurantUpdate
                updating={updating}
                handleUserInput={handleUserInput}
                items={items}
                handleSubmit={handleSubmit}
                selectedImages={selectedImages}
                sliderRef={sliderRef}
                onSelectFile={onSelectFile}
                setSelectedImages={(e) => setSelectedImages(e)}
                supportedCurrencies={supportedCurrencies}
                isChecked={isChecked}
                handleCheckboxChange={handleCheckboxChange}
                selectedOption={selectedOption}
                setColor={setColor}
                handleRadioChange={handleRadioChange}
                productSizes={productSizes}
                selec={selec}
                onSizeSelect={onSizeSelect}
                onSizeRemoved={onSizeRemoved}
                customSizes={customSizes}
                handleSizeChange={handleSizeChange}
                handleRemoveSize={handleRemoveSize}
                handleCustomSizeAdd={handleCustomSizeAdd}
                isColorChecked={isColorChecked}
                categories={categories}
                onCategorySelect={onCategorySelect}
                onCategoryRemoved={onCategoryRemoved}
                selectedCategoryValues={selectedCategoryValues}
                handleUpdateSelectedSubCategoriesModal={
                  handleUpdateSelectedSubCategoriesModal
                }
                multiSelectRef={multiSelectRef}
                handleColorCheckboxChange={handleColorCheckboxChange}
                color={color}
                colorList={colorList}
                handleColorRemove={handleColorRemove}
                handleColorAdd={handleColorAdd}
                isDiscountChecked={isDiscountChecked}
                handleDiscountChange={handleDiscountChange}
                onResCatRemoved={onResCatRemoved}
                onResCatSelect={onResCatSelect}
                resCategories={resCategories}
              />
            )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UpdateProductModal;
