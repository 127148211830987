import axios from "axios";
let token = localStorage.getItem("token");

const instance = axios.create({
  headers: {
    "Da-Secret": "pCweb",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function uploadBulkImages(data) {
  return instance({
    method: "POST",
    url: "https://api.primeegiftcard.com/prime.sika/v1/upload-bulk/market-products-files",
    maxBodyLength: Infinity,
    data: data,
  }).then((response) => response.data);
}
