import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
const UpdateCustomSuccess = ({
  current2,
  color,
  color1,
  pos,
  amount,
  logo,
  visible,
  DoneUpdating,
}) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="row web-center">
      <div className="col-lg-12">
        <div className="publish-size">
          <h4>e-Gift Card Published</h4>
        </div>
      </div>
      <div className="col-lg-12">
        <div
          style={{
            width: "345px",
            height: "200px",
            boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
            borderRadius: "20px",
          }}
        >
          {current2 === 2 && (
            <>
              <div
                className="left-logo"
                style={{
                  color: color,
                  border: "1px solid",
                  borderRadius: "20px",
                  width: "345px",
                  height: "200px",
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    background: color,
                    width: "99px",
                    position: "relative",
                  }}
                >
                  <div className="logo-size">
                    <img
                      src={logo}
                      alt="First slide"
                      className="upload-btn-img1"
                    />
                  </div>
                  {visible && (
                    <div
                      style={{ color: color1, transition: "ease all 100ms" }}
                    >
                      <h4 className="gift-text">e-Gift Card</h4>
                    </div>
                  )}
                </div>
                <div>
                  <img src="http://via.placeholder.com/246x200" alt="" />
                  {pos === 1 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                  {pos === 3 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        right: "20px",
                        bottom: "0px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {current2 === 3 && (
            <>
              <div
                className="right-logo"
                style={{
                  color: color,
                  border: "1px solid",
                  borderRadius: "20px",
                  width: "345px",
                  height: "200px",
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  overflow: "hidden",
                }}
              >
                <div style={{ width: "243px", position: "relative" }}>
                  <img src="http://placeimg.com/246/200/any" alt="" />
                  {pos === 2 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                  {pos === 4 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        left: "10px",
                        bottom: "0px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: color,
                    width: "99px",
                    position: "relative",
                  }}
                >
                  <div className="logo-size">
                    <img
                      src={logo}
                      alt="First slide"
                      className="upload-btn-img4"
                    />
                  </div>
                  {visible && (
                    <div
                      style={{ color: color1, transition: "ease all 100ms" }}
                    >
                      <h4 className="gift-text">e-Gift Card</h4>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {current2 === 4 && (
            <>
              <div
                className="down-logo"
                style={{
                  color: color,
                  border: "1px solid",
                  borderRadius: "20px",
                  width: "343px",
                  height: "200px",
                  display: "grid",
                  gridTemplateRows: "auto auto",
                  overflow: "hidden",
                }}
              >
                <div style={{ height: "90px", position: "relative" }}>
                  <img src="http://placeimg.com/343/132/any" alt="" />
                  {pos === 1 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                  {pos === 2 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        left: "15px",
                        top: "10px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                </div>
                <div style={{ backgroundColor: color, position: "relative" }}>
                  <label htmlFor="change-logo">
                    <div className="logo-size1">
                      <img
                        src={logo}
                        alt="First slide"
                        className="upload-btn-img-bottom"
                      />
                    </div>
                  </label>
                  {visible && (
                    <div
                      style={{ color: color1, transition: "ease all 100ms" }}
                    >
                      <h4 className="gift-text">e-Gift Card</h4>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {current2 === 5 && (
            <>
              <div
                className="up-logo"
                style={{
                  color: color,
                  border: "1px solid",
                  borderRadius: "20px",
                  width: "343px",
                  height: "200px",
                  display: "grid",
                  gridTemplateRows: "auto auto",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    height: "70px",
                    backgroundColor: color,
                    position: "relative",
                  }}
                >
                  <div className="logo-size1">
                    <img
                      src={logo}
                      alt="First slide"
                      className="upload-btn-img-bottom"
                    />
                  </div>
                  {visible && (
                    <div
                      style={{ color: color1, transition: "ease all 100ms" }}
                    >
                      <h4 className="gift-text">e-Gift Card</h4>
                    </div>
                  )}
                </div>
                <div style={{ height: "132px", position: "relative" }}>
                  <img src="http://placeimg.com/343/132/any" alt="" />
                  {pos === 3 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        right: "20px",
                        bottom: "0px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                  {pos === 4 && (
                    <div
                      style={{
                        color: color,
                        transition: "ease all 100ms",
                        position: "absolute",
                        left: "15px",
                        bottom: "0px",
                      }}
                    >
                      <h4>
                        GH¢ <span>{amount}</span>
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col-lg-12 mt-3 mb-4">
        <div className="success-text">
          <h4>
            <h3>Great!</h3>You successfully publish your custom e-Gift Card and
            it's live
            <span>on Prime app and Prime Plus website</span>
          </h4>
        </div>
      </div>
      <div className="col-lg-12">
        <hr />
      </div>
      <div className="col-lg-12">
        <div className="promo-size">
          <h4>Promote Sales on your Website</h4>
          <h5>
            Launch a digital gifting solution on your website without complex
            <span>
              {" "}
              integrations, Copy and paste your e-Gift card URL and paste on
              your website
            </span>
            to promote sales.
          </h5>
        </div>
      </div>
      <div className="col-lg-12">
        <hr />
      </div>
      <div className="col-lg-12 d-flex justify-content-between">
        <div>
          <button
            type="button"
            className="btn btn-success btn-block review-btn mt-2 ml-4"
          >
            Copy link
          </button>
        </div>
        <div>
          <button
            onClick={() => DoneUpdating()}
            type="button"
            className="btn btn-success btn-block review-btn mt-2 mr-4"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
export default UpdateCustomSuccess;
