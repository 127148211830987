const UpdateUserModal =()=>{
    return(
        <div
        className="modal fade pt-5"
        id="user_edit_page"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Manage Users</h3>
              <img
                src="assets/img/close-btn.png"
                alt=""
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
            <form className="card card-chart2 card-h11 card-h12-pad">
            <div>
                <h3>Add New User Information</h3><hr className="hr3"/>
            </div>
            <div className="ml-4 mt-5">
                <div className="form-group d-flex align-items-center justify-content-between mt-3">
                    <div className="form-name-width">
                        <label htmlFor="name"><h4 className="my-auto">User Type</h4></label>
                    </div>
                    <div className="form-box-width">
                        <select name="" id="" className="form-control form-fill-textbox1">
                            <option value="">Select UserType</option>
                            <option value="">Manager</option>
                            <option value="">Front Desk</option>
                            <option value="">Account</option>
                            <option value="">Other</option>
                        </select>
                    </div>
                </div>
                <div className="form-group d-flex align-items-center mt-4">
                    <div className="form-name-width">
                        <label htmlFor="name"><h4 className="my-auto">Full Name</h4></label>
                    </div>
                    <div className="form-box-width">
                        <input type="text" className="form-control form-fill-textbox1" />
                    </div>
                </div>
                <div className="form-group d-flex align-items-center mt-4">
                    <div className="form-name-width">
                        <label htmlFor="name"><h4 className="my-auto">Email</h4></label>
                    </div>
                    <div className="form-box-width">
                        <input type="text" className="form-control form-fill-textbox1" />
                    </div>
                </div>
                <div className="form-group d-flex align-items-center mt-4">
                    <div className="form-name-width">
                        <label htmlFor="name"><h4 className="my-auto">Telephone</h4></label>
                    </div>
                    <div className="form-box-width">
                        <input type="text" className="form-control form-fill-textbox1" />
                    </div>
                </div>
                <div className="form-group d-flex align-items-center mt-4">
                    <div className="form-name-width">
                        <label htmlFor="name"><h4 className="my-auto">Password</h4></label>
                    </div>
                    <div className="form-box-width">
                        <input type="password" className="form-control form-fill-textbox1" />
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <h3>Privileges</h3><hr className="hr3"/>
            </div>
            <div>
                <div className="note-font">
                    <h4>Select the options you would like to grant user access</h4>
                </div>
                <div className="ml-4">
                    <div className="radio-group1 mt-4 d-flex">
                        <div className="">
                            <label htmlFor="myRadioId1" className="radio1 d-flex align-items-center">
                                <input type="radio" name="radio-phone" id="myRadioId1" className="radio__input mr-1" />
                                <div className="radio__radio1"></div>
                                <h4 className="my-auto">Manage Account</h4>
                            </label>
                            <label htmlFor="myRadioId2" className="radio1 d-flex align-items-center mt-4">
                                <input type="radio" name="radio-phone" id="myRadioId2" className="radio__input mr-1" />
                                <div className="radio__radio1"></div>
                                <h4 className="my-auto">Manage Gift Cards</h4>
                            </label>
                            <label htmlFor="myRadioId3" className="radio1 d-flex align-items-center mt-4">
                                <input type="radio" name="radio-phone" id="myRadioId3" className="radio__input mr-1" />
                                <div className="radio__radio1"></div>
                                <h4 className="my-auto">Create Promotions/Deals</h4>
                            </label>
                        </div>
                        <div className="ml-4">
                            <label htmlFor="myRadioId4" className="radio1 d-flex align-items-center">
                                <input type="radio" name="radio-phone" id="myRadioId4" className="radio__input mr-1" />
                                <div className="radio__radio1"></div>
                                <h4 className="my-auto">Set up Payment Account</h4>
                            </label>
                            <label htmlFor="myRadioId5" className="radio1 d-flex align-items-center mt-4">
                                <input type="radio" name="radio-phone" id="myRadioId5" className="radio__input mr-1" />
                                <div className="radio__radio1"></div>
                                <h4 className="my-auto">Reports</h4>
                            </label>
                            <label htmlFor="myRadioId6" className="radio1 d-flex align-items-center mt-4">
                                <input type="radio" name="radio-phone" id="myRadioId6" className="radio__input mr-1" />
                                <div className="radio__radio1"></div>
                                <h4 className="my-auto">Transactions & Payouts</h4>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <input type="button" className="btn btn-success float-right save-btn-size" value="Add"/>
                </div>
            </div>
        </form>
            </div>
          </div>
        </div>
      </div>
    )
}
export default UpdateUserModal;