import React, {useEffect, useState} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const Header = () => {
	const navigate=useNavigate();

		const logout=()=>{
			axios.post('/prime.sika/v1/users/logout',{user_type:'merchant'})
			localStorage.removeItem('company_code');
			localStorage.removeItem('token');
			localStorage.removeItem('client_type');
			localStorage.removeItem('service_code');
			navigate('/')
		}
	const [userDetails,setUserDetails]=useState([]);
	useEffect(()=>{
		axios.get('/prime.sika/v1/users/merchant/details').then(res=>{
			setUserDetails(res.data.data.user_details);
		  }).catch(err=>{
			console.log(err.response)
		  })
    },[])
        return (
			<>
				<div className="header">
				<div className="row top-bar-mobile justify-content-between">
					<div className="col-4">
						<button className="mobile_btn" id="mobile_btn"> <i className="fas fa-bars"></i> </button>	
					</div>
					<div className="col-8">
						<div className="row">
							<div className="col-4">
								<div className="notification-icon-cover1">
									<a href="#"><img src="assets/img/notification.png" alt="" className="notification-icon-position1" /></a>
								</div>
							</div>
							<div className="col-8">
								<Dropdown>
									<Dropdown.Toggle variant="white"  className='d-flex admin_content'>
										<div className="image">
											<img src={userDetails?.person?.profile_pic_url} alt="" />
										</div>
										<div className="admin_name">
											<h4 className='my-auto mx-2' title={(userDetails?.person?.full_name)}>{(userDetails?.person?.full_name)}</h4>
										</div>
										<div className="admin_dropdownicon">
											<img src="assets/img/arrow-down.png" alt="" />
										</div>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item><Link to='/settings'>Settings</Link></Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item><button onClick={logout}>Logout</button></Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>
				</div>	
				</div>
			</>
        )
    }

export default Header;