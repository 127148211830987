import axios from "axios";
import { useState, useEffect } from "react";
import $ from "jquery";

const UpdateCategory = ({ review, userInput, handleUserInput, cat="", handleCat, handleCatID}) => {
  $(".form-padding4")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
        $(this).css("color", "#000");
      }
    })
    .trigger("change");

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/cards/categories")
      .then((res) => {
        if (res.data.status === 200) {
          setCategories(res.data.data.categories);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <div className="col-width mt-3">
      <div className="form-group form-position">
        <label>
          <h3>Category</h3>
        </label>

        <>
          <select
            className="form-control form-padding4"
            id="sel2"
            name="category"
            onChange={(e)=> {handleCat(categories.find(ele => ele?.id === parseInt(e.target.value))); handleCatID(categories.find(ele => ele?.id === parseInt(e.target.value)))}}
          >
            <option value="">Select category</option>;
            {categories.map((ele, i) => {
              return (
                <option selected={ele?.name === cat} value={ele?.id} key={i}>
                  {ele?.name}
                </option>
              );
            })}
          </select>
          <img
            src="assets/img/arrow-down.png"
            alt=""
            className="select-arrow-position"
          />
        </>
      </div>
    </div>
  );
};

export default UpdateCategory;
