import { Descriptions } from "antd";
import $ from "jquery";
const Description = ({ handleText, description }) => {
  $(".textarea-size")
    .change(function () {
      if ($(this).val() === "" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
        $(this).css("color", "#000");
      }
    })
    .trigger("change");

  return (
    <div className="row mt-3">
      <div className="col-12">
        <h3>E-gift Card Description</h3>
      </div>
      <div className="col-12">
        <textarea
          onChange={(e) => handleText(e.target.value)}
          value={description}
          name="description"
          id=""
          placeholder="Type your e-gift card description here"
          className="textarea-size"
        ></textarea>
      </div>
    </div>
  );
};

export default Description;
