import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const ManageMultipleOrderModal = ({
  showManageMultipleOrderModal,
  handleManageMultipleOrderModalClose,
  orderList,
  setOrderList,
  setOrders,
  orderCurrentPage,
  setOrderTotalPage,
  setSelectedOrders,
  fetchOrders,
  deliveryStatuses,
}) => {
  const [isloading, setLoading] = useState(false);

  const handleUserInput = (e, id) => {
    var temp = orderList.map((ele) => {
      if (ele.id == id) {
        return { ...ele, delivery_status: e.target.value };
      }
      return ele;
    });
    setOrderList(temp);
  };

  console.log("ssse", orderList);
  const changeOrderStatus = () => {
    Swal.fire({
      title: "Are you sure you want to change the delivery status(es)",
      text: "This action is changes the delivery status(es) of the order(s)",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isOkay) => {
      if (isOkay) {
        orderList.map((ele) => {
          setLoading(true);
          axios
            .put(
              `/market.place/v1/orders/state?delivery_state=${ele?.delivery_status}&reference=${ele?.reference}`
            )
            .then((res) => {
              if (res.data.success === true && res.data.status === 200) {
                setLoading(false);
                Swal.fire({
                  title: "Success",
                  text: "Delivery Status(es) Updated Successfully",
                  icon: "success",
                  button: "OK",
                  confirmButtonColor: "#00b17d",
                }).then((isOkay) => {
                  if (isOkay) {
                    fetchOrders();
                    handleManageMultipleOrderModalClose();
                  }
                });

                setSelectedOrders([]);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err.response);
            });
        });
      }
    });
  };

  return (
    <Modal
      show={showManageMultipleOrderModal}
      onHide={handleManageMultipleOrderModalClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex">
            <div>
              <h4 className="mt-3">Manage Product(s) Order</h4>
            </div>
            {orderList.length === 1 && (
              <div className="mt-3 ml-4">
                {orderList[0]?.delivery_status?.toLowerCase() ===
                  "returned" && (
                  <div className="btn btn-sm bg-dark-light mr-2">Returned</div>
                )}
                {orderList[0]?.delivery_status?.toLowerCase() ===
                  "delivered" && (
                  <div className="btn btn-sm bg-success-light mr-2">
                    Delivered
                  </div>
                )}
                {orderList[0]?.delivery_status?.toLowerCase() ===
                  "rejected" && (
                  <div className="btn btn-sm bg-danger-light mr-2">
                    Out of stock
                  </div>
                )}
                {orderList[0]?.delivery_status?.toLowerCase() ===
                  "request_waiting" && (
                  <div className="btn btn-sm bg-warning-light mr-2">
                    Pending
                  </div>
                )}
                {orderList[0]?.delivery_status?.toLowerCase() ===
                  "ready_for_pick_up" && (
                  <div className="btn btn-sm bg-primary-light mr-2">
                    Ready for pickup
                  </div>
                )}
                {orderList[0]?.delivery_status?.toLowerCase() ===
                  "accepted" && (
                  <div className="btn btn-sm bg-success-light1 mr-2">
                    Accepted
                  </div>
                )}
                {orderList[0]?.delivery_status?.toLowerCase() ===
                  "transiting" && (
                  <div className="btn btn-sm bg-warning-light1 mr-2">
                    Transiting
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            <button onClick={handleManageMultipleOrderModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ul className="pl-0 mt-4">
            {orderList?.map((list, index) => (
              <>
                <li key={index?.id} className="mb-5">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="product-img-cover1 mr-3">
                        <img
                          src={list?.product?.pictures[0]}
                          alt=""
                          className="product-img-size1"
                        />
                      </div>
                    </div>

                    <div className="col-lg-9 justify-content-between">
                      <div className="d-block my-auto">
                        <div className="mr-2">
                          <h4 className="delivery-text">
                            Product Name:{" "}
                            <span className="text-capitalize">
                              {list?.product?.name}
                            </span>
                          </h4>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h4 className="delivery-text">
                              Quantity Ordered: <span>{list?.quantity}</span>
                            </h4>
                          </div>
                          <div className="ml-2">
                            <h4 className="delivery-text">
                              Amount Paid:{" "}
                              {list?.net_amount < list?.gross_amount ? (
                                <>
                                  {" "}
                                  <span className="text-danger amt-font">
                                    <s className="ml-1 mr-2">
                                      GHS {list?.gross_amount}
                                    </s>
                                  </span>
                                  <span className="text-success">
                                    GHS {list?.net_amount}
                                  </span>
                                </>
                              ) : (
                                <span className="text-success">
                                  {" "}
                                  {list?.product?.currency} {list?.net_amount}
                                </span>
                              )}
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex">
                          <h4 className="delivery-text my-auto mr-3">
                            Delivery Status:
                          </h4>

                          <select
                            className="form-control"
                            name="delivery_status"
                            defaultValue={list?.delivery_status}
                            onChange={(e) => handleUserInput(e, list?.id)}
                          >
                            {deliveryStatuses?.map((ele, index) => (
                              <option
                                value={ele}
                                key={index}
                                className="text-capitalize"
                              >
                                {ele.toLowerCase() === "rejected"
                                  ? "REJECT"
                                  : ele.toLowerCase() === "accepted"
                                  ? "ACCEPT"
                                  : ele.toLowerCase() === "rejected"
                                  ? "REJECT"
                                  : ele.toLowerCase() === "delivered"
                                  ? "DELIVER"
                                  : ele.toLowerCase() === "returned"
                                  ? "RETURN"
                                  : ele.toLowerCase() === "ready_for_pick_up"
                                  ? "READY FOR PICKUP"
                                  : ele.toLowerCase() === "transiting"
                                  ? "TRANSIT"
                                  : ele}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {orderList?.length > 1 && <hr />}
              </>
            ))}
          </ul>
        </div>

        <div className="float-right mt-3">
          {!isloading && (
            <button className="btn btn-success" onClick={changeOrderStatus}>
              Change Status
            </button>
          )}
          {isloading && (
            <button className="btn btn-success" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>changing...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ManageMultipleOrderModal;
