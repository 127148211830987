
import NewMap from '../../components/NewMap';
const LocationModal = ({getMapLocation}) => {
  return (
    <>
      <div
        className="modal fade modal-z-index"
        id="location-modal"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content moa">
            <div className="modal-body modal-padding">
             <NewMap getMapLocation={getMapLocation}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  
}
export default LocationModal;
