import { useState, useEffect } from "react";
import Deals1 from "./Deals1";
import GiveBack1 from "./GiveBack1";
import PromoCodes1 from "./PromoCodes1";

const Promotions1 = ({ handleReviewData, promoState, setPromoState }) => {
  const [promotionChecked, setPromotionChecked] = useState(false);

  // giveBack
  const [giveStart, setGiveStart] = useState(null);
  const [giveEnd, setGiveEnd] = useState(null);
  const [giveAmount, setGiveAmount] = useState(null);

  // deals
  const [dealsName, setDealsName] = useState(null);
  const [dealsStart, setDealsStart] = useState(null);
  const [dealsAbout, setDealsAbout] = useState(null);
  const [dealsEnd, setDealsEnd] = useState(null);
  const [dealsDiscount, setDealsDiscount] = useState(null);
  const [dealsPercent, setDealsPercent] = useState(null);

  // promo
  const [promoStart, setPromoStart] = useState(null);
  const [promoEnd, setPromoEnd] = useState(null);
  const [promoDealsName, setPromoDealsName] = useState(null);
  const [promoAbout, setPromoAbout] = useState(null);

  const toggleChecked = (e) => {
    setPromotionChecked(!promotionChecked);
  };

  useEffect(() => {

    const data = {
      giveStart,
      giveEnd,
      giveAmount,
      type: "giveBack",
    };
    if (!promotionChecked) {
      data.type = "";
    }
    handleReviewData(data);
  }, [giveStart, giveEnd, giveAmount]);

  useEffect(() => {

    const data = {
      dealsName,
      dealsStart,
      dealsAbout,
      dealsEnd,
      dealsDiscount,
      dealsPercent,
      type: "deal",
    };
    if (!promotionChecked) {
      data.type = "";
    }
    handleReviewData(data);
  }, [
    dealsName,
    dealsStart,
    dealsAbout,
    dealsEnd,
    dealsDiscount,
    dealsPercent,
  ]);

  useEffect(() => {
    const data = {
      promoStart,
      promoEnd,
      promoDealsName,
      promoAbout,
      type: "promo",
    };
    if (!promotionChecked) {
      data.type = "";
    }
    handleReviewData(data);
  }, [promoStart, promoEnd, promoDealsName, promoAbout]);

  useEffect(() => {
    setPromotionChecked(promoState);
  }, [promoState]);

  var promotion = <></>;
  if (promotionChecked) {
    promotion = (
      <div className="row mt-3">
        <div className="col-12">
          <h3>Promotional Type</h3>
        </div>
        <div className="col-12">
          <div className="card card-chart1 z-position card-height-10">
            <ul className="nav btn-margin-left" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="btn btn-outline-success btn-size px-lg-4 px-sm-3 active"
                  id="pills-home-give-tab-give"
                  data-toggle="pill"
                  href="#pills-give-back-give"
                  role="tab"
                  aria-controls="pills-home-give"
                  aria-selected="true"
                >
                  Give Back
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="btn btn-outline-success btn-size px-lg-4 px-sm-3"
                  id="pills-profile-tab-deal"
                  data-toggle="pill"
                  href="#pills-deals-deal"
                  role="tab"
                  aria-controls="pills-profile-deal"
                  aria-selected="false"
                >
                  Deals
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="btn btn-outline-success btn-size px-lg-4 px-sm-3"
                  id="pills-promo-tab-promo"
                  data-toggle="pill"
                  href="#pills-promo-promo"
                  role="tab"
                  aria-controls="pills-promo-promo"
                  aria-selected="false"
                >
                  Promo Codes
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <GiveBack1
                handleAmount={(e) => setGiveAmount(e)}
                handleStart={(e) => setGiveStart(e)}
                handleEnd={(e) => setGiveEnd(e)}
              />
              <Deals1
                handleName={(e) => setDealsName(e)}
                handleStart={(e) => setDealsStart(e)}
                handleAbout={(e) => setDealsAbout(e)}
                handleEnd={(e) => setDealsEnd(e)}
                handleDiscount={(e) => setDealsDiscount(e)}
                handlePercent={(e) => setDealsPercent(e)}
              />
              <PromoCodes1
                handleStart={(e) => setPromoStart(e)}
                handleEnd={(e) => setPromoEnd(e)}
                handleDealsName={(e) => setPromoDealsName(e)}
                handleAbout={(e) => setPromoAbout(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {/* <div className="row">
        <div className="col-lg-12 mt-5">
          <div className="d-flex">
            <label
              htmlFor="runpromo"
              className="radio1 d-flex align-items-center"
            >
              <input
                type="checkbox"
                name="radio-phone"
                id="runpromo"
                className="checkbox-design mr-2"
                checked={promotionChecked}
                onChange={toggleChecked}
                promoState
              />
              <h4 className="my-auto" htmlFor="run-promo">
                Run Promotion
              </h4>
            </label>
          </div>
        </div>
      </div> */}

      {promotion}
    </>
  );
};

export default Promotions1;
