import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import LoginLayout from "./loginComponents/LoginLayout";
import NewPasswordForm from "./resetPasswordComponents/NewPasswordForm";
import ResetVerificationForm from "./resetPasswordComponents/ResetVerificationForm";

const ResetPassowrd = () => {
  const [phone, setPhone] = useState();
  const [processing, setProcessing] = useState(false);
  const [sendingSms, setSendingSms] = useState(false);
  const [userInput, setUserInput] = useState({
    phone: "",
  });

  const [current, setCurrent] = useState(1);
  const [token, setToken] = useState(null);
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    setUserInput((userInput) => ({
      ...userInput,
      phone: phone,
    }));
  }, [phone]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [errors, setError] = useState({
    first_name: "",
    surname: "",
    phone: "",
    password_confirmation: "",
  });

  const handleOnChange = (e) => {
    setError((error) => ({
      ...error,
      [e.target.name]: "",
    }));
    handleUserInput(e);
  };

  const resendViaSms = (e) => {
    setSendingSms(true);
    e.preventDefault();

    if (!userInput.phone || userInput.phone === "") {
      setError((error) => ({
        ...error,
        phone: "Please enter your phone number",
      }));
    } else {
      setLoading(true);
      axios
        .post("/prime.sika/v1/customers/user-otp", {
          telephone: userInput.phone,
          user_type: "merchant",
        })
        .then((res) => {
          setLoading(false);
          setCurrent(2);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
            confirmButtonColor: "#00b17d",
          });
        });
    }
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const handleValidation = (e) => {
    setProcessing(true);
    if (verificationCode.length !== 6) {
      setProcessing(false);
      setErrorMessage("Invalid Verification Code Entered");
    } else {
      setLoading(true);
      axios
        .post(
          `/prime.sika/v1/customers/verify-user-otp?telephone=${userInput.phone}&otp=${verificationCode}&user_type=merchant`
        )
        .then((res) => {
          localStorage.setItem("new_code", res.data.data.new_code);
          setCurrent(3);
          setProcessing(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setProcessing(false);
          console.log(err.response.data);
          setErrorMessage("Invalid Verification Code Entered");
        });
      // changeScreen(e);
    }
  };

  return (
    <>
      <LoginLayout>
        <div className="card card-size">
          <div className="card-body">
            {current === 1 && (
              <>
                <div className="mb-2">
                  <Link to="/">
                    <img src="/assets/img/arrow-left.png" alt="" />
                  </Link>
                </div>
                <div>
                  <h3 className="text-left">Forgot Password?</h3>
                </div>
                <div>
                  <h4>Recover your Password by entering your phone number</h4>
                </div>
                <form>
                  <div className="form-group mt-5">
                    {/* <PhoneInput
                                                type="tel"
                                                className="form-control textbx-size"
                                                placeholder="Enter your phone number here"
                                                defaultCountry="GH"
                                                value={phone}
                                                name="phone"
                                                onChange={setPhone}
                                            required
                                            /> */}
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className=" textbx-size"
                        placeholder="Enter your phone number here"
                        name="phone"
                        value={phone}
                        onChange={handleUserInput}
                      />
                      <div className="img-backg">
                        <img
                          src="/assets/img/call1.png"
                          alt=""
                          className="img-fluid img-pos"
                        />
                      </div>
                    </div>
                    <div className="text-danger">{errors.phone}</div>
                  </div>
                  {!isloading && (
                    <button
                      type="button"
                      className="btn btn-success log-in-btn-size mt-4"
                      onClick={resendViaSms}
                    >
                      Continue
                    </button>
                  )}
                  {isloading && (
                    <button
                      className="btn btn-success log-in-btn-size mt-4"
                      disabled
                    >
                      <i className="fas fa-spinner fa-spin mr-2"></i>Continuing
                    </button>
                  )}
                </form>
              </>
            )}
            {current === 2 && (
              <ResetVerificationForm
                current={current}
                userInput={userInput}
                setCurrent={setCurrent}
                setToken={setToken}
                token={token}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                setVerificationCode={setVerificationCode}
                handleValidation={handleValidation}
                setLoading={setLoading}
                isloading={isloading}
              />
            )}
            {current === 3 && (
              <NewPasswordForm
                current={current}
                userInput={userInput}
                handleUserInput={handleUserInput}
                setCurrent={setCurrent}
                token={token}
                setToken={setToken}
              />
            )}
          </div>
        </div>
        <div className="left-div-cont">
          <h5>
            PRIME - We are on a mission to make gifting fun, personalized and
            simple with e-gift card.
          </h5>
        </div>
      </LoginLayout>
    </>
  );
};
export default ResetPassowrd;
