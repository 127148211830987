import Slider from "react-slick";
import DashboardLayout from "../layouts/DashboardLayout";

const Marketing = () =>{
    const settings = {
        dots: true,
        infinite: true,
        variableWidth: true,
        autoplay: true,
        nextArrow: (
          <img
            src="assets/img/arrow.png"
            alt=""
            className="arrow-position1 slick-next"
          />
        ),
      };
    return (
        <DashboardLayout>
          <div className="row">
            <div className="col-lg-10">
              <div className="row mt-2">
                <div className="col-lg-12">
                  <div>
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item nbbn" role="presentation">
                        <a className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2 active" id="pills-promotions-tab" data-toggle="pill" href="#pills-promotions" role="tab" aria-controls="pills-promotions" aria-selected="TRUE">Promotions<div className="str-line1"></div></a>
                        
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2" id="pills-socials-tab" data-toggle="pill" href="#pills-socials" role="tab" aria-controls="pills-socials" aria-selected="false">Social Media<div className="str-line2"></div></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2" id="pills-email-tab" data-toggle="pill" href="#pills-email" role="tab" aria-controls="pills-email" aria-selected="false">Email<div className="str-line3"></div></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2" id="pills-sms-tab" data-toggle="pill" href="#pills-sms" role="tab" aria-controls="pills-sms" aria-selected="false">SMS<div className="str-line4"></div></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="btn bg-dark-light tab-btn1 px-lg-4 px-sm-3 mr-2" id="pills-reports-tab" data-toggle="pill" href="#pills-reports" role="tab" aria-controls="pills-reports" aria-selected="false">Reports<div className="str-line5"></div></a>
                      </li>
                    </ul><hr className="hr2"/>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-promotions" role="tabpanel" aria-labelledby="pills-promotions-tab">
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="card card-chart z-position card-h5">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row mt-3">
                              <div className="col-12">
                                <h3>Promotional Type</h3>
                              </div>
                              <div className="col-9">
                                <div className="card card-chart1 z-position card-height-13">
                                  <div className="row">
                                    <div className="col-12">
                                      <ul
                                        class="nav btn-margin-left1"
                                        id="pills-tab"
                                        role="tablist"
                                      >
                                        <li class="nav-item" role="presentation">
                                          <a
                                            class="btn btn-outline-success btn-size px-lg-4 px-sm-3 active"
                                            id="pills-home-tab1"
                                            data-toggle="pill"
                                            href="#pills-give-back1"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                          >
                                            Give Back
                                          </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <a
                                            class="btn btn-outline-success btn-size px-lg-4 px-sm-3"
                                            id="pills-profile-tab1"
                                            data-toggle="pill"
                                            href="#pills-deals1"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                          >
                                            Deals
                                          </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <a
                                            class="btn btn-outline-success btn-size px-lg-4 px-sm-3"
                                            id="pills-promo-tab1"
                                            data-toggle="pill"
                                            href="#pills-promo1"
                                            role="tab"
                                            aria-controls="pills-promo"
                                            aria-selected="false"
                                          >
                                            Promo Codes
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="pills-give-back1"
                                      role="tabpanel"
                                      aria-labelledby="pills-home-tab"
                                    >
                                      <section className="variable slider slider12 mt-3">
                                        <Slider {...settings}>
                                          <div className="card-cover1">
                                            <div>
                                              <img
                                                src="assets/img/pic.png"
                                                className="img-size1"
                                              />
                                            </div>
                                            <div>
                                              <h5>Breast Cancer Awareness</h5>
                                            </div>
                                            <div>
                                              <h6>
                                                Learn more about the organization
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="card-cover1">
                                            <div>
                                              <img
                                                src="assets/img/pic.png"
                                                className="img-size1"
                                              />
                                            </div>
                                            <div>
                                              <h5>Breast Cancer Awareness</h5>
                                            </div>
                                            <div>
                                              <h6>
                                                Learn more about the organization
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="card-cover1">
                                            <div>
                                              <img
                                                src="assets/img/pic.png"
                                                className="img-size1"
                                              />
                                            </div>
                                            <div>
                                              <h5>Breast Cancer Awareness</h5>
                                            </div>
                                            <div>
                                              <h6>
                                                Learn more about the organization
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="card-cover1">
                                            <div>
                                              <img
                                                src="assets/img/pic.png"
                                                className="img-size1"
                                              />
                                            </div>
                                            <div>
                                              <h5>Breast Cancer Awareness</h5>
                                            </div>
                                            <div>
                                              <h6>
                                                Learn more about the organization
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="card-cover1">
                                            <div>
                                              <img
                                                src="assets/img/pic.png"
                                                className="img-size1"
                                              />
                                            </div>
                                            <div>
                                              <h5>Breast Cancer Awareness</h5>
                                            </div>
                                            <div>
                                              <h6>
                                                Learn more about the organization
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="card-cover1">
                                            <div>
                                              <img
                                                src="assets/img/pic.png"
                                                className="img-size1"
                                              />
                                            </div>
                                            <div>
                                              <h5>Breast Cancer Awareness</h5>
                                            </div>
                                            <div>
                                              <h6>
                                                Learn more about the organization
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="card-cover1">
                                            <div>
                                              <img
                                                src="assets/img/pic.png"
                                                className="img-size1"
                                              />
                                            </div>
                                            <div>
                                              <h5>Breast Cancer Awareness</h5>
                                            </div>
                                            <div>
                                              <h6>
                                                Learn more about the organization
                                              </h6>
                                            </div>
                                          </div>
                                        </Slider>
                                      </section>
                                      <div className="form-group d-flex mt-2 display-sm-block">
                                        <span>
                                          <input
                                            className="form-control date-textbox4"
                                            type="date"
                                            placeholder="Start Date"
                                          />
                                          <input
                                            className="form-control date-textbox5"
                                            type="date"
                                            placeholder="End Date"
                                          />
                                        </span>
                                        <span>
                                          <input
                                            className="form-control amount-textbox1"
                                            type="text"
                                            placeholder="Enter amount to donate"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="pills-deals1"
                                      role="tabpanel"
                                      aria-labelledby="pills-profile-tab1"
                                    >
                                      <div className="form-group d-flex mt-3 display-sm-block">
                                        <span>
                                          <input
                                            className="form-control amount-textbox3 mr-3"
                                            type="text"
                                            placeholder="DealsName"
                                          />
                                        </span>
                                        <span>
                                          <input
                                            className="form-control date-textbox size-date"
                                            type="date"
                                            placeholder="Start Date"
                                          />
                                          <input
                                            className="form-control date-textbox1 size-date"
                                            type="date"
                                            placeholder="End Date"
                                          />
                                        </span>
                                      </div>
                                      <div className="row mt-5">
                                        <div className="col-lg-7">
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <a
                                                href="/merchants"
                                                className="btn btn-success logo-upload-btn"
                                              >
                                                <img
                                                  src="assets/img/gallery-export.png"
                                                  alt=""
                                                  className="gallery-img"
                                                />{" "}
                                                Upload Your Logo
                                              </a>
                                            </div>
                                            <div className="col-lg-12">
                                              <div className="radio-group1 mt-4">
                                                <div className="d-flex">
                                                  <label
                                                    htmlFor="myRadioId2"
                                                    className="radio1 d-flex align-items-center"
                                                  >
                                                    <input
                                                      type="radio"
                                                      name="radio-phone"
                                                      id="myRadioId2"
                                                      className="radio__input mr-1"
                                                    />
                                                    <div className="radio__radio1"></div>
                                                    <h4 className="my-auto">
                                                      Discount
                                                    </h4>
                                                  </label>
                                                  <input
                                                    className="form-control amount-textbox discount-ml amount-size"
                                                    type="text"
                                                    placeholder="5% Discount on every sale"
                                                  />
                                                </div>
                                                <div className="d-flex mt-3">
                                                  <label
                                                    htmlFor="myRadioId3"
                                                    className="radio1 d-flex align-items-center"
                                                  >
                                                    <input
                                                      type="radio"
                                                      name="radio-phone"
                                                      id="myRadioId3"
                                                      className="radio__input mr-1"
                                                    />
                                                    <div className="radio__radio1"></div>
                                                    <h4 className="my-auto">
                                                      Special
                                                    </h4>
                                                    <input
                                                      className="form-control amount-textbox2 buy-ml amount-size1"
                                                      type="text"
                                                      placeholder="Buy one get 2nd half price"
                                                    />
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-5">
                                          <a
                                            href="/merchants"
                                            className="btn btn-secondary1 logo-preview-btn mt-4"
                                          >
                                            <img
                                              src="assets/img/gallery-export1.png"
                                              alt=""
                                              className="gallery-img1"
                                            />{" "}
                                            <h6>Upload Your Logo</h6>
                                          </a>
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-12">
                                          <h3>About the Deal</h3>
                                        </div>
                                        <div className="col-12 text-color">
                                          <h4>What is the deal about</h4>
                                        </div>
                                        <div className="col-12">
                                          <textarea
                                            name=""
                                            id=""
                                            placeholder="Brief information about the deal"
                                            className="textarea-size4"
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="pills-promo1"
                                      role="tabpanel"
                                      aria-labelledby="pills-promo-tab1"
                                    >
                                      <div className="form-group d-flex mt-3 display-sm-block">
                                        <span>
                                          <input
                                            className="form-control amount-textbox3 mr-3"
                                            type="text"
                                            placeholder="DealsName"
                                          />
                                        </span>
                                        <span>
                                          <input
                                            className="form-control date-textbox size-date"
                                            type="date"
                                            placeholder="Start Date"
                                          />
                                          <input
                                            className="form-control date-textbox1 size-date"
                                            type="date"
                                            placeholder="End Date"
                                          />
                                        </span>
                                      </div>
                                      <div className="row mt-5">
                                        <div className="col-lg-12">
                                          <div className="row">
                                            <div className="col-lg-5">
                                              <a
                                                href="/merchants"
                                                className="btn btn-success logo-upload-btn"
                                              >
                                                <img
                                                  src="assets/img/gallery-export.png"
                                                  alt=""
                                                  className="gallery-img"
                                                />{" "}
                                                Upload Your Logo
                                              </a>
                                            </div>
                                            <div className="col-lg-7 d-flex align-items-center dt-mt">
                                              <div>
                                                <img
                                                  src="assets/img/scan-barcode.png"
                                                  alt=""
                                                  className="gallery-img1 img-fluid"
                                                />
                                              </div>
                                              <div className="scan-size d-flex">
                                                <h4 className="my-auto">
                                                  Quantity:
                                                </h4>
                                                <h5 className="my-auto">
                                                  200 Promo Codes uploaded
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-5">
                                        <div className="col-12">
                                          <h3>About the Deal</h3>
                                        </div>
                                        <div className="col-12 text-color">
                                          <h4>What is the deal about</h4>
                                        </div>
                                        <div className="col-12">
                                          <textarea
                                            name=""
                                            id=""
                                            placeholder="Brief information about the deal"
                                            className="textarea-size4"
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row mt-3 review-row-margin">
                              <div className="col-12">
                                <h3>Retailer Gift Card Terms & Conditions</h3>
                              </div>
                              <div className="col-12 text-color">
                                <h4>Add the terms and conditions for your card</h4>
                              </div>
                              <div className="col-12">
                                <textarea
                                  name=""
                                  id=""
                                  placeholder="Type your conditions here"
                                  className="textarea-size3"
                                ></textarea>
                              </div>
                            </div>

                            <div className="row">
                              <div className="button-cover1 mt-3 mb-5">
                                <button
                                  type="submit"
                                  className="btn btn-success btn-block review-btn"
                                >
                                  Review
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card card-table card-table-height2">
                        <div className="card-header card-header-margin d-flex justify-content-between">
                          <h3 className="my-auto">Promotions Running</h3>
                          <div className="d-flex">
                            <a
                              href="/merchants"
                              className="my-auto btn btn-success download-margin"
                            >
                              <img
                                src="assets/img/download.png"
                                alt=""
                                className="img-fluid mr-2"
                              />
                              Download
                            </a>
                            {/* <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                      <DropdownToggle nav className='d-flex admin_content2 ml-5'>
                                        <div className="admin_name2">
                                          <h4 className='my-auto'>This month</h4>
                                        </div>
                                        <div class="admin_dropdownicon">
                                          <img src="assets/img/arrow-down.png" alt="" />
                                        </div>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem><Link to=''>Settings</Link></DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem><Link to=''>Logout</Link></DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown> */}
                          </div>
                        </div>
                        <div className="card-body booking_card">
                          <div className="table-responsive">
                            <table className="datatable table table-stripped table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Type of Promo</th>
                                  <th>Date</th>
                                  <th>Promo Name</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-danger-light mr-2"
                                      >
                                        Inactive
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-warning-light mr-2"
                                      >
                                        Completes
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="checkbox-design"
                                    />
                                  </th>
                                  <td>Give Back Promo</td>
                                  <td>12 Jan. 2022</td>
                                  <td>Father's Day Give Back</td>
                                  <td>10%</td>
                                  <td>
                                    <div className="actions">
                                      {" "}
                                      <a
                                        href="/merchants"
                                        className="btn btn-sm bg-success-light mr-2"
                                      >
                                        Active
                                      </a>{" "}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-socials" role="tabpanel" aria-labelledby="pills-socials-tab">
                  socials
                </div>
                <div className="tab-pane fade" id="pills-email" role="tabpanel" aria-labelledby="pills-email-tab">
                  email
                </div>
                <div className="tab-pane fade" id="pills-sms" role="tabpanel" aria-labelledby="pills-sms-tab">
                  mnmnmn
                </div>
                <div className="tab-pane fade" id="pills-reports" role="tabpanel" aria-labelledby="pills-reports-tab">
                  reports
                </div>
              </div>
            </div>
          </div>
        </DashboardLayout>
    )
}
export default Marketing