import axios from "axios";
import { useState, useEffect } from "react";
import BankBranches from "./BankBranches";

const BankInfo = ({ handleUserInput, userInput }) => {
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/bank-accounts/supported-banks")
      .then((res) => {
        setBanks(res.data.data.banks);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/bank-accounts/supported-banks/branches", {
        params: { bank: userInput?.bank },
      })
      .then((res) => {
        setBranches(res.data.data.branches);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [banks, userInput]);

  return (
    <div id="bankinfo">
      <div className="form-group d-flex align-items-center mt-4">
        <div className="form-name-width">
          <label htmlFor="name">
            <h4 className="my-auto">Bank Name</h4>
          </label>
        </div>
        <div className="form-box-width">
          <select
            className="form-control form-fill-textbox1"
            id="sel2"
            name="bank"
            value={userInput?.bank}
            onChange={handleUserInput}
          >
            <option>Select Bank</option>
            {banks.map((bank, index) => (
              <option key={index} value={bank?.code}>
                {bank?.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <BankBranches
        branches={branches}
        userInput={userInput}
        handleUserInput={handleUserInput}
      />

      <div className="form-group d-flex align-items-center mt-4">
        <div className="form-name-width">
          <label htmlFor="name">
            <h4 className="my-auto">Account Name</h4>
          </label>
        </div>
        <div className="form-box-width">
          <input
            type="text"
            className="form-control form-fill-textbox1"
            onChange={handleUserInput}
            name="name"
            value={userInput?.name}
          />
        </div>
      </div>

      <div className="form-group d-flex align-items-center mt-4">
        <div className="form-name-width">
          <label htmlFor="name">
            <h4 className="my-auto">Account Number</h4>
          </label>
        </div>
        <div className="form-box-width">
          <input
            type="text"
            className="form-control form-fill-textbox1"
            value={userInput?.number}
            onChange={handleUserInput}
            name="number"
          />
        </div>
      </div>
    </div>
  );
};

export default BankInfo;
