import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const ChangeLocationStatus = ({
  showChangeLocationStatusModal,
  handleLocationStatusModalClose,
  setLocationList,
  locationList,
  changeMerchantCurrentStatus,
  changingStatus,
}) => {
  const handleUserInput = (e, id) => {
    var temp = locationList.map((ele) => {
      if (ele.id == id) {
        return { ...ele, status: e.target.value };
      }
      return ele;
    });
    setLocationList(temp);
  };

 

  return (
    <Modal
      show={showChangeLocationStatusModal}
      onHide={handleLocationStatusModalClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex">
            <div>
              <h4 className="mt-3">Manage Location(s)</h4>
            </div>
            {locationList.length === 1 && (
              <div className="mt-3 ml-4">
                {locationList[0]?.status?.toLowerCase() === "under_review" && (
                  <span className="btn btn-sm bg-warning-light ml-5">
                    Pending
                  </span>
                )}
                {locationList[0]?.status?.toLowerCase() === "approved" && (
                  <span className="btn btn-sm bg-success-light ml-5">
                    Active
                  </span>
                )}
                {locationList[0]?.status?.toLowerCase() === "suspended" && (
                  <span className="btn btn-sm bg-danger-light ml-5">
                    Suspended
                  </span>
                )}
              </div>
            )}
          </div>
          <div>
            <button onClick={handleLocationStatusModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ul className="pl-0 mt-4">
            {locationList?.map((list, index) => (
              <>
                <li key={index?.id} className="mb-5">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="product-img-cover1 mr-3">
                        <img
                          src={
                            list?.logo_url === null
                              ? "/assets/img/default.png"
                              : list?.logo_url
                          }
                          alt=""
                          className="product-img-size1"
                        />
                      </div>
                    </div>

                    <div className="col-lg-9 justify-content-between">
                      <div className="d-block my-auto">
                        <div className="mr-2">
                          <h4 className="delivery-text1">
                            Location/Branch : <span>{list?.branch}</span>
                          </h4>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h4 className="delivery-text">
                              Merchant Name : <span>{list?.name}</span>
                            </h4>
                          </div>
                          <div className="ml-2">
                            <h4 className="delivery-text">
                              Tel : <span>{list?.telephone}</span>
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex">
                          <h4 className="delivery-text my-auto mr-3">
                            Location Status:
                          </h4>
                          <select
                            className="form-control"
                            name="status"
                            value={list?.status}
                            onChange={(e) => handleUserInput(e, list?.id)}
                          >
                            <option value="suspended">Deactivate</option>
                            <option value="under_review">Pending</option>
                            <option value="approved">Activate</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {locationList?.length > 1 && <hr />}
              </>
            ))}
          </ul>
        </div>

        <div className="float-right mt-3">
          {!changingStatus && (
            <button
              className="btn btn-success"
              onClick={changeMerchantCurrentStatus}
            >
              Change Status
            </button>
          )}
          {changingStatus && (
            <button className="btn btn-success" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>changing...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ChangeLocationStatus;
