import AccountManager from "./merchantComponents/AccountManager";
import AffiliateForm from "./merchantComponents/AffiliateForm";
import BusinessDescription from "./merchantComponents/BusinessDescription";
import BusinessDetails from "./merchantComponents/BusinessDetails";
import Credentials from "./merchantComponents/Credentials";
import ThankYou from "./merchantComponents/ThankYou";
import Verification from "./merchantComponents/Verification";
import { useState } from "react";
import axios from "axios";
import LocationModal from "../settingsComponents/LocationModal";
import swal from "sweetalert";
import ResendCodeModal from "./merchantComponents/ResendCodeModal";

const MerchantSignUp = ({
  userTypeInput,
  handleUserTypeInput,
  setCurrent,
  current,
}) => {
  const [showResendModal, setShowResendModal] = useState(false);
  const [location, setlocation] = useState({
    lng: "",
    lat: "",
    address: "",
  });

  const getMapLocation = (lngParam, latParam, address) => {
    setlocation((location) => ({
      ...location,
      lng: lngParam,
      lat: latParam,
      address: address,
    }));
  };
  const [currentScreen, setCurrentScreen] = useState(1);
  const [userInput, setUserInput] = useState({
    affiliate_code: "",
    business_name: "",
    location: "",
    business_website: "",
    tin_number: "",
    telephone: "",
    email: "",
    business_description: "",
    position: "manager",
    fullname: "",
    username: "",
    password: "",
    password_confirm: "",
    pin: "",
    pin_confirm: "",
    merchant_type: "single",
    verification_code_destination: "sms",
    policy: "",
    special_offer: "",
    store_type_id: ""
  });

  const saveData = () => {
    const data = {
      agent_ref: userInput.affiliate_code,
      tin_number: userInput.tin_number,
      username: userInput.username,
      password: userInput.password,
      confirmed_password: userInput.password_confirm,
      pin: userInput.pin,
      confirmed_pin: userInput.pin_confirm,
      telephone: userInput.telephone,
      email: userInput.email,
      first_name: userInput.first_name,
      surname: userInput.surname,
      verify_option: userInput.verification_code_destination,
      position: userInput.position,
      description: userInput.business_description,
      service_codes: [userTypeInput.user_type],
      store_type_id: Number(userInput?.store_type_id),
      company: {
        address: userInput.business_address,
        name: userInput.business_name,
        business_registration_number: userInput.tin_number,
        tin_number: userInput.tin_number,
        type: userInput.merchant_type,
        branch: location?.address,
        website_url: userInput.business_website,
        email: userInput.email,
      },
      cordinates: {
        address: location?.address,
        latitude: location?.lat,
        longitude: location?.lng,
      },
    };
    axios
      .post("/prime.sika/v1/users/merchant/signup", data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
        setCurrentScreen(2);
      });
  };

  
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <div>
        {currentScreen === 1 && (
          <AffiliateForm
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            userInput={userInput}
            handleUserInput={handleUserInput}
            setCurrent={setCurrent}
            current={current}
          />
        )}
        {currentScreen === 2 && (
          <BusinessDetails
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            userInput={userInput}
            handleUserInput={handleUserInput}
            location={location}
            setlocation={setlocation}
          />
        )}
        {currentScreen === 3 && (
          <BusinessDescription
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            userInput={userInput}
            handleUserInput={handleUserInput}
          />
        )}
        {currentScreen === 4 && (
          <AccountManager
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            userInput={userInput}
            handleUserInput={handleUserInput}
          />
        )}
        {currentScreen === 5 && (
          <Credentials
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            userInput={userInput}
            handleUserInput={handleUserInput}
            saveData={saveData}
          />
        )}
        {currentScreen === 6 && (
          <Verification
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            userInput={userInput}
            handleUserInput={handleUserInput}
            setShowResendModal={(e) => setShowResendModal(e)}
          />
        )}
        {currentScreen === 7 && (
          <ThankYou
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            userInput={userInput}
            handleUserInput={handleUserInput}
          />
        )}
      </div>
      <LocationModal getMapLocation={getMapLocation} />
      <ResendCodeModal
        showResendModal={showResendModal}
        setShowResendModal={(e) => setShowResendModal(e)}
        userInput={userInput}
      />
    </>
  );
};

export default MerchantSignUp;
