import React from "react";
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import PageHeader from '../components/PageHeader';
import CustomerContactForm from "./customerCareComponent/CustomerContactForm";
const CustomerCare = () => {
        return (
            <>
                <div className="main-wrapper">
					<Header/>
					<Sidebar/>
					<div className="page-wrapper">
						<div className="content container-fluid">
							<PageHeader/>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="div-background">
                                        <div className="div-back-content">
                                            <h3>Customer Care</h3>
                                            <h4>We're always here to help you</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="card card-h8">
                                                            <div className="card-circle">
                                                                <div className="inner-card-circle">
                                                                    <img src="assets/img/box-search.png" alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="card-margin">
                                                                <h3>Track or resend <span>your gift card</span></h3>
                                                            </div>
                                                            <div className="card-margin">
                                                                <h4>Check the status of your eGift Cards <span>or resend with our real-time</span>tracker.</h4>
                                                            </div>
                                                            <div className="card-margin1">
                                                                <a href="">Track my e-gift card <img src="assets/img/arrow-right1.png" alt="" className="ml-3 arrow-mg-top"/></a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="card card-h8">
                                                            <div className="card-circle">
                                                                <div className="inner-card-circle1">
                                                                    <img src="assets/img/search-status.png" alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="card-margin">
                                                                <h3>Search our help <span>centre</span></h3>
                                                            </div>
                                                            <div className="card-margin">
                                                                <h4>Answers to FAQ can be found at our <span>Help Centre.</span></h4>
                                                            </div>
                                                            <div className="card-margin1">
                                                                <a href="">Search for help <img src="assets/img/arrow-right1.png" alt="" className="ml-3 arrow-mg-top"/> </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="card card-h8">
                                                            <div className="card-circle">
                                                                <div className="inner-card-circle2">
                                                                    <img src="assets/img/messages.png" alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="card-margin">
                                                                <h3>Chat with us</h3>
                                                            </div>
                                                            <div className="card-margin">
                                                                <h4>Need support or have a question? <span>We're here to help.</span></h4>
                                                            </div>
                                                            <div className="card-margin1">
                                                                <a href="">Start a chat <img src="assets/img/arrow-right1.png" alt="" className="ml-3 arrow-mg-top"/></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-11">
                                                <div className="card card-h9">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <CustomerContactForm/>
                                                        </div>
                                                        <div className="str-line"></div>
                                                        <div className="col-lg-5">
                                                            <div className="group-mess-icon">
                                                                <img src="assets/img/Groupmessageicon.png" alt="" className="img-fluid"/>
                                                            </div>
                                                            <div className="text-margin2 ml-3">
                                                                <h3>Can't find what you're after?</h3>
                                                            </div>
                                                            <div className="text-margin2 ml-3">
                                                                <h4>Our customer team is available Monday-Sunday to help solve your 
                                                                    <span>issues. We try our hardest to respond to all queries as soon as</span>
                                                                    possible, however, in some cases we may need some extra time to 
                                                                    <span>get the exact information you need</span> 
                                                                </h4>
                                                            </div>
                                                             <div className="d-block ml-3 group-marg">
                                                                <div>
                                                                    <h4 className="bg-success-light2 p-2"><img src="assets/img/sms1.png" alt="" /> info@primeegiftcard.com</h4>
                                                                </div>
                                                                <div>
                                                                    <h4 className="bg-success-light2 p-2"><img src="assets/img/call1.png" alt="" /> +233548958517 / +233207920300 / +233247117448</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
export default CustomerCare;