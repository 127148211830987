import { Modal } from "react-bootstrap";

const DocumentViewModal = ({
  showDocumentViewModal,
  handleDocumentViewModalClose,
  viewDocument,
  setCurrentScreen,
}) => {
  const viewPDF = () => {
    setCurrentScreen(2);
    handleDocumentViewModalClose();
  };
  return (
    <Modal show={showDocumentViewModal} onHide={handleDocumentViewModalClose}>
      <Modal.Header closeButton>
        <div className="col-md-12">
          <button
            onClick={handleDocumentViewModalClose}
            className="float-right"
          >
            <img src="/assets/img/close-btn.png" alt="" />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-md-12">
          <div className="url-cover">
            <h4>{viewDocument?.url}</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mt-4 d-flex justify-content-between">
            <div>
              <input
                type="button"
                className="btn btn-secondary"
                value="View PDF"
                onClick={viewPDF}
              />
            </div>
            <div>
            
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DocumentViewModal;
