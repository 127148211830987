import CardPurchases from "./transactionComponents/CardDisbursements";
import CardRedemptions from "./transactionComponents/CardRedemptions";
import ProductSales from "./transactionComponents/ProductSales";

const Transactions = () => {
  return (
    <div className="row">
      <div className="col-lg-2">
        <div className="card card-chart2 z-position card-h10">
          <div className="px-lg-2 px-sm-3 pt-4">
            <h3>Transactions</h3>
          </div>
          <ul
            className="nav d-block sidetab-font"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className="btn btn-outline-success tab-btn px-lg-2 px-sm-3 active"
                id="pills-redemptions-tab"
                data-toggle="pill"
                href="#pills-redemptions"
                role="tab"
                aria-controls="pills-redemptions"
                aria-selected="true"
              >
                Card Redemptions
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="btn btn-outline-success tab-btn px-lg-2 px-sm-3"
                id="pills-purchases-tab"
                data-toggle="pill"
                href="#pills-purchases"
                role="tab"
                aria-controls="pills-purchases"
                aria-selected="true"
              >
                Card Disbursements
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="btn btn-outline-success tab-btn px-lg-2 px-sm-3"
                id="product-sales-tab"
                data-toggle="pill"
                href="#product-sales"
                role="tab"
                aria-controls="product-sales"
                aria-selected="true"
              >
                Product Sales
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-10">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-redemptions"
            role="tabpanel"
            aria-labelledby="pills-redemptions-tab"
          >
            <CardRedemptions />
          </div>
          <div
            className="tab-pane fade show"
            id="pills-purchases"
            role="tabpanel"
            aria-labelledby="pills-purchases-tab"
          >
            <CardPurchases />
          </div>
          <div
            className="tab-pane fade show"
            id="product-sales"
            role="tabpanel"
            aria-labelledby="product-sales-tab"
          >
            <ProductSales />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Transactions;
