import { useState, useEffect, createRef, cardItem } from "react";

import swal from "sweetalert";
import axios from "axios";
import UpdateImageUpload from "./mainCardUpdateComponents/UpdateImageUpload";
import UpdateAmount from "./mainCardUpdateComponents/UpdateAmount";
import UpdateCategory from "./mainCardUpdateComponents/UpdateCategory";
import UpdatePromotion from "./mainCardUpdateComponents/UpdatePromotion";
import UpdateTerms from "./mainCardUpdateComponents/UpdateTerms";
import UpdateGiftCardGallery from "./mainCardUpdateComponents/UpdateGiftCardGallery";
import UpdateMainCardReview from "./mainCardUpdateComponents/UpdateMainCardReview";
import UpdateSuccess from "./mainCardUpdateComponents/UpdateSuccess";
import UpdateDescription from "./mainCardUpdateComponents/UpdateDescription";
const UpdateMainCard = ({
  current,
  setCurrent,
  cardItem,
  setCurrentDashboardScreen,
  DoneUpdating,
}) => {


  const [category, setCategory] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [newBgImage, setNewBgImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [tc, setTc] = useState(null);
  const [description, setDescription] = useState(null);
  const [amount, setAmount] = useState(null);
  const [color, setColor] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [pos, setPos] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [url, setUrl] = useState(null);
  const [amts, setAmts] = useState(null);
  const [show, toggle] = useState(false);
  const [cards, setCards] = useState([]);
  const [promoState, setPromoState] = useState(cardItem?.is_promotion);
  const [promotionChecked, setPromotionChecked] = useState(false);

  const [posLeftBottom, setLeftBottom] = useState(["bottom", "left"]);
  const [posRightBottom, setRightBottom] = useState(["bottom", "right"]);
  const [posLeftTop, setLeftTop] = useState(["top", "left"]);
  const [posRightTop, setRightTop] = useState(["top", "right"]);

  var card_style = "";
  if (pos === 1) {
    card_style = `<div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin-left: auto; margin-right: auto; position: relative;">
    <img src=${image} alt="" style="width: 50%; border-radius: 1rem; box-shadow: rgba(0, 0, 0, 0.31) 0px 3px 5px 0px; object-fit: cover;" />
    <div style="position: absolute; width: 50%; height: 100%;">
      <div style="color: ${color}; font-size: 1rem; font-weight: 600; position: absolute; top: 0px; right: 0px; padding: rem;">¢${amts}</div>
    </div>
  </div>`;
  } else if (pos === 2) {
    card_style = `<div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin-left: auto; margin-right: auto; position: relative;">
    <img src=${image} alt="" style="width: 50%; border-radius: 1rem; box-shadow: rgba(0, 0, 0, 0.31) 0px 3px 5px 0px; object-fit: cover;" />
    <div style="position: absolute; width: 50%; height: 100%;">
      <div style="color: ${color}; font-size: 1rem; font-weight: 600; position: absolute; top: 0px; left: 0px; padding: 1rem;">¢${amts}</div>
    </div>
  </div>`;
  } else if (pos === 3) {
    card_style = `<div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin-left: auto; margin-right: auto; position: relative;">
    <img src=${image} alt="" style="width: 50%; border-radius: 1rem; box-shadow: rgba(0, 0, 0, 0.31) 0px 3px 5px 0px; object-fit: cover;" />
    <div style="position: absolute; width: 50%; height: 100%;">
      <div style="color: ${color}; font-size: 1rem; font-weight: 600; position: absolute; bottom: 0px; right: 0px; padding: 1rem;">¢${amts}</div>
    </div>
  </div>`;
  } else if (pos === 4) {
    card_style = `<div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin-left: auto; margin-right: auto; position: relative;">
    <img src=${image} alt="" style="width: 50%; border-radius: 1rem; box-shadow: rgba(0, 0, 0, 0.31) 0px 3px 5px 0px; max-height: 12rem; min-height: 12rem; object-fit: cover;" />
    <div style="position: absolute; width: 50%; height: 100%;">
      <div style="color: ${color}; font-size: 1rem; font-weight: 600; position: absolute; bottom: 0px; left: 0px; padding: 1rem;">¢${amts}</div>
    </div>
  </div>`;
  }

  var amountPosition = null;
  if (pos === 1) {
    amountPosition = posRightTop;
  } else if (pos === 2) {
    amountPosition = posLeftTop;
  } else if (pos === 3) {
    amountPosition = posRightBottom;
  } else if (pos === 4) {
    amountPosition = posLeftBottom;
  }

  useEffect(() => {
    const data = {
      image,
      categoryName,
      title,
      tc,
      amts,
      categoryID,
    };
  }, [title, tc, amts, categoryName, image, categoryID]);

  const handleValidation = () => {
    // if (
    //   (amts[0] !== "" && parseFloat(amts[0]) < 25) ||
    //   (amts[1] !== "" && amts[1] < 25) ||
    //   (amts[2] !== "" && amts[2] < 25) ||
    //   (amts[3] !== "" && amts[3] < 25) ||
    //   (amts[4] !== "" && amts[4] < 25) ||
    //   (amts[4] !== "" && amts[4] < 25)
    // ) {
    //   swal({
    //     title: "Error",
    //     text: "Minimum Amount is GH¢ 25.00",
    //     icon: "error",
    //     button: "OK",
    //   });
    // } else
    if (promotionChecked && reviewData?.giveStart?.value === "giveBack") {
      swal({
        title: "Error",
        text: "Minimum Amount",
        icon: "error",
        button: "OK",
      });
    } else {
      setCurrent(2);
    }
  };
  const handleCatName = (e) => {
    setCategory(e);
    setImage(e.img);
    setCategoryName(e.item.category);
  };
  const company_code = localStorage.getItem("company_code");

  const makeRequest = (data) => {
    var config = {
      method: "put",
      url: "/prime.sika/v1/cards",
      data: data,
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        swal({
          title: "Error",
          text: error.response.data.error_msg,
          icon: "error",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            setCurrent(1);
          }
        });
      });
  };

  const handleClick = () => {
    switch (reviewData.type) {
      case "giveBack":
        var data = {
          card_id: cardItem?.id,
          company_code: company_code,
          amount: amount ? amount : cardItem?.amount,
          discounted_amount: null,
          description: description ? description : cardItem?.description,
          percentage_off: null,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title || cardItem?.title,
          image: newImage ? newImage : cardItem?.image,
          card_background: newBgImage ? newBgImage : cardItem?.card_background,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color
              ? color
              : cardItem?.web_design_props?.default_color,
            amount_position: amountPosition
              ? amountPosition
              : cardItem?.web_design_props?.amount_position,
            amount_position_number: pos
              ? pos
              : cardItem?.web_design_props?.amount_position_number,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID
            ? categoryID
            : cardItem?.card_category_id,
          has_coupon_tag: false,
          coupon_tag: null,
          card_category:
            category?.item.category || categoryName || cardItem?.card_category,
          default_color: color ? color : cardItem?.default_color,
          disable_image_upload: true,
          is_promotion: promotionChecked,
          promotion_title: null,
          promotion_start: reviewData.giveStart,
          promotion_end: reviewData.giveEnd,
          promo_description: null,
          give_back_campaign_id: "",
          who_created_id: 3,
          card_style: card_style ? card_style : cardItem?.card_style,
          owner_id: null,
          promo_type: reviewData.type,
          promo_state: null,
          promo_value: reviewData.giveAmount,
          is_feature: false,
          terms_and_conditions: tc ? tc : cardItem?.terms_and_conditions,
          groupings: {
            code: "0f911764b7a1a7a8e25c",
            group_id: 139,
            amounts: [
              {
                card_id: 187,
                amount: "0.0",
              },
            ],
          },
        };

        makeRequest(data);
        break;
      case "deal":
        var dealsData = {
          card_id: cardItem?.id,
          company_code: company_code,
          amount: amount ? amount : cardItem?.amount,
          description: description ? description : cardItem?.description,
          discounted_amount: reviewData.dealsDiscount,
          percentage_off: reviewData.dealsPercent,
          client_id: 2,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title || cardItem?.title,
          image: newImage ? newImage : cardItem?.image,
          card_background: newBgImage ? newBgImage : cardItem?.card_background,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color
              ? color
              : cardItem?.web_design_props?.default_color,
            amount_position: amountPosition
              ? amountPosition
              : cardItem?.web_design_props?.amount_position,
            amount_position_number: pos
              ? pos
              : cardItem?.web_design_props?.amount_position_number,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID
            ? categoryID
            : cardItem?.card_category_id,
          has_coupon_tag: false,
          coupon_tag: null,
          card_category:
            category?.item.category || categoryName || cardItem?.card_category,
          default_color: color ? color : cardItem?.default_color,
          disable_image_upload: true,
          is_promotion: promotionChecked,
          promotion_title: reviewData.dealsName,
          promotion_start: reviewData.dealsStart,
          promotion_end: reviewData.dealsEnd,
          promo_description: reviewData.dealsAbout,
          who_created_id: 3,
          promotion_terms: null,
          card_style: card_style ? card_style : cardItem?.card_style,
          owner_id: null,
          promo_type: "promotionDiscount",
          promo_state: null,
          promo_value: null,
          is_feature: false,
          terms_and_conditions: tc ? tc : cardItem?.terms_and_conditions,
          groupings: {
            group_id: 139,
            amounts: [
              {
                amount: "0.0",
              },
            ],
          },
        };
        makeRequest(dealsData);
        break;
      case "promo":
        var promoData = JSON.stringify({
          card_id: cardItem?.id,
          company_code: company_code,
          amount: amts.length === 1 ? amts : null,
          list_of_amounts: amount.length > 1 ? amts : null,
          image: newImage,
          description: description ? description : cardItem?.description,
          card_background: newBgImage,
          selected: true,
          default_color: color,
          card_category_id: categoryID
            ? categoryID
            : cardItem?.card_category_id,
          disable_image_upload: true,
          amount_position: pos,
          is_promotion: promotionChecked,
          promotion_type: reviewData.type,
          promo_type: null,
          promo_state: null,
          promotion_title: null,
          title: category?.item?.gallery[0].title || title,
          promotion_start: reviewData.promoStart,
          promotion_end: reviewData.promoEnd,
          promo_value: reviewData.promoAmount,
          promotion_description: null,
          created_at: new Date(),
          updated_at: new Date(),
          category: category?.item.category || categoryName,
          show_amount: true,
          give_back_campaign_id: "",
          card_style:
            '<div style="position: relative; display: flex; justify-content: space-between; border-width: 0px; background-color: transparent;"><div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin-left: auto; margin-right: auto; position: relative;"><img id="tempId" src={img ? img : img1} alt="" style="width: 50%; border-radius: 1rem; box-shadow: rgba(0, 0, 0, 0.31) 0px 3px 5px 0px; max-height: 12rem; min-height: 12rem; object-fit: cover;"><div style="position: absolute; width: 50%; height: 100%;"><div style="color: color; font-size: 1rem; position: absolute; top: 0px; right: 0px; padding: 1rem;">¢{amount}</div></div></div></div>',
          company_code: company_code,
          web_design_props: {
            logo_position: ["bottom", "left"],
            amount_position: ["top", "right"],
            default_color: "#fff",
            font_weight: "normal",
            show_amount: true,
          },
          state: "published",
        });
        makeRequest(promoData);
        break;
      default:
        var defaultData = {
          card_id: cardItem?.id,
          company_code: company_code,
          amount: amount ? amount : cardItem?.amount,
          description: description ? description : cardItem?.description,
          discounted_amount: null,
          percentage_off: null,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title || cardItem?.title,
          image: newImage ? newImage : cardItem?.image,
          card_background: newBgImage ? newBgImage : cardItem?.card_background,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color
              ? color
              : cardItem?.web_design_props?.default_color,
            amount_position: amountPosition
              ? amountPosition
              : cardItem?.web_design_props?.amount_position,
            amount_position_number: pos
              ? pos
              : cardItem?.web_design_props?.amount_position_number,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID
            ? categoryID
            : cardItem?.card_category_id,
          has_coupon_tag: false,
          coupon_tag: null,
          card_category:
            category?.item.category || categoryName || cardItem?.card_category,
          default_color: color ? color : cardItem?.default_color,
          disable_image_upload: true,
          is_promotion: promotionChecked,
          promotion_title: null,
          promotion_start: null,
          promo_description: null,
          promotion_terms: null,
          card_style: card_style ? card_style : cardItem?.card_style,
          owner_id: null,
          promo_type: null,
          promo_state: null,
          promo_value: null,
          is_feature: false,
          terms_and_conditions: tc ? tc : cardItem?.terms_and_conditions,
          groupings: {
            amounts: [
              {
                amount: "0.0",
              },
            ],
          },
        };
        makeRequest(defaultData);
        break;
    }
  };

  return (
    <>
      <div>
        {current === 1 && (
          <form action="">
            <UpdateImageUpload
              cardItem={cardItem}
              handleNewImage={(e) => setNewImage(e)}
              handlePos={(e) => setPos(e)}
              handleTitle={(e) => setTitle(e)}
              handleImage={(e) => setImage(e)}
              handleColor={(e) => setColor(e)}
              handleBgImage={(e) => setNewBgImage(e)}
              setBackgroundImage={(e) => setBackgroundImage(e)}
              image={image ? image : cardItem?.image}
              newImage={newImage ? newImage : cardItem?.image}
              title={
                category?.item?.gallery[0].title ||
                title?.length > 0 ||
                title == ""
                  ? title
                  : title || cardItem?.title
              }
              amount={category?.item.gallery[0].amount || amount}
              color={color ? color : cardItem?.default_color}
              pos={
                pos ? pos : cardItem?.web_design_props?.amount_position_number
              }
              backgroundImage={
                backgroundImage ? backgroundImage : cardItem?.card_background
              }
            />
            <UpdateAmount
              handleAmts={(e) => setAmount(e)}
              amount={
                amount?.length > 0 || amount == "" ? amount : cardItem?.amount
              }
              show={show}
            />
            <UpdateCategory
              handleCat={(e) => setCategoryName(e.name)}
              handleCatID={(e) => setCategoryID(e.id)}
              cat={
                category?.item.category ||
                categoryName ||
                cardItem?.card_category
              }
            />
            <UpdatePromotion
              handleReviewData={(e) => setReviewData(e)}
              promotionChecked={promotionChecked}
              setPromotionChecked={setPromotionChecked}
              promoState={promoState}
              setPromoState={setPromoState}
              cardItem={cardItem}
            />
            <UpdateTerms
              terms={
                tc?.length > 0 || tc == "" ? tc : cardItem?.terms_and_conditions
              }
              handleText={(e) => setTc(e)}
            />
            <UpdateDescription
              description={
                description?.length > 0 || description == ""
                  ? description
                  : cardItem?.description
              }
              handleDescription={(e) => setDescription(e)}
            />
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-success btn-block review-btn mt-2"
                  onClick={() => handleValidation()}
                >
                  Review
                </button>
              </div>
            </div>
            <UpdateGiftCardGallery handleCategory={(e) => handleCatName(e)} />
          </form>
        )}
      </div>
      {current === 2 && (
        <UpdateMainCardReview
          handleImage={(e) => setImage(e)}
          type={reviewData.type}
          current={current}
          setCurrent={setCurrent}
          image={image || category?.img || cardItem?.image}
          color={color ? color : cardItem?.default_color}
          category={
            category?.item.category || categoryName || cardItem?.card_category
          }
          terms={tc ? tc : cardItem?.terms_and_conditions}
          handlePublish={handleClick}
          pos={pos ? pos : cardItem?.web_design_props?.amount_position_number}
          amount={amount ? amount : cardItem?.amount}
          description={description ? description : cardItem?.description}
          title={category?.item?.gallery[0].title || title || cardItem?.title}
          backgroundImage={
            backgroundImage ? backgroundImage : cardItem?.card_background
          }
        />
      )}
      {current === 3 && (
        <UpdateSuccess
          current={current}
          setCurrent={setCurrent}
          color={color ? color : cardItem?.default_color}
          image={image || category?.img || cardItem?.image}
          pos={pos ? pos : cardItem?.web_design_props?.amount_position_number}
          amount={amount ? amount : cardItem?.amount}
          DoneUpdating={DoneUpdating}
        />
      )}
    </>
  );
};

export default UpdateMainCard;
