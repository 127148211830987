import { useState, useEffect } from "react";
import UpdateDeals from "./updatePromotionComponents/UpdateDeals";
import UpdateGiveBack from "./updatePromotionComponents/UpdateGiveBack";
import UpdatePromoCodes from "./updatePromotionComponents/UpdatePromoCodes";

const UpdatePromotion = ({ handleReviewData, promoState, promotionChecked, setPromotionChecked, cardItem}) => {

  // giveBack
  const [giveStart, setGiveStart] = useState(null);
  const [giveEnd, setGiveEnd] = useState(null);
  const [giveAmount, setGiveAmount] = useState(null);

  // deals
  const [dealsName, setDealsName] = useState(null);
  const [dealsStart, setDealsStart] = useState(null);
  const [dealsAbout, setDealsAbout] = useState(null);
  const [dealsEnd, setDealsEnd] = useState(null);
  const [dealsDiscount, setDealsDiscount] = useState(null);
  const [dealsPercent, setDealsPercent] = useState(null);

  // promo
  const [promoStart, setPromoStart] = useState(null);
  const [promoEnd, setPromoEnd] = useState(null);
  const [promoDealsName, setPromoDealsName] = useState(null);
  const [promoAbout, setPromoAbout] = useState(null);


  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    var hour = ("0" + date.getUTCHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    return `${date.getFullYear()}-${mnth}-${day} ${hour}:${minutes}:00`;
  }

  const toggleChecked = (e) => {
    setPromotionChecked(!promotionChecked);
  };

  useEffect(() => {

    const data = {
      giveStart,
      giveEnd,
      giveAmount,
      type: "giveBack",
    };
    if (!promotionChecked) {
      data.type = ""
    }
    handleReviewData(data);
  }, [giveStart, giveEnd, giveAmount]);

  useEffect(() => {

    const data = {
      dealsName,
      dealsStart,
      dealsAbout,
      dealsEnd,
      dealsDiscount,
      dealsPercent,
      type: "deal",
    };
    if (!promotionChecked) {
      data.type = ""
    }
    handleReviewData(data);
  }, [dealsName, dealsStart, dealsAbout, dealsEnd, dealsDiscount, dealsPercent]);

  useEffect(() => {
    const data = {
      promoStart,
      promoEnd,
      promoDealsName,
      promoAbout,
      type: "promo"
    };
    if (!promotionChecked) {
      data.type = ""
    }
    handleReviewData(data);
  }, [promoStart, promoEnd, promoDealsName, promoAbout]);

  useEffect(() => {
    const data = {
      type: ""
    };
    if (!promotionChecked) {
      data.type = ""
    }
    handleReviewData(data);
  }, []);
  useEffect(() => {
    setPromotionChecked(promoState)
  }, [promoState])


  var promotion = <></>;
  if (promotionChecked) {
    promotion = (
      <div className="row mt-3">
        <div className="col-12">
          <h3>Promotional Type</h3>
        </div>
        <div className="col-12">
          <div className="card card-chart1 z-position card-height-10">
            <ul className="nav btn-margin-left" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                  <a
                  className="btn btn-outline-success btn-size px-lg-4 px-sm-3 active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-give-back"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Give Back
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                className="btn btn-outline-success btn-size px-lg-4 px-sm-3"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-deals"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Deals
              </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                className="btn btn-outline-success btn-size px-lg-4 px-sm-3"
                id="pills-promo-tab"
                data-toggle="pill"
                href="#pills-promo"
                role="tab"
                aria-controls="pills-promo"
                aria-selected="false"
              >
                Promo Codes
              </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <UpdateGiveBack
                handleAmount={(e) => setGiveAmount(e)}
                handleStart={(e) => setGiveStart(e)}
                handleEnd={(e) => setGiveEnd(e)}
                giveStart={giveStart ? giveStart : convertDate(cardItem?.promotion_start)}
                giveEnd={giveEnd ? giveEnd : convertDate(cardItem?.promotion_end)}
                giveAmount={giveAmount?.length > 0 || giveAmount == "" ? giveAmount : cardItem?.promo_value}
              />
              <UpdateDeals
                handleName={(e) => setDealsName(e)}
                handleStart={(e) => setDealsStart(e)}
                handleAbout={(e) => setDealsAbout(e)}
                handleEnd={(e) => setDealsEnd(e)}
                handleDiscount={(e) => setDealsDiscount(e)}
                handlePercent={(e) => setDealsPercent(e)}
              />
              <UpdatePromoCodes
                handleStart={(e) => setPromoStart(e)}
                handleEnd={(e) => setPromoEnd(e)}
                handleDealsName={(e) => setPromoDealsName(e)}
                handleAbout={(e) => setPromoAbout(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-12 mt-5">
          <div className="d-flex">
            <label
              htmlFor="run-promo"
              className="radio1 d-flex align-items-center"
            >
              <input
                type="checkbox"
                name="radio-phone"
                id="run-promo"
                className="checkbox-design mr-2"
                checked={promotionChecked}
                onChange={toggleChecked}
                promoState
              />
              <h4 className="my-auto" htmlFor="run-promo">Run Promotion</h4>
            </label>
          </div>
        </div>
      </div>

      {promotion}
    </>
  );
};

export default UpdatePromotion;
