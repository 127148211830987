import React from "react";
import Slider from "react-slick";
import { HuePicker } from "react-color";
import Multiselect from "multiselect-react-dropdown";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { uploadBulkImages } from "../../UploadImageFolder/uploadBulkImages";

function Groceries({
  setProducts,
  supportedCurrencies,
  setCurrentScreen,
  setProductSummaries,
  categories,
  finalChecked,
  onCategorySelect,
  onCategoryRemoved,
  handleUserEntry,
  userEntry,
  productSizes,
  handleSelectedSubCategoriesModal,
  onSizeSelect,
  onSizeRemoved,
  sizes,
  setUserEntry,
  isChecked,
  setIsChecked,
  isColorChecked,
  setIsColorChecked,
  setIsDiscountChecked,
  isDiscountChecked,
  color,
  setColor,
  colorList,
  setColorList,
  customSizes,
  setCustomSizes,
  focusedIndex,
  setFocusedIndex,
}) {
  const settings = {
    infinite: true,
    autoplay: false,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };
  const [newImage, setNewImage] = useState(null);

  let selec;
  if (sizes[0] === "Not Applicable") {
    selec = 1;
  }
  const [selectedOption, setSelectedOption] = useState("non_numeric");
console.log("cate", categories)
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.id);
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };
  const handleColorCheckboxChange = () => {
    setIsColorChecked((prev) => !prev);
  };
  const handleDiscountChange = () => {
    setIsDiscountChecked((prev) => !prev);
  };
  const handleColorAdd = () => {
    if (colorList.length > 1) {
      const prevItemValue = colorList[colorList.length - 2].color;
      const currentItemValue = colorList[colorList.length - 1].color;

      if (currentItemValue === prevItemValue) {
        Swal.fire({
          title: "Duplicated colors",
          icon: "info",
          // confirmButtonColor: "#338540",
          confirmButtonText: "Okay",
        });
      } else {
        setColorList([...colorList, { color: "" }]);
        setFocusedIndex(colorList.length);
      }
    } else {
      setColorList([...colorList, { color: "" }]);
      setFocusedIndex(colorList.length);
    }
  };
  useEffect(() => {
    if (!isColorChecked) {
      setColor("");
      setColorList([]);
      setFocusedIndex(0);
    } else {
      setColor("#000");
      setColorList([{ color: "" }]);
    }
  }, [isColorChecked]);

  useEffect(() => {
    if (!isDiscountChecked) {
      setUserEntry({
        ...userEntry,
        discount_start_date: "",
        discount_end_date: "",
        product_discount: "",
      });
    }
  }, [isDiscountChecked]);

  useEffect(() => {
    if (isColorChecked && colorList && colorList[focusedIndex]) {
      const updatedColorList = [...colorList];
      updatedColorList[focusedIndex] = { color };
      setColorList(updatedColorList);
    }
  }, [color, focusedIndex, isColorChecked]);

  const handleCustomSizeAdd = () => {
    if (
      customSizes.length > 0 &&
      customSizes[customSizes.length - 1].size === ""
    ) {
      Swal.fire({
        title: "Field can't be empty",
        icon: "info",
        confirmButtonText: "Okay",
      });
    } else if (customSizes.length > 1) {
      const currentValue = customSizes[customSizes.length - 1].size;
      const prevValue = customSizes[customSizes.length - 2].size;

      if (currentValue === prevValue) {
        Swal.fire({
          title: "Duplicated sizes",
          icon: "info",
          // confirmButtonColor: "#338540",
          confirmButtonText: "Okay",
        });
      } else {
        setCustomSizes([...customSizes, { size: "" }]);
      }
    } else {
      setCustomSizes([...customSizes, { size: "" }]);
    }
  };

  const handleSizeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...customSizes];
    list[index][name] = value;
    setCustomSizes(list);
  };
  const handleRemoveSize = (e, index) => {
    const list = [...customSizes];
    list.splice(index, 1);
    setCustomSizes(list);
  };

  let data = new FormData();
  const sliderRef = useRef(null);
  const [isloading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  let multiSelectRef = useRef();
  const onSelectFile = async (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      data.append("files[]", file);
      return URL.createObjectURL(file);
    });
    let image = await uploadBulkImages(data);
    setNewImage(image.data.files);
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  };

  const handleColorRemove = (e, index) => {
    const list = [...colorList];
    Swal.fire({
      title: "Are you sure you want to remove this color?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#338540",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        list.splice(index, 1);
        setColorList(list);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const hasDuplicate = colorList.some((item, index) =>
      colorList
        .slice(index + 1)
        .some((otherItem) => item.color === otherItem.color)
    );
    const hasDuplicateSizes = customSizes.some((item, index) =>
      customSizes
        .slice(index + 1)
        .some((otherItem) => item.size === otherItem.size)
    );
    const data = new FormData();
    data.append("name", userEntry.product_name);
    finalChecked?.map((ele) => {
      data.append("category_ids[]", ele);
    });
    newImage?.map((ele) => {
      data.append("pictures[]", ele?.url);
    });
    if (selectedOption === "non_numeric") {
      sizes?.map((ele) => {
        data.append("custom_sizes[]", ele);
      });
      data.append("has_custom_sizes", false);
    }
    if (selectedOption === "numeric") {
      customSizes?.map((ele) => {
        data.append("custom_sizes[]", ele.size);
      });
      data.append("has_custom_sizes", true);
    }

    data.append("weight", userEntry.product_weight);
    data.append("price", userEntry.product_price);
    data.append("description", userEntry.product_description);
    colorList.map((ele) => {
      data.append("colors[]", ele.color);
    });
    data.append("quantity", userEntry.product_quantity);
    data.append("min_quantity", userEntry.min_product_quantity);
    data.append("discount", userEntry.product_discount);
    if (isDiscountChecked && userEntry.discount_start_date !== "") {
      data.append("discount_start_date", userEntry.discount_start_date);
    }
    if (isDiscountChecked && userEntry.discount_end_date !== "") {
      data.append("discount_end_date", userEntry.discount_end_date);
    }
    data.append("currency", userEntry.product_currency);
    data.append(
      "return_policy",
      userEntry.product_return_policy === ""
        ? "No policy"
        : userEntry.product_return_policy
    );
    data.append("benefits", userEntry.product_benefits);

    if (selectedImages.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please upload product image(s)",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else if (finalChecked?.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Product category is required",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else if (hasDuplicate) {
      Swal.fire({
        title: "Error",
        text: "There is duplication in product color",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else if (hasDuplicateSizes) {
      Swal.fire({
        title: "Error",
        text: "There is duplication in product size",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else if (
      userEntry.discount_end_date != "" &&
      userEntry.discount_start_date === ""
    ) {
      Swal.fire({
        title: "Error",
        text: "Discount start date is required",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else {
      axios
        .post("/market.place/v1/products/add", data)
        .then((res) => {
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: "Product Uploaded Successfully",
            icon: "success",
            button: "OK",
          }).then((isOkay) => {
            if (isOkay) {
              setUserEntry({
                product_name: "",
                categories: [],
                productSizes: [],
                product_weight: "",
                product_price: "",
                product_description: "",
                product_color: [],
                product_quantity: "",
                product_discount: "",
                product_currency: "",
                product_return_policy: "",
                product_benefits: "",
                min_product_quantity: "",
                discount_start_date: "",
                discount_end_date: "",
              });
              setColor("");
              setColorList([]);
              setFocusedIndex(0);
              setIsChecked(false);
              setIsColorChecked(false);
              setIsDiscountChecked(false);
              axios
                .get("/market.place/v1/products/list")
                .then((res) => {
                  setProducts(res.data.data.products);
                  setCurrentScreen(1);
                })
                .catch((err) => {
                  console.log(err.response);
                });

              axios
                .get("/market.place/v1/reports/products-summaries")
                .then((res) => {
                  setProductSummaries(res.data.data.records);
                })
                .catch((err) => {
                  console.log(err.response);
                });
            }
          });
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
          setLoading(false);
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row mx-3 mt-3">
        <div className="col-lg-6 col-pad">
          <div className="row">
            <div className="col-lg-12 col-pad-left">
              <div className="form-group form-position">
                <input
                  type="text"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Enter Product Name *"
                  name="product_name"
                  value={userEntry.product_name}
                  onChange={handleUserEntry}
                  required
                />
              </div>
            </div>
            <div className="col-lg-8 col-pad">
              <section className="variable slider">
                <div
                  className={`${
                    selectedImages.length >= 1
                      ? "upload-img-btn1"
                      : "upload-btn1"
                  }`}
                >
                  <div
                    className={`${
                      selectedImages.length > 1
                        ? "d-flex justify-content-between arrows-position"
                        : "d-lg-none d-none"
                    }`}
                  >
                    <img
                      src="/assets/img/arrow-left1.png"
                      alt=""
                      onClick={() => sliderRef.current.slickPrev()}
                      className="cur-pointer cur-width"
                    />
                    <img
                      src="/assets/img/arrow-right2.png"
                      alt=""
                      onClick={() => sliderRef.current.slickNext()}
                      className="ms-3 cur-pointer cur-width"
                    />
                  </div>
                  <Slider ref={sliderRef} {...settings}>
                    {selectedImages &&
                      selectedImages.map((image, index) => {
                        return (
                          <div className="img-kk" key={image}>
                            <img
                              src={image}
                              alt=""
                              className="upload-btn-img2"
                            />
                            <input
                              type="button"
                              onClick={() =>
                                setSelectedImages(
                                  selectedImages.filter((e) => e !== image)
                                )
                              }
                              className="btn bg-danger-light delete-img"
                              value="delete"
                            />
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </section>
            </div>
            <div className="col-lg-4 col-pad">
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <select
                    name="product_currency"
                    value={userEntry.product_currency}
                    onChange={handleUserEntry}
                    className="form-control form-padding-textbx mb-3"
                    required
                  >
                    <option value="">Currency *</option>
                    {supportedCurrencies?.map((supportedCurrency, index) => (
                      <option
                        value={supportedCurrency}
                        key={index}
                        className="text-capitalize"
                      >
                        {supportedCurrency}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/arrow-down.png"
                    alt=""
                    className="category-arrow-pos"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Price *"
                    value={userEntry.product_price}
                    name="product_price"
                    onChange={handleUserEntry}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Quantity *"
                    value={userEntry.product_quantity}
                    name="product_quantity"
                    onChange={handleUserEntry}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-pad-left">
              <div className="col-display-small note-text">
                <label
                  htmlFor="upload-image3"
                  className="btn-upload-size1 btn btn-success pt-2"
                >
                  <img
                    src="/assets/img/upload-bg-logo.png"
                    alt=""
                    className="mr-2 my-auto"
                  />
                  Upload Image
                </label>
                <input
                  type="file"
                  id="upload-image3"
                  onChange={(e) => {
                    onSelectFile(e);
                  }}
                  multiple
                  accept="image/"
                  name="product_image"
                />
                <h5 className="mb-3">
                  <span>Note:</span> you can upload more than one image of
                  product
                </h5>
              </div>
            </div>
            <div className="col-lg-12 col-pad-left">
              <label htmlFor="check1" className="label-style">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  id="check1"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <h4 className="label-size">Add Product Size</h4>
              </label>
            </div>
            {isChecked && (
              <div className="col-lg-12 col-pad-left">
                <div className="d-flex">
                  <label
                    htmlFor="non_numeric"
                    className="radio1 d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="non_numeric"
                      className="radio__input mr-1"
                      checked={selectedOption === "non_numeric"}
                      onChange={handleRadioChange}
                    />
                    <div className="radio__radio radio-size"></div>
                    <h4 className="my-auto">Select Size(s)</h4>
                  </label>
                  <label
                    htmlFor="numeric"
                    className="radio1 d-flex align-items-center ml-4"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="numeric"
                      className="radio__input mr-1"
                      checked={selectedOption === "numeric"}
                      onChange={handleRadioChange}
                    />
                    <div className="radio__radio radio-size"></div>
                    <h4 className="my-auto">Custom</h4>
                  </label>
                </div>
                {selectedOption === "non_numeric" && (
                  <div className="form-group form-position">
                    <Multiselect
                      className="form-control form-padding-textbx mb-3"
                      options={productSizes}
                      isObject={false}
                      required
                      placeholder="Product Size"
                      // ref={multiSelectRef}
                      selectionLimit={selec}
                      onSelect={onSizeSelect}
                      onRemove={onSizeRemoved}
                    />
                    <img
                      src="/assets/img/arrow-down.png"
                      alt=""
                      className="category-arrow-pos"
                    />
                  </div>
                )}
                {selectedOption === "numeric" && (
                  <div className="custom-size-div-width">
                    {customSizes?.map((ele, index) => {
                      return (
                        <>
                          <input
                            type="text"
                            name="size"
                            id={`size${index}`}
                            value={ele.size}
                            required
                            onChange={(e) => handleSizeChange(e, index)}
                            className={
                              customSizes.length === 1
                                ? "custom-size-box mr-2 mb-3"
                                : "custom-size-box mb-3"
                            }
                            style={{
                              borderRadius: `${
                                customSizes.length === 1
                                  ? "10px"
                                  : "10px 0px 0px 10px"
                              }`,
                            }}
                          />
                          {customSizes.length > 1 && (
                            <div
                              className="minus-cover mr-2"
                              onClick={(e) => handleRemoveSize(e, index)}
                            >
                              <div className="minus"></div>
                            </div>
                          )}
                          {customSizes?.length - 1 === index &&
                            customSizes?.length < 10 && (
                              <div
                                className="plus-cover mb-3"
                                onClick={handleCustomSizeAdd}
                              >
                                <img
                                  src="/assets/img/plus1.svg"
                                  alt=""
                                  className="plus-width"
                                />
                              </div>
                            )}
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div className="col-lg-12 col-pad-left">
              <textarea
                placeholder="Enter Product Return Policy"
                className={
                  isColorChecked
                    ? "textarea-size8-active1 form-control mb-3"
                    : isChecked && !isColorChecked
                    ? "textarea-size8-active3 form-control mb-3"
                    : "textarea-size8 form-control mb-3"
                }
                value={userEntry.product_return_policy}
                name="product_return_policy"
                onChange={handleUserEntry}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-pad-right">
          <div className="row">
            <div className="col-lg-12 col-pad-right">
              <div className="form-group form-position">
                <Multiselect
                  required
                  className="form-control form-padding-textbx mb-3"
                  options={categories}
                  displayValue="name"
                  placeholder="Category *"
                  onSelect={onCategorySelect}
                  onRemove={onCategoryRemoved}
                  ref={multiSelectRef}
                  selectionLimit={2}
                />
                <img
                  src="/assets/img/arrow-down.png"
                  alt=""
                  className="category-arrow-pos"
                />
              </div>
            </div>
            <div className="col-lg-12 col-pad-right">
              <button
                type="button"
                className="btn btn-success w-100 mb-3"
                onClick={() => handleSelectedSubCategoriesModal()}
              >
                View Selected Categories
              </button>
            </div>
            <div className="col-lg-6 col-pad-right">
              <div className="form-group form-position">
                <input
                  type="number"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Min Product Quantity *"
                  value={userEntry.min_product_quantity}
                  name="min_product_quantity"
                  onChange={handleUserEntry}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-pad-right">
              <div className="form-group form-position">
                <input
                  type="text"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Enter Product Weight"
                  value={userEntry.product_weight}
                  name="product_weight"
                  onChange={handleUserEntry}
                />
              </div>
            </div>
            <div className="col-lg-12 col-pad-right">
              <label htmlFor="color_check" className="label-style">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  id="color_check"
                  checked={isColorChecked}
                  onChange={handleColorCheckboxChange}
                />
                <h4 className="label-size">Add Product Color(s)</h4>
              </label>
            </div>
            {isColorChecked && (
              <div className="col-lg-12 col-pad-right">
                <div className="row d-flex">
                  <div className="col-1">
                    <div
                      className="black-color mr-2"
                      onClick={(color) => {
                        setColor("#000");
                      }}
                    ></div>
                  </div>
                  <div className="col-9">
                    <HuePicker
                      color={color}
                      onChangeComplete={(color) => {
                        setColor(color.hex);
                      }}
                      className="mt-1 ml-3"
                      width="100%"
                    />
                  </div>
                  <div className="col-1">
                    <div
                      className="white-color ml-2"
                      onClick={(color) => {
                        setColor("#fff");
                      }}
                    ></div>
                  </div>
                </div>
                <div className="color-div-width mt-3">
                  {colorList.map((ele, index) => {
                    return (
                      <>
                        <div
                          className={
                            colorList.length > 1
                              ? "color-circle cur-pointer mr-2"
                              : "color-circle mr-2"
                          }
                          key={index}
                          onClick={(e) =>
                            colorList.length > 1 && handleColorRemove(e, index)
                          }
                          style={{
                            background: `${colorList[index].color}`,
                            border: `1px solid ${
                              colorList[index].color === "#fff"
                                ? "#000"
                                : colorList[index].color
                            }`,
                          }}
                        ></div>
                        {colorList?.length - 1 === index &&
                          colorList.length < 10 && (
                            <div
                              className="plus-circle"
                              onClick={handleColorAdd}
                            >
                              <img
                                src="/assets/img/plus1.svg"
                                alt=""
                                className="plus-width"
                              />
                            </div>
                          )}
                      </>
                    );
                  })}
                </div>
                <div className="note-text">
                  <h5 className="mb-4 mt-1">
                    <span>Note:</span> you can remove color by clicking on it
                  </h5>
                </div>
              </div>
            )}
            <div className="col-lg-12 col-pad-right">
              <textarea
                placeholder="Enter Product Benefits"
                className={
                  isColorChecked && !isChecked
                    ? "textarea-size2-active1 form-control mb-3"
                    : isChecked && isColorChecked
                    ? "textarea-size2-active2 form-control mb-3"
                    : isChecked && !isColorChecked
                    ? "textarea-size2-active3 form-control mb-3"
                    : "textarea-size2 form-control mb-3"
                }
                value={userEntry.product_benefits}
                name="product_benefits"
                onChange={handleUserEntry}
              ></textarea>
            </div>
            <div className="col-lg-12 col-pad-right">
              <textarea
                placeholder="Enter Product Description *"
                className={
                  isColorChecked && !isChecked
                    ? "textarea-size10-active1 form-control"
                    : isChecked && isColorChecked
                    ? "textarea-size10-active2 form-control"
                    : isChecked && !isColorChecked
                    ? "textarea-size10-active3 form-control"
                    : "textarea-size10 form-control"
                }
                value={userEntry.product_description}
                name="product_description"
                onChange={handleUserEntry}
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-pad1">
          <label htmlFor="check2" className="label-style">
            <input
              type="checkbox"
              className="checkbox-design"
              id="check2"
              checked={isDiscountChecked}
              onChange={handleDiscountChange}
            />
            <h4 className="label-size">Add Discount</h4>
          </label>
        </div>
        {isDiscountChecked && (
          <>
            <div className="col-lg-12 col-pad1 mt-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group form-position">
                    <input
                      type="number"
                      className="form-control form-padding-textbx"
                      placeholder="Enter Discount *"
                      value={userEntry.product_discount}
                      name="product_discount"
                      required
                      onChange={handleUserEntry}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-pad-left1 mt-4">
              <div className="form-group form-position">
                <label htmlFor="">
                  <h4 className="dis-font">Start Date</h4>
                </label>
                <input
                  type="datetime-local"
                  className="form-control form-padding-textbx mb-3"
                  name="discount_start_date"
                  value={userEntry.discount_start_date}
                  onChange={handleUserEntry}
                />
              </div>
            </div>
            <div className="col-lg-6 col-pad-right1 mt-4">
              <div className="form-group form-position">
                <label htmlFor="">
                  <h4 className="dis-font">End Date</h4>
                </label>
                <input
                  type="datetime-local"
                  className="form-control form-padding-textbx mb-3"
                  name="discount_end_date"
                  value={userEntry.discount_end_date}
                  onChange={handleUserEntry}
                />
              </div>
            </div>
          </>
        )}
        <div className="w-100 mt-1 mb-4 mt-1">
          {!isloading && (
            <input
              type="submit"
              className="btn btn-success w-100"
              value="Upload product"
            />
          )}
          {isloading && (
            <button className="btn btn-success w-100" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>Uploading...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default Groceries;
