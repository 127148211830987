import { elements } from "chart.js";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const ProductCategoryModal = ({
  showProductCategoryModal,
  handleProductCategoryClose,
  subCatories,
  setChecked,
  handleCheck,
  handleChildCheck,
  saveChecked,
  selectedParent,
  setFinalChecked,
  handleParentCheck,
  productCat,
  deleteParentCat,
  selectCatIds,
  setSelectedParent
}) => {
  const handleSkip = () => {
    var temp = selectedParent;

    var deleteSelected = deleteParentCat?.filter((value) => {
      return selectCatIds?.find((val) => {
        return value === val;
      });
    });

    deleteSelected?.map((ele) => {
      temp.delete(ele);
    });
    var tempCat = [];
    for (let [key, value] of temp) {
      tempCat.push(key);

      tempCat.push(...value);
    }
    setSelectedParent(temp);
    setFinalChecked([...tempCat, ...productCat]);
    handleProductCategoryClose();
  };

  return (
    <Modal show={showProductCategoryModal} onHide={handleSkip}>
      <Modal.Header closeButton>
        {/* <Modal.Title> */}
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4 className="mt-3">Product Sub-categories</h4>
          </div>
        </div>
        {/* </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="product-list-font">
          <h4>
            Your product might also fall under these categories. Please select
            option(s) which best fit your product
          </h4>
        </div>
        <ul className="pl-0 mt-4">
          {subCatories?.sub_categories?.map((list, index) => (
            <li key={[index?.id]}>
              <div className="d-flex  check-text-size">
                <input
                  // disabled={checkboxDisable}
                  type="checkbox"
                  id={list?.code}
                  value={list?.id}
                  className="checkbox-design radio_check"
                  onChange={(e) => {
                    handleCheck(parseInt(e.target.value), e.target.checked);
                    handleParentCheck(parseInt(list?.id), null);
                  }}
                  checked={
                    selectedParent.has(parseInt(list?.id)) ? true : false
                  }
                />
                <h4 className="ml-2">{list?.name}</h4>
              </div>
              <ul>
                {list?.sub_categories?.map((sublist, i) => (
                  <li key={i}>
                    <div className="d-flex  check-text-size">
                      <input
                        // disabled={checkboxDisable}
                        type="checkbox"
                        id={sublist?.code}
                        value={sublist?.id}
                        onChange={(e) => {
                          handleChildCheck(
                            [parseInt(e.target.value), parseInt(list?.id)],
                            e.target.checked
                          );
                          handleParentCheck(
                            parseInt(list?.id),
                            parseInt(sublist?.id)
                          );
                        }}
                        checked={
                          selectedParent.get(list?.id)?.includes(sublist?.id)
                            ? true
                            : false
                        }
                        className="checkbox-design radio_check"
                      />
                      <h4 className="ml-2 sublist-font">{sublist?.name}</h4>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <div className="d-flex justify-content-between">
          <div className="">
            <button className="btn btn-danger" onClick={handleSkip}>
              Cancel
            </button>
          </div>
          <div className="">
            <button className="btn btn-success" onClick={saveChecked}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ProductCategoryModal;
