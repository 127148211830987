import Groceries from "./uploadproductsComponents/Groceries";
import RestaurantUpload from "./uploadproductsComponents/RestaurantUpload";
const UploadProducts = ({
  setProducts,
  resCats,
  supportedCurrencies,
  setCurrentScreen,
  setProductSummaries,
  onResCatSelect,
  onResCatRemoved,
  categories,
  resCategories,
  finalChecked,
  onCategorySelect,
  onCategoryRemoved,
  handleUserEntry,
  userEntry,
  productSizes,
  resProductSizes,
  handleSelectedSubCategoriesModal,
  onSizeSelect,
  onSizeRemoved,
  sizes,
  setUserEntry,
  isChecked,
  setIsChecked,
  isColorChecked,
  setIsColorChecked,
  setIsDiscountChecked,
  isDiscountChecked,
  color,
  setColor,
  colorList,
  setColorList,
  customSizes,
  setCustomSizes,
  focusedIndex,
  setFocusedIndex,
  store_type,
}) => {
  return (
    <div className="card card-chart z-position card-height1">
      <div className="card-header card-header-color">
        <h3>Upload Products</h3>
      </div>
      {(store_type === "undefined" ||
        store_type.toLowerCase() === "others") && (
        <Groceries
          sizes={sizes}
          productSizes={productSizes}
          onSizeSelect={onSizeSelect}
          onSizeRemoved={onSizeRemoved}
          onCategoryRemoved={onCategoryRemoved}
          onCategorySelect={onCategorySelect}
          handleSelectedSubCategoriesModal={handleSelectedSubCategoriesModal}
          setProductSummaries={setProductSummaries}
          setCurrentScreen={setCurrentScreen}
          setProducts={setProducts}
          supportedCurrencies={supportedCurrencies}
          categories={categories}
          finalChecked={finalChecked}
          handleUserEntry={handleUserEntry}
          setUserEntry={setUserEntry}
          userEntry={userEntry}
          isChecked={isChecked}
          setIsChecked={(e) => setIsChecked(e)}
          isColorChecked={isColorChecked}
          setIsColorChecked={(e) => setIsColorChecked(e)}
          isDiscountChecked={isDiscountChecked}
          setIsDiscountChecked={(e) => setIsDiscountChecked(e)}
          color={color}
          setColor={(e) => setColor(e)}
          colorList={colorList}
          setColorList={(e) => setColorList(e)}
          customSizes={customSizes}
          setCustomSizes={(e) => setCustomSizes(e)}
          focusedIndex={focusedIndex}
          setFocusedIndex={(e) => setFocusedIndex(e)}
        />
      )}
      {store_type !== "undefined" &&
        store_type.toLowerCase() === "restaurant" && (
          <RestaurantUpload
            resCats={resCats}
            onResCatSelect={onResCatSelect}
            onResCatRemoved={onResCatRemoved}
            sizes={sizes}
            resProductSizes={resProductSizes}
            onSizeSelect={onSizeSelect}
            onSizeRemoved={onSizeRemoved}
            onCategoryRemoved={onCategoryRemoved}
            onCategorySelect={onCategorySelect}
            handleSelectedSubCategoriesModal={handleSelectedSubCategoriesModal}
            setProductSummaries={setProductSummaries}
            setCurrentScreen={setCurrentScreen}
            setProducts={setProducts}
            resCategories={resCategories}
            supportedCurrencies={supportedCurrencies}
            finalChecked={finalChecked}
            handleUserEntry={handleUserEntry}
            setUserEntry={setUserEntry}
            userEntry={userEntry}
            store_type={store_type}
            isChecked={isChecked}
            setIsChecked={(e) => setIsChecked(e)}
            isColorChecked={isColorChecked}
            setIsColorChecked={(e) => setIsColorChecked(e)}
            isDiscountChecked={isDiscountChecked}
            setIsDiscountChecked={(e) => setIsDiscountChecked(e)}
            color={color}
            setColor={(e) => setColor(e)}
            colorList={colorList}
            setColorList={(e) => setColorList(e)}
            customSizes={customSizes}
            setCustomSizes={(e) => setCustomSizes(e)}
            focusedIndex={focusedIndex}
            setFocusedIndex={(e) => setFocusedIndex(e)}
          />
        )}
    </div>
  );
};
export default UploadProducts;
