import $ from "jquery";
import { useRef, createRef, useEffect } from "react";
import { useState } from "react";
const Amounts = ({ amount, handleAmount, count, handleAmts, amts = [], handleTextbox, amountTextbox = [], show, spliceText }) => {
  $(".form-padding2")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
      }
    })
    .trigger("change");

  $(".form-padding3")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #000");
      }
    })
    .trigger("change");




  const handleRef = (item, i) => {
 
    if(item.current.value === ""){
      spliceText(i)
    }else{
      var temp = amts
      temp[i] = item.current.value
      handleAmts(temp)
    }
  }



  return (
    <div className="row mt-3">
      <div className="col-12">
        <h3>Enter Card Amounts in Ghana Cedis</h3>
      </div>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap">
          {
            amountTextbox.map((item, i) => {
              return <input
                defaultValue={amts[i] || ""}
                ref={ele => item.current = ele}
                onChange={() => handleRef(item, i)} className="form-control form-padding2 text-center mb-3 mr-2" type="tel" name="amount" />
            })
          }
          {
            amountTextbox.length < 10  ?
              <>
                <div onClick={() => handleTextbox()} disabled><img src="assets/img/add.png" alt="" className="mt-2 cur-pointer"/></div>
              </>
              : null
          }
        </div>
        {/* <input type="number" value={amt} className="form-control form-padding2 text-center" name="amount" onChange={(e) => handleAmt(e)}/> */}
      </div>
    </div>
  );
};

export default Amounts;
