import { HuePicker } from "react-color";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { uploadToBucket } from "../../UploadImageFolder/uploadToBucket";
const ImageUpload1 = ({
  handleNewLogo,
  handleBgImage,
  handleTitle,
  title,
  handleColor,
  color,
  handleColor1,
  color1,
  handleLogo,
  amount,
  setBackgroundImage,
  backgroundImage,
  logo,
  current2,
  setCurrent2,
  handleVisible,
  visible,
  handlePos,
  pos
}) => {
  const settings = {
    dots: true,
    infinite: true,
    variableWidth: true,
    autoplay: true,
    nextArrow: (
      <img
        src="assets/img/arrow.png"
        alt=""
        className="arrow-position1 slick-next"
      />
    )
  };
  const [value, setValue] = useState(title);
  const [img, setImg] = useState(logo);



  const handleText = (e) => {
    setValue(e.target.value);
    handleTitle(e.target.value);
  };
  const handleLogoUpload = async (e) => {
    let data  =  new FormData()
    data.append("file", e.target.files[0]);
    let new_logo = await uploadToBucket(data);

    handleNewLogo(new_logo.data.url)
    handleLogo(URL.createObjectURL(e.target.files[0]));
    setImg(URL.createObjectURL(e.target.files[0]));
  };
  const [backgroundImg, setBackgroundImg] = useState(null)
  const handleBackground = async (e) => {
    let data  =  new FormData()
    data.append("file", e.target.files[0]);
    let new_bg_image = await uploadToBucket(data);

    handleBgImage(new_bg_image.data.url)
    setBackgroundImg(URL.createObjectURL(e.target.files[0]))
    setBackgroundImage(URL.createObjectURL(e.target.files[0]))
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <div className="upload-bt mt-4">
            {current2 === 1 && (
              <div style={{ width: "345px", height: "200px", border: "1px dashed #338540", borderRadius: "20px" }}>
                <h4 style={{ textAlign: "-webkit-center", marginTop: "80px", fontSize: "18px", color: "#338540", fontWeight: "500" }}>Select a template to position your logo</h4>
              </div>
            )}
            {current2 === 2 && (
              <>
                <div className="left-logo" style={{ color: color, border: "1px solid", borderRadius: "20px", width: "345px", height: "200px", display: "grid", gridTemplateColumns: "auto auto", overflow: "hidden", boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)" }}>
                  <div style={{ background: color, width: "99px", position: "relative" }}>
                    <label htmlFor="change-logo">
                      <div className={`${img ? "change-logo logo-size" : "change-logo"}`}>
                        <img
                          src={img ? img : "assets/img/upload-bg-logo.png"}
                          alt=""
                          className={`${img ? "upload-btn-img1" : "upload-image-icon-position1"
                            }`}
                        />
                        {img ? (
                          ""
                        ) : (
                          <>
                            <h4 className="text-white upload-txt ml-1" style={{ fontSize: "14px", marginLeft: "-4px", fontWeight: "400" }}>
                              Upload Image
                            </h4>
                            <h5 className="text-white ml-2" style={{ fontSize: "8px", marginLeft: "-4px", fontWeight: "400" }}>
                              PNG file with a transparent background
                            </h5>
                          </>
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      id="change-logo"
                      onChange={handleLogoUpload}
                    />
                    {visible && (
                      <div style={{ color: color1, transition: "ease all 100ms", zIndex: "1" }}>
                        <h4 className="gift-text">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                  <div style={{ width: "246px" }}>
                    <img src="http://via.placeholder.com/246x200" alt="" />
                    {pos === 1 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", right: "20px", top: "30px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                    {pos === 3 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", right: "20px", top: "190px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
                <h6 className="text-danger mt-2">select a position to place amount</h6>
                <div className="d-flex">
                  <div className="d-flex">
                    <label htmlFor="CustomUpperRight" className="d-flex cur-pointer mr-5">
                      <input type="radio" name="amount_position" id="CustomUpperRight" defaultChecked onChange={() => handlePos(1)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Upper Right</h6>
                    </label>
                  </div>
                  <div className="d-flex">
                    <label htmlFor="CustomLowerRight" className="d-flex cur-pointer mr-5">
                      <input type="radio" name="amount_position" id="CustomLowerRight" onChange={() => handlePos(3)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Lower Right</h6>
                    </label>

                  </div>
                </div>
              </>
            )}
            {current2 === 3 && (
              <>
                <div className="right-logo" style={{ color: color, border: "1px solid", borderRadius: "20px", width: "345px", height: "200px", display: "grid", gridTemplateColumns: "auto auto", overflow: "hidden", boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)" }}>
                  <div style={{ width: "243px" }}>
                    <img src="http://placeimg.com/246/200/any" alt="" />
                    {pos === 2 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", left: "25px", top: "30px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                    {pos === 4 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", left: "25px", top: "190px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                  </div>
                  <div style={{ backgroundColor: color, width: "99px", position: "relative" }}>
                    <label htmlFor="change-logo">
                      <div className={`${img ? "change-logo logo-size" : "change-logo"}`}>
                        <img
                          src={img ? img : "assets/img/upload-bg-logo.png"}
                          alt=""
                          className={`${img ? "upload-btn-img4" : "upload-image-icon-position1"}`}
                        />
                        {img ? (
                          ""
                        ) : (
                          <>
                            <h4 className="text-white upload-txt ml-1" style={{ fontSize: "14px", marginLeft: "-4px", fontWeight: "400" }}>
                              Upload Image
                            </h4>
                            <h5 className="text-white ml-2" style={{ fontSize: "8px", marginLeft: "-4px", fontWeight: "400" }}>
                              PNG file with a transparent background
                            </h5>
                          </>
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      id="change-logo"
                      onChange={handleLogoUpload}
                    />
                    {visible && (
                      <div style={{ color: color1, transition: "ease all 100ms" }}>
                        <h4 className="gift-text">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                </div>
                <h6 className="text-danger mt-2">select a position to place amount</h6>
                <div className="d-flex ">
                  <div className="d-flex">
                    <label htmlFor="CustomUpperLeft" className="d-flex cur-pointer mr-5">
                      <input type="radio" name="amount_position" id="CustomUpperLeft" defaultChecked onChange={() => handlePos(2)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Upper Left</h6>
                    </label>
                  </div>
                  <div className="d-flex">
                    <label htmlFor="CustomLowerLeft" className="d-flex cur-pointer mr-5">
                      <input type="radio" name="amount_position" id="CustomLowerLeft" onChange={() => handlePos(4)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Lower Left</h6>
                    </label>
                  </div>
                </div>
              </>
            )}
            {current2 === 4 && (
              <>
                <div className="down-logo" style={{ color: color, border: "1px solid", borderRadius: "20px", width: "343px", height: "200px", display: "grid", gridTemplateRows: "auto auto", overflow: "hidden", boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)" }}>
                  <div style={{ height: "90px" }}>
                    <img src="http://placeimg.com/343/132/any" alt="" />
                    {pos === 1 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", right: "20px", top: "30px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                    {pos === 2 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", left: "25px", top: "30px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                  </div>
                  <div style={{ backgroundColor: color, position: "relative" }}>
                    <label htmlFor="change-logo">
                      <div className={`${img ? "logo-size1" : "change-logo change-logo-bottom d-flex"}`}>
                        <img
                          src={img ? img : "assets/img/upload-bg-logo.png"}
                          alt=""
                          className={`${img ? "upload-btn-img-bottom" : "upload-image-icon-position-bottom img-fluid"}`}
                        />
                        {img ? ("") : (
                          <div className="bottom-logo">
                            <h4 className="text-white ml-1">
                              Upload logo
                            </h4>
                          </div>
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      id="change-logo"
                      onChange={handleLogoUpload}
                    />
                    {visible && (
                      <div style={{ color: color1, transition: "ease all 100ms" }}>
                        <h4 className="gift-text">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                </div>
                <h6 className="text-danger mt-2">select a position to place amount</h6>
                <div className="d-block ">
                  <div className="d-flex">
                    <label htmlFor="CustomUpperRight1" className="d-flex cur-pointer mr-5">
                      <input type="radio" name="amount_position" id="CustomUpperRight1" defaultChecked onChange={() => handlePos(1)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Upper Right</h6>
                    </label>
                    <label htmlFor="CustomUpperLeft1" className="d-flex cur-pointer">
                      <input type="radio" name="amount_position" id="CustomUpperLeft1" onChange={() => handlePos(2)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Upper Left</h6>
                    </label>
                  </div>
                </div>
              </>
            )}
            {current2 === 5 && (
              <>
                <div className="up-logo" style={{ color: color, border: "1px solid", borderRadius: "20px", width: "343px", height: "200px", display: "grid", gridTemplateRows: "auto auto", overflow: "hidden", boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)" }}>
                  <div style={{ height: "70px", backgroundColor: color, position: "relative" }}>
                    <label htmlFor="change-logo">
                      <div className={`${img ? "logo-size1" : "change-logo change-logo-bottom d-flex"}`}>
                        <img
                          src={img ? img : "assets/img/upload-bg-logo.png"}
                          alt=""
                          className={`${img ? "upload-btn-img-bottom" : "upload-image-icon-position-bottom img-fluid"}`}
                        />
                        {img ? ("") : (
                          <div className="bottom-logo">
                            <h4 className="text-white ml-1">
                              Upload logo
                            </h4>
                          </div>
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      id="change-logo"
                      onChange={handleLogoUpload}
                    />
                    {visible && (
                      <div style={{ color: color1, transition: "ease all 100ms" }}>
                        <h4 className="gift-text">e-Gift Card</h4>
                      </div>
                    )}
                  </div>
                  <div style={{ height: "132px", position: "relative" }}>
                    <img src="http://placeimg.com/343/132/any" alt="" />
                    {pos === 3 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", right: "20px", bottom: "0px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                    {pos === 4 && (
                      <div style={{ color: color, transition: "ease all 100ms", position: "absolute", left: "15px", bottom: "0px" }}>
                        <h4 >
                          GH¢ <span>{amount}</span>
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
                <h6 className="text-danger mt-2">select a position to place amount</h6>
                <div className="d-block ">
                  <div className="d-flex">
                    <label htmlFor="CustomLowerRight1" className="d-flex cur-pointer mr-5">
                      <input type="radio" name="amount_position" id="CustomLowerRight1" defaultChecked onChange={() => handlePos(3)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Lower Right</h6>
                    </label>
                    <label htmlFor="CustomLowerLeft1" className="d-flex cur-pointer">
                      <input type="radio" name="amount_position" id="CustomLowerLeft1" onChange={() => handlePos(4)} className="radio__input" />
                      <div className="radio__radio3"></div>
                      <h6 className="my-auto">Lower Left</h6>
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-lg-12 mt-3">
            <div className="row d-flex">
              <div className="col-1">
                <div className="black-color" onClick={(color) => { handleColor("#000") }}></div>
              </div>
              <div className="col-9">
                <HuePicker
                  color={color}
                  onChangeComplete={(color) => {
                    handleColor(color.hex);
                  }}
                  className="mt-1 ml-3"
                  width="100%"
                />
              </div>
              <div className="col-1">
                <div className="white-color" onClick={(color) => { handleColor("#fff") }}></div>
              </div>
            </div>
            <h4 className="note-size mt-1">
              <span>Select a background color for your logo</span>
            </h4>
          </div>
        </div>
        <div className="col-lg-6 col-12 mt-4">
          <div className="row ml-lg-2">
            <div className="col-12">
              <div className="form-group">
                <input
                  className="form-control form-padding1 mt-lg-3 mt-3"
                  type="text"
                  placeholder="Enter card name here"
                  value={title}
                  onChange={(e) => handleText(e)}
                  name="card_name"
                />
              </div>
            </div>
            <div className="col-12">
              <label
                htmlFor="upload-b-image1"
                className="btn-upload-size btn btn-success pt-2 mt-3"
              >
                <img
                  src="/assets/img/upload-bg-logo.png"
                  alt=""
                  className="mr-2 my-auto"
                />
                Upload Background Image
              </label>
              <input type="file" className=" mt-lg-5 mt-3" id="upload-b-image1" onChange={handleBackground} />
              <h4 className="back-img-size d-flex">
                Image: <span className="ml-2">{backgroundImage}</span>
              </h4>
              <h4 className="note-size">
                This image will be displayed at the back of your e-gift card on
                the prime mobile app
              </h4>
            </div>
            <div className="col-12 mt-4">
              <div className="d-flex justify-content-between mr-5">
                <div>
                  <h4 className="e-gift-size">e-Gift Card</h4>
                </div>
                <div className="d-flex">
                  <label
                    for="show"
                    className="radio2 d-flex align-items-center mr-3"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="show"
                      className="radio__input"
                      onChange={(e) => handleVisible(true)}
                      defaultChecked
                    />
                    <div className="radio__radio3"></div>
                    <h4 className="my-auto">Show</h4>
                  </label>
                  <label
                    for="dont-show"
                    className="radio2 d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="dont-show"
                      className="radio__input"
                      onClick={(e) => handleVisible(false)}
                    />
                    <div className="radio__radio3"></div>
                    <h4 className="my-auto">Don't Show</h4>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-1">
                  <div className="black-color" onClick={(color1) => { handleColor1("#000") }}></div>
                </div>
                <div className="col-9">
                  <HuePicker
                    color={color1}
                    onChangeComplete={(color1) => {
                      handleColor1(color1.hex);
                    }}
                    className="mt-1 ml-3"
                    width="100%"
                  />
                </div>
                <div className="col-1">
                  <div className="white-color" onClick={(color1) => { handleColor1("#fff") }}></div>
                </div>
              </div>
              <h4 className="note-size mt-1">
                Optional:{" "}
                <span>
                  Change the color of the e-gift card to stand out from the
                  background
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="card  z-position card-height-9">
            <div className="row dt-mb">
              <div className="col-lg-8 dt-ml">
                <div className="col-12">
                  <h3>Select Design Template</h3>
                </div>
                <div className="col-12 text-color">
                  <h4>Select the template to position your logo</h4>
                </div>
                <div className="col-12 text-color1">
                  <h4 className="note-size">Optional: <span>your logo design should can come with the word e-Gift Card somewhere on the design</span></h4>
                </div>
              </div>
            </div>
            <section className="variable slider">
              <Slider {...settings}>
                <div className="card-img-position">
                  <label htmlFor="card1" className="radio d-flex align-items-center justify-content-center">
                    <input type="radio" name="signup" id="card1" className="radio__input1 mr-2" onChange={() => setCurrent2(2)} />
                    <div className="radio__radio2">
                      <span className="check-icon">
                        <span className="icon"></span>
                      </span>
                      <img src="/assets/img/left_details.png" alt="" className="gallery-position" />
                      <div className="img-text-position">
                        <h5>
                          Your company <span>details goes here</span>
                        </h5>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="card-img-position">
                  <label htmlFor="card3" className="radio d-flex align-items-center">
                    <input type="radio" name="signup" id="card3" className="radio__input1 mr-2" onChange={() => setCurrent2(4)} />
                    <div className="radio__radio2">
                      <span className="check-icon">
                        <span className="icon"></span>
                      </span>
                      <img src="assets/img/down_details.png" alt="" className="gallery-position" />
                      <div className="img-text-position2">
                        <h5>
                          Your company <span>details goes here</span>
                        </h5>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="card-img-position">
                  <label htmlFor="card4" className="radio d-flex align-items-center">
                    <input type="radio" name="signup" id="card4" className="radio__input1 mr-2" onChange={() => setCurrent2(5)} />
                    <div className="radio__radio2">
                      <span className="check-icon">
                        <span className="icon"></span>
                      </span>
                      <img src="assets/img/up_details.png" alt="" className="gallery-position" />
                      <div className="img-text-position3">
                        <h5>
                          Your company <span>details goes here</span>
                        </h5>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="card-img-position">
                  <label htmlFor="card5" className="radio d-flex align-items-center">
                    <input type="radio" name="signup" id="card5" className="radio__input1 mr-2" onChange={() => setCurrent2(3)} />
                    <div className="radio__radio2">
                      <span className="check-icon">
                        <span className="icon"></span>
                      </span>
                      <img src="assets/img/right_details.png" alt="" className="gallery-position" />
                      <div className="img-text-position1">
                        <h5>
                          Your company <span>details goes here</span>
                        </h5>
                      </div>
                    </div>
                  </label>
                </div>
              </Slider>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload1;
