import { Link } from "react-router-dom";
import BusinessDetailForm from "./businessinfoComponent/BusinessDetailForm";
import LogoUpload from "./businessinfoComponent/LogoUpload";
const BusinessInfo = ({
  person,
  company,
  handleCompanyUserInput,
  handleCordinatesChange,
  cordinates,
  updateBusinessDetails,
  updateLogo,
  logoimage,
  setLogoimage,
  onImageChange,
  isBusinessLoading,
  setBusinessLoading,
  location,
  setlocation,
  setProfileLoading,
  isProfileloading,
  detailsLoading,
}) => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="pills-profile1"
        role="tabpanel"
        aria-labelledby="pills-profile-tab1"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-chart2 card-h10 card-h10-pad">
              <div>
                <h3>Business Information</h3>
                <hr className="hr3" />
              </div>
              <div>
                <h4>
                  We do not sell or share your details without your permission.
                  Find out more in our <Link to="">Privacy Policy.</Link>
                  <span>
                    Your{" "}
                    <b>
                      business name, location address, business registration
                      number, business description, website and tin number{" "}
                    </b>{" "}
                    can be updated via{" "}
                    <b className="merch-color">
                      Prime Merchant Business Information Settings
                    </b>
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <LogoUpload
              company={company}
              updateLogo={updateLogo}
              logoimage={logoimage}
              setLogoimage={setLogoimage}
              onImageChange={onImageChange}
              setProfileLoading={setProfileLoading}
              isProfileloading={isProfileloading}
            />
            <BusinessDetailForm
              company={company}
              location={location}
              setlocation={setlocation}
              handleCompanyUserInput={handleCompanyUserInput}
              handleCordinatesChange={handleCordinatesChange}
              cordinates={cordinates}
              updateBusinessDetails={updateBusinessDetails}
              isBusinessLoading={isBusinessLoading}
              setBusinessLoading={setBusinessLoading}
              detailsLoading={detailsLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
