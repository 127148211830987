import Videos from "./resourceComponent/videoComponents/Videos";
import Documents from "./resourceComponent/documentComponents/Documents";
import DocumentViewModal from "./resourceComponent/documentComponents/DocumentViewModal";
import { useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import DashboardLayout from "../layouts/DashboardLayout";
const Resource = () => {
  const [viewDocument, setViewDocument] = useState([]);
  const [viewVideo, setViewVideo] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [showDocumentViewModal, setShowDocumentViewModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const newplugin = defaultLayoutPlugin();
  const [videos, setVideos] = useState([]);
  const [index, setIndex] = useState(viewDocument?.id);
  const [indexVideo, setIndexVideo] = useState(viewVideo?.id);

  const PreviewScreen = (e, ele) => {
    setIndex(ele?.id);
    setCurrentScreen(3);
    setViewDocument(ele);
  };
  const PreviewVideoScreen = (e, ele) => {
    setIndexVideo(ele?.id);
    setCurrentScreen(2);
    setViewVideo({});
    setViewVideo(ele);
  };
  const handleDocumentViewModalClose = (e) => {
    setShowDocumentViewModal(false);
  };
  return (
    <>
      <DashboardLayout>
        {currentScreen === 1 && (
          <>
            <div className="col-lg-12">
              <Videos
                PreviewVideoScreen={PreviewVideoScreen}
                videos={videos}
                setVideos={(e) => setVideos(e)}
              />
            </div>
            <div className="col-lg-12">
              <Documents
                PreviewScreen={PreviewScreen}
                documents={documents}
                setDocuments={(e) => setDocuments(e)}
              />
            </div>
          </>
        )}
        {currentScreen === 2 && (
          <>
            <div className="col-12">
              <div className="row d-flex justify-content-between mb-5">
                <div>
                  <button
                    className="back-btn"
                    onClick={() => setCurrentScreen(1)}
                  >
                    <div className="d-flex">
                      <img
                        src="/assets/img/previous.png"
                        alt=""
                        className="img-fluid"
                      />
                      <h4 className="back-color my-auto ml-2">Back</h4>
                    </div>
                  </button>
                </div>
                <div className="slick-cover">
                  {videos?.map((ele, i) => (
                    <div
                      key={ele?.id}
                      className="cursor-pointer"
                      onClick={(e) => PreviewVideoScreen(e, ele)}
                    >
                      <div
                        className={
                          indexVideo === ele?.id
                            ? "videos-cover2-active mb-3 cursor-pointer mr-3"
                            : "videos-cover2 mb-3 cursor-pointer mr-3"
                        }
                      >
                        <video className="play2" muted>
                          <source src={ele?.url} type="video/mp4" />
                        </video>
                      </div>
                      <div
                        className={
                          indexVideo === ele?.id
                            ? "videos-names1-active"
                            : "videos-names1"
                        }
                      >
                        <h3>{ele.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="videos-cover1">
                    {viewVideo?.url && (
                      <video className="play1" controls>
                        <source src={viewVideo?.url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
                <div className="col-md-8 my-4">
                  <a href={viewVideo?.url}>
                    <input
                      type="button"
                      className="btn btn-success"
                      value="Download Video"
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {currentScreen === 3 && (
          <>
            <div className="col-12">
              <div className="row d-flex justify-content-between mb-5 align-items-center">
                <div>
                  <button
                    className="back-btn"
                    onClick={() => setCurrentScreen(1)}
                  >
                    <div className="d-flex">
                      <img
                        src="/assets/img/previous.png"
                        alt=""
                        className="img-fluid"
                      />
                      <h4 className="back-color my-auto ml-2">Back</h4>
                    </div>
                  </button>
                </div>
                <div className="slick-cover">
                  {documents.map((ele, i) => (
                    <div
                      key={i}
                      className="cursor-pointer"
                      onClick={(e) => PreviewScreen(e, ele)}
                    >
                      <div
                        className={
                          index === ele?.id
                            ? "card document-card-cover1-active mb-2 mr-4"
                            : "card document-card-cover1 mb-2 mr-4"
                        }
                      >
                        <div className="document-cover1">
                          <img src="/assets/img/pdf.png" alt="" />
                        </div>
                      </div>
                      <div
                        className={
                          index === ele?.id
                            ? "documents-names1-active"
                            : "documents-names1"
                        }
                      >
                        <h3 title={ele?.title}>{ele.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row justify-content-center">
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js`}
                >
                  {({ worker }) => (
                    <>
                      {viewDocument?.url && (
                        <Viewer
                          fileUrl={viewDocument?.url}
                          worker={worker} // Pass the worker to the Viewer
                        />
                      )}
                    </>
                  )}
                </Worker>
              </div>
            </div>
          </>
        )}
      </DashboardLayout>
      <DocumentViewModal
        showDocumentViewModal={showDocumentViewModal}
        handleDocumentViewModalClose={handleDocumentViewModalClose}
        viewDocument={viewDocument}
        setCurrentScreen={setCurrentScreen}
      />
    </>
  );
};
export default Resource;
