import { useState } from "react";
import Slider from "react-slick";
const GiveBack1 = ({ handleStart, handleEnd, handleAmount}) => {
  const settings = {
    dots: true,
    infinite: true,
    variableWidth: true,
    autoplay: true,
    nextArrow: (
      <img
        src="assets/img/arrow.png"
        alt=""
        className="slick-next arrow-position1"
      />
    ),
  };

  return (
    <div
      className="tab-pane fade show active"
      id="pills-give-back-give"
      role="tabpanel"
      aria-labelledby="pills-home-tab-give"
    >
      <section className="mt-3 slider">
        <Slider {...settings}>
          <div className="card-cover">
            <div>
              <img src="assets/img/pic.png" className="img-size" alt="" />
            </div>
            <div>
              <h5>Breast Cancer Awareness</h5>
            </div>
            <div>
              <h6>Learn more about the organization</h6>
            </div>
          </div>
          <div className="card-cover">
            <div>
              <img src="assets/img/pic.png" className="img-size" alt="" />
            </div>
            <div>
              <h5>Breast Cancer Awareness</h5>
            </div>
            <div>
              <h6>Learn more about the organization</h6>
            </div>
          </div>
          <div className="card-cover">
            <div>
              <img src="assets/img/pic.png" className="img-size" alt="" />
            </div>
            <div>
              <h5>Breast Cancer Awareness</h5>
            </div>
            <div>
              <h6>Learn more about the organization</h6>
            </div>
          </div>
          <div className="card-cover">
            <div>
              <img src="assets/img/pic.png" className="img-size" alt="" />
            </div>
            <div>
              <h5>Breast Cancer Awareness</h5>
            </div>
            <div>
              <h6>Learn more about the organization</h6>
            </div>
          </div>
          <div className="card-cover">
            <div>
              <img src="assets/img/pic.png" className="img-size" alt="" />
            </div>
            <div>
              <h5>Breast Cancer Awareness</h5>
            </div>
            <div>
              <h6>Learn more about the organization</h6>
            </div>
          </div>
          <div className="card-cover">
            <div>
              <img src="assets/img/pic.png" className="img-size" alt="" />
            </div>
            <div>
              <h5>Breast Cancer Awareness</h5>
            </div>
            <div>
              <h6>Learn more about the organization</h6>
            </div>
          </div>
          <div className="card-cover">
            <div>
              <img src="assets/img/pic.png" className="img-size" alt="" />
            </div>
            <div>
              <h5>Breast Cancer Awareness</h5>
            </div>
            <div>
              <h6>Learn more about the organization</h6>
            </div>
          </div>
        </Slider>
      </section>
      <div className="form-group d-flex mt-2 display-sm-block">
        <span className="display-sm-block">
          <input
            onChange={(e) => handleStart(e.target.value)}
            className="form-control date-textbox"
            type="date"
            placeholder="Start Date"
          />
          <input
            onChange={(e) => handleEnd(e.target.value)}
            className="form-control date-textbox1 dt-mb"
            type="date"
            placeholder="End Date"
          />
        </span>
        <span>
          <input
            onChange={(e) => handleAmount(e.target.value)}

            className="form-control amount-textbox"
            type="text"
            placeholder="Enter amount to donate"
          />
        </span>
      </div>
    </div>
  );
};
export default GiveBack1;
