import $ from 'jquery'
import { useState, useEffect } from 'react';
import swal from 'sweetalert'
const GoLiveStream =(
   { setImageUpload,
    imageUpload}
    )=>{
    $(".form-padding-textbx").change(function(){
        if($(this).val() === '0' || $(this).val() === ''){
            $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
        }
        else{
            $(this).css("border", "2px solid #000")
            $(this).css("color", "#000")
        }
    }).trigger("change");
    $(".form-padding-textbox").change(function(){
        if($(this).val() === '0' || $(this).val() === ''){
            $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
        }
        else{
            $(this).css("border", "2px solid #000")
            $(this).css("color", "#000")
        }
    }).trigger("change");
    $(".textarea-size6").change(function(){
        if($(this).val() === '0' || $(this).val() === ''){
            $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
        }
        else{
            $(this).css("border", "2px solid #000")
            $(this).css("color", "#000")
        }
    }).trigger("change");
    
    const intialValues = {
        streamtype: "",
        duration: "",
        aboutproduct:"",
        productamount:"",
        imageUpload:""
      };
      const [formValues, setFormValues] = useState(intialValues);
      const [formErrors, setFormErrors] = useState({});
      const [isSubmit, setIsSubmit] = useState(false)
    
      const handleChange = (e) =>{
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
      };
    
      const handleSubmit = (e) =>{
        e.preventDefault();
        setFormErrors (validate(formValues));
        setIsSubmit(true)
      };
      const handleImageUpload = (e) => {
        setImageUpload(URL.createObjectURL(e.target.files[0]));
      };
      var image;
      if (imageUpload === "") {
        image = (
          <>
            <label htmlFor="upload-image">
              <div className="upload-btn3">
                <img
                  src="assets/img/upload-image-icon.png"
                  alt=""
                  className="upload-image-icon-position3"
                />
                <h4 className="text-success">Upload Image</h4>
              </div>
            </label>
          <input type="file" id="upload-image" onChange={handleImageUpload} name="imageUpload" value={formValues.imageUpload}/>
          </>
        );
      } else {
        image = (
          <>
            <label htmlFor="upload-image">
            <div className="upload-butn3" >
              <img
                src={imageUpload}
                alt=""
                className="upload-btn1-img"
              />
            </div>
            </label>
            <input type="file" id="upload-image" onChange={handleImageUpload} name="imageUpload" value={formValues.imageUpload}/>
            <input type="button" value="delete" className='btn bg-danger-light del-btn-pos1' onClick={()=> setImageUpload("")}/>
          </>
          
        );
      }

      useEffect(()=>{
        console.log(formErrors)
        if(Object.keys(formErrors).length === 0 && isSubmit){
            console.log(formValues)
            swal({
                title: "Success",
                text: "Product Uploaded Successfully",
                icon: "success",
                button: "OK",
              });
        }
      },[formErrors]);
    
      const validate = (values) =>{
        const errors = {}
        if (!values.streamtype){
            errors.streamtype = "Please select streamtype";
        }
        if (!values.duration) {
            errors.duration = "Please set stream duration";
          } 
          if (!values.imageUpload){
            errors.imageUpload = "Please select streamtype";
        }
        if (!values.aboutproduct){
            errors.aboutproduct = "Product description is required";
        }
        if (!values.productamount){
            errors.productamount = "Product amount is required";
        }
        return errors;
      };
    return(
        <div className="card card-chart z-position card-height1">
            <div className="card-header card-header-color1">
                <h3>Live Streaming</h3>
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div className="row mx-3 mt-3">
                    <div className="col-lg-6 col-pad">
                        <div className="form-group form-position">
                            <label><h3>Stream Type</h3></label>
                            
                            <select className="form-control form-padding-textbx" id="sel2" name="streamtype" value={formValues.streamtype} onChange={handleChange}>
                                <option>Select stream type</option>
                                <option>####</option>
                                <option>####</option>
                            </select>
                            <img src="assets/img/arrow-down.png" alt="" className="select-arrow-position1" />
                        </div>
                        <div className="text-danger danger-size">{formErrors.streamtype}</div>
                    </div>
                    <div className="col-lg-6 col-pad">
                        <div className="form-group form-position">
                            <label><h3>Duration</h3></label>
                            
                            <select className="form-control form-padding-textbx" id="sel2" name="duration" value={formValues.duration} onChange={handleChange}>
                                <option>Select duration</option>
                                <option>####</option>
                                <option>####</option>
                            </select>
                            <img src="assets/img/arrow-down.png" alt="" className="select-arrow-position1" />
                        </div>
                        <div className="text-danger danger-size">{formErrors.duration}</div>
                    </div>
                </div>
                <div className="row mx-3 mt-3">
                    <div className="col-lg-6 col-pad">
                        <div className="row">
                            <div className="col-lg-12">
                                {image}
                                <div className="text-danger danger-size">{formErrors.imageUpload}</div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-6 col-pad">
                        <textarea id="" placeholder="Write about the project" className="textarea-size6 form-control" name="aboutproduct" value={formValues.aboutproduct} onChange={handleChange}></textarea>
                        <div className="text-danger danger-size">{formErrors.aboutproduct}</div>
                        <div className="row">
                            <div className="col-lg-7 col-pad-right">
                                <div className="form-group form-position form-mr-top">
                                    <input type="text" className="form-control form-padding-textbox" placeholder="Product amount" name="productamount" value={formValues.productamount} onChange={handleChange}/>
                                </div>
                                <div className="text-danger danger-size">{formErrors.productamount}</div>
                            </div>
                            <div className="col-lg-5 col-pad-left">
                                <div className="form-group form-position mt-3">
                                    <a href="#" className="btn form-btn"><img src="assets/img/document-text.png" alt="" className="img-fluid" /> Upload PDF</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-width mt-4 mb-4">
                        <div className="col-lg-12 col-pad">
                        <input
                            type="submit"
                            className="btn upload-product-btn1"
                            value="Go to Live Streaming"
                        />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default GoLiveStream;