import axios from "axios";
import { useEffect, useState } from "react";
const MerchantDashboardDocuments = ({
  PreviewScreen,
  documents,
  setDocuments,
}) => {
  const [isloading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
    axios
      .get("/prime.sika/v1/merchants/merchant-resources?page=1&limit=10")
      .then((res) => {
        let docs = res.data.data.resources.filter(
          (ele) => ele?.type.toLowerCase() === "document"
        );
        setLoading(true)
        setDocuments(docs);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err.response);
      });
  }, []);
  return (
    <div
      className="card card-h7 tab-pane fade show active"
      id="pills-merchant"
      role="tabpanel"
      aria-labelledby="pills-merchant-tab"
    >
      <div className="row">
        {documents.length > 0 ? (
          documents.map((ele, i) => (
            <div
              key={i}
              className="col-lg-3 cursor-pointer"
              onClick={(e) => PreviewScreen(e, ele)}
            >
              <div className="card document-card-cover mb-2">
                <div className="document-cover">
                  <img src="/assets/img/pdf.png" alt="" />
                </div>
              </div>
              <div className="documents-names">
                <h3>{ele.title}</h3>
              </div>
            </div>
          ))
        ) : (
          <div className="error-alert-position col-12">
            <h1>No Documents Found</h1>
          </div>
        )}
      </div>
    </div>
  );
};
export default MerchantDashboardDocuments;
