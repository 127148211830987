import { useState } from "react";
import { Modal } from "react-bootstrap";
import UpdateCustomCard from "./updateEgiftCardComponents/UpdateCustomCard";
import UpdateMainCard from "./updateEgiftCardComponents/UpdateMainCard";

const UpdateEgiftCardModal = ({
  handleUpdateEgiftCardModalClose,
  showUpdateEgiftCardModal,
  cardItem,
  setCurrentDashboardScreen,
  current1,
  setCurrent1,
  current,
  setCurrent,
  DoneUpdating,
}) => {
  let currentMain;
  if (cardItem?.web_design_props?.type === "custom") {
    currentMain = 1;
  } else {
    currentMain = 2;
  }

  return (
    <Modal
      show={showUpdateEgiftCardModal}
      onHide={handleUpdateEgiftCardModalClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div>
            {cardItem?.web_design_props?.type === "custom" ? (
              <h4 className="mt-3">Update Custom Egift Card</h4>
            ) : (
              <h4 className="mt-3">Update Main Egift Card</h4>
            )}
          </div>
          <div>
            <button onClick={handleUpdateEgiftCardModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {currentMain === 1 && (
          <UpdateCustomCard
            cardItem={cardItem}
            current1={current1}
            setCurrent1={setCurrent1}
            DoneUpdating={DoneUpdating}
          />
        )}
        {currentMain === 2 && (
          <UpdateMainCard
            setCurrentDashboardScreen={setCurrentDashboardScreen}
            current={current}
            setCurrent={setCurrent}
            cardItem={cardItem}
            handleUpdateEgiftCardModalClose={handleUpdateEgiftCardModalClose}
            DoneUpdating={DoneUpdating}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
export default UpdateEgiftCardModal;
