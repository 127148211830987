const LiveShopProductTable = () =>{
    return(
        <div class="card card-table card-table-height3">
        <div class="card-header card-header-margin">
            <h3 class="my-auto">Product Stocks</h3>
        </div>
        <div class="card-body booking_card">
            <div class="table-responsive">
                <table class="datatable table table-stripped table table-hover table-center mb-0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Sold</th>
                            <th>In stock</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-success-light mr-2">In stock</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-dark-light mr-2">Stock out</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-danger-light mr-2">Low</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-dark-light mr-2">Stock out</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-success-light mr-2">In Stock</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-danger-light mr-2">Low</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-success-light mr-2">In Stock</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-success-light mr-2">In Stock</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="edit-customer.html"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#452</td>
                            <td>Product name here</td>
                            <td>$15</td>
                            <td>58</td>
                            <td>257</td>
                            <td>
                                <div class="actions"> <a href="#" class="btn btn-sm bg-success-light mr-2">In Stock</a> </div>
                            </td>
                            <td class="text-right">
                                <div class="dropdown dropdown-action"> <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v ellipse_color"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="#"><i class="fas fa-pencil-alt m-r-5"></i> Edit</a> <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i class="fas fa-trash-alt m-r-5"></i> Delete</a> </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}
export default LiveShopProductTable;