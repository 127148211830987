import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
const AffiliateForm = ({
  setCurrentScreen,
  currentScreen,
  userInput,
  handleUserInput,
  setCurrent, 
  current
}) => {
  // const show2 = () => {
  //   document.getElementById('form-step-div1').style.display = "none";
  //   document.getElementById('show-next').style.display = "block";
  // }

  const handleValidation = () => {
    setLoading(true);
    axios
      .get(`/acc.egift.card/v1/users/agent/${userInput.affiliate_code}/check`)
      .then((res) => {
        setCurrentScreen(2);
      })
      .catch((err) => {
        if (userInput.affiliate_code === "") {
          swal({
            title: "Error",
            text: "Enter affiliate code",
            icon: "error",
            button: "OK",
          });
          setLoading(false);
        }
        if (err.response.data.status === 404) {
          swal({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
          setLoading(false);
        }
      });
  };
  const [isloading, setLoading] = useState(false);

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-6 col-12 ">
          <div className="left-div-content-6 left-div-img1 mt-3">
            <img src="assets/img/bg8.png" alt="" className="img-fluid" />
          </div>
          <div className="left-div-content-6 gift-color">
            <h1>
              Our company branded <span>eGift Card</span>
            </h1>
          </div>
          <div className="left-div-content-3">
            <ul className="ul-style">
              <li>Increased new customer acquisition</li>
              <li>Improved our customer loyalty</li>
              <li>Improved employee engagement</li>
              <li>Increased sales</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0">
          <div className="card card-size3 card-size3-mar">
            <div className="card-body card-size-body">
              <div className="mb-4">
                <img
                  src="assets/img/arrow-left.png"
                  alt=""
                  className="arrow-margin-top"
                  onClick={() => setCurrent(current - 1)}
                />
              </div>
              <div>
                <h3>Affiliate Code</h3>
              </div>
              <div>
                <h4>Enter Affiliate code below to start signup</h4>
              </div>
              <div className="form-group form-group-marg">
                <input
                  type="text"
                  className="form-control textbx-size"
                  placeholder="Affiliate Code"
                  value={userInput.affiliate_code}
                  name="affiliate_code"
                  onChange={handleUserInput}
                />
                <div className="img-backg">
                  <img
                    src="assets/img/two-icons.png"
                    alt=""
                    className="img-fluid img-pos"
                  />
                </div>
              </div>
              <div className="cont-margin1 continue-btn1">
                {!isloading && (
                  <button
                    type="button"
                    className="btn btn-success btn-next"
                    onClick={() => handleValidation()}
                  >
                    Start
                  </button>
                )}
                {isloading && (
                  <button className="btn btn-success btn-next" disabled>
                    <i className="fas fa-spinner fa-spin mr-2"></i>Starting...
                  </button>
                )}
              </div>

              <div className="row content-items-last1">
                <div className="col-12">
                  <h4>
                    Don't have an account?
                    <Link to="/" className="text-success ml-1">
                      Sign In
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateForm;
