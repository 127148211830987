import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";

const ChangeMerchantStatus = ({
  showChangeMerchantStatusModal,
  handleChangeMerchantStatusClose,
  merchantInput,
  setMerchantInput,
  isloading,
  setLoading,
  merchantsTable,
  SubMerchants,
  setMerchantTable,
}) => {
  const handleUserInput = (e) => {
    setMerchantInput((merchantInput) => ({
      ...merchantInput,
      [e.target.name]: e.target.value,
    }));
  };

  const changeMerchantCurrentStatus = () => {
    if (merchantInput?.status?.toLowerCase() === "approved") {
      setLoading(true);
      axios
        .post(`/prime.sika/v1/managers/merchants/${merchantInput?.id}/approve`)
        .then((res) => {
          setLoading(false);
          handleChangeMerchantStatusClose();
          Swal.fire({
            title: "Success",
            text: "Merchant Status Changed Successfully",
            icon: "success",
            button: "OK",
            confirmButtonColor: "#00b17d",
          }).then((isOkay) => {
            if (isOkay) {
              SubMerchants();
            }
          });
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            swal.fire({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
            });
          }
          setLoading(false);
        });
    } else if (merchantInput?.status?.toLowerCase() === "suspended") {
      setLoading(true);
      axios
        .post(`/prime.sika/v1/managers/merchants/${merchantInput?.id}/suspend`)
        .then((res) => {
          setLoading(false);
          handleChangeMerchantStatusClose();
          Swal.fire({
            title: "Success",
            text: "Merchant Status Changed Successfully",
            icon: "success",
            button: "OK",
            confirmButtonColor: "#00b17d",
          }).then((isOkay) => {
            if (isOkay) {
              SubMerchants();
            }
          });
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            swal.fire({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
            });
          }
        });
    }
  };
  return (
    <Modal
      show={showChangeMerchantStatusModal}
      onHide={handleChangeMerchantStatusClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex">
            <div>
              <h4 className="mt-3">Change Merchant Status</h4>
            </div>
            <div className="mt-3 ml-4">
              {merchantInput?.status?.toLowerCase() === "approved" && (
                <div className="btn btn-sm bg-success-light mr-2">Approved</div>
              )}
              {merchantInput?.status?.toLowerCase() === "suspended" && (
                <div className="btn btn-sm bg-dark-light mr-2">Suspended</div>
              )}
              {merchantInput?.status?.toLowerCase() === "under_review" && (
                <div className="btn btn-sm bg-warning-light mr-2">Pending</div>
              )}
            </div>
          </div>
          <div>
            <button onClick={handleChangeMerchantStatusClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4>Status</h4>
        </div>
        <div>
          <select
            className="form-control"
            name="status"
            value={merchantInput?.status}
            onChange={handleUserInput}
          >
            <option value="suspended">Deactivate</option>
            <option value="under_review">Pending</option>
            <option value="approved">Activate</option>
          </select>
        </div>
        <div className="text-center mt-5">
          {!isloading && (
            <button
              className="btn btn-success"
              onClick={changeMerchantCurrentStatus}
            >
              Change Status
            </button>
          )}
          {isloading && (
            <button className="btn btn-success" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>changing...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ChangeMerchantStatus;
