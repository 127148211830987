const ProfileEdit = () => {
    return(
<div class="card card-chart z-position card-height-5 text-center">
												<div class="row">
													<div class="col-12 d-flex">
														<div class="image-cover">
															<img src="assets/img/profile.png" alt="" />
														</div>
														<h2>Antor Paul</h2>
														
													</div>
												</div>
												<div class="row">
													<div class="col-12 d-flex mt-3">
														<div>
															<img src="assets/img/sms.png" alt="" />
														</div>
															<h6>antorpaulraj@gmail.com</h6>
													</div>
													<div class="col-12 d-flex mt-2">
														<div>
															<img src="assets/img/call.png" alt="" />
														</div>
														<h6>+123456789</h6>
													</div>
													<div class="col-12 d-flex mt-2">
														<div>
															<img src="assets/img/briefcase.png" alt="" />
														</div>
														<h6>Company Name</h6>
													</div>
													<div class="col-12 d-flex mt-2">
														<div>
															<img src="assets/img/location.png" alt="" />
														</div>
														<h6>84/E/2 West Jafrabad</h6>
													</div>
												</div>
												<div class="row">
													<div class="col-12 mt-3">
														<a href="#" class="btn btn-outline-success mt-3 edit-button-width">Edit Profile</a>
													</div>
												</div>
											</div>
    )
}
export default ProfileEdit;