import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import MerchantSignUp from "./signupComponents/MerchantSignUp";
import AffiliateSignUp from "./signupComponents/AffiliateSignUp";

const Signup = () => {
  const [current, setCurrent] = useState(1);
  const [userTypeInput, setUserTypeInput] = useState({
    user_type: "egift",
  });
  const handleUserTypeInput = (e) => {
    setUserTypeInput((userTypeInput) => ({
      ...userTypeInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckedRadio = () => {
    if (
      userTypeInput.user_type === "egift" ||
      userTypeInput.user_type === "primepay" ||
      userTypeInput.user_type === "market_place"
    ) {
      setCurrent(2);
    } else {
      setCurrent(3);
    }
  };

  return (
    <>
      <form action="">
        {current === 1 && (
          <>
            <div>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6 col-12 justify-content-center">
                  <div className="mt-3">
                    <img
                      src="assets/img/bg5.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="left-div-content-2 gift-color">
                    <h1>
                      The Prime gift Solution, <span>eGift Card</span>
                    </h1>
                  </div>
                  <div className="left-div-content-3">
                    <h3>
                      Sell your company branded e-gift card{" "}
                      <span>and increase your revenue</span>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6 col-12 justify-content-center">
                  <div className="card card-size2 mt-3">
                    <div className="card-body card-size-body">
                      <div>
                        <h3>Sign up as</h3>
                      </div>
                      <div>
                        <h4>Select one</h4>
                      </div>
                      <div className="radio-group">
                        <div className="d-block">
                          <label
                            htmlFor="myRadioId"
                            className="radio d-flex align-items-center"
                          >
                            <input
                              type="radio"
                              name="user_type"
                              id="myRadioId"
                              className="radio__input mr-2"
                              value="egift"
                              onChange={handleUserTypeInput}
                              defaultChecked
                            />
                            <div className="radio__radio"></div>
                            <h4 className="my-auto">Merchant</h4>
                          </label>
                          <div className="my-auto text-margin-left">
                            <h5>
                              Create, sell, track and promote your eGift cards.
                              <span className="ml-2">
                                <a
                                  href="https://primeegiftcard.com/merchant"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  read more
                                </a>
                              </span>{" "}
                            </h5>
                          </div>
                        </div>
                        <div className="d-block mt-4">
                          <label
                            htmlFor="myRadioId4"
                            className="radio d-flex align-items-center"
                          >
                            <input
                              type="radio"
                              name="user_type"
                              id="myRadioId4"
                              className="radio__input mr-2"
                              value="primepay"
                              onChange={handleUserTypeInput}
                            />
                            <div className="radio__radio"></div>
                            <h4 className="my-auto">PrimePay</h4>
                          </label>
                          <div className="my-auto text-margin-left">
                            <h5>
                              Redeem a customer's gift card, and see how your
                              business is doing on-the-go.
                              <span className="ml-2">
                                <a href="#" target="_blank" rel="noreferrer">
                                  read more
                                </a>
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div className="d-block mt-4">
                          <label
                            htmlFor="myRadioId5"
                            className="radio d-flex align-items-center"
                          >
                            <input
                              type="radio"
                              name="user_type"
                              id="myRadioId5"
                              className="radio__input mr-2"
                              value="market_place"
                              onChange={handleUserTypeInput}
                            />
                            <div className="radio__radio"></div>
                            <h4 className="my-auto">MarketPlace</h4>
                          </label>
                          <div className="my-auto text-margin-left">
                            <h5>
                              Sell, track and promote your products
                              <span className="ml-2">
                                <a
                                  href="https://primeegiftcard.com/marketplace"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  read more
                                </a>
                              </span>{" "}
                            </h5>
                          </div>
                        </div>
                        <div className="d-block justify-content-between mt-4">
                          <label
                            htmlFor="myRadioId1"
                            className="radio d-flex align-items-center"
                          >
                            <input
                              type="radio"
                              name="user_type"
                              id="myRadioId1"
                              className="radio__input mr-2"
                              value="affiliate"
                              onChange={handleUserTypeInput}
                            />
                            <div className="radio__radio"></div>
                            <h4 className="my-auto">Affiliate</h4>
                          </label>
                          <div className="my-auto text-margin-left">
                            <h5>
                              Earn generous commissions for every gift card sold
                              by signing up local businesses
                              <span className="ml-2">
                                <a
                                  href="https://primeegiftcard.com/affiliate"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  read more
                                </a>
                              </span>{" "}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 continue-btn">
                        <input
                          type="button"
                          className="btn btn-success"
                          value="Continue"
                          onClick={handleCheckedRadio}
                        />
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <h4>
                            Have an account?
                            <Link to="/" className="text-success ml-1">
                              Sign In
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="float-right d-flex mb-4 align-items-center justify-content-between foot-width mr-3">
                    <a href="/signup">
                      <img
                        src="assets/img/linkedin.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                    <a href="/signup">
                      <img
                        src="assets/img/twitter.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                    <a href="/signup">
                      <img
                        src="assets/img/facebook.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                    <a href="/signup">
                      <img
                        src="assets/img/instagram.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                    <a href="/signup">
                      <img
                        src="assets/img/youtube.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                    <h5>primeegiftcard</h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {current === 2 && (
          <MerchantSignUp
            userTypeInput={userTypeInput}
            handleUserTypeInput={handleUserTypeInput}
            setCurrent={setCurrent}
            current={current}
          />
        )}
        {current === 3 && (
          <AffiliateSignUp current={current} setCurrent={setCurrent} />
        )}
      </form>
    </>
  );
};
export default Signup;
