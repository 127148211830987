import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
const MainCardReview = ({
  current,
  setCurrent,
  image,
  color,
  amount,
  category,
  terms,
  type,
  handlePublish,
  pos,
  amts,
  description,
  newBgImage,
  title
}) => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  const handle = () => {
    handlePublish();
    setCurrent(3);
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="float-right arrow-margin-top1">
            <button onClick={() => setCurrent(1)}>
              <img src="/assets/img/arrow-left.png" alt="" />
            </button>
          </div>
        </div>
        <div className="col-lg-12 mt-4">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="main-upload-butn-cover">
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  {amts.map((ele, i) => (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 main-upload-butn-img"
                        src={image}
                        alt="First slide"
                      />
                      {pos === 1 && (
                        <div
                          style={{ color: color }}
                          className="card-amount-pos1-top-r"
                        >
                          <h4>
                            GH¢ <span>{ele}</span>
                          </h4>
                        </div>
                      )}
                      {pos === 2 && (
                        <div
                          style={{ color: color }}
                          className="card-amount-pos1-top-l"
                        >
                          <h4>
                            GH¢ <span>{ele}</span>
                          </h4>
                        </div>
                      )}
                      {pos === 3 && (
                        <div
                          style={{ color: color }}
                          className="card-amount-pos1-down-r"
                        >
                          <h4>
                            GH¢ <span>{ele}</span>
                          </h4>
                        </div>
                      )}
                      {pos === 4 && (
                        <div
                          style={{ color: color }}
                          className="card-amount-pos1-down-l"
                        >
                          <h4>
                            GH¢ <span>{ele}</span>
                          </h4>
                        </div>
                      )}
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="d-block">
              <div className=" d-flex my-auto justify-content-center">
                <h4 className="back-img-size my-auto">Background-Image:</h4>
                <div className="back-con mr-3">
                  <img src={newBgImage} alt="" />
                </div>
              </div>
              <div className="mt-4">
                <h4 className="back-img-siz d-flex">Card Name: <span className="ml-2 text-secondary">{title}</span></h4>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <h3>Enter Card Amounts in Ghana Cedis</h3>
        </div>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap">
            {amts.map((item, i) => {
              return (
                <input
                  key={i}
                  value={item}
                  disabled
                  className="form-control form-padding2 text-center mb-3 mr-2"
                  type="number"
                  name="amount"
                />
              );
            })}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="col-width mt-3">
            <div className="form-group form-position">
              <label>
                <h3>Category</h3>
              </label>

              <>
                <div
                  className="form-control form-padding4 form-review-padding"
                  id="sel2"
                >
                  <img
                    src="assets/img/arrow-down.png"
                    alt=""
                    className="select-arrow-position"
                  />
                  {category}
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row mt-3">
            <div className="col-12">
              <h3>Retailer Gift Card Terms & Conditions</h3>
            </div>
            <div className="col-12 text-color">
              <h4>Add the terms and conditions for your card</h4>
            </div>
            <div className="col-12">
              <div name="terms" className="textarea-sz">
                {terms}
              </div>
            </div>
            <div className="col-12 mt-3">
              <h3>E-gift Card Description</h3>
            </div>
            <div className="col-12">
              <div name="terms" className="textarea-sz">
                {description}
              </div>
            </div>
            <hr className="hr1" />
            <div className="col-lg-12 d-flex justify-content-between mt-4 mb-3">
              <div className="d-flex mt-3">
                {type === "" ? (
                  <div className="promotion-type"></div>
                ) : (
                  <>
                    <div className="mr-2">
                      <img src="assets/img/lovely.png" alt="" />
                    </div>
                    <div className="promotion-type">
                      <h4 className="my-auto">
                        Promotion: <span>{capitalize(type)}</span>
                      </h4>
                    </div>
                  </>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-success btn-block review-btn mt-2"
                  onClick={() => handle()}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainCardReview;
