import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slider from "react-slick";
const AddCoupons = ({
  current,
  setCurrent,
  adding,
  setAdding,
  setFile,
  userInput,
  handleUserInput,
  saveData,
  selectedItems,
  setSelectedItems,
  file,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    variableWidth: true,
    autoplay: true,
    dots: false,
    // nextArrow: (
    //   <img
    //     src="assets/img/arrow.png"
    //     alt=""
    //     className="arrow-position1 slick-next"
    //   />
    // ),
  };
  const [cards, setCards] = useState([]);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/cards/list")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCards(res.data.data.cards);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const checkBoxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      var temp = selectedItems;
      temp = temp.filter((id) => {
        return id !== value;
      });
      temp ? setSelectedItems(temp) : setSelectedItems([]);
    }
  };

  const checkAllHandler = () => {
    if (cards?.length === selectedItems?.length) {
      setSelectedItems([]);
    } else {
      const cardIds = cards?.map((item) => {
        return item?.id;
      });
      setSelectedItems(cardIds);
    }
  };

  var cardList1 = cards.map((card, index) => {
    if (card?.web_design_props.type === "custom") {
      var rightLogo =
        card?.web_design_props?.logo_position[0] === "center" &&
        card?.web_design_props?.logo_position[1] === "right";
      var leftLogo =
        card?.web_design_props?.logo_position[0] === "center" &&
        card?.web_design_props?.logo_position[1] === "left";
      var downLogo =
        card?.web_design_props?.logo_position[0] === "bottom" &&
        card?.web_design_props?.logo_position[1] === "right";
      var topLogo =
        card?.web_design_props?.logo_position[0] === "top" &&
        card?.web_design_props?.logo_position[1] === "right";
      var classProp1;
      if (
        card?.web_design_props?.amount_position[0] === "top" &&
        card?.web_design_props?.amount_position[1] === "left" &&
        rightLogo
      ) {
        classProp1 = "card-e-gift-img-top-l2";
      } else if (
        card?.web_design_props?.amount_position[0] === "bottom" &&
        card?.web_design_props?.amount_position[1] === "left" &&
        rightLogo
      ) {
        classProp1 = "card-e-gift-img-down-l2";
      } else if (
        card?.web_design_props?.amount_position[0] === "top" &&
        card?.web_design_props?.amount_position[1] === "right" &&
        leftLogo
      ) {
        classProp1 = "card-e-gift-img-top-r2";
      } else if (
        card?.web_design_props?.amount_position[0] === "bottom" &&
        card?.web_design_props?.amount_position[1] === "right" &&
        leftLogo
      ) {
        classProp1 = "card-e-gift-img-down-r2";
      } else if (
        card?.web_design_props?.amount_position[0] === "top" &&
        card?.web_design_props?.amount_position[1] === "left" &&
        downLogo
      ) {
        classProp1 = "card-e-gift-img-top-l2";
      } else if (
        card?.web_design_props?.amount_position[0] === "top" &&
        card?.web_design_props?.amount_position[1] === "right" &&
        downLogo
      ) {
        classProp1 = "card-e-gift-img-top-r2";
      } else if (
        card?.web_design_props?.amount_position[0] === "bottom" &&
        card?.web_design_props?.amount_position[1] === "left" &&
        topLogo
      ) {
        classProp1 = "card-e-gift-img-down-l2";
      } else if (
        card?.web_design_props?.amount_position[0] === "bottom" &&
        card?.web_design_props?.amount_position[1] === "right" &&
        topLogo
      ) {
        classProp1 = "card-e-gift-img-down-r1";
      }
      return (
        <div className="d-block" key={index}>
          {rightLogo && (
            <div
              className="right-logo img"
              style={{
                color: card?.default_color,
                border: "0.5px solid",
                borderRadius: "10px",
                width: "181px",
                height: "101px",
                display: "grid",
                gridTemplateColumns: "auto auto",
                overflow: "hidden",
                boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
              }}
            >
              <div
                style={{
                  width: "120px",
                  height: "101px",
                  position: "relative",
                }}
              >
                <img src="http://placeimg.com/100/101/any" alt="" />
                <div
                  style={{ color: card?.default_color }}
                  className={classProp1}
                >
                  <h4>
                    GH¢ <span>{card?.amount}</span>
                  </h4>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: card?.default_color,
                  width: "61px",
                  position: "relative",
                }}
              >
                <div className="logo-size-prev1">
                  <img
                    src={card?.logo}
                    alt="First slide"
                    className="upload-btn-img4"
                  />
                </div>
                {card?.web_design_props?.show_egift_card && (
                  <div style={{ color: card?.web_design_props?.egift_color }}>
                    <h4 className="gift-text-size5">e-Gift Card</h4>
                  </div>
                )}
              </div>
            </div>
          )}
          {leftLogo && (
            <div
              className="left-logo img"
              style={{
                color: card?.default_color,
                border: "0.5px solid",
                borderRadius: "10px",
                width: "181px",
                height: "101px",
                display: "grid",
                gridTemplateColumns: "auto auto",
                overflow: "hidden",
                boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
              }}
            >
              <div
                style={{
                  background: card?.default_color,
                  width: "61px",
                  position: "relative",
                }}
              >
                <div className="logo-size-prev1">
                  <img
                    src={card?.logo}
                    alt="First slide"
                    className="upload-btn-img1"
                  />
                </div>
                {card?.web_design_props?.show_egift_card && (
                  <div style={{ color: card?.web_design_props?.egift_color }}>
                    <h4 className="gift-text-size5">e-Gift Card</h4>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "120px",
                  height: "101px",
                  position: "relative",
                }}
              >
                <img src="http://placeimg.com/180/151/any" alt="" />
                <div
                  style={{ color: card?.default_color }}
                  className={classProp1}
                >
                  <h4>
                    GH¢ <span>{card?.amount}</span>
                  </h4>
                </div>
              </div>
            </div>
          )}
          {downLogo && (
            <div
              className="down-logo img"
              style={{
                color: card?.default_color,
                border: "0.5px solid",
                borderRadius: "10px",
                width: "181px",
                height: "101px",
                display: "grid",
                gridTemplateRows: "auto auto",
                overflow: "hidden",
                boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
              }}
            >
              <div style={{ height: "69px", position: "relative" }}>
                <img src="http://placeimg.com/250/97/any" alt="" />
                <div
                  style={{ color: card?.default_color }}
                  className={classProp1}
                >
                  <h4>
                    GH¢ <span>{card?.amount}</span>
                  </h4>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: card?.default_color,
                  position: "relative",
                  height: "32px",
                }}
              >
                <div className="logo-size1-prev2">
                  <img
                    src={card?.logo}
                    alt="First slide"
                    className="upload-btn-img-bottom"
                  />
                </div>
                {card?.web_design_props?.show_egift_card && (
                  <div
                    style={{
                      color: card?.web_design_props?.egift_color,
                      transition: "ease all 100ms",
                    }}
                  >
                    <h4 className="gift-text-size1">e-Gift Card</h4>
                  </div>
                )}
              </div>
            </div>
          )}
          {topLogo && (
            <div
              className="up-logo img"
              style={{
                color: card?.default_color,
                border: "0.5px solid",
                borderRadius: "10px",
                width: "181px",
                height: "101px",
                display: "grid",
                gridTemplateRows: "auto auto",
                overflow: "hidden",
                boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
              }}
            >
              <div
                style={{
                  height: "32px",
                  backgroundColor: card?.default_color,
                  position: "relative",
                }}
              >
                <div className="logo-size1-prev2">
                  <img
                    src={card?.logo}
                    alt="First slide"
                    className="upload-btn-img-bottom"
                  />
                </div>
                {card?.web_design_props?.show_egift_card && (
                  <div
                    style={{
                      color: card?.web_design_props?.egift_color,
                      transition: "ease all 100ms",
                    }}
                  >
                    <h4 className="gift-text-size1">e-Gift Card</h4>
                  </div>
                )}
              </div>
              <div style={{ height: "69px", position: "relative" }}>
                <img src="http://placeimg.com/250/97/any" alt="" />
                <div
                  style={{ color: card?.default_color }}
                  className={classProp1}
                >
                  <h4>
                    GH¢ <span>{card?.amount}</span>
                  </h4>
                </div>
              </div>
            </div>
          )}
          <input
            type="checkbox"
            className="checkbox-design mt-2"
            value={card?.id}
            onChange={checkBoxHandler}
            checked={selectedItems?.includes(card?.id)}
          />
        </div>
      );
    } else {
      var classProp;

      if (
        card?.web_design_props?.amount_position[0] === "top" &&
        card?.web_design_props?.amount_position[1] === "right"
      ) {
        classProp = "card-e-gift-img-top-r1";
      } else if (
        card?.web_design_props?.amount_position[0] === "top" &&
        card?.web_design_props?.amount_position[1] === "left"
      ) {
        classProp = "card-e-gift-img-top-l1";
      } else if (
        card?.web_design_props?.amount_position[0] === "bottom" &&
        card?.web_design_props?.amount_position[1] === "left"
      ) {
        classProp = "card-e-gift-img-down-l1";
      } else if (
        card?.web_design_props?.amount_position[0] === "bottom" &&
        card?.web_design_props?.amount_position[1] === "right"
      ) {
        classProp = "card-e-gift-img-down-r1";
      }
      return (
        <>
          <div className="d-block">
            <div className="card-e-gift-img-cover1">
              <img src={card?.image} alt="" className="card-e-gift-img5" />
              <h4 style={{ color: card?.default_color }} className={classProp}>
                GH¢ <span>{parseFloat(card?.amount).toFixed(2)}</span>
              </h4>
            </div>
          </div>
          <input
            type="checkbox"
            className="checkbox-design mt-2"
            value={card?.id}
            onChange={checkBoxHandler}
            checked={selectedItems?.includes(card?.id)}
          />
        </>
      );
    }
  });

  return (
    <div className="row">
      <div className="col-lg-12">
        <div class="card card-tablet">
          <div class="card-header card-header-margin d-flex justify-content-between">
            <button className="back-btn" onClick={() => setCurrent(1)}>
              <div className="d-flex">
                <img
                  src="/assets/img/previous.png"
                  alt=""
                  className="img-fluid"
                />
                <h4 className="back-color my-auto ml-2">Back</h4>
              </div>
            </button>
          </div>
          <div class="card-body booking_card">
            <form action="" className="coupon-form-width">
              <div className="form-control-width">
                <h4>Name</h4>
                <input
                  type="text"
                  className="form-control form-cont-text"
                  placeholder="name"
                  name="name"
                  value={userInput.name}
                  onChange={handleUserInput}
                />
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="width-control">
                  <h4>Start Date</h4>
                  <input
                    type="datetime-local"
                    min="<?php echo date('Y-m-d')?>"
                    className="form-control form-cont-text"
                    name="start_date"
                    value={userInput.start_date}
                    onChange={handleUserInput}
                  />
                </div>
                <div className="width-control">
                  <h4>Expiry Date</h4>
                  <input
                    type="datetime-local"
                    min="<?php echo date('Y-m-d')?>"
                    className="form-control form-cont-text"
                    name="expire_date"
                    value={userInput.expire_date}
                    onChange={handleUserInput}
                  />
                </div>
              </div>
              <div className="form-control-width mt-4">
                <h4>CSV Codes</h4>
                <div className="csv-height ">
                  <input
                    type="file"
                    id="upload-file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <label htmlFor="upload-file" className="cur-pointer">
                    {file === "" ? (
                      <div>
                        <img
                          src="assets/img/directbox-default.png"
                          alt=""
                          className="img-fluid"
                        />
                        <h4 className="click-text">
                          Click here to select a CSV file for Upload
                        </h4>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <img
                          src="assets/img/csv.webp"
                          alt=""
                          className="img-fluid"
                          width="70px"
                        />
                        <div>
                          <h4 className="mt-4 ml-3">{file.name}</h4>
                        </div>
                      </div>
                    )}
                  </label>
                  <h4 className="download-text">
                    Download Template csv file and replace 'XXXX' with your code
                  </h4>
                  <div>
                    <a href="/assets/template.csv" className="underline-a">
                      Coupons-Template.csv
                    </a>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <h4>OR</h4>
              </div>
              <div className="form-control-width">
                <h4>String codes</h4>
                <textarea
                  name="string_codes"
                  value={userInput.string_codes}
                  onChange={handleUserInput}
                  className="form-control texbox-size1"
                  placeholder="XXXX, XXXX, XXXX"
                >
                  {" "}
                </textarea>
              </div>
              <div className="mt-4">
                <div className="d-flex justify-content-between">
                  <h4>Cards</h4>
                  <div className="d-flex ">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={checkAllHandler}
                    >
                      {cards?.length === selectedItems?.length
                        ? "Unselect All"
                        : "Select All"}
                    </button>
                  </div>
                </div>
                <div className="py-1">
                  <Slider {...settings}>{cardList1}</Slider>
                </div>
              </div>
              <div className="form-control-width">
                <h4>Description</h4>
                <textarea
                  name="description"
                  value={userInput.description}
                  onChange={handleUserInput}
                  className="form-control texbox-size1"
                  placeholder="Description"
                ></textarea>
              </div>
              <div className="form-control-width mt-4">
                <h4>Terms & Conditions</h4>
                <textarea
                  name="terms_and_conditions"
                  value={userInput.terms_and_conditions}
                  onChange={handleUserInput}
                  className="form-control texbox-size1"
                  placeholder="Terms and Conditions"
                >
                  {" "}
                </textarea>
              </div>
              <div className="form-control-width mt-4">
                {!adding && (
                  <button
                    type="button"
                    className="btn btn-success form-control-width"
                    onClick={saveData}
                  >
                    ADD COUPONS
                  </button>
                )}
                {adding && (
                  <button
                    className="btn btn-success form-control-width"
                    disabled
                  >
                    <i className="fas fa-spinner fa-spin mr-2"></i>Adding...
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCoupons;
