import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

const PromoTable = () => {
  const [coupons, setCoupons] = useState("");
  const [isloading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
    axios
      .get("/prime.sika/v1/managers/coupons/list?limit=5")
      .then((res) => {
        setLoading(true);
        setCoupons(res.data.data.coupons);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  }, []);
  return (
    <div className="card card-table card-table-height8">
      <div className="card-header card-header-margin d-flex justify-content-between">
        <h3 className="my-auto">PromoCodes</h3>
        {/* <a href="/merchants" className="my-auto btn btn-success">
          <img
            src="assets/img/download.png"
            alt=""
            className="img-fluid mr-2"
          />
          Download
        </a> */}
      </div>

      <div className="card-body booking_card">
        <div className="table-responsive">
          <table className="datatable table table-stripped table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Total</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {coupons.length === 0 ? (
                <tr className="error-alert-position mt-5">
                  <td colSpan={6}>
                    {" "}
                    <h1>No Records Found</h1>
                  </td>
                </tr>
              ) : (
                coupons.map((ele, i) => {
                  return (
                    <tr key={i}>
                      <td>{ele.name}</td>
                      <td>{ele.total}</td>
                      <td>{moment(ele.created_at).format("ll")}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div>
            <Link to="/coupons">
              <button className="btn btn-success view-more-butn">
                View more
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PromoTable;
