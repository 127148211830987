import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Sidebar = () => {

  const navigate = useNavigate();

  const logout = () => {
    axios.post("/prime.sika/v1/users/logout", { user_type: "merchant" });
    localStorage.removeItem("company_code");
    localStorage.removeItem("token");
    localStorage.removeItem("client_type");
    localStorage.removeItem("service_code");

    navigate("/");
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div className="row align-content-center justify-content-center mt-4">
          <div className="col-10">
            <div className="logo-position">
              <img src="assets/img/prime-seller.png" width="50%" alt="logo" />
            </div>
          </div>
        </div>
        <hr />

        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="active">
              {" "}
              <NavLink to="/dashboard">
                <img src="/assets/img/category.png" alt="" />{" "}
                <span>Dashboard</span>
              </NavLink>{" "}
            </li>
            {localStorage?.getItem("service_code") === "market_place" ? (
              <li>
                {" "}
                <NavLink to="/merchants" state={"true"}>
                  <img src="/assets/img/shop.png" alt="" />{" "}
                  <span>E-Gift Cards</span>
                </NavLink>{" "}
              </li>
            ) : (
              <li>
                {" "}
                <NavLink to="/merchants">
                  <img src="/assets/img/shop.png" alt="" />{" "}
                  <span>E-Gift Cards</span>
                </NavLink>{" "}
              </li>
            )}
            {localStorage?.getItem("service_code") === "egift" ? (
              <li>
                {" "}
                <NavLink to="/marketplace" state={"true"}>
                  <img src="/assets/img/shopping-cart.png" alt="" />{" "}
                  <span>Marketplace</span>
                </NavLink>{" "}
              </li>
            ) : (
              <li>
                {" "}
                <NavLink to="/marketplace">
                  <img src="/assets/img/shopping-cart.png" alt="" />{" "}
                  <span>Marketplace</span>
                </NavLink>{" "}
              </li>
            )}
            {/* <li> <NavLink to="/liveshop"><img src="/assets/img/bag.png" alt="" /> <span>LiveShop</span></NavLink> </li> */}
            {/* <li>
              {" "}
              <NavLink to="/marketing">
                <img src="/assets/img/marketing.png" alt="" />{" "}
                <span>Marketing</span>
              </NavLink>{" "}
            </li> */}
            {localStorage?.getItem("service_code") === "market_place" ? (
              <li>
                {" "}
                <NavLink to="/coupons" state={"true"}>
                  <img src="/assets/img/direct-inbox.png" alt="" />{" "}
                  <span>Coupons</span>
                </NavLink>{" "}
              </li>
            ) : (
              <li>
                {" "}
                <NavLink to="/coupons">
                  <img src="/assets/img/direct-inbox.png" alt="" />{" "}
                  <span>Coupons</span>
                </NavLink>{" "}
              </li>
            )}
            <li>
              {" "}
              <NavLink to="/reports">
                <img src="/assets/img/note-text.png" alt="" />{" "}
                <span>Reports</span>
              </NavLink>{" "}
            </li>
            <li>
              {" "}
              <NavLink to="/resource">
                <img src="/assets/img/archive-book.png" alt="" />{" "}
                <span>Resource</span>
              </NavLink>{" "}
            </li>
            <li>
              {" "}
              <NavLink to="/customercare">
                <img src="/assets/img/customer-care.png" alt="" />{" "}
                <span>Customer Care</span>
              </NavLink>{" "}
            </li>
            <li>
              {" "}
              <NavLink to="/settings" state={"true"}>
                <img src="/assets/img/setting-2.png" alt="" />{" "}
                <span>Settings</span>
              </NavLink>{" "}
            </li>
            <li>
              <button onClick={logout} className="log-out-btn btn">
                <img src="/assets/img/logout.png" alt="" /> <span>Log Out</span>
              </button>
            </li>
            <br />
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
