import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import PagePagination from "../../../components/PagePagination";

const MerchantPrivileges = ({
  merchantsTable,
  setMerchantTable,
  handleChangeMerchantStatusShow,
  handleUpdateMerchantShow,
  setTotalPage,
  setCurrentPage,
  totalPage,
  setSearch,
  loadMerchants,
  SubMerchants,
  setLoadMerchants
}) => {
  const [activeIndex, setActiveIndex] = useState("");

  const DeleteMerchant = (e, i) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to delete merchant data?",
      text: "Take note, if merchant has transaction, the account will be archived",
      icon: "warning",
      showDenyButton: false,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#00b17d",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
      setLoadMerchants(false);
        axios
          .delete(`prime.sika/v1/managers/merchants/${i}`)
          .then((res) => {
            Swal.fire({
              title: "Success",
              text: "Merchant Account Deleted",
              icon: "success",
              button: "OK",
              confirmButtonColor: "#00b17d",
            }).then((isOkay) => {
              if (isOkay) {
                SubMerchants();
              }
            });
          })
          .catch((err) => {
            console.log(err.response);
            setLoadMerchants(true);
          });
      }
    });
    return false;
  };

  return (
    <div className="card card-table card-table-height1">
      <div className="card-header card-header-margin d-flex justify-content-between">
        <h3 className="my-auto">All Merchants</h3>
        <div className="form-width">
          <input
            type="text"
            className="form-control"
            placeholder="Search merchant..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="card-body booking_card">
        <div className="table-cover">
          <div className="d-flex table-content justify-content-between">
            <div className="div-size4">Name</div>
            <div className="div-size3">Location</div>
            <div className="div-size2">Telephone No.</div>
            <div className="div-size2">Date</div>
            <div className="div-size2">Status</div>
            <div className="div-size2"></div>
          </div>
        </div>
        {loadMerchants ? (
          <>
            {merchantsTable.length === 0 ? (
              <div className="no-available1">
                <h1>No Merchant Found</h1>
              </div>
            ) : (
              merchantsTable?.map((ele, i) => {
                const isActive = i === activeIndex;

                return (
                  <div key={i} className="row-one">
                    <div className="d-flex justify-content-between table-content2">
                      <div className="div-size4">
                        <p title={ele.name}>
                          {isActive ? (
                            <img
                              src="assets/img/arr-down.png"
                              alt=""
                              className="mr-2"
                              onClick={(i) => !setActiveIndex(i)}
                            />
                          ) : (
                            <img
                              src="assets/img/arr-right.png"
                              alt=""
                              className="mr-2 cur-pointer"
                              onClick={() => setActiveIndex(i)}
                            />
                          )}
                          {ele.name}
                        </p>
                      </div>
                      <div className="div-size3" title={ele.branch}>
                        {ele.branch}
                      </div>
                      <div className="div-size2">{ele.telephone}</div>
                      <div className="div-size2">
                        {moment(ele.created_at).format("ll")}
                      </div>
                      <div className="div-size2">
                        {ele?.status?.toLowerCase() === "under_review" && (
                          <div className="d-flex">
                            <div className="circ-pending my-auto"></div>
                            <span class="ml-1">Pending</span>
                          </div>
                        )}
                        {ele?.status?.toLowerCase() === "approved" && (
                          <div className="d-flex">
                            <div className="circ-approved my-auto"></div>
                            <span class="ml-1">Approved</span>
                          </div>
                        )}
                        {ele?.status?.toLowerCase() === "suspended" && (
                          <div className="d-flex">
                            <div className="circ-suspended my-auto"></div>
                            <span class="ml-1">Suspended</span>
                          </div>
                        )}
                      </div>
                      <div className="div-size2 d-flex">
                        <button
                          type="button"
                          onClick={(e) =>
                            handleUpdateMerchantShow(e, (i = ele))
                          }
                        >
                          <img
                            src="/assets/img/edit-2.png"
                            alt=""
                            width="20px"
                            height="20px"
                            className="mr-3 cur-pointer mg-top"
                            title="Edit"
                          />
                        </button>
                        <button
                          type="button"
                          onClick={(e) => DeleteMerchant(e, (i = ele.id))}
                        >
                          <img
                            src="/assets/img/trash.png"
                            alt=""
                            width="20px"
                            height="20px"
                            className="mr-3 cur-pointer mg-top"
                            title="Delete"
                          />
                        </button>
                        <button
                          type="button"
                          onClick={(e) =>
                            handleChangeMerchantStatusShow(e, (i = ele))
                          }
                        >
                          <img
                            src="/assets/img/change-status.png"
                            alt=""
                            width="20px"
                            height="20px"
                            className="mr-3 cur-pointer mg-top"
                            title="Change Status"
                          />
                        </button>
                      </div>
                    </div>
                    <hr />
                    {isActive && (
                      <div className="d-flex justify-content-between content-back-color">
                        <div className="bg-success-light2 priv-width ">
                          Privileges
                        </div>
                        <div className="d-flex privilege-width">
                          <div className="d-flex div-size1">
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              id="add"
                              name="add"
                            />
                            <label htmlFor="add">
                              <h4 className="mt-1 ml-2">Add card</h4>
                            </label>
                          </div>
                          <div className="d-flex div-size1">
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              id="manage"
                              name="manage"
                            />
                            <label htmlFor="manage">
                              <h4 className="mt-1 ml-2">Manage Account</h4>
                            </label>
                          </div>
                          <div className="d-flex div-size1">
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              id="set"
                              name="set"
                            />
                            <label htmlFor="set">
                              <h4 className="mt-1 ml-2">Set Up Account</h4>
                            </label>
                          </div>
                          <div className="d-flex div-size1">
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              id="cards"
                              name="cards"
                            />
                            <label htmlFor="cards">
                              <h4 className="mt-1 ml-2">Manage Cards</h4>
                            </label>
                          </div>
                          <div className="d-flex div-size1">
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              id="cards"
                              name="cards"
                            />
                            <label htmlFor="cards">
                              <h4 className="mt-1 ml-2">Manage Cards</h4>
                            </label>
                          </div>
                          <div className="d-flex div-size1">
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              id="cards"
                              name="cards"
                            />
                            <label htmlFor="cards">
                              <h4 className="mt-1 ml-2">Reports</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </>
        ) : (
          <div className="no-data-div">
            <div className="no-data">
              <i className="fas fa-spinner fa-spin mr-2"></i>
            </div>
          </div>
        )}
      </div>
      <div className="mr-2">
        <PagePagination
          setTotalPage={setTotalPage}
          totalPage={totalPage}
          setCurrentPage={(e) => setCurrentPage(e)}
        />
      </div>
    </div>
  );
};
export default MerchantPrivileges;
