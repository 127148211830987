import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Swal from "sweetalert";
const ListOfUsers = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [activeIndex, setActiveIndex] = useState("");
  const [merchantsTable, setMerchantTable] = useState([]);
  const [isloading, setLoading] = useState(false)


  useEffect(() => {
    axios
      .get("/prime.sika/v1/managers/merchants")
      .then((res) => {
        setMerchantTable(res.data.data.merchants);
      })
      .catch((err) => {
        if(err?.response?.data?.status===400){
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
          setLoading(false)
        }
      });
  }, []);

  const DeleteMerchant = (e, i) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to delete merchant data?",
      text: "Take note, if merchant has transaction, the account will be archived",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonColor: "#00b17d"
    })
      .then((isOkay) => {
        if (isOkay) {
          axios.delete(`/prime.sika/v1/managers/merchants/${i}`).then(res => {
            if (res.data.success === true && res.data.status === 200) {
              axios
                .get("/prime.sika/v1/managers/merchants")
                .then((res) => {
                  setMerchantTable(res.data.data.merchants);
                })
                .catch((err) => {
                  console.log(err.response);
                });
            }
          }).catch(err => {
            console.log(err.response)
          })
        }
      })
    return false
  }



  // https://api.primeegiftcard.com/prime.sika/v1/managers/merchants/48

  const transform1 = (e) => {
    switch (e) {
      case "under_review":
        return <div className="d-flex"><div className="circ-pending my-auto"></div><span class="ml-1">Pending</span></div>
        break;
      case "approved":
        return <div className="d-flex"><div className="circ-approved my-auto"></div><span class="ml-1">Approved</span></div>
        break;
      case "suspended":
        return <div className="d-flex"><div className="circ-suspended my-auto"></div><span class="ml-1">Suspended</span></div>
        break;

      default:
        break;
    }
  }
  return (
    <div
      className="tab-pane fade"
      id="pills-list-of-users"
      role="tabpanel"
      aria-labelledby="pills-list-of-users-tab"
    >
        <div className="card card-table card-table-height1">
      <div className="card-header card-header-margin d-flex justify-content-between">
        <h3 className="my-auto">All Merchants</h3>
        <a href="/merchants" className="my-auto btn btn-success">
          <img
            src="/assets/img/settings-1.png"
            alt=""
            className="img-fluid"
          />
        </a>
      </div>
      <div className="card-body booking_card">
        <div className="table-cover">
          <div className="d-flex table-content justify-content-between">
            <div className="div-size4">Name</div>
            <div className="div-size3">Location</div>
            <div className="div-size2">Telephone No.</div>
            <div className="div-size2">Date</div>
            <div className="div-size2">Status</div>
            <div className="div-size2"></div>
          </div>
        </div>
        {merchantsTable.length === 0 ? (
          <div className="no-available1">
            <h1>No Merchant Found</h1>
          </div>
        ):(
        merchantsTable.map((ele, i) => {
          const isActive = i === activeIndex;

          return (
            <div key={i} className="row-one">
              <div
                className="d-flex justify-content-between table-content2"

              >
                <div className="div-size4">
                  <p>
                    {isActive ? (
                      <img src="assets/img/arr-down.png" alt="" className="mr-2" onClick={(i) => !setActiveIndex(i)} />
                    ) : (
                      <img src="assets/img/arr-right.png" alt="" className="mr-2 cur-pointer" onClick={() => setActiveIndex(i)} />
                    )}
                    {ele.name}
                  </p>
                </div>
                <div className="div-size3">{ele.branch}</div>
                <div className="div-size2">{ele.telephone}</div>
                <div className="div-size2">{moment(ele.created_at).format('ll')}</div>
                <div className="div-size2">{transform1(ele.status)}</div>
                <div className="div-size2 d-flex">
                  <button>
                    <img src="/assets/img/edit-2.png" alt="" width="20px" height="20px" className="mr-3 cur-pointer mg-top" title="Edit" />
                  </button>
                  <button type="button" onClick={(e) => DeleteMerchant(e,i = ele.id)} >
                    <img src="/assets/img/trash.png" alt="" width="20px" height="20px" className="mr-3 cur-pointer mg-top" title="Delete" />
                  </button>
                  <div>
                    <i className="fas fa-ellipsis-v ellipse_color cur-pointer" onClick={(event) => {setAnchorEl(event.currentTarget);}}></i>

                    <Popover
                      anchorEl={anchorEl}
                      open={open}
                      id={i}
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                      anchorOrigin={{
                        horizontal: "left",
                        vertical: "bottom",
                      }}
                    >
                      <div className="bng2" id={i} name={i}>
                      {ele.status === "approved" ? (
                        <>
                      <div className="d-flex px-2"><div className="circ-pending my-auto"></div><span class="ml-1">Pending</span></div><hr />
                      <div className="d-flex px-2"><div className="circ-suspended my-auto"></div><span class="ml-1">Suspended</span></div><hr className="mb-0" />
                      </>
                      ): ele.status === "under_review" ? (
                        <>
                        <div className="d-flex px-2"><div className="circ-approved my-auto"></div><span class="ml-1">Approved</span></div><hr />
                        <div className="d-flex px-2"><div className="circ-suspended my-auto"></div><span class="ml-1">Suspended</span></div><hr className="mb-0" />
                        </>
                      ):(
                        <>
                        <div className="d-flex px-2"><div className="circ-pending my-auto"></div><span class="ml-1">Pending</span></div><hr />
                        <div className="d-flex px-2"><div className="circ-approved my-auto"></div><span class="ml-1">Approved</span></div><hr />
                        </>
                      )}
                        
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
              <hr />
              {isActive && (
                <div className="d-flex justify-content-between content-back-color">
                  <div className="bg-success-light2 priv-width ">
                    Privileges
                  </div>
                  <div className="d-flex privilege-width">
                    <div className="d-flex div-size1">
                      <input
                        type="checkbox"
                        className="checkbox-design"
                        id="add"
                        name="add"
                      />
                      <label htmlFor="add">
                        <h4 className="mt-1 ml-2">Add card</h4>
                      </label>
                    </div>
                    <div className="d-flex div-size1">
                      <input
                        type="checkbox"
                        className="checkbox-design"
                        id="manage"
                        name="manage"
                      />
                      <label htmlFor="manage">
                        <h4 className="mt-1 ml-2">Manage Account</h4>
                      </label>
                    </div>
                    <div className="d-flex div-size1">
                      <input
                        type="checkbox"
                        className="checkbox-design"
                        id="set"
                        name="set"
                      />
                      <label htmlFor="set">
                        <h4 className="mt-1 ml-2">Set Up Account</h4>
                      </label>
                    </div>
                    <div className="d-flex div-size1">
                      <input
                        type="checkbox"
                        className="checkbox-design"
                        id="cards"
                        name="cards"
                      />
                      <label htmlFor="cards">
                        <h4 className="mt-1 ml-2">Manage Cards</h4>
                      </label>
                    </div>
                    <div className="d-flex div-size1">
                      <input
                        type="checkbox"
                        className="checkbox-design"
                        id="cards"
                        name="cards"
                      />
                      <label htmlFor="cards">
                        <h4 className="mt-1 ml-2">Manage Cards</h4>
                      </label>
                    </div>
                    <div className="d-flex div-size1">
                      <input
                        type="checkbox"
                        className="checkbox-design"
                        id="cards"
                        name="cards"
                      />
                      <label htmlFor="cards">
                        <h4 className="mt-1 ml-2">Reports</h4>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        }))}
      </div>
    </div>
    </div>
  );
};
export default ListOfUsers;



// https://api.primeegiftcard.com/prime.sika/v1/managers/merchants/53/approve
// https://api.primeegiftcard.com/prime.sika/v1/managers/merchants/54/suspend