const LiveShopCustomersTable =()=>{
    return(
<div class="card card-chart z-position card-height4">
												<div class="card-header">
													<h4 class="card-title">Customer's from Livestream</h4>
												</div>
												<div class="cover-container1 pt-3">
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid"/>
															</div>
														</div>
													</div><hr/>
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
													<div class="d-flex mrgin-small">
														<div class="video-cover2 ml-3">
															<img src="assets/img/feedback-pic.png" alt="" class="img-fluid" />
														</div>
														<div class="my-auto ml-4">
															<h5>Mark Nies</h5>
															<h6>marknies@gmail.com</h6>
														</div>
														<div class="d-flex ml-5">
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/message-text.png" alt="" class="img-fluid" />
															</div>
															<div class="img-icon-cover text-center pt-2 ml-2">
																<img src="assets/img/notification-bing.png" alt="" class="img-fluid" />
															</div>
														</div>
													</div><hr />
												</div>
											</div>
    )
}
export default LiveShopCustomersTable;