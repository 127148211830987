const Subscribe = ({ card, setCard }) => {
  return (
    <>
      <div
        className="modal fade"
        id="subscribe_modal"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog pt-5" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title modal-text-font">Subscribe to Feature this card - <span>{card?.title}</span></h3>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="d-flex">
                  <label htmlFor="pac" className="my-auto mr-2">
                    <h4>Packages:</h4>
                  </label>
                  <select name="" id="" className="form-control">
                    <option value="">Select a package</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Subscribe;