import { useState } from "react";
import ReactPaginate from "react-paginate";

const ManageMultipleLocation = ({
  locationNumber,
  setLocationNumber,
  setMerchants,
  merchants,
  setCurrentLocationPage,
  totalLocationPage,
  loadLocation,
  handleLocationStatus,
  selectedLocations,
  setSelectedLocations,
  DeleteMerchants
}) => {
  

  const checkBoxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    console.log(value);
    if (isSelected) {
      setSelectedLocations([...selectedLocations, value]);
    } else {
      var temp = selectedLocations;
      temp = temp.filter((id) => {
        return id !== value;
      });
      temp ? setSelectedLocations(temp) : setSelectedLocations([]);
    }
  };

  let selectedLocationsIds;
  let selectedLocationValues = [];
  merchants?.map((ele, i) => {
    selectedLocationsIds = selectedLocations?.includes(ele?.id);
    if (selectedLocationsIds === true) {
      selectedLocationValues.push(ele);
    }
  });

  var temp = merchants.filter((ele) =>
    Math.min(ele.status === "approved")
  ).length;
  return (
    <div className="card card-chart z-position card-height-7">
      <div className="card-header card-header-margin">
        <div className="row">
          <div className="col-10">
            <div>
              <h4>Manage Multiple Locations</h4>
            </div>
            <div className="d-flex">
              <h6 className="border-right pr-2">
                No. of Locations: {locationNumber}
              </h6>
              <h6 className="pl-2">
                Active Locations: <span className="text-success"> {temp}</span>
              </h6>
            </div>
          </div>
          {selectedLocations?.length === 0 && (
            <div className="col-12">
              <h6 className="text-danger">
                Select checkbox(es) to manage location(s)
              </h6>
            </div>
          )}
          {selectedLocations?.length > 0 && (
            <div className="col-12">
              <div className="row justify-content-between">
                <div className="col-6">
                  <button
                    className="btn btn-outline-danger mb-3"
                    onClick={(e) => DeleteMerchants(e, selectedLocationValues)}

                  >
                    {" "}
                    Delete
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-outline-success mb-3"
                    onClick={(e) => handleLocationStatus(e, selectedLocationValues)}
                  >
                    {" "}
                    Change Status
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card-body booking_card">
        <div className="row">
          <div className="col-12">
            {loadLocation ? (
              <>
                {merchants.length === 0 ? (
                  <div className="no-available">
                    <h1> No Location Found</h1>
                  </div>
                ) : (
                  merchants.map((ele, i) => {
                    return (
                      <div key={i} className="row-one">
                        <div className="d-flex justify-content-between table-content2">
                          <div>
                            <input
                              type="checkbox"
                              className="checkbox-design mr-3"
                              value={ele?.id}
                              onChange={checkBoxHandler}
                              checked={selectedLocations?.includes(ele?.id)}
                            />
                          </div>
                          <div className="div-size13" title={ele.branch}>
                            {ele.branch}
                          </div>
                          <div className="mr-5">
                            {ele?.status?.toLowerCase() === "under_review" && (
                              <span className="btn btn-sm bg-warning-light ml-5">
                                Pending
                              </span>
                            )}
                            {ele?.status?.toLowerCase() === "approved" && (
                              <span className="btn btn-sm bg-success-light ml-5">
                                Active
                              </span>
                            )}
                            {ele?.status?.toLowerCase() === "suspended" && (
                              <span className="btn btn-sm bg-danger-light ml-5">
                                Suspended
                              </span>
                            )}
                          </div>
                          <div className="">
                            <img src="/assets/img/eye.png" alt="" />
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                )}
              </>
            ) : (
              <div className="no-data-div">
                <div className="no-data">
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mr-2">
        <div className="mt-2">
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={".."}
            pageCount={totalLocationPage}
            marginPagesDisplayed={2}
            onPageChange={(e) => setCurrentLocationPage(e.selected + 1)}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
};
export default ManageMultipleLocation;
