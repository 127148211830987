import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const Verification = ({
  setCurrentScreen,
  currentScreen,
  userInput,
  setShowResendModal,
}) => {
  const box1 = useRef("");
  const box2 = useRef("");
  const box3 = useRef("");
  const box4 = useRef("");
  const box5 = useRef("");
  const box6 = useRef("");

  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [sendingSms, setSendingSms] = useState(false);

  useEffect(() => {
    box1.current.focus();
  }, []);

  const [code, setCode] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  });

  useEffect(() => {
    setVerificationCode(
      code.one + code.two + code.three + code.four + code.five + code.six
    );
  }, [code]);

  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleNextFocus = (e) => {
    setErrorMessage("");
    setCode((code) => ({
      ...code,
      [e.target.id]: e.target.value.toString(),
    }));

    if (e.target.id === "one" && e.target.value !== "") {
      box2.current.focus();
    }
    if (e.target.id === "two" && e.target.value !== "") {
      box3.current.focus();
    }
    if (e.target.id === "three" && e.target.value !== "") {
      box4.current.focus();
    }
    if (e.target.id === "four" && e.target.value !== "") {
      box5.current.focus();
    }
    if (e.target.id === "five" && e.target.value !== "") {
      box6.current.focus();
    }
    //code.one+code.two+code.three+code.four+code.five+code.six
  };

  const handleValidation = (e) => {
    setProcessing(true);
    if (verificationCode.length !== 6) {
      setErrorMessage("Invalid Verification Code Entered");
    } else {
      axios
        .post(
          `/prime.sika/v1/users/merchant/${verificationCode}/verify?telephone=${userInput.telephone}`
        )
        .then((res) => {
          setProcessing(false);
          setCurrentScreen(7);
        })
        .catch((err) => {
          setProcessing(false);
          console.log(err.response.data);
          setErrorMessage("Invalid Verification Code Entered");
        });
    }
  };

  const resendViaSms = (e) => {
    setSendingSms(true);
    axios
      .post("/prime.sika/v1/users/business_customer/resend-verification", {
        telephone: userInput.phone,
      })
      .then((res) => {
        setSendingSms(false);
      })
      .catch((err) => {
        setSendingSms(false);
        console.log(err.response);
      });
  };

  return (
    <div className="">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content">
              <h1 className="text-white">Promote Sale</h1>
            </div>
            <div className="left-content">
              <h2 className="text-white">On your website or social media</h2>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Launch a digital gifting solution on your website without
                <span>
                  complex integrations, Copy and paste Prime widget URL
                </span>
                on your website to promote sales
              </h4>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Share your gift card on your social media platforms to
                <span>increase sales</span>
              </h4>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-warning learn-more-btn">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img">
            <img src="assets/img/bg6.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size1 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex float-right log-size">
                    <h4>
                      Already registered?
                      <Link to="/" className="btn btn-outline-success">
                        Log In
                      </Link>
                    </h4>
                  </div>
                  <div className="mb-4">
                    <img
                      src="assets/img/arrow-left.png"
                      alt=""
                      className="arrow-margin-top btn-prev"
                      onClick={() => setCurrentScreen(currentScreen - 1)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h3>Verify Account</h3>
                  </div>
                  <div>
                    <h4>Enter your details here</h4>
                  </div>
                  <div className="d-flex line-grp">
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                  </div>
                  <div className="font-enter">
                    <h4>
                      Last step, <span>Enter the six digit code sent you</span>
                    </h4>
                  </div>
                  <div className="form-group mt-4 d-flex">
                    <input
                      type="text"
                      id="one"
                      className="form-control textbox-size1"
                      min={0}
                      max={9}
                      maxLength={1}
                      ref={box1}
                      value={code.one}
                      onChange={handleNextFocus}
                    />
                    <input
                      type="text"
                      id="two"
                      className="form-control textbox-size1 ms-2"
                      maxLength="1"
                      ref={box2}
                      value={code.two}
                      onChange={handleNextFocus}
                    />
                    <input
                      type="text"
                      id="three"
                      className="form-control textbox-size1 ms-2"
                      maxLength="1"
                      ref={box3}
                      value={code.three}
                      onChange={handleNextFocus}
                    />
                    <input
                      type="text"
                      id="four"
                      className="form-control textbox-size1 ms-2"
                      maxLength="1"
                      ref={box4}
                      value={code.four}
                      onChange={handleNextFocus}
                    />
                    <input
                      type="text"
                      id="five"
                      className="form-control textbox-size1 ms-2"
                      maxLength="1"
                      ref={box5}
                      value={code.five}
                      onChange={handleNextFocus}
                    />
                    <input
                      type="text"
                      id="six"
                      className="form-control textbox-size1 ms-2"
                      maxLength="1"
                      ref={box6}
                      value={code.six}
                      onChange={handleNextFocus}
                    />
                  </div>
                  <small className="text-danger">{errorMessage}</small>
                  <div className="d-flex justify-content-between">
                    <div className="mt-4 continue-btn">
                      <button
                        type="button"
                        onClick={() => handleValidation()}
                        className="btn btn-success btn-next"
                      >
                        Verify
                      </button>
                    </div>
                    <div>
                      <button
                        className="mr-5 btn btn-outline-success mt-4 cur-pointer"
                        type="button"
                        onClick={() => setShowResendModal(true)}
                      >
                        Resend code
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
