import $ from "jquery";
import { useEffect } from "react";

const BusinessDetailForm = ({
  company,
  handleCompanyUserInput,
  handleCordinatesChange,
  updateBusinessDetails,
  isBusinessLoading,
  location,
  cordinates,
  setlocation,
  detailsLoading,
}) => {

  $(".form-fill-textbox1")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.8)");
        $(this).css("color", "rgb(94, 94, 94, 0.8)");
      }
    })
    .trigger("change");
  $(".form-fill-textarea1")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.8)");
        $(this).css("color", "rgb(94, 94, 94, 0.8)");
      }
    })
    .trigger("change");

  return (
    <form
      className="card card-chart2 card-h11 card-h10-pad"
      onSubmit={updateBusinessDetails}
    >
      <div>
        <h3>Your Business Details</h3>
        <hr className="hr3" />
      </div>
      {detailsLoading ? (
        <>
          <div className="ml-4">
            <div className="form-group d-flex align-items-center mt-4">
              <div className="form-name-width1">
                <label htmlFor="name">
                  <h4 className="my-auto">Company Name</h4>
                </label>
              </div>
              <div className="form-box-width1">
                <input
                  type="text"
                  className="form-control form-fill-textbox1"
                  name="name"
                  value={company?.name}
                  onChange={handleCompanyUserInput}
                />
              </div>
            </div>
            <div className="form-group d-flex align-items-center mt-4">
              <div className="form-name-width1">
                <label htmlFor="name">
                  <h4 className="my-auto">Company's Location Address</h4>
                </label>
              </div>
              <div className="form-box-width1">
                <a data-toggle="modal" href="#location-modal">
                  <input
                    type="text"
                    className="form-control form-fill-textbox1"
                    name="location"
                    value={location?.address}
                    disabled
                  />
                  <img
                    src="assets/img/location.png"
                    alt=""
                    className="company-loc-icon"
                  />
                </a>
              </div>
            </div>
            <div className="form-group d-flex align-items-center mt-4">
              <div className="form-name-width1">
                <label htmlFor="name">
                  <h4 className="my-auto">Registration number</h4>
                </label>
              </div>
              <div className="form-box-width1">
                <input
                  type="text"
                  className="form-control form-fill-textbox1"
                  name="business_registration_number"
                  value={company?.business_registration_number}
                  onChange={handleCompanyUserInput}
                />
              </div>
            </div>
            <div className="form-group d-flex align-items-center mt-4">
              <div className="form-name-width1">
                <label htmlFor="name">
                  <h4 className="my-auto">Website</h4>
                </label>
              </div>
              <div className="form-box-width1">
                <input
                  type="text"
                  className="form-control form-fill-textbox1"
                  name="website_url"
                  value={company?.website_url}
                  onChange={handleCompanyUserInput}
                />
              </div>
            </div>
            <div className="form-group d-flex align-items-center mt-4">
              <div className="form-name-width1">
                <label htmlFor="name">
                  <h4 className="my-auto">Tin Number</h4>
                </label>
              </div>
              <div className="form-box-width1">
                <input
                  type="text"
                  className="form-control form-fill-textbox1"
                  name="tin_number"
                  value={company?.tin_number}
                  onChange={handleCompanyUserInput}
                />
              </div>
            </div>
            <div className="form-group d-flex align-items-center mt-4">
              <div className="form-name-width1">
                <label htmlFor="name">
                  <h4 className="my-auto">Business Description</h4>
                </label>
              </div>
              <div className="form-box-width1">
                <textarea
                  className="form-control form-fill-textarea1"
                  name="description"
                  value={company?.description}
                  onChange={handleCompanyUserInput}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mt-5">
            {!isBusinessLoading && (
              <button
                type="submit"
                className="btn btn-success float-right save-btn-size"
                value="Save"
              >
                Save
              </button>
            )}
            {isBusinessLoading && (
              <button
                className="btn btn-success float-right save-btn-size"
                disabled
              >
                <i className="fas fa-spinner fa-spin mr-2"></i>Saving
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="no-data-div p-20">
          <div className="no-data">
            <i className="fas fa-spinner fa-spin mr-2"></i>
          </div>
        </div>
      )}
    </form>
  );
};
export default BusinessDetailForm;
