import React, { useState, useEffect } from "react";
import axios from "axios";
import PagePagination from "../../../../components/PagePagination";
import moment from "moment";
const PromoCodes = ({
  handleAbout,
  handleDealsName,
  handleStart,
  handleEnd,
  radioButtonHandler
}) => {
  const [userDetails, setUserDetails] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isloading, setLoading] = useState(false);

 

  useEffect(() => {
    axios
      .get("/prime.sika/v1/users/merchant/details")
      .then((res) => {
        setUserDetails(res.data.data.user_details);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const couponLists = () => {
    setLoading(false);
    axios
      .get(`/prime.sika/v1/managers/coupons/list?limit=10&page=${currentPage}`)
      .then((res) => {
        setLoading(true);
        setCoupons(res.data.data.coupons);
        setTotalPage(res.data.data.total_pages);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    couponLists();
  }, [currentPage]);
  return (
    <div
      className="tab-pane fade"
      id="pills-promo"
      role="tabpanel"
      aria-labelledby="pills-promo-tab"
    >
      <div className="table-responsive table-promo-h">
        <table className="datatable table table-stripped table table-hover table-center mb-0 table-text-font">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Description</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {isloading ? (
              <>
                {coupons.length === 0 ? (
                  <tr className="error-alert-position2">
                    <td colSpan={6}>
                      {" "}
                      <h1>No Records Found</h1>
                    </td>
                  </tr>
                ) : (
                  coupons.map((ele, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <label htmlFor={`s${i}d`} className="radio1">
                            <input
                              name="coupon"
                              type="radio"
                              className="radio__input mr-1 cur-pointer"
                              id={`s${i}d`}
                              value={ele?.tag_id}
                              onChange={(e) => radioButtonHandler(e, i = ele)}
                              // checked={selectedItems?.includes(ele?.tag_id)}
                            />
                            <div className="radio__radio1"></div>
                          </label>
                        </td>
                        <td>{ele.name}</td>
                        <td>{ele.description}</td>

                        <td>{ele.total}</td>
                      </tr>
                    );
                  })
                )}
              </>
            ) : (
              <div className="no-data">
                <i className="fas fa-spinner fa-spin mr-2"></i>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <div className="mr-2">
        <PagePagination
          setTotalPage={setTotalPage}
          totalPage={totalPage}
          setCurrentPage={(e) => setCurrentPage(e)}
        />
      </div>
    </div>
  );
};
export default PromoCodes;
