import React, { useState, useEffect } from 'react';
import axios from 'axios';
const PromoCodes1 = ({handleAbout, handleDealsName, handleStart, handleEnd}) => {
  const [userDetails,setUserDetails]=useState([]);
	useEffect(()=>{
		axios.get('/prime.sika/v1/users/merchant/details').then(res=>{
			setUserDetails(res.data.data.user_details);
		  }).catch(err=>{
			console.log(err.response)
		  })
    },[])
  return (
    <div
      className="tab-pane fade"
      id="pills-promo-promo"
      role="tabpanel"
      aria-labelledby="pills-promo-tab-promo"
    >
      <div className="form-group d-flex mt-3 display-sm-block">
        <span>
          <input
            onChange={(e) => handleDealsName(e.target.value)}
            className="form-control amount-textbox mr-3 dt-mb"
            type="text"
            placeholder="DealsName"
          />
        </span>
        <span>
          <input
            onChange={(e) => handleStart(e.target.value)}
            className="form-control date-textbox"
            type="date"
            placeholder="Start Date"
          />
          <input
            onChange={(e) => handleEnd(e.target.value)}
            className="form-control date-textbox1"
            type="date"
            placeholder="End Date"
          />
        </span>
      </div>
      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-5">
            <div
            className="logo-preview-btn"
          >
           <img src={userDetails?.person?.companies[0]?.logo_url} alt="" />
          </div>
            </div>
            <div className="col-lg-7 d-flex align-items-center dt-mt">
              <div>
                <img
                  src="assets/img/scan-barcode.png"
                  alt=""
                  className="gallery-img1 img-fluid"
                />
              </div>
              <div className="scan-size d-flex">
                <h4 className="my-auto">Quantity:</h4>
                <h5 className="my-auto">200 Promo Codes uploaded</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <h3>About the Deal</h3>
        </div>
        <div className="col-12 text-color">
          <h4>What is the deal about</h4>
        </div>
        <div className="col-12">
          <textarea
            onChange={(e) => handleAbout(e.target.value)}
            name=""
            id=""
            placeholder="Brief information about the deal"
            className="textarea-size5"
          ></textarea>
        </div>
      </div>
    </div>
  );
};
export default PromoCodes1;
