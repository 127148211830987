import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
const SettingsHeader = () => {
  const navigate = useNavigate();

  const logout = () => {
    axios.post("/prime.sika/v1/users/logout", { user_type: "merchant" });
    localStorage.removeItem("company_code");
    localStorage.removeItem("token");
    localStorage.removeItem("client_type");
    localStorage.removeItem("service_code");
    navigate("/");
  };
  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    axios
      .get("/prime.sika/v1/users/merchant/details")
      .then((res) => {
        setUserDetails(res.data.data.user_details);
      })
      .catch((err) => {
        if (err?.response?.data?.status === 401) {
          localStorage.removeItem("company_code");
          localStorage.removeItem("token");
          localStorage.removeItem("client_type");
          localStorage.removeItem("service_code");
          navigate("/");
        }
        console.log(err.response);
      });
  }, []);
  return (
    <>
      <div class="page-header">
        <div class="row justify-content-between">
          <div class="col-xl-5"></div>
          <div className="col-xl-3">
            <h6 className="text-secondary text-margin-top text-font mt-3">
              User
            </h6>
            <h3 className="text-font-1 company-name-margin text-capitalize">
              {userDetails?.person?.full_name}
            </h3>
          </div>

          <div className="col-xl-3 top-content-mobile">
            <div className="row">
              {/* <div className="col-xl-3">
                <div className="notification-icon-cover">
                  <a href="#">
                    <img
                      src="/assets/img/notification.png"
                      alt=""
                      className="notification-icon-position"
                    />
                  </a>
                </div>
              </div> */}
              <div className="col-xl-12">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    className="d-flex admin_content"
                    no-caret
                  >
                    <div className="image">
                      <img
                        src={
                          userDetails?.person?.companies[0]?.logo_url === null
                            ? "/assets/img/default.png"
                            : userDetails?.person?.companies[0]?.logo_url
                        }
                        alt=""
                      />
                    </div>
                    <div className="admin_name">
                      <h4
                        className="my-auto mx-2"
                        title={userDetails?.person?.full_name}
                      >
                        {userDetails?.person?.full_name}
                      </h4>
                    </div>
                    <div className="admin_dropdownicon">
                      <img src="assets/img/arrow-down.png" alt="" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/settings" className="link-color">
                        Settings
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <button onClick={logout} className="logout-btn">
                        Logout
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="d-flex">
              <div class="image1">
                <img
                  src={
                    userDetails?.person?.companies[0]?.logo_url === null
                      ? "/assets/img/default.png"
                      : userDetails?.person?.companies[0]?.logo_url
                  }
                  alt=""
                />
              </div>
              <div className="ml-2">
                <h3 class="text-font-1 text-capitalize">
                  {userDetails?.person?.companies[0]?.name}
                </h3>
                <h5 className="text-marg">
                  Ghana, Accra - Member since September 2022
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SettingsHeader;
