import axios from "axios";
import { useEffect, useState } from "react";
import MarketPlaceCards from "./MarketPlaceCards";

const MarketplaceStats = ({productSummaries}) => {
  
  return (
    <>
      <MarketPlaceCards
        image="/assets/img/card-tick.png"
        value={productSummaries?.total_store_products}
        title="Total Number of Products"
        background="card board1 fill5"
      />
      <MarketPlaceCards
        image="/assets/img/wallet.png"
        value={productSummaries?.total_store_orders}
        title="Total Number of Orders"
        background="card board1 fill6"
      />
      <MarketPlaceCards
        image="/assets/img/dollar-circle.png"
        value="$5,800"
        title="Total Revenue"
        background="card board1 fill7"
      />
      <MarketPlaceCards
        image="/assets/img/3d-square.png"
        value="100"
        title="Last Deposit"
        background="card board1 fill8"
      />
    </>
  );
};
export default MarketplaceStats;
