const Notice = () => {
  return (
    <div className="col-lg-12">
      <div className="card1 card-height-4 bg-danger-light d-flex align-items-center">
        <div className="notice-cover">
          <h3 className="text-white">Notice:</h3>
        </div>
        <div className="notice-coverh4">
          <h4>
            Remember to promote your e-gift card for the upcoming mother's day -
            May 6, 2022
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Notice;
