import React from "react";
import Popover from "@material-ui/core/Popover";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Multiselect from "multiselect-react-dropdown";

const NewLocation = ({
  ChangeCurrentScreenToManageLocation,
  userInput,
  handleUserInput,
  location,
  saveData,
  setReports,
  setManageAccount,
  setManageGiftCards,
  setCreatePromotionsOrDeals,
  setSetUpPaymentAccount,
  setTransactionsAndPayouts,
  reports,
  manageAccount,
  manageGiftCards,
  createPromotionsOrDeals,
  paymentAccount,
  transactionPayout,
  isloading,
  merchants,
  merchantBranch,
  setUserInput,
  branches,
  handleBranch,
  merchantServices,
  onMerchantTypeSelect,
  onMerchantTypeRemoved,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [errors, setError] = useState({
    branch: "",
    location: "",
    telephone: "",
    service_type: "",
  });

  const handleOnChange = (e) => {
    setError((errors) => ({
      ...errors,
      [e.target.name]: "",
    }));
    handleUserInput(e);
  };

  const handleValidation = (e) => {
    e.preventDefault();
    // if (userInput.merchantname === "" || !userInput.merchantname) {
    //   setError((errors) => ({
    //     ...errors,
    //     merchantname: "Please Enter Merchant's name",
    //   }));
    // } else if (location?.address === "") {
    //   swal({
    //     title: "Error",
    //     text: "Select Merchant Location",
    //     icon: "error",
    //     button: "OK",
    //   });
    // } else if (userInput.telephone === "" || !userInput.telephone) {
    //   setError((errors) => ({
    //     ...errors,
    //     telephone: "Please Enter Merchant's number",
    //   }));
    // }

    saveData();
  };

  return (
    <>
      <div class="col-lg-12">
        <div class="card card-chart1 z-position card-height-12">
          <div class="col-lg-12">
            <h2 class="mt-4">Add New Branch</h2>
            <div class="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <input
                        name="merchantname"
                        class="form-control frame-textbox12 mb-3"
                        type="text"
                        placeholder="Enter merchants name"
                        value={userInput.merchantname}
                        required
                        onChange={handleOnChange}
                      />
                      <div className="text-danger">{errors.merchantname}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <input
                        class="form-control frame-textboxes"
                        type="number"
                        placeholder="Enter telephone number"
                        name="telephone"
                        value={userInput.telephone}
                        onChange={handleOnChange}
                        required
                      />
                      <div className="text-danger">{errors.telephone}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-1">
                <div className="row">
                  <div className="col-lg-6">
                    <a data-toggle="modal" href="#location-modal">
                      <div class="form-group">
                        <input
                          class="form-control frame-textboxes"
                          type="text"
                          placeholder="Location"
                          value={location?.address}
                          // onChange={(e) => setLocation(location1?.address)}
                          readOnly
                          name="location"
                          required
                        />
                      </div>
                      <img
                        src="assets/img/location.png"
                        alt=""
                        className="company-loc-icon1"
                      />
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <Multiselect
                        className="form-control frame-textboxes"
                        options={merchantServices}
                        // isObject={true}
                        displayValue="name"
                        placeholder="-- Select merchant type"
                        onSelect={onMerchantTypeSelect}
                        onRemove={onMerchantTypeRemoved}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mt-4">
                  <h4 className="priv-font">Privileges</h4>
                </div>
                <hr />
                <div>
                  <div className="radio-group1 mt-4 d-flex">
                    <div className="">
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={manageAccount}
                          type="checkbox"
                          name="merchant_account"
                          id="merchant_account"
                          className="checkbox-design"
                          value="merchant_account"
                          onChange={() => setManageAccount(!manageAccount)}
                        />
                        <label htmlFor="merchant_account">
                          <h4 className="mt-3 ml-2">Manage Account</h4>
                        </label>
                      </div>
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={manageGiftCards}
                          type="checkbox"
                          name="manage_gift_cards"
                          id="manage_gift_cards"
                          className="checkbox-design"
                          value="manage_gift_cards"
                          onChange={() => setManageGiftCards(!manageGiftCards)}
                        />
                        <label htmlFor="manage_gift_cards">
                          <h4 className="mt-3 ml-2">Manage Gift Cards</h4>
                        </label>
                      </div>
                    </div>
                    <div className="ml-5">
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={createPromotionsOrDeals}
                          type="checkbox"
                          name="create_promotions_or_deals"
                          id="create_promotions_or_deals"
                          className="checkbox-design"
                          value="create_promotions_or_deals"
                          onChange={() =>
                            setCreatePromotionsOrDeals(!createPromotionsOrDeals)
                          }
                        />
                        <label htmlFor="create_promotions_or_deals">
                          <h4 className="mt-3 ml-2">
                            Create Promotions / Deals
                          </h4>
                        </label>
                      </div>
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={paymentAccount}
                          type="checkbox"
                          name="set_up_payment_account"
                          id="set_up_payment_account"
                          className="checkbox-design"
                          value="set_up_payment_account"
                          onChange={() =>
                            setSetUpPaymentAccount(!paymentAccount)
                          }
                        />
                        <label htmlFor="set_up_payment_account">
                          <h4 className="mt-3 ml-2">Set up Payment Account</h4>
                        </label>
                      </div>
                    </div>
                    <div className="ml-5">
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={reports}
                          type="checkbox"
                          name="reports"
                          id="reports"
                          className="checkbox-design"
                          value="reports"
                          onChange={() => setReports(!reports)}
                        />
                        <label htmlFor="reports">
                          <h4 className="mt-3 ml-2">Reports</h4>
                        </label>
                      </div>
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={transactionPayout}
                          type="checkbox"
                          value="transactions_and_payouts"
                          name="transactions_and_payouts"
                          id="transactions_and_payouts"
                          className="checkbox-design"
                          onChange={() =>
                            setTransactionsAndPayouts(!transactionPayout)
                          }
                        />
                        <label htmlFor="transactions_and_payouts">
                          <h4 className="mt-3 ml-2">Transactions & Payouts</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                {!isloading && (
                  <button
                    type="button"
                    className="btn btn-success send-invite-btn"
                    onClick={handleValidation}
                  >
                    Add
                  </button>
                )}
                {isloading && (
                  <button className="btn btn-success send-invite-btn" disabled>
                    <i className="fas fa-spinner fa-spin mr-2"></i>Starting...
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-5">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="form-box-width">
              <select
                className="form-control list-cover"
                id="sel2"
                name="merchant_branch"
                onChange={(e) => handleBranch(e.target.value)}
              >
                <option value="">List Of Merchants</option>
                {merchants.map((ele, index) => (
                  <option key={index} value={ele?.id}>
                    {ele?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <button
              onClick={() => ChangeCurrentScreenToManageLocation()}
              className="btn btn-dark btn-loc-size float-right"
            >
              Manage Branches
            </button>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-3">
        <div className="text-center lapaz">
          <h3 title={branches}>{branches}</h3>
        </div>
        <hr />
      </div>
    </>
  );
};
export default NewLocation;
