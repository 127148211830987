const AccountType = ({ handleUserInput, accountType }) => {
  return (
    <div className="form-group d-flex align-items-center justify-content-between mt-3">
      <div className="form-name-width">
        <label htmlFor="name">
          <h4 className="my-auto">Account Type</h4>
        </label>
      </div>
      <div className="form-box-width">
        <select
          className="form-control form-fill-textbox1"
          onChange={handleUserInput}
          id="account_type"
          name="account_type"
          value={accountType}
        >
          {/* <option value="">-- select account type -- </option> */}
          <option value="bank">Bank</option>
          <option value="momo">Mobile Money</option>
        </select>
      </div>
    </div>
  );
};

export default AccountType;
