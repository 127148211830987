import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginLayout from "./loginComponents/LoginLayout";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import $ from "jquery";
import swal from "sweetalert";

const MerchantLogin = () => {
  $(".textbx-size")
    .change(function () {
      if ($(this).val() === "") {
        $(this).css("border", "2px solid rgba(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid #338540");
        $(this).css("color", "#338540");
      }
    })
    .trigger("change");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const navigate = useNavigate();

  const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    } else {
      localStorage.removeItem("company_code");
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  const [userInput, setUserInput] = useState({
    forname: "",
    password: "",
    user_type: "merchant",
    remember: false,
    errors: "",
  });

  const handleUserInput = (e) => {
    setUserInput(() => ({
      ...userInput,
      [e.target.name]: e.target.value,
      errors: "",
    }));
  };
  const [isloading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      forname: userInput.forname,
      password: userInput.password,
      user_type: userInput.user_type,
    };
    setLoading(true);
    axios
      .post("/prime.sika/v1/users/login", data)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          const data = res.data.data;
          localStorage.setItem("token", data.token);
          axios
            .get("/prime.sika/v1/users/merchant/details")
            .then((res) => {
              if (res.data.status === 200 && res.data.success === true) {
                navigate("/dashboard");
                console.log("details", res.data.data.user_details);
                localStorage.setItem(
                  "client_type",
                  res.data.data.user_details.person.companies[0].client_type
                );
                localStorage.setItem(
                  "company_code",
                  res.data.data.user_details.person.companies[0].code
                );
                const service_code =
                  res.data.data.user_details.person.companies[0].services;

                localStorage.setItem(
                  "service_code",
                  res.data.data.user_details.person.companies[0].services[0]
                    .code
                );

                localStorage.setItem(
                  "store_type",
                  res?.data?.data?.user_details.person?.companies[0]?.store_type
                    ?.name
                );
              }
            })
            .catch((err) => {
              console.log(err.response);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err.response);
        swal({
          title: "Error",
          text: err?.response?.data?.error_msg,
          icon: "error",
          button: "OK",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <LoginLayout>
        <form onSubmit={handleSubmit}>
          <div className="card card-size mt-3">
            <div className="card-body">
              <div>
                <h3>Log in to Prime Merchant</h3>
              </div>
              <div>
                <h4>Enter your details below</h4>
              </div>
              <div className="text-danger">{userInput.errors}</div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className=" textbx-size"
                  placeholder="Enter your username or number here"
                  name="forname"
                  value={userInput.forname}
                  onChange={handleUserInput}
                />
                <div className="img-backg">
                  <img
                    src="assets/img/sms1.png"
                    alt=""
                    className="img-fluid img-pos"
                  />
                </div>
              </div>
              <div className="form-group mt-4 password-group">
                <input
                  type={type}
                  className="form-control textbx-size"
                  placeholder="Enter your password here"
                  name="password"
                  value={userInput.password}
                  onChange={handleUserInput}
                />
                <span onClick={handleToggle}>
                  <Icon icon={icon} size={18} className="view-password-icon" />
                </span>
                <div className="img-backg">
                  <img
                    src="assets/img/lock.png"
                    alt=""
                    className="img-fluid img-pos"
                  />
                </div>
              </div>
              <div className="content-items mt-4">
                <div className="d-flex">
                  <input
                    type="checkbox"
                    className="checkbox-design"
                    id="remember"
                    name="remember"
                    value={userInput.remember}
                    onChange={handleUserInput}
                  />
                  <label htmlFor="remember">
                    <h4>Remember me</h4>
                  </label>
                </div>
                <div>
                  <Link to="/resetpassword" className="text-success">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-width align-items-center">
                <div className="line-or"></div>
                <div className="mt-2">
                  <h4>Or</h4>
                </div>
                <div className="line-or"></div>
              </div>
              <div className="form-group mt-2">
                <input
                  type="password"
                  className="form-control textbx-size"
                  placeholder="Enter your four digit pin"
                  name="pin"
                  value={userInput.pin}
                  onChange={handleUserInput}
                />
                <div className="img-backg">
                  <img
                    src="assets/img/scan-barcode.png"
                    alt=""
                    className="img-fluid img-pos"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  {!isloading && (
                    <button
                      type="submit"
                      className="btn btn-success log-in-btn-size"
                    >
                      Log In
                    </button>
                  )}
                  {isloading && (
                    <button
                      className="btn btn-success log-in-btn-size"
                      disabled
                    >
                      <i className="fas fa-spinner fa-spin mr-2"></i>Logging in
                    </button>
                  )}
                </div>
              </div>
              <div className="row content-items-last">
                <div className="col-12">
                  <h4>
                    Don't have an account?
                    <Link to="/signup" className="text-success ml-1">
                      Sign Up
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </form>
      </LoginLayout>
    </>
  );
};
export default MerchantLogin;
