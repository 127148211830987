const BankBranches = ({ branches, userInput, handleUserInput }) => {
  return (
    <div className="form-group d-flex align-items-center mt-4">
      <div className="form-name-width">
        <label htmlFor="name">
          <h4 className="my-auto">Branch</h4>
        </label>
      </div>
      <div className="form-box-width">
        <select
          className="form-control form-fill-textbox1"
          id="branch"
          name="branch"
          value={userInput?.branch}
          onChange={handleUserInput}
        >
          <option>Select Branch</option>
          {branches?.map((branch, index) => (
            <option key={index} value={branch?.code}>
              {branch?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BankBranches;
