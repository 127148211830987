import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment/moment";
const CustomersData = ({ location }) => {
  const [cardDisbursementTable, setCardDisbursementTable] = useState([]);
  const [original, setOriginal] = useState([]);
  const [isloading, setLoading] = useState(false);
  const company_code = localStorage.getItem("company_code");

  useEffect(() => {
    var now = new Date();
    setLoading(false);
    axios
      .get(
        `/prime.sika/v1/merchant-accounts/disbursements?merchant_code=${company_code}&limit=10`
      )
      .then((res) => {
        var temp = original.filter((ele) => ele.created_at.includes(now));
        setLoading(true);
        setOriginal(res.data.data.disbursements);
        setCardDisbursementTable(res.data.data.disbursements);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  }, []);

  return (
    <div className="card card-chart z-position card-height-2">
      <div className="card-header card-header-margin">
        <h3 className="my-auto">Disbursements</h3>
      </div>
      <div className="card-body booking_card">
        <div className="table-responsive">
          <table className="datatable table table-stripped table table-hover table-center mb-0">
            <thead>
              <tr>
                <td>Date</td>
                {location.pathname === "/dashboard" && <td>Reference</td>}
                <td>Amount</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {isloading ? (
                <>
                  {cardDisbursementTable?.length === 0 ? (
                    <div className="no-data">
                      <h1> No Transaction Available</h1>
                    </div>
                  ) : (
                    cardDisbursementTable?.map((ele, i) => {
                      return (
                        <tr>
                          <td>
                            {moment(ele?.payments[0]?.created_at).format("L")}
                          </td>
                          {location.pathname === "/dashboard" && (
                            <td>
                              {ele?.payments[0]?.reference
                                ? ele?.payments[0]?.reference
                                : "N/A"}
                            </td>
                          )}
                          <td>
                            ¢
                            {parseFloat(
                              ele?.payments[0]?.disbursement_amount
                            ).toFixed(2)}
                          </td>
                          <td>
                            {ele?.payments[0]?.status.toLowerCase() ===
                              "paid" && (
                              <span class="btn btn-sm bg-success-light mr-2">
                                Paid
                              </span>
                            )}
                            {ele?.payments[0]?.status.toLowerCase() ===
                              "failed" && (
                              <span class="btn btn-sm bg-danger-light mr-2">
                                Failed
                              </span>
                            )}
                            {ele?.payments[0]?.status.toLowerCase() ===
                              "waiting_for_payment" && (
                              <span class="btn btn-sm bg-warning-light mr-2">
                                Pending
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              ) : (
                <div className="no-data">
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default CustomersData;
