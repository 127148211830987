import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import PagePagination from "../../components/PagePagination";
const TransactionTable = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [loadTransactions, setLoadTranstions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const FetchTransactions = () => {
    setLoadTranstions(false);
    axios
      .get(
        `/prime.sika/v1/merchants/reports/transactions?limit=10&page=${currentPage}`
      )
      .then((res) => {
        setLoadTranstions(true);
        setTransactions(res.data.data.transactions);
        setTotalPage(res.data.data.total_pages);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          swal({
            text: "User Session Expired",
            button: "OK",
          });
          localStorage.removeItem("token");
          navigate("/");
        } else {
          console.log(err);
          setLoadTranstions(true);
        }
      });
  };

  useEffect(() => {
    FetchTransactions();
  }, [currentPage]);

  return (
    <div className="card card-table card-t-height1">
      <div className="card-body booking_card">
        <div className="table-responsive">
          <table className="datatable table table-stripped table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Transaction Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loadTransactions ? (
                <>
                  {transactions.length === 0 ? (
                    <tr className="error-alert-position1 mt-5">
                      <td rowSpan={6}>
                        {" "}
                        <h1>No Transactions Found</h1>
                      </td>
                    </tr>
                  ) : (
                    transactions.map((ele, i) => {
                      return (
                        <tr key={ele.id}>
                          <td>{ele.id}</td>
                          <td>¢{parseFloat(ele.amount).toFixed(2)}</td>
                          <td>
                            {" "}
                            {
                              new Date(ele.created_at)
                                .toLocaleString()
                                .split(",")[0]
                            }
                          </td>
                          <td>
                            {ele?.transaction_type.toLowerCase() ===
                              "card_redemption" && "Card Redeemed"}
                            {ele?.transaction_type.toLowerCase() ===
                              "card_purchase" && "Card Purchased"}
                            {ele?.transaction_type.toLowerCase() ===
                              "kick_back" && "Kick Back"}
                            {ele?.transaction_type.toLowerCase() ===
                              "card_cash_out" && "Card Cash Out"}
                          </td>
                          <td>
                            {ele?.status.toLowerCase() === "paid" && (
                              <span class="btn btn-sm bg-success-light mr-2">
                                Paid
                              </span>
                            )}
                            {ele?.status.toLowerCase() === "failed" && (
                              <span class="btn btn-sm bg-danger-light mr-2">
                                Failed
                              </span>
                            )}
                            {ele?.status.toLowerCase() === "pending" && (
                              <span class="btn btn-sm bg-warning-light mr-2">
                                Pending
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              ) : (
                <div className="no-data">
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mr-2">
        <PagePagination
          setTotalPage={setTotalPage}
          totalPage={totalPage}
          setCurrentPage={(e) => setCurrentPage(e)}
        />
      </div>
    </div>
  );
};

export default TransactionTable;
