import { Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Swal from "sweetalert2";
import axios from "axios";

const DetailedOrderModal = ({
  items,
  showDetailedOrderModal,
  handleDetailedOrderClose,
  deliveryStatuses,
  fetchOrders,
}) => {
  const sliderRef = useRef(null);
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };
  const [userInput, setUserInput] = useState([]);
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    setUserInput(items);
  }, [items]);
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
 

  const changeOrderStatus = () => {
    Swal.fire({
      title: "Are you sure you want to change the delivery status",
      text: "This action is changes the delivery status of the order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isOkay) => {
      if (isOkay) {
        setLoading(true);
        axios
          .put(
            `/market.place/v1/orders/state?delivery_state=${userInput?.delivery_status}&reference=${userInput?.reference}`
          )
          .then((res) => {
            if (res.data.success === true && res.data.status === 200) {
              setLoading(false);
              Swal.fire({
                title: "Success",
                text: "Delivery Status(es) Updated Successfully",
                icon: "success",
                button: "OK",
                confirmButtonColor: "#00b17d",
              }).then((isOkay) => {
                if (isOkay) {
                  fetchOrders();
                  handleDetailedOrderClose();
                }
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
          });
      }
    });
  };
  return (
    <Modal show={showDetailedOrderModal} onHide={handleDetailedOrderClose}>
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4 className="mt-3">Product Order Details</h4>
          </div>
          <div>
            <button onClick={handleDetailedOrderClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row product-details">
          <div className="col-lg-6">
            <div className="col-lg-12 col-pad">
              <div className="product-image-cover">
                <div
                  className={`${
                    items?.pictures?.length > 1
                      ? "d-flex justify-content-between arrows-position"
                      : "d-lg-none d-none"
                  }`}
                >
                  <img
                    src="/assets/img/arrow-left1.png"
                    alt=""
                    onClick={() => sliderRef.current.slickPrev()}
                    className="cur-pointer cur-width"
                  />
                  <img
                    src="/assets/img/arrow-right2.png"
                    alt=""
                    onClick={() => sliderRef.current.slickNext()}
                    className="ms-3 cur-pointer cur-width"
                  />
                </div>
                <Slider ref={sliderRef} {...settings}>
                  {items?.product?.pictures?.map((image, i) => {
                    return (
                      <div className="img-kk1" key={i}>
                        <img src={image} alt="" className="product-image" />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-4">
                <h4>
                  Product Name : <span>{items?.product?.name}</span>
                </h4>
              </div>
              <div className="mt-4">
                <h4>
                  Product Price :{" "}
                  <span>
                    {items?.product?.currency} {items?.unit_amount?.toFixed(2)}
                  </span>
                </h4>
              </div>
              <div className="mt-4">
                <h4>
                  Delivery Option :{" "}
                  <span className="text-capitalize">
                    {items?.delivery_option}
                  </span>
                </h4>
              </div>
              <div className="mt-4">
                <h4>
                  Payment Status
                  {items?.payment_status?.toLowerCase() === "not_paid" && (
                    <div className="btn btn-sm bg-warning-light mr-2">
                      Not Paid
                    </div>
                  )}
                  {items?.payment_status?.toLowerCase() === "fully_paid" && (
                    <div className="btn btn-sm bg-success-light mr-2">
                      Fully Paid
                    </div>
                  )}
                  {items?.payment_status?.toLowerCase() ===
                    "partially_paid" && (
                    <div className="btn btn-sm bg-dark-light mr-2">
                      Partially Paid
                    </div>
                  )}
                  {items?.payment_status?.toLowerCase() === "canceled" && (
                    <div className="btn btn-sm bg-danger-light mr-2">
                      Canceled
                    </div>
                  )}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-lg-12">
              <div className="row justify-content-end">
                <div className="col-lg-12">
                  <h3>
                    Amount Paid :
                    {items?.net_amount < items?.gross_amount ? (
                      <>
                        {" "}
                        <span className="text-danger amt-font">
                          <s className="ml-1 mr-2">GHS {items?.gross_amount}</s>
                        </span>
                        <span className="text-success">
                          GHS {items?.net_amount}
                        </span>
                      </>
                    ) : (
                      <span className="text-success">
                        {" "}
                        {items?.product?.currency} {items?.net_amount}
                      </span>
                    )}
                  </h3>
                </div>
              </div>
              <div className="mt-4">
                <h4>
                  Customer's Name :{" "}
                  <span>{items?.delivery_address?.full_name}</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-4">
                <h4>
                  Customer's Telephone :{" "}
                  <span>{items?.delivery_address?.phone_number}</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-4">
                <h4>
                  Customer's Address :{" "}
                  <span>{items?.delivery_address?.location_name}</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-3">
                <h4>
                  Delivery Status :
                  <span className="ml-1">
                    {items?.delivery_status?.toLowerCase() === "complete" && (
                      <div className="btn btn-sm bg-success-light mr-2">
                        Complete
                      </div>
                    )}
                    {items?.delivery_status?.toLowerCase() === "delivered" && (
                      <div className="btn btn-sm bg-dark-light mr-2">
                        Delivered
                      </div>
                    )}
                    {items?.delivery_status?.toLowerCase() === "rejected" && (
                      <div className="btn btn-sm bg-danger-light mr-2">
                        Out of stock
                      </div>
                    )}
                    {items?.delivery_status?.toLowerCase() ===
                      "request_waiting" && (
                      <div className="btn btn-sm bg-warning-light mr-2">
                        Pending
                      </div>
                    )}
                    {items?.delivery_status?.toLowerCase() ===
                      "ready_for_pick_up" && (
                      <div className="btn btn-sm bg-primary-light mr-2">
                        Ready for pickup
                      </div>
                    )}
                    {items?.delivery_status?.toLowerCase() === "accepted" && (
                      <div className="btn btn-sm bg-success-light1 mr-2">
                        Accepted
                      </div>
                    )}
                    {items?.delivery_status?.toLowerCase() === "in_transit" && (
                      <div className="btn btn-sm bg-warning-light1 mr-2">
                        In transit
                      </div>
                    )}
                  </span>
                </h4>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-4">
                <h4>
                  Quantity Ordered : <span>{items?.quantity}</span>
                </h4>
              </div>
              <div className="mt-4">
                <h4>
                  Product Unit Discount :{" "}
                  <span>
                    {items?.product?.currency}{" "}
                    {items?.product?.discount.toFixed(2)}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-12 col-pad">
              <div className="col-md-12 col-pad">
                <div className="d-flex">
                  <h4 className="delivery-text my-auto mr-3">
                    Delivery Status:
                  </h4>

                  <select
                    className="form-control"
                    name="delivery_status"
                    value={userInput?.delivery_status}
                    onChange={(e) => handleUserInput(e, items?.id)}
                  >
                    {deliveryStatuses?.map((ele, index) => (
                      <option
                        value={ele}
                        key={index}
                        className="text-capitalize"
                      >
                        {ele.toLowerCase() === "rejected"
                          ? "REJECT"
                          : ele.toLowerCase() === "accepted"
                          ? "ACCEPT"
                          : ele.toLowerCase() === "rejected"
                          ? "REJECT"
                          : ele.toLowerCase() === "delivered"
                          ? "DELIVER"
                          : ele.toLowerCase() === "returned"
                          ? "RETURN"
                          : ele.toLowerCase() === "ready_for_pick_up"
                          ? "READY FOR PICKUP"
                          : ele.toLowerCase() === "transiting"
                          ? "TRANSIT"
                          : ele}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12 col-pad">
                <div className="float-right mt-4">
                  {!isloading && (
                    <button
                      className="btn btn-success"
                      onClick={changeOrderStatus}
                    >
                      Change Status
                    </button>
                  )}
                  {isloading && (
                    <button className="btn btn-success" disabled>
                      <i className="fas fa-spinner fa-spin mr-2"></i>changing...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DetailedOrderModal;
