const AccountVerificationForm = ({ setAffiliateScreen, affiliateScreen}) => {
    return(
        <>
         <div className=""> 
            <div className="row justify-content-between">
            <div className="col-lg-5 col-12 left-div1">
                <div className="left-div-padding">

                    <div className="left-div-content">
                        <h1 className="text-white">Prime Affiliate <span>Program</span></h1>
                    </div>
                    <div className="left-content">
                        <h3 className="text-white text-weight">Earn generous commisssions for every gift card 
                        <span>sold by signing up local businesses.</span>
                        Becoming your own boss is the best gift you can <span>ever give yourself</span></h3>
                    </div>
                    <div className="mt-5">
                        <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                    </div>
                </div>
                <div className="back-img">
                    <img src="/assets/img/bg6.png" alt="" />
                </div>
            </div>
            <div className="col-lg-6 col-12 pr-0 right-div">
                <div className="card card-size1 mt-5">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex float-right log-size">
                                <h4>Already registered?<a href="login.html" className="btn btn-outline-primary">Log In</a></h4>
                            </div>
                            <div className="mb-4">
                                <button><img src="/assets/img/arrow-left.png" alt="" className="arrow-margin-top btn-prev1" onClick={()=> setAffiliateScreen(affiliateScreen - 1)}/></button>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div>
                                <h3>Verify Account</h3>
                            </div>
                            <div>
                                <h4>Enter the verification code sent to your phone</h4>
                            </div>
                            <div className="d-flex line-grp">
                                <div className="line active1"></div>
                                <div className="line active1"></div>
                                <div className="line active1"></div>
                                <div className="line"></div>
                            </div>
                            <div className="font-enter">
                                <h4>Last step, <span>Enter the six digit code sent to you</span></h4>
                            </div>
                            <div className="form-group mt-4">
                                <input type="number" className="form-control textbx-size1" placeholder="Six digit code" />
                            </div>
                            <div className="mt-4 continue-btn">
                                <button className="btn btn-primary btn-next1" onClick={()=> setAffiliateScreen(4)}>Verify</button>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default AccountVerificationForm;