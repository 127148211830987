import axios from "axios";
import React, { useState, useEffect } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import ReportsStats from "./reportsComponents/ReportsStats";
import DetailTransaction from "./reportsComponents/DetailTransaction";
import Transactions from "./reportsComponents/Transactions";

const Reports = () => {
  useEffect(() => {
    if (!localStorage.getItem("company_code")) {
      axios
        .get("/prime.sika/v1/users/merchant/details")
        .then((res) => {
          if (res.data.status === 200 && res.data.success === true) {
            localStorage.setItem(
              "client_type",
              res.data.data.user_details.person.companies[0].client_type
            );
            localStorage.setItem(
              "company_code",
              res.data.data.user_details.person.companies[0].code
            );
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, []);

  const [cards, setCards] = useState([]);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/cards/list")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCards(res.data.data.cards);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <>
      <DashboardLayout>
        <Transactions />
      </DashboardLayout>
    </>
  );
};
export default Reports;
