import React, { useState, useEffect } from "react";
import ModalPage from "./ModalPage";
import DashboardLayout from "../layouts/DashboardLayout";
import Notice from "./merchantComponents/Notice";
import TransactionTable from "./merchantComponents/TransactionTable";
import GeneralStats from "./merchantComponents/GeneralStats";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PromoTable from "./merchantComponents/PromoTable";
import LinkedAccount from "./merchantComponents/LinkedAccount";
import CustomersData from "./merchantComponents/CustomersData";
import NewLocation from "./merchantComponents/NewLocation";
import ManageMultipleLocation from "./merchantComponents/ManageMultipleLocation";
import ManageLocation from "./merchantComponents/ManageLocation";
import ViewMoreRedemption from "./merchantComponents/ViewMoreRedemption";
import LocationModal from "./settingsComponents/LocationModal";
import axios from "axios";
import swal from "sweetalert";
import ChangeMerchantStatus from "./merchantComponents/managelocationComponent/ChangeMerchantStatus";
import MonthlyTotalCharts from "./dashboardComponents/Charts/MonthlyTotalCharts";
import Swal from "sweetalert2";
import UpdateMerchantPage from "./merchantComponents/managelocationComponent/UpdateMerchantPage";
import ActivateNotificationModal from "./ActivateNotificationModal";
import ChangeLocationStatus from "./merchantComponents/manageMultipleLocationComponent/ChangeLocationStatus";
import CreatedCards from "./merchantComponents/CreatedCards";

const Merchants = () => {
  const [cards, setCards] = useState([]);
  const [current, setCurrent] = useState(1);
  const [isloading, setLoading] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [isUpdateloading, setUpdateLoading] = useState(false);
  const [merchantInput, setMerchantInput] = useState("");
  const [search, setSearch] = useState("");
  const [showChangeMerchantStatusModal, setShowChangeMerchantStatusModal] =
    useState(false);
  const [showUpdateMerchantModal, setShowUpdateMerchantModal] = useState(false);
  const [showChangeLocationStatusModal, setShowChangeLocationStatusModal] =
    useState(false);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [activate, setActivate] = useState("");

  const ActivateButtonLocation = useLocation();
  useEffect(() => {
    setActivate(ActivateButtonLocation.state);
  }, []);

  const [location, setlocation] = useState({
    lng: "",
    lat: "",
    address: "",
  });

  const getMapLocation = (lngParam, latParam, address) => {
    setlocation((location) => ({
      ...location,
      lng: lngParam,
      lat: latParam,
      address: address,
    }));
  };

  const [userInput, setUserInput] = useState({
    merchantname: "",
    location: "",
    telephone: "",
    permissions: [],
    service_type: "",
  });

  const [permissions, setPermissions] = useState([]);
  const [reports, setReports] = useState(false);
  const [manageAccount, setManageAccount] = useState(false);
  const [manageGiftCards, setManageGiftCards] = useState(false);
  const [createPromotionsOrDeals, setCreatePromotionsOrDeals] = useState(false);
  const [paymentAccount, setSetUpPaymentAccount] = useState(false);
  const [transactionPayout, setTransactionsAndPayouts] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const [merchantsTable, setMerchantTable] = useState([]);
  const [loadMerchants, setLoadMerchants] = useState(false);

  const handleLocationStatus = (e, selectedLocationValues) => {
    setLocationList(selectedLocationValues);
    setShowChangeLocationStatusModal(true);
  };

  const handleLocationStatusModalClose = (e) => {
    setShowChangeLocationStatusModal(false);
  };

  const SubMerchants = () => {
    if (search === "") {
      setLoadMerchants(false);
      axios
        .get(`/prime.sika/v1/managers/merchants?limit=10&page=${currentPage}`)
        .then((res) => {
          setLoadMerchants(true);
          setMerchantTable(res.data.data.merchants);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            Swal.fire({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
            });
            setLoadMerchants(true);
          }
        });
    } else {
      setLoadMerchants(false);
      axios
        .get(
          `/prime.sika/v1/managers/merchants?limit=1000&search_string=${search}`
        )
        .then((res) => {
          setLoadMerchants(true);
          setMerchantTable(res.data.data.merchants);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            Swal.fire({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
            });
            setLoadMerchants(true);
          }
        });
    }
  };

  const [loadCards, setLoadCards] = useState(false);
  const [merchantServices, setMerchantServices] = useState([]);
  useEffect(() => {
    axios
      .get("/prime.sika/v1/managers/merchants/services")
      .then((res) => {
        setMerchantServices(res.data.data.services);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  useEffect(() => {
    SubMerchants();
  }, [currentPage, search]);

  const fetchCards = () => {
    setLoadCards(false);
    axios
      .get("/prime.sika/v1/cards/list")
      .then((res) => {
        setLoadCards(true);
        setCards(res.data.data.cards);
      })
      .catch((err) => {
        setLoadCards(true);
        console.log(err.response);
      });
  };
  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    setlocation((location) => ({
      ...location,
      lat: merchantInput?.cordinates?.lat,
      lng: merchantInput?.cordinates?.long,
      address: merchantInput?.cordinates?.address,
    }));
  }, [merchantInput]);

  const saveData = () => {
    const data = {
      name: userInput.merchantname,
      branch: location?.address,
      telephone: userInput.telephone,
      service_codes: userInput?.service_type,
      cordinates: {
        address: location?.address,
        latitude: location?.lat,
        longitude: location?.lng,
      },
      persmission: {
        manage_account: manageAccount,
        manage_gift_cards: manageGiftCards,
        reports: reports,
        promotions: createPromotionsOrDeals,
        payment: paymentAccount,
        transaction: transactionPayout,
      },
    };
    setLoading(true);
    axios
      .post("/prime.sika/v1/managers/merchants/initiate-signup", data)
      .then((res) => {
        swal({
          title: "Success",
          text: "Merchant added successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            setUserInput({
              merchantname: "",
              location: "",
              telephone: "",
              permissions: [],
            });
            setReports(false);
            setManageAccount(false);
            setManageGiftCards(false);
            setCreatePromotionsOrDeals(false);
            setSetUpPaymentAccount(false);
            setTransactionsAndPayouts(false);
            axios
              .get("/prime.sika/v1/managers/merchants")
              .then((res) => {
                if (res.data.success === true && res.data.status === 200) {
                  setMerchantTable(res.data.data.merchants);
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.status === 400) {
          swal({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
          setLoading(false);
        }
      });
  };

  const ChangeCurrentScreenToManageLocation = () => {
    setUserInput({
      merchantname: "",
      telephone: "",
      permissions: [],
      service_type: "",
    });
    setSelectedLocations([]);
    setReports(false);
    setManageAccount(false);
    setManageGiftCards(false);
    setCreatePromotionsOrDeals(false);
    setSetUpPaymentAccount(false);
    setTransactionsAndPayouts(false);
    setCurrent(2);
  };

  const ChangeCurrentScreenToDasboard = () => {
    SubMerchants();
    setUserInput({ ...userInput });
    setReports(false);
    setManageAccount(false);
    setManageGiftCards(false);
    setCreatePromotionsOrDeals(false);
    setSetUpPaymentAccount(false);
    setTransactionsAndPayouts(false);
    setlocation({
      lng: "",
      lat: "",
      address: "",
    });
    setCurrent(1);
  };

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleMerchantInput = (e) => {
    setMerchantInput((merchantInput) => ({
      ...merchantInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeMerchantStatusClose = (e) => {
    setShowChangeMerchantStatusModal(false);
  };
  const handleActivateNotificationModalClose = () => {
    setActivate(false);
    navigate("/dashboard");
  };
  const handleChangeMerchantStatusShow = (e, ele) => {
    setMerchantInput(ele);
    setShowChangeMerchantStatusModal(true);
  };
  const handleUpdateMerchantClose = (e) => {
    setShowUpdateMerchantModal(false);
  };
  const handleUpdateMerchantShow = (e, ele) => {
    setMerchantInput(ele);
    setCurrent(3);
  };
  const [merchants, setMerchants] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loadLocation, setLoadLocation] = useState(false);
  const [locationNumber, setLocationNumber] = useState(0);
  const [currentLocationPage, setCurrentLocationPage] = useState(1);
  const [totalLocationPage, setTotalLocationPage] = useState(1);

  const [new_users, setUsers] = useState(0);
  const [total_sales, setSales] = useState(0);
  const [in_store_card_use, setCardUse] = useState(0);
  const [cashed_out, setCashedOut] = useState(0);
  const [stats, setStats] = useState([]);
  const [isLoadingFund, setLoadingFund] = useState(false);

  const subLocations = () => {
    setLoadLocation(false);
    axios
      .get(
        `/prime.sika/v1/managers/merchants?limit=10&page=${currentLocationPage}`
      )
      .then((res) => {
        setLoadLocation(true);
        setMerchants(res.data.data.merchants);
        setTotalLocationPage(res.data.data.total_pages);
        setLocationNumber(res.data.data.total_records);
      })
      .catch((err) => {
        setLoadLocation(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    subLocations();
  }, [currentLocationPage]);

  const changeMerchantCurrentStatus = () => {
    locationList.map((ele) => {
      if (ele?.status?.toLowerCase() === "approved") {
        setChangingStatus(true);
        axios
          .post(`/prime.sika/v1/managers/merchants/${ele?.id}/approve`)
          .then((res) => {
            setChangingStatus(false);
            handleLocationStatusModalClose();
            Swal.fire({
              title: "Success",
              text: "Merchant Status Changed Successfully",
              icon: "success",
              button: "OK",
              confirmButtonColor: "#00b17d",
            }).then((isOkay) => {
              if (isOkay) {
                subLocations();
                setSelectedLocations([]);
                setlocation({
                  lng: "",
                  lat: "",
                  address: "",
                });
              }
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
            });
            setChangingStatus(false);
          });
      } else if (ele?.status?.toLowerCase() === "suspended") {
        setChangingStatus(true);
        axios
          .post(`/prime.sika/v1/managers/merchants/${ele?.id}/suspend`)
          .then((res) => {
            setChangingStatus(false);
            handleLocationStatusModalClose();

            Swal.fire({
              title: "Success",
              text: "Merchant Status Changed Successfully",
              icon: "success",
              button: "OK",
              confirmButtonColor: "#00b17d",
            }).then((isOkay) => {
              if (isOkay) {
                subLocations();
                setSelectedLocations([]);
              }
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
            });
            setChangingStatus(false);
          });
      }
    });
  };

  const DeleteMerchants = (e, selectedLocationValues) => {
    Swal.fire({
      title: "Are you sure you want to delete merchant data?",
      text: "Take note, if merchant has transaction, the account will be archived",
      icon: "warning",
      showDenyButton: false,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#00b17d",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        selectedLocationValues.map((ele) => {
          axios
            .delete(`prime.sika/v1/managers/merchants/${ele?.id}`)
            .then((res) => {
              Swal.fire({
                title: "Success",
                text: "Merchant Account Deleted",
                icon: "success",
                button: "OK",
                confirmButtonColor: "#00b17d",
              }).then((isOkay) => {
                if (isOkay) {
                  subLocations();
                  setSelectedLocations([]);
                }
              });
            })
            .catch((err) => {
              console.log(err.response);
              setLoading(false);
            });
        });
      }
    });
    return false;
  };

  const [fund, setFund] = useState(0);

  const fetchAvailableFunds = () => {
    setLoadingFund(false);
    axios
      .get("/prime.sika/v1/merchants/dashboard/available-funds")
      .then((res) => {
        setLoadingFund(true);
        if (res.data.status === 200) {
          setFund(res.data.data.available_funds);
        }
      })
      .catch((err) => {
        setLoadingFund(true);
        console.log(err.response);
      });
  };

  useEffect(() => {
    fetchAvailableFunds();
  }, []);
  const loadStats = () => {
    setLoadingFund(false);

    axios
      .get("/prime.sika/v1/merchants/dashboard/general-stats")
      .then((res) => {
        setLoadingFund(true);
        setStats(res.data.data);
        setUsers(stats["new_users"]["count"]);
        setSales(stats["total_sales"]["count"]);
        setCardUse(stats["in_store_card_use"]["count"]);
        setCashedOut(stats["cashed_out"]["count"]);
      })
      .catch((err) => {
        setLoadingFund(true);
        console.log(err);
      });
  };

  useEffect(() => {
    loadStats();
  }, []);

  const [totalSales, setTotalSales] = useState([]);
  const [isChatLoading, setChatLoading] = useState(false);
  const [merchantBranch, setMerchantBranch] = useState();
  const [mainBranch, setMainBranch] = useState();

  useEffect(() => {
    axios
      .get("/prime.sika/v1/users/merchant/details")
      .then((res) => {
        setMerchantBranch(res.data.data.user_details?.person?.companies[0]?.id);
        setMainBranch(res.data.data.user_details?.person?.companies[0]?.id);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const fetchMonthlyTotalsCardPurchase = () => {
    setChatLoading(false);
    axios
      .get(
        "/prime.sika/v1/merchants/dashboard/monthly-totals?for=card_purchase"
      )
      .then((res) => {
        if (res.data.status === 200) {
          setChatLoading(true);
          setTotalSales(res.data.data.totals);
        }
      })
      .catch((err) => {
        setChatLoading(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    fetchMonthlyTotalsCardPurchase();
  }, []);

  const handleBranch = (e) => {
    setDateValue("");
    var value = e;
    setMerchantBranch(e);
    if (e === "") {
      value = merchantBranch;
      setMerchantBranch(mainBranch);
    }
    setLoadingFund(false);
    axios
      .get(
        `/prime.sika/v1/merchants/dashboard/available-funds?merchant_ids[]=${value}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          setLoadingFund(true);
          setFund(res.data.data.available_funds);
        }
      })
      .catch((err) => {
        setLoadingFund(true);
        console.log(err.response);
      });

    setChatLoading(false);
    axios
      .get(
        `/prime.sika/v1/merchants/dashboard/monthly-totals?for=card_purchase&merchant_ids[]=${value}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          setChatLoading(true);
          setTotalSales(res.data.data.totals);
        }
      })
      .catch((err) => {
        setChatLoading(false);
        console.log(err.response);
      });
  };

  const merchantType = localStorage?.getItem("client_type").toLowerCase();
  const [dateValue, setDateValue] = useState("");

  const SearchStats = (e) => {
    var searchDate = e;
    const date = new Date(searchDate);
    const month = date
      .toLocaleString("en-US", { month: "short" })
      .toLocaleLowerCase();
    const year = date.getFullYear();
    if (merchantType === "single") {
      if (searchDate === "") {
        fetchAvailableFunds();
        fetchMonthlyTotalsCardPurchase();
      } else {
        setLoadingFund(false);
        axios
          .get(
            `/prime.sika/v1/merchants/dashboard/available-funds?month=${month}&year=${year}`
          )
          .then((res) => {
            setLoadingFund(true);
            if (res.data.status === 200) {
              setFund(res.data.data.available_funds);
            }
          })
          .catch((err) => {
            setLoadingFund(true);
            console.log(err.response);
          });

        setChatLoading(false);
        axios
          .get(
            `/prime.sika/v1/merchants/dashboard/monthly-totals?for=card_purchase&month=${month}&year=${year}`
          )
          .then((res) => {
            if (res.data.status === 200) {
              setChatLoading(true);
              setTotalSales(res.data.data.totals);
            }
          })
          .catch((err) => {
            setChatLoading(false);
            console.log(err.response);
          });
      }
    } else {
      setLoadingFund(false);
      axios
        .get(
          `/prime.sika/v1/merchants/dashboard/available-funds?merchant_ids[]=${merchantBranch}&month=${month}&year=${year}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setLoadingFund(true);
            setFund(res.data.data.available_funds);
          }
        })
        .catch((err) => {
          setLoadingFund(true);
          console.log(err.response);
        });

      setChatLoading(false);
      axios
        .get(
          `/prime.sika/v1/merchants/dashboard/monthly-totals?for=card_purchase&merchant_ids[]=${merchantBranch}&month=${month}&year=${year}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setChatLoading(true);
            setTotalSales(res.data.data.totals);
          }
        })
        .catch((err) => {
          setChatLoading(false);
          console.log(err.response);
        });
    }
  };

  const onMerchantTypeSelect = (selectedList, selectedItem) => {
    var merchantType = [];
    selectedList.map((ele, index) => merchantType.push(ele.code));
    setUserInput((userInput) => ({
      ...userInput,
      service_type: merchantType,
    }));
  };

  const onMerchantTypeRemoved = (selectedList, selectedItem) => {
    var merchantType = [];
    selectedList.map((ele, index) => merchantType.push(ele.code));
    setUserInput((userInput) => ({
      ...userInput,
      service_type: merchantType,
    }));
  };

  return (
    <>
      <DashboardLayout>
        {current === 1 && (
          <div id="page-wrapper-div1">
            <div className="row align-content-center justify-content-center">
              <div className="col-lg-12 col-12">
                <div className="card card-chart1 z-position card-height-3">
                  <h2>Merchants Cards</h2>
                  <div className="row text-center">
                    <CreatedCards
                      cards={cards}
                      setCards={setCards}
                      loadCards={loadCards}
                      setLoadCards={setLoadCards}
                    />

                    <div className="col-xl-3 col-6 col-lg-3 card-mar-b">
                      <div className="bg-card1">
                        <a data-toggle="modal" href="#upload_e-gift_card">
                          <div className="upload-div">
                            <img
                              src="/assets/img/add-circle.png"
                              alt=""
                              className="img-fluid mb-2"
                            />
                            <h4>Upload new gift Card</h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-3 col-6 col-lg-3 card-mar-b">
                      <div className="bg-card1 fill3">
                        <img
                          src="assets/img/bg9.png"
                          alt=""
                          className="img-fluid bg9-pos"
                        />
                        <h6 className="more-customers1">
                          Attract more customers with
                        </h6>
                        <h5 className="promo-pos1">
                          Promo <span>Giveback &</span>Deals
                        </h5>
                        <img
                          src="assets/img/logo1.png"
                          alt=""
                          className="card-logo-position2 img-fluid"
                        />
                        {/* <Link to="/marketing">
                          <button className="btn btn-white btn-position">
                            Start
                          </button>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <Notice />
                  {localStorage?.getItem("client_type") === "franchise" ? (
                    <NewLocation
                      handleBranch={(e) => handleBranch(e)}
                      merchants={merchants}
                      setMerchants={setMerchants}
                      branches={branches}
                      setBranches={setBranches}
                      ChangeCurrentScreenToManageLocation={
                        ChangeCurrentScreenToManageLocation
                      }
                      onMerchantTypeSelect={onMerchantTypeSelect}
                      onMerchantTypeRemoved={onMerchantTypeRemoved}
                      merchantServices={merchantServices}
                      merchantBranch={merchantBranch}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      handleUserInput={handleUserInput}
                      location={location}
                      setlocation={setlocation}
                      saveData={saveData}
                      setPermissions={setPermissions}
                      setReports={setReports}
                      setManageAccount={setManageAccount}
                      setManageGiftCards={setManageGiftCards}
                      setCreatePromotionsOrDeals={setCreatePromotionsOrDeals}
                      setSetUpPaymentAccount={setSetUpPaymentAccount}
                      setTransactionsAndPayouts={setTransactionsAndPayouts}
                      reports={reports}
                      manageAccount={manageAccount}
                      manageGiftCards={manageGiftCards}
                      createPromotionsOrDeals={createPromotionsOrDeals}
                      paymentAccount={paymentAccount}
                      transactionPayout={transactionPayout}
                      isloading={isloading}
                      setLoading={setLoading}
                    />
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 col-lg-12">
                    <div className="row">
                      <GeneralStats fund={fund} isLoadingFund={isLoadingFund} />
                      {/* Revenue Analytics Chart */}
                      <div className="col-lg-12">
                        <div className="d-flex float-right z-position mb-3">
                          <h4 className="my-auto mr-3">Month/Year</h4>
                          <input
                            type="month"
                            value={dateValue}
                            className="form-control search-cover"
                            onChange={(e) => {
                              setDateValue(e.target.value);
                              SearchStats(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="card card-chart z-position card-height">
                          <MonthlyTotalCharts
                            title="Monthly Total Sales"
                            aspect={2 / 1}
                            totalSales={totalSales}
                            isChatLoading={isChatLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <TransactionTable />
                  </div>
                  {/* <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <ReportsTable />
                      </div>
                      <div className="col-lg-6">
                        <PromoTable />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-md-12 col-lg-4">
                <div className="row">
                  <div className="col-12">
                    <CustomersData location={location}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {localStorage?.getItem("client_type") === "franchise" ? (
                      <ManageMultipleLocation
                        locationNumber={locationNumber}
                        setLocationNumber={setLocationNumber}
                        setMerchants={setMerchants}
                        merchants={merchants}
                        setCurrentLocationPage={setCurrentLocationPage}
                        totalLocationPage={totalLocationPage}
                        loadLocation={loadLocation}
                        handleLocationStatus={handleLocationStatus}
                        selectedLocations={selectedLocations}
                        setSelectedLocations={setSelectedLocations}
                        DeleteMerchants={DeleteMerchants}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-12">
                    <LiveshopVideosTable />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-12">
                    {/* <FeedbackTable /> */}
                    <PromoTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {current === 2 && (
          <ManageLocation
            setSearch={setSearch}
            search={search}
            setTotalPage={setTotalPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPage={totalPage}
            ChangeCurrentScreenToDasboard={ChangeCurrentScreenToDasboard}
            userInput={userInput}
            handleUserInput={handleUserInput}
            location={location}
            setlocation={setlocation}
            saveData={saveData}
            setPermissions={setPermissions}
            setReports={setReports}
            setManageAccount={setManageAccount}
            setManageGiftCards={setManageGiftCards}
            setCreatePromotionsOrDeals={setCreatePromotionsOrDeals}
            setSetUpPaymentAccount={setSetUpPaymentAccount}
            setTransactionsAndPayouts={setTransactionsAndPayouts}
            reports={reports}
            manageAccount={manageAccount}
            manageGiftCards={manageGiftCards}
            createPromotionsOrDeals={createPromotionsOrDeals}
            paymentAccount={paymentAccount}
            transactionPayout={transactionPayout}
            isloading={isloading}
            setLoadMerchants={setLoadMerchants}
            loadMerchants={loadMerchants}
            merchantsTable={merchantsTable}
            setMerchantTable={setMerchantTable}
            handleChangeMerchantStatusShow={handleChangeMerchantStatusShow}
            handleUpdateMerchantShow={handleUpdateMerchantShow}
            SubMerchants={() => SubMerchants()}
            onMerchantTypeSelect={onMerchantTypeSelect}
            onMerchantTypeRemoved={onMerchantTypeRemoved}
            merchantServices={merchantServices}
          />
        )}
        {current === 3 && (
          <UpdateMerchantPage
            location={location}
            setlocation={setlocation}
            handleUpdateMerchantShow={handleUpdateMerchantShow}
            setShowUpdateMerchantModal={setShowUpdateMerchantModal}
            showUpdateMerchantModal={showUpdateMerchantModal}
            setMerchantInput={setMerchantInput}
            merchantInput={merchantInput}
            handleUpdateMerchantClose={handleUpdateMerchantClose}
            isUpdateloading={isUpdateloading}
            setUpdateLoading={setUpdateLoading}
            merchantsTable={merchantsTable}
            setMerchantTable={setMerchantTable}
            setCurrent={setCurrent}
            SubMerchants={() => SubMerchants()}
            merchantServices={merchantServices}
            onMerchantTypeRemoved={onMerchantTypeRemoved}
            onMerchantTypeSelect={onMerchantTypeSelect}
            ChangeCurrentScreenToManageLocation={() =>
              ChangeCurrentScreenToManageLocation()
            }
          />
        )}
      </DashboardLayout>
      <ActivateNotificationModal
        activate={activate}
        handleActivateNotificationModalClose={
          handleActivateNotificationModalClose
        }
      />
      <LocationModal getMapLocation={getMapLocation} />
      <ChangeMerchantStatus
        setMerchantInput={setMerchantInput}
        merchantInput={merchantInput}
        handleMerchantInput={handleMerchantInput}
        showChangeMerchantStatusModal={showChangeMerchantStatusModal}
        setShowChangeMerchantStatusModal={setShowChangeMerchantStatusModal}
        handleChangeMerchantStatusClose={handleChangeMerchantStatusClose}
        isloading={isloading}
        setLoading={setLoading}
        merchantsTable={merchantsTable}
        setMerchantTable={setMerchantTable}
        SubMerchants={() => SubMerchants()}
      />

      <ModalPage />
      <ViewMoreRedemption />
      <ChangeLocationStatus
        showChangeLocationStatusModal={showChangeLocationStatusModal}
        handleLocationStatusModalClose={handleLocationStatusModalClose}
        setLocationList={setLocationList}
        locationList={locationList}
        changeMerchantCurrentStatus={changeMerchantCurrentStatus}
        changingStatus={changingStatus}
      />
    </>
  );
};
export default Merchants;
