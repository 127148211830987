import React from "react";
import { HuePicker } from "react-color";
import Multiselect from "multiselect-react-dropdown";
import Slider from "react-slick";

function GroceriesUpdate({
  updating,
  handleUserInput,
  items,
  handleSubmit,
  selectedImages,
  sliderRef,
  onSelectFile,
  supportedCurrencies,
  setSelectedImages,
  handleRadioChange,
  isChecked,
  handleCheckboxChange,
  selectedOption,
  setColor,
  productSizes,
  selec,
  onSizeSelect,
  onSizeRemoved,
  customSizes,

  handleSizeChange,
  handleRemoveSize,
  handleCustomSizeAdd,
  isColorChecked,
  categories,
  onCategorySelect,
  onCategoryRemoved,
  selectedCategoryValues,

  handleUpdateSelectedSubCategoriesModal,
  multiSelectRef,
  handleColorCheckboxChange,
  color,
  colorList,
  handleColorRemove,
  handleColorAdd,
  isDiscountChecked,
  handleDiscountChange,
}) {
  const settings = {
    infinite: true,
    autoplay: false,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row mx-3 mt-3">
        <div className="col-lg-6 col-pad">
          <div className="row">
            <div className="col-lg-12 col-pad-left">
              <div className="form-group form-position">
                <input
                  type="text"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Enter Product Name *"
                  name="name"
                  value={items?.name}
                  onChange={handleUserInput}
                  required
                />
              </div>
            </div>
            <div className="col-lg-8 col-pad">
              <section className="variable slider">
                <div
                  className={`${
                    selectedImages.length >= 1
                      ? "upload-img-btn1"
                      : "upload-btn1"
                  }`}
                >
                  <div
                    className={`${
                      selectedImages?.length > 1
                        ? "d-flex justify-content-between arrows-position"
                        : "d-lg-none d-none"
                    }`}
                  >
                    <img
                      src="/assets/img/arrow-left1.png"
                      alt=""
                      onClick={() => sliderRef.current.slickPrev()}
                      className="cur-pointer cur-width"
                    />
                    <img
                      src="/assets/img/arrow-right2.png"
                      alt=""
                      onClick={() => sliderRef.current.slickNext()}
                      className="ms-3 cur-pointer cur-width"
                    />
                  </div>
                  <Slider ref={sliderRef} {...settings}>
                    {selectedImages?.map((image, index) => {
                      return (
                        <div className="img-kk3" key={image}>
                          <img src={image} alt="" className="upload-btn-img2" />
                          <input
                            type="button"
                            onClick={() =>
                              setSelectedImages(
                                selectedImages.filter((e) => e !== image)
                              )
                            }
                            className="btn bg-danger-light delete-img"
                            value="delete"
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </section>
            </div>
            <div className="col-lg-4 col-pad">
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <select
                    name="currency"
                    value={items?.currency}
                    onChange={handleUserInput}
                    className="form-control form-padding-textbx mb-3"
                    required
                  >
                    <option value="">Currency *</option>
                    {supportedCurrencies?.map((supportedCurrency, index) => (
                      <option
                        value={supportedCurrency}
                        key={index}
                        className="text-capitalize"
                      >
                        {supportedCurrency}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/arrow-down.png"
                    alt=""
                    className="category-arrow-pos"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Price *"
                    value={items?.price}
                    name="price"
                    onChange={handleUserInput}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Quantity *"
                    value={items?.quantity}
                    name="quantity"
                    onChange={handleUserInput}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-pad-left">
              <div className="col-display-small note-text">
                <label
                  htmlFor="upload-image12"
                  className="btn-upload-size1 btn btn-success pt-2"
                >
                  <img
                    src="/assets/img/upload-bg-logo.png"
                    alt=""
                    className="mr-2 my-auto"
                  />
                  Upload Image
                </label>
                <input
                  type="file"
                  id="upload-image12"
                  onChange={(e) => {
                    onSelectFile(e);
                  }}
                  multiple
                  accept="image/"
                  name="product_image"
                />
                <h5 className="mb-3">
                  <span>Note:</span> you can upload more than one image of
                  product
                </h5>
              </div>
            </div>
            <div className="col-lg-12 col-pad-left">
              <label htmlFor="check1" className="label-style">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  id="check1"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <h4 className="label-size">Add Product Size</h4>
              </label>
            </div>
            {isChecked && (
              <div className="col-lg-12 col-pad-left">
                <div className="d-flex">
                  <label
                    htmlFor="non_numeric"
                    className="radio1 d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="non_numeric"
                      className="radio__input mr-1"
                      checked={selectedOption === "non_numeric"}
                      onChange={handleRadioChange}
                    />
                    <div className="radio__radio radio-size"></div>
                    <h4 className="my-auto">Select Size(s)</h4>
                  </label>
                  <label
                    htmlFor="numeric"
                    className="radio1 d-flex align-items-center ml-4"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="numeric"
                      className="radio__input mr-1"
                      checked={selectedOption === "numeric"}
                      onChange={handleRadioChange}
                    />
                    <div className="radio__radio radio-size"></div>
                    <h4 className="my-auto">Custom</h4>
                  </label>
                </div>
                {selectedOption === "non_numeric" && (
                  <div className="form-group form-position">
                    <Multiselect
                      className="form-control form-padding-textbx mb-3"
                      options={productSizes}
                      isObject={false}
                      placeholder="Product Size"
                      // ref={multiSelectRef}
                      selectionLimit={selec}
                      selectedValues={
                        items?.has_custom_sizes === false
                          ? items?.custom_sizes
                          : []
                      }
                      onSelect={onSizeSelect}
                      onRemove={onSizeRemoved}
                      onChange={handleUserInput}
                    />
                    <img
                      src="/assets/img/arrow-down.png"
                      alt=""
                      className="category-arrow-pos"
                    />
                  </div>
                )}
                {selectedOption === "numeric" && (
                  <div className="custom-size-div-width">
                    {customSizes?.map((ele, index) => {
                      return (
                        <>
                          <input
                            type="text"
                            name="size"
                            id={`size${index}`}
                            value={ele.size}
                            onChange={(e) => handleSizeChange(e, index)}
                            className={
                              customSizes.length === 1
                                ? "custom-size-box mr-2 mb-3"
                                : "custom-size-box mb-3"
                            }
                            style={{
                              borderRadius: `${
                                customSizes.length === 1
                                  ? "10px"
                                  : "10px 0px 0px 10px"
                              }`,
                            }}
                          />
                          {customSizes.length > 1 && (
                            <div
                              className="minus-cover mr-2"
                              onClick={(e) => handleRemoveSize(e, index)}
                            >
                              <div className="minus"></div>
                            </div>
                          )}
                          {customSizes?.length - 1 === index &&
                            customSizes?.length < 10 && (
                              <div
                                className="plus-cover mb-3"
                                onClick={handleCustomSizeAdd}
                              >
                                <img
                                  src="/assets/img/plus1.svg"
                                  alt=""
                                  className="plus-width"
                                />
                              </div>
                            )}
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div className="col-lg-12 col-pad-left">
              <textarea
                placeholder="Enter Product Return Policy"
                className={
                  isColorChecked
                    ? "textarea-size8-active1 form-control mb-3"
                    : isChecked && !isColorChecked
                    ? "textarea-size8-active3 form-control mb-3"
                    : "textarea-size8 form-control mb-3"
                }
                value={items?.return_policy}
                name="return_policy"
                onChange={handleUserInput}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-pad-right">
          <div className="row">
            <div className="col-lg-12 col-pad-right">
              <div className="form-group form-position">
                <Multiselect
                  required
                  className="form-control form-padding-textbx mb-3"
                  options={categories}
                  displayValue="name"
                  placeholder="Category *"
                  onSelect={onCategorySelect}
                  onRemove={onCategoryRemoved}
                  selectedValues={selectedCategoryValues}
                  ref={multiSelectRef}
                  selectionLimit={2}
                />
                <img
                  src="/assets/img/arrow-down.png"
                  alt=""
                  className="category-arrow-pos"
                />
              </div>
            </div>
            <div className="col-lg-12 col-pad-right">
              <button
                type="button"
                className="btn btn-success w-100 mb-3"
                onClick={() => handleUpdateSelectedSubCategoriesModal()}
              >
                View Selected Categories
              </button>
            </div>
            <div className="col-lg-6 col-pad-right">
              <div className="form-group form-position">
                <input
                  type="number"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Min Product Quantity"
                  value={items?.min_quantity}
                  name="min_quantity"
                  onChange={handleUserInput}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-pad-right">
              <div className="form-group form-position">
                <input
                  type="text"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Enter Product Weight"
                  value={items?.weight}
                  name="weight"
                  onChange={handleUserInput}
                />
              </div>
            </div>
            <div className="col-lg-12 col-pad-right">
              <label htmlFor="color_check" className="label-style">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  id="color_check"
                  checked={isColorChecked}
                  onChange={handleColorCheckboxChange}
                />
                <h4 className="label-size">Add Product Color(s)</h4>
              </label>
            </div>
            {isColorChecked && (
              <div className="col-lg-12 col-pad-right">
                <div className="row d-flex">
                  <div className="col-1">
                    <div
                      className="black-color mr-2"
                      onClick={(color) => {
                        setColor("#000");
                      }}
                    ></div>
                  </div>
                  <div className="col-9">
                    <HuePicker
                      color={color}
                      onChangeComplete={(color) => {
                        setColor(color.hex);
                      }}
                      className="mt-1 ml-3"
                      width="100%"
                    />
                  </div>
                  <div className="col-1">
                    <div
                      className="white-color ml-2"
                      onClick={(color) => {
                        setColor("#fff");
                      }}
                    ></div>
                  </div>
                </div>
                <div className="color-div-width mt-3">
                  {colorList?.map((ele, index) => {
                    return (
                      <>
                        <div
                          className={
                            colorList.length > 1
                              ? "color-circle cur-pointer mr-2"
                              : "color-circle mr-2"
                          }
                          key={index}
                          onClick={(e) =>
                            colorList.length > 1 && handleColorRemove(e, index)
                          }
                          style={{
                            background: `${colorList[index]?.color}`,
                            border: `1px solid ${
                              colorList[index]?.color === "#fff"
                                ? "#000"
                                : colorList[index]?.color
                            }`,
                          }}
                        ></div>
                        {colorList?.length - 1 === index &&
                          colorList?.length < 10 && (
                            <div
                              className="plus-circle"
                              onClick={handleColorAdd}
                            >
                              <img
                                src="/assets/img/plus1.svg"
                                alt=""
                                className="plus-width"
                              />
                            </div>
                          )}
                      </>
                    );
                  })}
                </div>
                <div className="note-text">
                  <h5 className="mb-4 mt-1">
                    <span>Note:</span> you can remove color by clicking on it
                  </h5>
                </div>
              </div>
            )}
            <div className="col-lg-12 col-pad-right">
              <textarea
                placeholder="Enter Product Benefits"
                className={
                  isColorChecked && !isChecked
                    ? "textarea-size2-active1 form-control mb-3"
                    : isChecked && isColorChecked
                    ? "textarea-size2-active2 form-control mb-3"
                    : isChecked && !isColorChecked
                    ? "textarea-size2-active3 form-control mb-3"
                    : "textarea-size2 form-control mb-3"
                }
                value={items?.benefits}
                name="benefits"
                onChange={handleUserInput}
              ></textarea>
            </div>
            <div className="col-lg-12 col-pad-right">
              <textarea
                placeholder="Enter Product Description *"
                className={
                  isColorChecked && !isChecked
                    ? "textarea-size10-active1 form-control"
                    : isChecked && isColorChecked
                    ? "textarea-size10-active2 form-control"
                    : isChecked && !isColorChecked
                    ? "textarea-size10-active3 form-control"
                    : "textarea-size10 form-control"
                }
                value={items?.description}
                name="description"
                onChange={handleUserInput}
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-pad1">
          <label htmlFor="check2" className="label-style">
            <input
              type="checkbox"
              className="checkbox-design"
              id="check2"
              checked={isDiscountChecked}
              onChange={handleDiscountChange}
            />
            <h4 className="label-size">Add Discount</h4>
          </label>
        </div>
        {isDiscountChecked && (
          <>
            <div className="col-lg-12 col-pad1 mt-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group form-position">
                    <input
                      type="number"
                      className="form-control form-padding-textbx"
                      placeholder="Enter Discount"
                      value={items?.discount}
                      name="discount"
                      required
                      onChange={handleUserInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-pad-left1 mt-4">
              <div className="form-group form-position">
                <label htmlFor="">
                  <h4 className="dis-font">Start Date</h4>
                </label>
                <input
                  type="datetime-local"
                  className="form-control form-padding-textbx mb-3"
                  name="discount_start_date"
                  value={items?.discount_start_date?.slice(0, 16)}
                  onChange={handleUserInput}
                />
              </div>
            </div>
            <div className="col-lg-6 col-pad-right1 mt-4">
              <div className="form-group form-position">
                <label htmlFor="">
                  <h4 className="dis-font">End Date</h4>
                </label>
                <input
                  type="datetime-local"
                  className="form-control form-padding-textbx mb-3"
                  name="discount_end_date"
                  value={items?.discount_end_date?.slice(0, 16)}
                  onChange={handleUserInput}
                />
              </div>
            </div>
          </>
        )}
        <div className="w-100 mb-4">
          {!updating && (
            <input
              type="submit"
              className="btn btn-success w-100"
              value="Update product"
            />
          )}
          {updating && (
            <button className="btn btn-success w-100" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>Updating...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default GroceriesUpdate;
