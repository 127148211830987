import { Modal } from "react-bootstrap";
import { useRef } from "react";
import Slider from "react-slick";

const DetailedProductModal = ({
  products,
  setProducts,
  setItems,
  store_type,
  items,
  showDetailedProductModal,
  setShowDetailedProductModal,
  handleDetailedProductClose,
}) => {
  const sliderRef = useRef(null);
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };
  console.log("wwe", items);
  let categories = items?.belongs_to_product_types;
  return (
    <Modal show={showDetailedProductModal} onHide={handleDetailedProductClose}>
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4 className="mt-3">Detailed Product Stocks</h4>
          </div>
          <div>
            <button onClick={handleDetailedProductClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row product-details">
          <div className="col-lg-6">
            <div className="col-lg-12 col-pad">
              <div className="product-image-cover">
                <div
                  className={`${
                    items?.pictures?.length > 1
                      ? "d-flex justify-content-between arrows-position"
                      : "d-lg-none d-none"
                  }`}
                >
                  <img
                    src="/assets/img/arrow-left1.png"
                    alt=""
                    onClick={() => sliderRef.current.slickPrev()}
                    className="cur-pointer cur-width"
                  />
                  <img
                    src="/assets/img/arrow-right2.png"
                    alt=""
                    onClick={() => sliderRef.current.slickNext()}
                    className="ms-3 cur-pointer cur-width"
                  />
                </div>
                <Slider ref={sliderRef} {...settings}>
                  {items?.pictures?.map((image, i) => {
                    return (
                      <div className="img-kk1" key={i}>
                        <img src={image} alt="" className="product-image" />
                      </div>
                    );
                  })}
                </Slider>
                {/* <img src/> */}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-3">
                <h4>
                  Product Code : <span>{items?.code}</span>
                </h4>
              </div>
              <div className="mt-4">
                <h4>
                  Product Name : <span>{items?.name}</span>
                </h4>
              </div>
              {(store_type === "undefined" ||
                store_type.toLowerCase() === "others") && (
                <div className="mt-4">
                  <h4 className="d-flex">
                    <div>Category :</div>
                    <ul>
                      <li>
                        <h4>Main</h4>
                        <ul>
                          {categories
                            ?.filter((category) => category.is_main === true)
                            .map((ele, i) => (
                              <li>
                                <span>{ele?.name}</span>
                              </li>
                            ))}
                        </ul>
                      </li>
                      {categories?.filter(
                        (category) => category.is_main === false
                      ).length > 0 && (
                        <li>
                          <h4>Sub</h4>
                          <ul>
                            {categories
                              ?.filter((category) => category.is_main === false)
                              .map((ele, i) => (
                                <li>
                                  <span>{ele?.name}</span>
                                </li>
                              ))}
                          </ul>
                        </li>
                      )}
                    </ul>
                  </h4>
                </div>
              )}
              {store_type !== "undefined" &&
                store_type.toLowerCase() === "restaurant" && (
                  <div className="mt-4">
                    <h4 className="d-flex">
                      <div>Category :</div>
                      <ul>
                        <li>
                          {categories
                            ?.filter((category) => category.is_main === false)
                            .map((ele, i) => (
                              <li>
                                <span>{ele?.name}</span>
                              </li>
                            ))}
                        </li>
                      </ul>
                    </h4>
                  </div>
                )}
              {(store_type === "undefined" ||
                store_type.toLowerCase() === "others") && (
                <div className="mt-4">
                  <h4>Product Color :</h4>
                  <div className="color-div-width1 mt-3">
                    {items?.colors?.map((ele, index) => {
                      return (
                        <div
                          className="color-circle mr-2"
                          key={index}
                          style={{
                            background: `${ele}`,
                            border: `1px solid ${
                              ele === "#fff" || ele === "white" ? "#000" : ele
                            }`,
                          }}
                        ></div>
                      );
                    })}
                  </div>
                </div>
              )}
              {(store_type === "undefined" ||
                store_type.toLowerCase() === "others") && (
                <div className="mt-4">
                  <h4>
                    Quantity In stock : <span>{items?.quantity_in_stock}</span>
                  </h4>
                </div>
              )}{" "}
              {(store_type === "undefined" ||
                store_type.toLowerCase() === "others") && (
                <div className="mt-4">
                  <h4>
                    Product Return Policy : <span>{items?.return_policy}</span>
                  </h4>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-lg-12">
              <div>
                <h4>
                  Product Currency : <span>{items?.currency}</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-4">
                <h4>
                  Product Price : <span>{items?.price}</span>
                </h4>
              </div>
            </div>
            {(store_type === "undefined" ||
              store_type.toLowerCase() === "others") && (
              <div className="col-lg-12">
                <div className="mt-3">
                  <h4>
                    Product State :
                    <span className="ml-1">
                      {items?.product_quantity_status?.toLowerCase() ===
                        "high_quantity" && (
                        <div className="btn btn-sm bg-success-light mr-2">
                          In stock
                        </div>
                      )}
                      {items?.product_quantity_status?.toLowerCase() ===
                        "empty_quantity" && (
                        <div className="btn btn-sm bg-dark-light mr-2">
                          Out of stock
                        </div>
                      )}
                      {items?.product_quantity_status?.toLowerCase() ===
                        "low_quantity" && (
                        <div className="btn btn-sm bg-danger-light mr-2">
                          Low
                        </div>
                      )}
                    </span>
                  </h4>
                </div>
              </div>
            )}

            <div className="col-lg-12">
              {(store_type === "undefined" ||
                store_type.toLowerCase() === "others") && (
                <div className="mt-5">
                  <h4>
                    Product Quantity : <span>{items?.quantity}</span>
                  </h4>
                </div>
              )}
              <div className="mt-4">
                <h4 className="d-flex">
                  <div>Product Size(s) :</div>
                  <ul>
                    {items?.size?.map((ele, i) => (
                      <li>
                        <span>{ele}</span>
                      </li>
                    ))}
                  </ul>
                </h4>
              </div>
              <div className="mt-4">
                <h4>
                  Product Weight : <span>{items?.weight}</span>
                </h4>
              </div>
              {(store_type === "undefined" ||
                store_type.toLowerCase() === "others") && (
                <div className="mt-4">
                  <h4>
                    Quantity Sold : <span>{items?.quantity_sold}</span>
                  </h4>
                </div>
              )}
              <div className="mt-4">
                <h4>
                  Product Discount : <span>{items?.discount}</span>
                </h4>
              </div>

              <div className="mt-4">
                <h4>
                  Product Description : <span>{items?.description}</span>
                </h4>
              </div>
              {(store_type === "undefined" ||
                store_type.toLowerCase() === "others") && (
                <div className="mt-4">
                  <h4>
                    Product Benefits : <span>{items?.benefits}</span>
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DetailedProductModal;
