
import Cards from "./Cards"

const LiveShopStats =()=>{
    return(
        <>
        <Cards
        image="/assets/img/user-octagon.png"
        value="2500"
        title="Followers"
        background="card board1 fill5"
      />
      <Cards
        image="/assets/img/tag-user.png"
        value="574"
        title="Comments"
        background="card board1 fill6"
      />
      <Cards
        image="/assets/img/video.png"
        value="257K"
        title="Streamed Videos"
        background="card board1 fill7"
      />
      <Cards
        image="/assets/img/money-recive.png"
        value="$25K"
        title="Earned from Stream"
        background="card board1 fill8"
      />
        </>
    )
}
export default LiveShopStats;