import DashboardLayout from "../layouts/DashboardLayout";
import AddCoupons from "./couponsComponents/AddCoupons";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import ActivateNotificationModal from "./ActivateNotificationModal";
import PagePagination from "../components/PagePagination";
import EditCouponModal from "./couponsComponents/EditCouponModal";
import Swal from "sweetalert2";

const Coupons = () => {
  const [current, setCurrent] = useState(1);
  const [coupons, setCoupons] = useState("");
  const [isloading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [activate, setActivate] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [file, setFile] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditCouponModal, setShowEditCouponModal] = useState(false);
  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  const ActivateButtonLocation = useLocation();
  useEffect(() => {
    setActivate(ActivateButtonLocation.state);
  }, []);

  const handleActivateNotificationModalClose = () => {
    setActivate(false);
    navigate("/dashboard");
  };

  const couponLists = () => {
    setLoading(false);
    axios
      .get(`/prime.sika/v1/managers/coupons/list?limit=10&page=${currentPage}`)
      .then((res) => {
        setLoading(true);
        setCoupons(res.data.data.coupons);
        setTotalPage(res.data.data.total_pages);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    couponLists();
  }, [currentPage]);

  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    var hour = ("0" + date.getUTCHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    return `${date.getFullYear()}-${mnth}-${day} ${hour}:${minutes}:00`;
  }

  const handleEditCouponModal = (e, ele) => {
    setItems(ele);
    setShowEditCouponModal(true);
  };

  const handleEditCouponModalClose = (e) => {
    setShowEditCouponModal(false);
  };
  const saveData = () => {
    const data = new FormData();
    if (file === "") {
      data.append("string_codes", userInput.string_codes);
    } else {
      data.append("csv_codes", file);
    }
    data.append("name", userInput.name);
    data.append("card_ids", selectedItems?.toString());
    data.append("description", userInput.description);
    data.append("terms_and_conditions", userInput.terms_and_conditions);
    // if (userInput.string_codes !== []) {
    //   data.append("string_codes", userInput.string_codes);
    // }
    data.append("start_date", convertDate(userInput.start_date));
    data.append("expire_date", convertDate(userInput.expire_date));

    setLoading(true);

    setAdding(true);
    axios
      .post("/prime.sika/v1/managers/coupons", data)
      .then((res) => {
        setAdding(false);
        swal({
          title: "Success",
          text: "Coupon added successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
        setAdding(false);
        setLoading(false);
      });
  };

  const DeleteCoupon = () => {
    Swal.fire({
      title: "Are you sure you want to delete coupon(s)",
      text: "This action is irreversible.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isOkay) => {
      if (isOkay) {
        selectedItems.map((ele) => {
          axios
            .delete(
              `https://api.primeegiftcard.com/prime.sika/v1/managers/coupons/tag/${ele}`
            )
            .then((res) => {
              couponLists();
            })
            .catch((err) => {
              console.log(err.response);
            });
        });
      }
    });
  };

  const [userInput, setUserInput] = useState({
    name: "",
    string_codes: [],
    card_ids: [],
    description: "",
    terms_and_conditions: "",
    start_date: "",
    expire_date: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleItems = (e) => {
    setItems((items) => ({
      ...items,
      [e.target.name]: e.target.value,
    }));
  };

  const checkBoxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      var temp = selectedItems;
      temp = temp.filter((id) => {
        return id !== value;
      });
      temp ? setSelectedItems(temp) : setSelectedItems([]);
    }
  };

  const checkAllHandler = () => {
    if (coupons?.length === selectedItems?.length) {
      setSelectedItems([]);
    } else {
      const cardIds = coupons?.map((item) => {
        return item?.tag_id;
      });
      setSelectedItems(cardIds);
    }
  };

  return (
    <>
      <DashboardLayout>
        {current === 1 && (
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-table coupon-table-height2">
                <div className="card-header card-header-margin d-flex justify-content-between">
                  <h3 className="my-auto">Coupons</h3>
                  <button
                    className="my-auto btn btn-success"
                    onClick={() => setCurrent(2)}
                  >
                    <img
                      src="assets/img/add1.png"
                      alt=""
                      className="img-fluid mr-2"
                    />
                    Add Coupons
                  </button>
                </div>
                <div className="card-body booking_card">
                  <div className="table-responsive">
                    {selectedItems?.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger mb-3"
                        onClick={DeleteCoupon}
                      >
                        {" "}
                        Delete
                      </button>
                    )}
                    {selectedItems?.length === 0 && (
                      <div className="text-danger mb-3">
                        Delete Coupon(s) by selecting the checkboxes
                      </div>
                    )}
                    <table className="datatable table table-stripped table table-hover table-center mb-0">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              onChange={checkAllHandler}
                              checked={
                                coupons?.length === selectedItems?.length
                                  ? true
                                  : false
                              }
                            />
                            {/* <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={checkAllHandler}
                                  >
                                    {cards?.length === selectedItems?.length
                                      ? "Unselect All"
                                      : "Select All"}
                                  </button> */}
                          </th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Total</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isloading ? (
                          <>
                            {coupons.length === 0 ? (
                              <tr className="error-alert-position2">
                                <td colSpan={6}>
                                  {" "}
                                  <h1>No Records Found</h1>
                                </td>
                              </tr>
                            ) : (
                              coupons.map((ele, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="checkbox-design"
                                        value={ele?.tag_id}
                                        onChange={checkBoxHandler}
                                        checked={selectedItems?.includes(
                                          ele?.tag_id
                                        )}
                                      />
                                    </td>
                                    <td>{ele.name}</td>
                                    <td>{ele.description}</td>

                                    <td>{ele.total}</td>
                                    <td>
                                      {moment(ele.created_at).format("lll")}
                                    </td>
                                    <td>
                                      <img
                                        src="assets/img/edit-2.png"
                                        alt=""
                                        className="cur-pointer img-fluid"
                                        width="15%"
                                        title="Edit coupon"
                                        onClick={(e) =>
                                          handleEditCouponModal(e, (i = ele))
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </>
                        ) : (
                          <div className="no-data">
                            <i className="fas fa-spinner fa-spin mr-2"></i>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mr-2">
                  <PagePagination
                    setTotalPage={setTotalPage}
                    totalPage={totalPage}
                    setCurrentPage={(e) => setCurrentPage(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {current === 2 && (
          <AddCoupons
            current={current}
            setFile={(e) => setFile(e)}
            file={file}
            setCurrent={setCurrent}
            adding={adding}
            setAdding={setAdding}
            userInput={userInput}
            handleUserInput={handleUserInput}
            saveData={saveData}
            selectedItems={selectedItems}
            setSelectedItems={(e) => setSelectedItems(e)}
          />
        )}
      </DashboardLayout>
      <ActivateNotificationModal
        activate={activate}
        handleActivateNotificationModalClose={
          handleActivateNotificationModalClose
        }
      />
      <EditCouponModal
        setShowEditCouponModal={setShowEditCouponModal}
        showEditCouponModal={showEditCouponModal}
        handleEditCouponModalClose={handleEditCouponModalClose}
        setItems={setItems}
        items={items}
        setFile={(e) => setFile(e)}
        file={file}
        handleItems={handleItems}
      />
    </>
  );
};
export default Coupons;
