import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
const UpdateSuccess = ({
  img,
  image,
  color,
  amount,
  pos,
  amts,
  DoneUpdating,
}) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="row web-center">
      <div className="col-lg-12">
        <div className="publish-size">
          <h4>e-Gift Card Published</h4>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="main-upload-butn-cover">
          <img
            className="d-block  main-upload-butn-img"
            src={image}
            alt="First slide"
          />
          {pos === 1 && (
            <div style={{ color: color }} className="card-amount-pos1-top-r">
              <h4>
                GH¢ <span>{amount}</span>
              </h4>
            </div>
          )}
          {pos === 2 && (
            <div style={{ color: color }} className="card-amount-pos1-top-l">
              <h4>
                GH¢ <span>{amount}</span>
              </h4>
            </div>
          )}
          {pos === 3 && (
            <div style={{ color: color }} className="card-amount-pos1-down-r">
              <h4>
                GH¢ <span>{amount}</span>
              </h4>
            </div>
          )}
          {pos === 4 && (
            <div style={{ color: color }} className="card-amount-pos1-down-l">
              <h4>
                GH¢ <span>{amount}</span>
              </h4>
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-12 mt-3 mb-4">
        <div className="success-text">
          <h4>
            <h3>Great!</h3>You successfully publish your main e-Gift Card and
            it's live
            <span>on Prime app and Prime Plus website</span>
          </h4>
        </div>
      </div>
      <div className="col-lg-12">
        <hr />
      </div>
      <div className="col-lg-12">
        <div className="promo-size">
          <h4>Promote Sales on your Website</h4>
          <h5>
            Launch a digital gifting solution on your website without complex
            <span>
              {" "}
              integrations, Copy and paste your e-Gift card URL and paste on
              your website
            </span>
            to promote sales.
          </h5>
        </div>
      </div>
      <div className="col-lg-12">
        <hr />
      </div>
      <div className="col-lg-12 d-flex justify-content-between">
        <div>
          <button
            type="button"
            className="btn btn-success btn-block review-btn mt-2 ml-4"
          >
            Copy link
          </button>
        </div>
        <div>
          <button
            onClick={() => DoneUpdating()}
            type="button"
            className="btn btn-success btn-block review-btn mt-2 mr-4"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
export default UpdateSuccess;
