import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import axios from "axios";

const MonthlyTotalCharts = ({ aspect, title, isChatLoading, totalSales }) => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <div className="chart">
      <div className="title">{title}</div>
      {isChatLoading ? (
        <>
          <ResponsiveContainer width="100%" aspect={aspect}>
            <AreaChart
              width={730}
              height={250}
              data={totalSales}
              margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
            >
              <defs>
                <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#338540" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#338540" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" stroke="gray" />
              <CartesianGrid strokeDasharray="2 2" className="chartGrid" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#338540"
                fillOpacity={1}
                fill="url(#total)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      ) : (
        <div className="chart-h">
          <div className="no-data">
            <i className="fas fa-spinner fa-spin mr-2"></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthlyTotalCharts;
