import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

export const ExportToExcel = ({
  apiData,
  fileName,
  isExporting,
  disabled,
  totalRecords,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    if (totalRecords >= 5000) {
      Swal.fire({
        title: "Error",
        text: "Can't download More Than 5,000 Data",
        icon: "error",
        button: "OK",
        confirmButtonColor: "#00b17d",
      });
      return;
    }
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      {/* {!isexporting && (
        <button
          onClick={(e) => exportToCSV(apiData, fileName)}
          className="btn btn-success"
        >
          Export As Excel
        </button>
      )}
      {isexporting && ( */}
    
      {!isExporting && (
        <button
          onClick={(e) => exportToCSV(apiData, fileName)}
          className="btn btn-success excel-button"
          disabled={disabled}
        >
          Export As Excel
        </button>
      )}
      {isExporting && (
        <button className="btn btn-success excel-button" disabled>
          <i className="fas fa-spinner fa-spin mr-2"></i>
          Exporting
        </button>
      )}
    </div>
  );
};
