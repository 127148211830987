import React, { useState, useEffect } from 'react';
import axios from 'axios';
const Deals1 = ({ handleAbout, handleEnd, handleName, handleStart, handleDiscount, handlePercent }) => {
  const [userDetails,setUserDetails]=useState([]);
  const [disabled, setDisabled] = useState(true)
	useEffect(()=>{
		axios.get('/prime.sika/v1/users/merchant/details').then(res=>{
			setUserDetails(res.data.data.user_details);
		  }).catch(err=>{
			console.log(err.response)
		  })
    },[])
  return (
    <div
      className="tab-pane fade"
      id="pills-deals-deal"
      role="tabpanel"
      aria-labelledby="pills-profile-tab-deal"
    >
      <div className="form-group d-flex mt-3 display-sm-block">
        <span>
          <input
            onChange={(e) => handleName(e.target.value)}
            className="form-control amount-textbox mr-3 dt-mb"
            type="text"
            placeholder="DealsName"
            // value={card?.promotion_title}
          />
        </span>
        <span>
          <input
            onChange={(e) => handleStart(e.target.value)}
            className="form-control date-textbox"
            type="date"
            placeholder="Start Date"
            // value={card?.promotion_start}
          />
          <input
            onChange={(e) => handleEnd(e.target.value)}
            className="form-control date-textbox1"
            type="date"
            placeholder="End Date"
            // value={card?.promotion_end}
          />
        </span>
      </div>
      <div className="row mt-5">
        <div className="col-lg-7">
          <div className="row">
            <div className="col-lg-12 mt-3">
              <div className="radio-group1 mt-4">
                <div className="d-flex">
                  <label
                    htmlFor="myRadio2P"
                    className="radio1 d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="myRadio2P"
                      className="radio__input mr-1"
                      defaultChecked={()=>setDisabled(false)}
                    />
                    <div className="radio__radio1"></div>
                    <h4 className="my-auto">Discount</h4>
                  </label>
                  <input type="text"
                    className="form-control amount-textbox discount-ml"
                    placeholder="5% Discount on every sale"
                    onChange={(e) => handlePercent(e.target.value)}
                  />
                </div>
                <div className="d-flex mt-3">
                  <label
                    htmlFor="myRadio3P"
                    className="radio1 d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="myRadio3P"
                      className="radio__input mr-1"
                      onChange={()=> disabled(false)}
                    />
                    <div className="radio__radio1"></div>
                    <h4 className="my-auto">Special</h4>
                    <input type="text"
                      className="form-control amount-textbox4 buy-ml"
                      placeholder="Buy one get 2nd half price"
                      onChange={(e) => handleDiscount(e.target.value)}
                      disabled={disabled}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div
            className="logo-preview-btn mt-4"
          >
           <img src={userDetails?.person?.companies[0]?.logo_url} alt="" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <h3>About the Deal</h3>
        </div>
        <div className="col-12 text-color">
          <h4>What is the deal about</h4>
        </div>
        <div className="col-12">
          <textarea
            onChange={(e) => handleAbout(e.target.value)}
            // value={card?.promo_description}
            name=""
            id=""
            placeholder="Brief information about the deal"
            className="textarea-size5"
          ></textarea>
        </div>
      </div>
    </div>
  );
};
export default Deals1;
