import { useState } from "react";
import MerchantPrivileges from "./managelocationComponent/MerchantPrivileges";
import swal from "sweetalert";
import Multiselect from "multiselect-react-dropdown";

const ManageLocation = ({
  ChangeCurrentScreenToDasboard,
  userInput,
  handleUserInput,
  location,
  saveData,
  setReports,
  setManageAccount,
  setManageGiftCards,
  setCreatePromotionsOrDeals,
  setSetUpPaymentAccount,
  setTransactionsAndPayouts,
  reports,
  manageAccount,
  manageGiftCards,
  createPromotionsOrDeals,
  paymentAccount,
  transactionPayout,
  isloading,
  merchantsTable,
  setMerchantTable,
  handleChangeMerchantStatusShow,
  handleUpdateMerchantShow,
  setTotalPage,
  setCurrentPage,
  totalPage,
  setSearch,
  search,
  currentPage,
  setLoadMerchants,
  loadMerchants,
  SubMerchants,
  onMerchantTypeSelect,
  onMerchantTypeRemoved,
  merchantServices,
}) => {
  const [errors, setError] = useState({
    branch: "",
    location: "",
    telephone: "",
  });

  const handleOnChange = (e) => {
    setError((errors) => ({
      ...errors,
      [e.target.name]: "",
    }));
    handleUserInput(e);
  };
  const handleValidation = (e) => {
    e.preventDefault();
    if (userInput.merchantname === "" || !userInput.merchantname) {
      setError((errors) => ({
        ...errors,
        merchantname: "Please Enter Merchant's name",
      }));
    } else if (location?.address === "") {
      swal({
        title: "Error",
        text: "Select Merchant Location",
        icon: "error",
        button: "OK",
      });
    } else if (userInput.telephone1 === "" || !userInput.telephone) {
      setError((errors) => ({
        ...errors,
        telephone1: "Please Enter Merchant's number",
      }));
    }
    // else {
    //   reset();
    // }
    saveData();
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <button
            className="back-btn"
            onClick={() => ChangeCurrentScreenToDasboard()}
          >
            <div className="d-flex">
              <img
                src="/assets/img/previous.png"
                alt=""
                className="img-fluid"
              />
              <h4 className="back-color my-auto ml-2">Back</h4>
            </div>
          </button>
        </div>
        <div className="col-lg-12 mt-3">
          <h4 className="add-n-color">Add new location and set privileges</h4>
        </div>
      </div>

      <div className="row">
        <div class="col-lg-12">
          <form action="">
            <div class="card card-chart1 z-position card-height-12">
              <div class="col-lg-8">
                <h2 class="mt-4">Add New Branch</h2>
                <div class="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <input
                        class="form-control frame-textbox mb-3"
                        type="text"
                        placeholder="Enter merchants name"
                        name="merchantname"
                        value={userInput.merchantname}
                        onChange={handleOnChange}
                      />
                      <div className="text-danger">{errors.merchantname}</div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-1">
                    <div class="form-group">
                      <input
                        class="form-control frame-textbox"
                        type="number"
                        placeholder="Enter telephone number"
                        name="telephone"
                        value={userInput.telephone}
                        onChange={handleOnChange}
                      />
                      <div className="text-danger">{errors.telephone}</div>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-1">
                    <div class="form-group">
                      <a data-toggle="modal" href="#location-modal">
                        <input
                          class="form-control frame-textbox"
                          type="text"
                          placeholder="Location"
                          value={location?.address}
                          readOnly
                          name="location"
                        />
                        <img
                          src="assets/img/location.png"
                          alt=""
                          className="company-loc-icon2"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <Multiselect
                        className="form-control frame-textboxes"
                        options={merchantServices}
                        // isObject={true}
                        displayValue="name"
                        placeholder="-- Select merchant type"
                        onSelect={onMerchantTypeSelect}
                        onRemove={onMerchantTypeRemoved}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 frame3-position1 mt-2">
                <img
                  src="assets/img/addnewframe.png"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <div className="col-lg-8">
                <div className="mt-4">
                  <h4 className="priv-font">Privileges</h4>
                </div>
                <hr />
                <div>
                  <div className="radio-group1 mt-1 d-flex justify-content-between">
                    <div className="">
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={
                            //   permissions.includes("merchant account") &&
                            manageAccount
                          }
                          type="checkbox"
                          name="merchant_account"
                          id="merchant_account"
                          className="checkbox-design"
                          value="merchant_account"
                          onChange={() => setManageAccount(!manageAccount)}
                        />
                        <label htmlFor="merchant_account">
                          <h4 className="mt-3 ml-2">Manage Account</h4>
                        </label>
                      </div>
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={
                            //   permissions.includes("Manage Gift Cards") &&
                            manageGiftCards
                          }
                          type="checkbox"
                          name="manage_gift_cards"
                          id="manage_gift_cards"
                          className="checkbox-design"
                          value="manage_gift_cards"
                          onChange={() => setManageGiftCards(!manageGiftCards)}
                        />
                        <label htmlFor="manage_gift_cards">
                          <h4 className="mt-3 ml-2">Manage Gift Cards</h4>
                        </label>
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={
                            //   permissions.includes("Create Promotions/ Deals") &&
                            createPromotionsOrDeals
                          }
                          type="checkbox"
                          name="create_promotions_or_deals"
                          id="create_promotions_or_deals"
                          className="checkbox-design"
                          value="create_promotions_or_deals"
                          onChange={() =>
                            setCreatePromotionsOrDeals(!createPromotionsOrDeals)
                          }
                        />
                        <label htmlFor="create_promotions_or_deals">
                          <h4 className="mt-3 ml-2">
                            Create Promotions / Deals
                          </h4>
                        </label>
                      </div>
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={
                            //   permissions.includes("Set up Payment Account") &&
                            paymentAccount
                          }
                          type="checkbox"
                          name="set_up_payment_account"
                          id="set_up_payment_account"
                          className="checkbox-design"
                          value="set_up_payment_account"
                          onChange={() =>
                            setSetUpPaymentAccount(!paymentAccount)
                          }
                        />
                        <label htmlFor="set_up_payment_account">
                          <h4 className="mt-3 ml-2">Set up Payment Account</h4>
                        </label>
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={
                            // permissions.includes("Reports") &&
                            reports
                          }
                          type="checkbox"
                          name="reports"
                          id="reports"
                          className="checkbox-design"
                          value="reports"
                          onChange={() => setReports(!reports)}
                        />
                        <label htmlFor="reports">
                          <h4 className="mt-3 ml-2">Reports</h4>
                        </label>
                      </div>
                      <div className="d-flex radio1 d-flex align-items-center">
                        <input
                          checked={
                            //   permissions.includes("Transactions & Payouts") &&
                            transactionPayout
                          }
                          type="checkbox"
                          value="transactions_and_payouts"
                          name="transactions_and_payouts"
                          id="transactions_and_payouts"
                          className="checkbox-design"
                          onChange={() =>
                            setTransactionsAndPayouts(!transactionPayout)
                          }
                        />
                        <label htmlFor="transactions_and_payouts">
                          <h4 className="mt-3 ml-2">Transactions & Payouts</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                {!isloading && (
                  <button
                    type="submit"
                    className="btn btn-success send-invite-btn"
                    onClick={handleValidation}
                  >
                    Add
                  </button>
                )}
                {isloading && (
                  <button className="btn btn-success send-invite-btn" disabled>
                    <i className="fas fa-spinner fa-spin mr-2"></i>Add...
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MerchantPrivileges
            merchantsTable={merchantsTable}
            setMerchantTable={setMerchantTable}
            handleChangeMerchantStatusShow={handleChangeMerchantStatusShow}
            handleUpdateMerchantShow={handleUpdateMerchantShow}
            setTotalPage={setTotalPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            currentPage={currentPage}
            setSearch={setSearch}
            search={search}
            loadMerchants={loadMerchants}
            SubMerchants={() => SubMerchants()}
            setLoadMerchants={setLoadMerchants}
          />
        </div>
      </div>
    </>
  );
};
export default ManageLocation;
