import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const OrdersTable = ({
  location,
  orders,
  setCurrentScreen,
  handleDetailedOrderShow,
  handleManageMultipleOrderShow,
  selectedOrders,
  setSelectedOrders,
  setOrderCurrentPage,
  orderTotalPage,
  isorderloading,
}) => {
  const checkBoxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    if (isSelected) {
      setSelectedOrders([...selectedOrders, value]);
    } else {
      var temp = selectedOrders;
      temp = temp.filter((id) => {
        return id !== value;
      });
      temp ? setSelectedOrders(temp) : setSelectedOrders([]);
    }
  };

  const checkAllHandler = () => {
    if (orders?.length === selectedOrders?.length) {
      setSelectedOrders([]);
    } else {
      const cardIds = orders?.map((item) => {
        return item?.id;
      });
      setSelectedOrders(cardIds);
    }
  };

  let selectedOrderIds;
  let selectedOrderValues = [];
  const [disable, setDisable] = useState(false);
  orders?.map((ele, i) => {
    selectedOrderIds = selectedOrders?.includes(ele?.id);
    if (selectedOrderIds === true) {
      selectedOrderValues.push(ele);
    }
  });
  return (
    <>
      {location?.pathname === "/dashboard" ? (
        ""
      ) : (
        <div className="row mb-2">
          <div className="col-12">
            <button className="back-btn" onClick={() => setCurrentScreen(1)}>
              <div className="d-flex">
                <img
                  src="/assets/img/previous.png"
                  alt=""
                  className="img-fluid"
                />
                <h4 className="back-color my-auto ml-2">Back</h4>
              </div>
            </button>
          </div>
        </div>
      )}
      <div className="card card-table-height-1">
        <div className="card-header card-header-margin d-flex justify-content-between">
          <h3 className="my-auto">Orders</h3>
          <input type="text" className="w-50" />
        </div>
        <div className="card-body booking_card">
          <div className="table-responsive">
            {location?.pathname === "/dashboard" ? (
              ""
            ) : (
              <>
                {selectedOrders?.length > 0 && (
                  <button
                    className="btn btn-outline-success mb-3"
                    onClick={(e) =>
                      handleManageMultipleOrderShow(e, selectedOrderValues)
                    }
                  >
                    {" "}
                    Manage Order(s)
                  </button>
                )}
                {selectedOrders?.length === 0 && (
                  <div className="text-danger mb-3">
                    Manage Order(s) by selecting the checkboxes
                  </div>
                )}
              </>
            )}
            <table className="datatable table table-stripped table table-hover table-center mb-0">
              <thead>
                <tr>
                  {location?.pathname === "/dashboard" ? (
                    ""
                  ) : (
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox-design"
                        onChange={checkAllHandler}
                        checked={
                          orders?.length === selectedOrders?.length
                            ? true
                            : false
                        }
                      />
                    </td>
                  )}
                  <th>Product</th>
                  <th>Unit Price</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th>Delivery Option</th>
                  <th>Telephone</th>
                  <th>Date</th>
                  <th>Delivery Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isorderloading ? (
                  <>
                    {orders?.map((ele, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              className={
                                ele?.delivery_status?.toLowerCase() ===
                                "customer_canceled"
                                  ? "checkbox-design3"
                                  : "checkbox-design cursor-pointer"
                              }
                              value={ele?.id}
                              disabled={
                                ele?.delivery_status?.toLowerCase() ===
                                "customer_canceled"
                                  ? true
                                  : false
                              }
                              onChange={checkBoxHandler}
                              checked={selectedOrders?.includes(ele?.id)}
                            />
                          </td>
                          <td>{ele?.product?.name}</td>
                          <td>
                            {ele?.product?.currency}{" "}
                            {ele?.unit_amount?.toFixed(2)}
                          </td>
                          <td>{ele?.quantity}</td>
                          <td>
                            {ele?.product?.currency}{" "}
                            {ele?.gross_amount?.toFixed(2)}
                          </td>
                          <td className="text-capitalize">
                            {ele?.delivery_option}
                          </td>
                          <td>{ele?.delivery_address?.phone_number}</td>
                          <td>{moment(ele?.created_at).format("LLL")}</td>
                          <td>
                            <div>
                              {ele?.delivery_status?.toLowerCase() ===
                                "returned" && (
                                <div className="btn btn-sm bg-dark-light mr-2">
                                  Returned
                                </div>
                              )}
                              {ele?.delivery_status?.toLowerCase() ===
                                "delivered" && (
                                <div className="btn btn-sm bg-success-light mr-2">
                                  Delivered
                                </div>
                              )}
                              {ele?.delivery_status?.toLowerCase() ===
                                "rejected" && (
                                <div className="btn btn-sm bg-danger-light mr-2">
                                  Out of stock
                                </div>
                              )}
                              {ele?.delivery_status?.toLowerCase() ===
                                "customer_canceled" && (
                                <div className="btn btn-sm bg-danger-light mr-2">
                                  Customer Canceled
                                </div>
                              )}
                              {ele?.delivery_status?.toLowerCase() ===
                                "request_waiting" && (
                                <div className="btn btn-sm bg-warning-light mr-2">
                                  Pending
                                </div>
                              )}
                              {ele?.delivery_status?.toLowerCase() ===
                                "ready_for_pick_up" && (
                                <div className="btn btn-sm bg-primary-light mr-2">
                                  Ready for pickup
                                </div>
                              )}
                              {ele?.delivery_status?.toLowerCase() ===
                                "accepted" && (
                                <div className="btn btn-sm bg-success-light1 mr-2">
                                  Accepted
                                </div>
                              )}
                              {ele?.delivery_status?.toLowerCase() ===
                                "transiting" && (
                                <div className="btn btn-sm bg-warning-light1 mr-2">
                                  Transiting
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <div>
                                <button
                                  onClick={(e) =>
                                    handleDetailedOrderShow(e, (i = ele))
                                  }
                                >
                                  <img
                                    src="/assets/img/eye.png"
                                    alt=""
                                    className="cur-pointer"
                                    title="view product order details"
                                  />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <div className="no-data">
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mr-2">
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={".."}
            pageCount={orderTotalPage}
            marginPagesDisplayed={2}
            onPageChange={(e) => setOrderCurrentPage(e.selected + 1)}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  );
};
export default OrdersTable;
