import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
const CustomCardReview = ({
  current2,
  setCurrent1,
  color,
  color1,
  category,
  terms,
  type,
  handlePublish,
  pos,
  amts,
  logo,
  visible,
  title,
  backgroundImage,
  description,
}) => {
  const handle = () => {
    handlePublish();
    setCurrent1(3);
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="float-right arrow-margin-top1">
            <button onClick={() => setCurrent1(1)}>
              <img src="/assets/img/arrow-left.png" alt="" />
            </button>
          </div>
        </div>
        <div className="col-lg-12 mt-4">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div
                style={{
                  width: "345px",
                  height: "200px",
                  boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 15%)",
                  borderRadius: "20px",
                }}
              >
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  {amts.map((ele, i) => (
                    <Carousel.Item>
                      {current2 === 2 && (
                        <>
                          <div
                            className="left-logo"
                            style={{
                              color: color,
                              border: "1px solid",
                              borderRadius: "20px",
                              width: "345px",
                              height: "200px",
                              display: "grid",
                              gridTemplateColumns: "auto auto",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                background: color,
                                width: "99px",
                                position: "relative",
                              }}
                            >
                              <div className="logo-size">
                                <img
                                  src={logo}
                                  alt="First slide"
                                  className="upload-btn-img1"
                                />
                              </div>
                              {visible && (
                                <div
                                  style={{
                                    color: color1,
                                    transition: "ease all 100ms",
                                  }}
                                >
                                  <h4 className="gift-text">e-Gift Card</h4>
                                </div>
                              )}
                            </div>
                            <div>
                              <img
                                src="http://via.placeholder.com/246x200"
                                alt=""
                              />
                              {pos === 1 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    right: "20px",
                                    top: "10px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                              {pos === 3 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    right: "20px",
                                    bottom: "0px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {current2 === 3 && (
                        <>
                          <div
                            className="right-logo"
                            style={{
                              color: color,
                              border: "1px solid",
                              borderRadius: "20px",
                              width: "345px",
                              height: "200px",
                              display: "grid",
                              gridTemplateColumns: "auto auto",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{ width: "243px", position: "relative" }}
                            >
                              <img
                                src="http://placeimg.com/246/200/any"
                                alt=""
                              />
                              {pos === 2 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    left: "10px",
                                    top: "10px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                              {pos === 4 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    left: "10px",
                                    bottom: "0px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                backgroundColor: color,
                                width: "99px",
                                position: "relative",
                              }}
                            >
                              <div className="logo-size">
                                <img
                                  src={logo}
                                  alt="First slide"
                                  className="upload-btn-img4"
                                />
                              </div>
                              {visible && (
                                <div
                                  style={{
                                    color: color1,
                                    transition: "ease all 100ms",
                                  }}
                                >
                                  <h4 className="gift-text">e-Gift Card</h4>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {current2 === 4 && (
                        <>
                          <div
                            className="down-logo"
                            style={{
                              color: color,
                              border: "1px solid",
                              borderRadius: "20px",
                              width: "343px",
                              height: "200px",
                              display: "grid",
                              gridTemplateRows: "auto auto",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{ height: "90px", position: "relative" }}
                            >
                              <img
                                src="http://placeimg.com/343/132/any"
                                alt=""
                              />
                              {pos === 1 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    right: "20px",
                                    top: "10px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                              {pos === 2 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    left: "15px",
                                    top: "10px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                backgroundColor: color,
                                position: "relative",
                              }}
                            >
                              <label htmlFor="change-logo">
                                <div className="logo-size1">
                                  <img
                                    src={logo}
                                    alt="First slide"
                                    className="upload-btn-img-bottom"
                                  />
                                </div>
                              </label>
                              {visible && (
                                <div
                                  style={{
                                    color: color1,
                                    transition: "ease all 100ms",
                                  }}
                                >
                                  <h4 className="gift-text">e-Gift Card</h4>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {current2 === 5 && (
                        <>
                          <div
                            className="up-logo"
                            style={{
                              color: color,
                              border: "1px solid",
                              borderRadius: "20px",
                              width: "343px",
                              height: "200px",
                              display: "grid",
                              gridTemplateRows: "auto auto",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                height: "70px",
                                backgroundColor: color,
                                position: "relative",
                              }}
                            >
                              <div className="logo-size1">
                                <img
                                  src={logo}
                                  alt="First slide"
                                  className="upload-btn-img-bottom"
                                />
                              </div>
                              {visible && (
                                <div
                                  style={{
                                    color: color1,
                                    transition: "ease all 100ms",
                                  }}
                                >
                                  <h4 className="gift-text">e-Gift Card</h4>
                                </div>
                              )}
                            </div>
                            <div
                              style={{ height: "132px", position: "relative" }}
                            >
                              <img
                                src="http://placeimg.com/343/132/any"
                                alt=""
                              />
                              {pos === 3 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    right: "20px",
                                    bottom: "0px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                              {pos === 4 && (
                                <div
                                  style={{
                                    color: color,
                                    transition: "ease all 100ms",
                                    position: "absolute",
                                    left: "15px",
                                    bottom: "0px",
                                  }}
                                >
                                  <h4>
                                    GH¢ <span>{ele}</span>
                                  </h4>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="d-block">
                <div className=" d-flex my-auto justify-content-center">
                  <h4 className="back-img-size my-auto">Background-Image:</h4>
                  <div className="back-con mr-3">
                    <img src={backgroundImage === null ? "/assets/img/default.png" : backgroundImage} alt="" />
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="back-img-siz d-flex">
                    Card Name:{" "}
                    <span className="ml-2 text-secondary">{title}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <h3>Enter Card Amounts in Ghana Cedis</h3>
        </div>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap">
            {amts.map((item, i) => {
              return (
                <input
                  key={i}
                  value={item}
                  disabled
                  className="form-control form-padding2 text-center mb-3 mr-2"
                  type="number"
                  name="amount"
                />
              );
            })}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="col-width mt-3">
            <div className="form-group form-position">
              <label>
                <h3>Category</h3>
              </label>

              <>
                <div
                  className="form-control form-padding4 form-review-padding"
                  id="sel2"
                >
                  <img
                    src="assets/img/arrow-down.png"
                    alt=""
                    className="select-arrow-position"
                  />
                  {category}
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row mt-3">
            <div className="col-12">
              <h3>Retailer Gift Card Terms & Conditions</h3>
            </div>
            <div className="col-12 text-color">
              <h4>Add the terms and conditions for your card</h4>
            </div>
            <div className="col-12">
              <div name="terms" className="textarea-sz">
                {terms}
              </div>
            </div>
            <div className="col-12 mt-3">
              <h3>E-gift Card Description</h3>
            </div>
            <div className="col-12">
              <div name="terms" className="textarea-sz">
                {description}
              </div>
            </div>
            <hr className="hr1" />
            <div className="col-lg-12 d-flex justify-content-between mt-4 mb-3">
              <div className="d-flex mt-3">
                {type === "" ? (
                  <div className="promotion-type"></div>
                ) : (
                  <>
                    <div className="mr-2">
                      <img src="assets/img/lovely.png" alt="" />
                    </div>
                    <div className="promotion-type">
                      <h4 className="my-auto">
                        Promotion:{" "}
                        <span className="text-capitalize">{type}</span>
                      </h4>
                    </div>
                  </>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-success btn-block review-btn mt-2"
                  onClick={() => handle()}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomCardReview;
