import "./App.css";
import Home from "./pages/Dashboard";
import Merchants from "./pages/Merchants";
import MarketPlace from "./pages/MarketPlace";
import LiveShop from "./pages/LiveShop";
import Resource from "./pages/Resource";
import CustomerCare from "./pages/CustomersCare";
import Settings from "./pages/Settings";
import { Route, Routes, useNavigate } from "react-router-dom";
import MerchantLogin from "./pages/MerchantLogin";
import Signup from "./pages/Signup";
import axios from "axios";
import { useEffect, useState } from "react";
import Marketing from "./pages/Marketing";
import $ from "jquery";
import Reports from "./pages/Reports";
import Coupons from "./pages/Coupons.js";
import ResetPassowrd from "./pages/ResetPassword";
window.jQuery = $;

function App() {
  axios.defaults.headers.post["Accept"] = "application/json";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Da-Secret"] = "pCweb";
  // axios.defaults.baseURL = `https://api.20pesewas.com`;
  // axios.defaults.baseURL = `https://2acc-154-160-24-252.ngrok-free.app`;
  axios.defaults.baseURL = `https://api.primeegiftcard.com`;
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  const navigate = useNavigate();
  const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<MerchantLogin />}></Route>
        <Route path="/resetpassword" element={<ResetPassowrd />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route path="/merchants" element={<Merchants />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        <Route path="/liveshop" element={<LiveShop />} />
        <Route path="/resource" element={<Resource />} />
        <Route path="/customercare" element={<CustomerCare />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/marketing" element={<Marketing />}></Route>
        <Route path="/coupons" element={<Coupons />}></Route>
        <Route path="/reports" element={<Reports />}></Route>
      </Routes>
    </>
  );
}

export default App;
