import React from "react";
import Slider from "react-slick";
import { HuePicker } from "react-color";
import Multiselect from "multiselect-react-dropdown";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { uploadBulkImages } from "../../UploadImageFolder/uploadBulkImages";

function RestaurantUpload({
  setProductSummaries,
  setProducts,
  onResCatSelect,
  onResCatRemoved,
  supportedCurrencies,
  resCategories,
  setCurrentScreen,
  resCats,
  handleUserEntry,
  userEntry,
  resProductSizes,
  onSizeSelect,
  onSizeRemoved,
  sizes,
  setUserEntry,
  isChecked,
  setIsChecked,
  setFocusedIndex,
}) {
  const settings = {
    infinite: true,
    autoplay: false,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };
  const [newImage, setNewImage] = useState(null);

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };

  let data = new FormData();
  const sliderRef = useRef(null);
  const [isloading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  let multiSelectRef = useRef();
  const onSelectFile = async (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      data.append("files[]", file);
      return URL.createObjectURL(file);
    });
    let image = await uploadBulkImages(data);
    setNewImage(image.data.files);
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("name", userEntry.product_name);
    resCats?.map((ele) => {
      data.append("category_ids[]", ele?.id);
    });
    newImage?.map((ele) => {
      data.append("pictures[]", ele?.url);
    });

    sizes?.map((ele) => {
      data.append("custom_sizes[]", ele);
    });
    data.append("has_custom_sizes", true);

    data.append("price", userEntry.product_price);
    data.append("description", userEntry.product_description);

    // data.append("quantity", userEntry.product_quantity);

    data.append("currency", userEntry.product_currency);

    if (selectedImages.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please upload product image(s)",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else if (resCats?.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Product category is required",
        icon: "error",
        button: "OK",
      });
      setLoading(false);
    } else {
      axios
        .post("/market.place/v1/products/add", data)
        .then((res) => {
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: "Product Uploaded Successfully",
            icon: "success",
            button: "OK",
          }).then((isOkay) => {
            if (isOkay) {
              setUserEntry({
                product_name: "",
                categories: [],
                productSizes: [],
                product_price: "",
                product_description: "",
                // product_quantity: "",
                product_currency: "",
              });
              setFocusedIndex(0);
              setIsChecked(false);
              axios
                .get("/market.place/v1/products/list")
                .then((res) => {
                  setProducts(res.data.data.products);
                  setCurrentScreen(1);
                })
                .catch((err) => {
                  console.log(err.response);
                });

              axios
                .get("/market.place/v1/reports/products-summaries")
                .then((res) => {
                  setProductSummaries(res.data.data.records);
                })
                .catch((err) => {
                  console.log(err.response);
                });
            }
          });
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
          setLoading(false);
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row mx-3 mt-3">
        <div className="col-lg-6 col-pad">
          <div className="row">
            <div className="col-lg-12 col-pad-left">
              <div className="form-group form-position">
                <input
                  type="text"
                  className="form-control form-padding-textbx mb-3"
                  placeholder="Enter Dish Name *"
                  name="product_name"
                  value={userEntry.product_name}
                  onChange={handleUserEntry}
                  required
                />
              </div>
            </div>
            <div className="col-lg-8 col-pad">
              <section className="variable slider">
                <div
                  className={`${
                    selectedImages.length >= 1
                      ? "upload-img-btn1"
                      : "upload-btn1"
                  }`}
                >
                  <div
                    className={`${
                      selectedImages.length > 1
                        ? "d-flex justify-content-between arrows-position"
                        : "d-lg-none d-none"
                    }`}
                  >
                    <img
                      src="/assets/img/arrow-left1.png"
                      alt=""
                      onClick={() => sliderRef.current.slickPrev()}
                      className="cur-pointer cur-width"
                    />
                    <img
                      src="/assets/img/arrow-right2.png"
                      alt=""
                      onClick={() => sliderRef.current.slickNext()}
                      className="ms-3 cur-pointer cur-width"
                    />
                  </div>
                  <Slider ref={sliderRef} {...settings}>
                    {selectedImages &&
                      selectedImages.map((image, index) => {
                        return (
                          <div className="img-kk" key={image}>
                            <img
                              src={image}
                              alt=""
                              className="upload-btn-img2"
                            />
                            <input
                              type="button"
                              onClick={() =>
                                setSelectedImages(
                                  selectedImages.filter((e) => e !== image)
                                )
                              }
                              className="btn bg-danger-light delete-img"
                              value="delete"
                            />
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </section>
            </div>
            <div className="col-lg-4 col-pad">
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <select
                    name="product_currency"
                    value={userEntry.product_currency}
                    onChange={handleUserEntry}
                    className="form-control form-padding-textbx mb-3"
                    required
                  >
                    <option value="">Currency *</option>
                    {supportedCurrencies?.map((supportedCurrency, index) => (
                      <option
                        value={supportedCurrency}
                        key={index}
                        className="text-capitalize"
                      >
                        {supportedCurrency}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/arrow-down.png"
                    alt=""
                    className="category-arrow-pos"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Price *"
                    value={userEntry.product_price}
                    name="product_price"
                    onChange={handleUserEntry}
                    required
                  />
                </div>
              </div>
              {/* <div className="col-lg-12 col-pad">
                <div className="form-group form-position">
                  <input
                    type="number"
                    className="form-control form-padding-textbx mb-3"
                    placeholder="Quantity *"
                    value={userEntry.product_quantity}
                    name="product_quantity"
                    onChange={handleUserEntry}
                    required
                  />
                </div>
              </div> */}
            </div>
            <div className="col-lg-12 col-pad-left">
              <div className="col-display-small note-text">
                <label
                  htmlFor="upload-image3"
                  className="btn-upload-size1 btn btn-success pt-2 mt-3"
                >
                  <img
                    src="/assets/img/upload-bg-logo.png"
                    alt=""
                    className="mr-2 my-auto"
                  />
                  Upload Image
                </label>
                <input
                  type="file"
                  id="upload-image3"
                  onChange={(e) => {
                    onSelectFile(e);
                  }}
                  multiple
                  accept="image/"
                  name="product_image"
                />
                <h5 className="mb-3">
                  <span>Note:</span> you can upload more than one image of
                  product
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-pad-right">
          <div className="row">
            <div className="col-lg-12 col-pad-right">
              <div className="form-group form-position">
                <Multiselect
                  className="form-control form-padding-textbx mb-3"
                  ref={multiSelectRef}
                  onSelect={onResCatSelect}
                  onRemove={onResCatRemoved}
                  required
                  options={resCategories}
                  displayValue="name"
                  placeholder="Category *"
                />
                <img
                  src="/assets/img/arrow-down.png"
                  alt=""
                  className="category-arrow-pos"
                />
              </div>
            </div>
            <div className="col-lg-12 col-pad-right">
              <label htmlFor="check1" className="label-style">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  id="check1"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <h4 className="label-size">Add Product Size</h4>
              </label>
            </div>
            {isChecked && (
              <div className="col-lg-12 col-pad-right">
                <div className="form-group form-position">
                  <Multiselect
                    className="form-control form-padding-textbx mb-3"
                    options={resProductSizes}
                    isObject={false}
                    required
                    placeholder="Product Size"
                    onSelect={onSizeSelect}
                    onRemove={onSizeRemoved}
                  />
                  <img
                    src="/assets/img/arrow-down.png"
                    alt=""
                    className="category-arrow-pos"
                  />
                </div>
              </div>
            )}
            <div className="col-lg-12 col-pad-right">
              <textarea
                placeholder="Enter Product Description *"
                className={
                  isChecked
                    ? "textarea-size10-active11 form-control"
                    : "textarea-size11 form-control"
                }
                value={userEntry.product_description}
                name="product_description"
                onChange={handleUserEntry}
                required
              ></textarea>
            </div>
          </div>
        </div>

        <div className="w-100 mt-1 mb-4 mt-1">
          {!isloading && (
            <input
              type="submit"
              className="btn btn-success w-100"
              value="Upload product"
            />
          )}
          {isloading && (
            <button className="btn btn-success w-100" disabled>
              <i className="fas fa-spinner fa-spin mr-2"></i>Uploading...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default RestaurantUpload;
