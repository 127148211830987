import { useState } from "react";
import { Modal } from "react-bootstrap";

const EnableMarketPlaceModal = ({
  activate,
  handleEnableMarketPlaceModalClose,
}) => {
  return (
    <Modal show={activate} onHide={handleEnableMarketPlaceModalClose}>
      <Modal.Header closeButton>
        <div className="w-100">
          <div className="float-right">
            <button onClick={handleEnableMarketPlaceModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="message-content">
                <h4>
                  This service is currently disabled. Kindly click on the <span className="text-success"> enable button</span> to enjoy this service.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row justify-content-center mt-4">
          <div className="col-lg-6">
            <div className="text-center">
            <button className="btn btn-success w-50" onClick={handleEnableMarketPlaceModalClose}>Enable</button>
          </div>
          </div>
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EnableMarketPlaceModal;
