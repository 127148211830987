import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import SettingsHeader from "../components/SettingsHeader";
import swal from "sweetalert";
import PersonalInfo from "./settingsComponents/PersonalInfo";
import BusinessInfo from "./settingsComponents/BusinessInfo";
import Account from "./settingsComponents/Account";
import Credentials from "./settingsComponents/Credentials";
import LocationModal from "./settingsComponents/LocationModal";
import ManagementInfo from "./settingsComponents/ManagementInfo";
import UpdateUserModal from "./settingsComponents/managementinfoComponent/UpdateUserModal";
import { useState, useEffect } from "react";
import axios from "axios";
import ProfileSideTab from "./settingsComponents/partials/ProfileSideTab";
import GeneralSettingsTab from "./settingsComponents/partials/GeneralSettingsTab";
import { uploadToBucket } from "./UploadImageFolder/uploadToBucket";
import SettingsAuthModal from "./settingsComponents/SettingsAuthModal";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Settings = ({ profileimage }) => {
  const [userInput, setUserInput] = useState({});
  const [person, setPerson] = useState({});
  const [company, setCompany] = useState({});
  const [cordinates, setCordinates] = useState({});
  const [isPersonalLoading, setPersonalLoading] = useState(false);
  const [isBusinessLoading, setBusinessLoading] = useState(false);
  const [isProfileloading, setProfileLoading] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [showSettingsAuthModal, setShowSettingsAuthModal] = useState("");
  const navigate = useNavigate();

  const ActivateButtonLocation = useLocation();
  useEffect(() => {
    setShowSettingsAuthModal(ActivateButtonLocation.state);
  }, []);

  const handleSettingsAuthModalClose = () => {
    setShowSettingsAuthModal(false);
    navigate("/dashboard");
  };

  const loadMerchantDetails = () => {
    setDetailsLoading(false);
    axios
      .get("/prime.sika/v1/users/merchant/details")
      .then((res) => {
        setDetailsLoading(true);
        setUserInput(res.data.data.user_details);
        setPerson(res.data.data.user_details.person);
        setCompany(res.data.data.user_details.person.companies[0]);
        setCordinates(
          res.data.data.user_details.person.companies[0].cordinates
        );
      })
      .catch((err) => {
        setDetailsLoading(false);
        console.log(err.response);
      });
  };

  useEffect(() => {
    loadMerchantDetails();
  }, []);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePersonUserInput = (e) => {
    setPerson((person) => ({
      ...person,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCompanyUserInput = (e) => {
    setCompany((company) => ({
      ...company,
      [e.target.name]: e.target.value,
    }));
  };

  const [location, setlocation] = useState({
    lng: "",
    lat: "",
    address: "",
  });

  useEffect(() => {
    setlocation((location) => ({
      ...location,
      lat: cordinates?.latitude,
      lng: cordinates?.longitude,
      address: cordinates?.address,
    }));
  }, [cordinates]);

  const getMapLocation = (lngParam, latParam, address) => {
    setlocation((location) => ({
      ...location,
      lng: lngParam,
      lat: latParam,
      address: address,
    }));
  };

  const handleCordinatesChange = (e) => {
    setCordinates((cordinates) => ({
      ...cordinates,
      [e.target.name]: e.target.value,
    }));
  };

  const updateLogo = (e) => {
    e.preventDefault();
    var data = {
      logo_url: newImage,
      cordinates: {
        latitude: location?.lat,
        longitude: location?.lng,
        address: location?.address,
      },
    };
    setProfileLoading(true);
    axios
      .put(
        `/prime.sika/v1/accounts/merchant/profile?company_code=${localStorage.getItem(
          "company_code"
        )}`,
        data
      )
      .then((res) => {
        setProfileLoading(false);
        swal({
          title: "Success",
          text: "Logo Updated Successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            loadMerchantDetails();
          }
        });
      })
      .catch((err) => {
        console.log(err.response);
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
        setProfileLoading(false);
      });
  };

  const updatePersonalDetails = (e) => {
    e.preventDefault();
    var data = {
      email: company.email,
      user: {
        first_name: person.first_name,
        surname: person.surname,
        telephone: userInput.phone_number,
        username: userInput.username,
      },
      cordinates: {
        latitude: location?.lat,
        longitude: location?.lng,
        address: location?.address,
      },
    };
    setPersonalLoading(true);
    axios
      .put(
        `/prime.sika/v1/accounts/merchant/profile?company_code=${localStorage.getItem(
          "company_code"
        )}`,
        data
      )
      .then((res) => {
        setPersonalLoading(false);
        //Show Sweet Alert Here
        Swal.fire({
          title: "Success",
          text: "Details Updated Successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            loadMerchantDetails();
          }
        });
      })
      .catch((err) => {
        console.log(err.response);
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
        setPersonalLoading(false);
      });
  };

  const updateBusinessDetails = (e) => {
    e.preventDefault();
    var data = {
      name: company.name,
      email: company.email,
      phone_number: userInput.phone_number,
      branch: company.branch,
      // address: company.address,
      tin_number: company.tin_number,
      description: company.description,
      website_url: company.website_url,
      business_registration_number: company.business_registration_number,
      user: {
        first_name: person.first_name,
        surname: person.surname,
        telephone: userInput.phone_number,
        username: userInput.username,
      },
      cordinates: {
        latitude: location?.lat,
        longitude: location?.lng,
        address: location?.address,
      },
    };
    setBusinessLoading(true);
    axios
      .put(
        `/prime.sika/v1/accounts/merchant/profile?company_code=${localStorage.getItem(
          "company_code"
        )}`,
        data
      )
      .then((res) => {
        setBusinessLoading(false);
        Swal.fire({
          title: "Success",
          text: "Details Updated Successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            loadMerchantDetails();
          }
        });
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
        console.log(err.response);
        setBusinessLoading(false);
      });
  };

  const hn = (e) => {
    e.preventDefault();
    swal("Are you sure you want to do this?", {
      buttons: ["No", "Yes"],
    });
  };

  const [logoimage, setLogoimage] = useState(null);
  const onImageChange = async (e) => {
    let data = new FormData();
    data.append("file", e.target.files[0]);
    let image = await uploadToBucket(data);

    setNewImage(image.data.url);

    setLogoimage(URL.createObjectURL(e.target.files[0]));
  };

  const [permissions, setPermissions] = useState([]);
  const [reports, setReports] = useState(false);
  const [manageAccount, setManageAccount] = useState(false);
  const [manageGiftCards, setManageGiftCards] = useState(false);
  const [createPromotionsOrDeals, setCreatePromotionsOrDeals] = useState(false);
  const [paymentAccount, setSetUpPaymentAccount] = useState(false);
  const [transactionPayout, setTransactionsAndPayouts] = useState(false);

  const saveData = () => {
    const data = {
      name: userInput.merchantname || userInput.merchantname1,
      branch: location?.address,
      telephone: userInput.telephone || userInput.telephone1,
      cordinates: {
        address: location?.address,
        latitude: location?.lat,
        longitude: location?.lng,
      },
      persmission: {
        manage_account: manageAccount,
        manage_gift_cards: manageGiftCards,
        reports: reports,
        promotions: createPromotionsOrDeals,
        payment: paymentAccount,
        transaction: transactionPayout,
      },
    };
    setLoading(true);
    axios
      .post("/prime.sika/v1/managers/merchants/initiate-signup", data)
      .then((res) => {
        swal({
          title: "Success",
          text: "Merchant added successfully",
          icon: "success",
          button: "OK",
        }).then((isOkay) => {
          if (isOkay) {
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        if (err.response.data.status === 400) {
          swal({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
          setLoading(false);
        }
      });
  };
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <SettingsHeader />
            <GeneralSettingsTab />
            <div className="row">
              <div className="col-lg-11">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="card card-back tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row">
                      <ProfileSideTab />
                      <div className="col-lg-9">
                        <div className="tab-content" id="pills-tabContent">
                          <PersonalInfo
                            userInput={userInput}
                            person={person}
                            handleUserInput={handleUserInput}
                            handlePersonUserInput={handlePersonUserInput}
                            updatePersonalDetails={updatePersonalDetails}
                            handleCompanyUserInput={handleCompanyUserInput}
                            company={company}
                            isPersonalLoading={isPersonalLoading}
                            setPersonalLoading={setPersonalLoading}
                            detailsLoading={detailsLoading}
                          />
                          <BusinessInfo
                            person={person}
                            company={company}
                            cordinates={cordinates}
                            handleCompanyUserInput={handleCompanyUserInput}
                            handleCordinatesChange={handleCordinatesChange}
                            location={location}
                            setlocation={setlocation}
                            updateBusinessDetails={updateBusinessDetails}
                            updateLogo={updateLogo}
                            logoimage={logoimage}
                            setLogoimage={setLogoimage}
                            onImageChange={onImageChange}
                            isBusinessLoading={isBusinessLoading}
                            setBusinessLoading={setBusinessLoading}
                            setProfileLoading={setProfileLoading}
                            isProfileloading={isProfileloading}
                            detailsLoading={detailsLoading}
                          />
                          <Account />
                          <Credentials />
                        </div>
                      </div>
                    </div>
                  </div>
                  {localStorage?.getItem("client_type") === "franchise" ? (
                    <ManagementInfo
                      userInput={userInput}
                      handleUserInput={handleUserInput}
                      location={location}
                      setlocation={setlocation}
                      saveData={saveData}
                      setPermissions={setPermissions}
                      setReports={setReports}
                      setManageAccount={setManageAccount}
                      setManageGiftCards={setManageGiftCards}
                      setCreatePromotionsOrDeals={setCreatePromotionsOrDeals}
                      setSetUpPaymentAccount={setSetUpPaymentAccount}
                      setTransactionsAndPayouts={setTransactionsAndPayouts}
                      reports={reports}
                      manageAccount={manageAccount}
                      manageGiftCards={manageGiftCards}
                      createPromotionsOrDeals={createPromotionsOrDeals}
                      paymentAccount={paymentAccount}
                      transactionPayout={transactionPayout}
                      isloading={isloading}
                      setLoading={setLoading}
                    />
                  ) : (
                    ""
                  )}
                  <div
                    className="card card-back tab-pane fade"
                    id="pills-reports"
                    role="tabpanel"
                    aria-labelledby="pills-reports-tab"
                  >
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="card card-chart2 z-position card-h10">
                          <div className="px-lg-2 px-sm-3 pt-4">
                            <h3>Action</h3>
                          </div>
                          <ul
                            className="nav d-block sidetab-font"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <a
                                className="btn btn-outline-success tab-btn px-lg-2 px-sm-3 active"
                                id="pills-payout-tab1"
                                data-toggle="pill"
                                href="#pills-payout1"
                                role="tab"
                                aria-controls="pills-payout1"
                                aria-selected="true"
                              >
                                Change Merchant Type
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-payout1"
                            role="tabpanel"
                            aria-labelledby="pills-payout-tab1"
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="card card-chart2 card-h10 card-h10-pad">
                                  <div>
                                    <h3>Merchant Information</h3>
                                    <hr className="hr3" />
                                  </div>
                                  <div>
                                    <h4>
                                      <span>
                                        {" "}
                                        You can change your merchant status via
                                        <b className="merch-color ml-1">
                                          Prime Merchant Type Settings
                                        </b>
                                      </span>
                                      <b>Note: </b>Changing your merchant status
                                      will affect some functionalities by the
                                      current merchant status
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <form
                                  className="card card-chart2 card-h11 card-h10-pad"
                                  onSubmit={hn}
                                >
                                  <div>
                                    <h3>Merchant Status</h3>
                                    <hr className="hr3" />
                                  </div>
                                  <div className="ml-4">
                                    <div className="radio-group1 mt-4 d-flex">
                                      <div className="">
                                        <label
                                          htmlFor="single_merchant"
                                          className="radio1 d-flex align-items-center"
                                        >
                                          <input
                                            type="radio"
                                            name="radio-phone"
                                            id="single_merchant"
                                            className="radio__input mr-1"
                                            defaultChecked
                                          />
                                          <div className="radio__radio1"></div>
                                          <h4 className="my-auto">
                                            Single Merchant
                                          </h4>
                                        </label>
                                        <label
                                          htmlFor="franchise_merchant"
                                          className="radio1 d-flex align-items-center mt-4"
                                        >
                                          <input
                                            type="radio"
                                            name="radio-phone"
                                            id="franchise_merchant"
                                            className="radio__input mr-1"
                                          />
                                          <div className="radio__radio1"></div>
                                          <h4 className="my-auto">
                                            Franchise Merchant
                                          </h4>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mt-5">
                                      <button
                                        type="submit"
                                        className="btn btn-success float-right save-btn-size"
                                        value="Save"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LocationModal getMapLocation={getMapLocation} />
      <UpdateUserModal />
      <SettingsAuthModal
        handleSettingsAuthModalClose={handleSettingsAuthModalClose}
        showSettingsAuthModal={showSettingsAuthModal}
        setShowSettingsAuthModal={setShowSettingsAuthModal}
      />
    </>
  );
};
export default Settings;
