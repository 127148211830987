import React, { useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import PageHeader from '../components/PageHeader';
import TransactionTable from './merchantComponents/TransactionTable';
import LiveShopStats from './liveshopComponents/LiveShopStats';
import LiveShopProductTable from './liveshopComponents/LiveShopProductTable';
import ProfileEdit from './liveshopComponents/ProfileEdit';
import LiveShopSubscription from './liveshopComponents/LiveShopSubscription';
import LiveShopCustomersTable from './liveshopComponents/LiveShopCustomersTable';
import GoLiveStream from './liveshopComponents/GoLiveStream';
const LiveShop = () =>{
	const [imageUpload, setImageUpload] = useState("");
        return (
            <>
				<div className="main-wrapper">
					<Header/>
					<Sidebar/>
					<div className="page-wrapper">
						<div className="content container-fluid">
							<PageHeader/>
							<div className="row">
								<div className="col-lg-8">
									<div className="row">
										<LiveShopStats/>
										<div className="col-lg-12">
											 <GoLiveStream
												imageUpload={imageUpload}
												setImageUpload={setImageUpload}
											/>
										</div>
										<div className="col-lg-12">
											<LiveShopProductTable/>
										</div>
										<div className="col-lg-12">
											<TransactionTable/>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="row">
										<div className="col-lg-12">
											<ProfileEdit/>
										</div>
										<div className="col-lg-12">
											<LiveShopSubscription/>
										</div>
										<div className="col-lg-12">
											<LiveShopCustomersTable/>
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
            </>
        )
    }
export default LiveShop;