import axios from "axios";
import { useState } from "react";
import swal from "sweetalert";

const CustomerContactForm = () => {
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    telephone: "",
    title: "",
    message: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      message: userInput.message,
      title: userInput.title,
      name: userInput.name,
      telephone: userInput.telephone,
      email: userInput.email,
    };
    setLoading(true);
    axios
      .post("/prime.sika/v1/contact-us/send", data)
      .then((res) => {
        setLoading(false);

        if (res.data.status === 200 && res.data.success === true) {
          swal({
            title: "Success",
            text: "Your message has successfully been sent to our customer support center. We will attend to you as soon as possible. Thank You!",
            // timer: 5000,
            icon: "success",
            confirmButtonColor: "#338540",
          });

          setUserInput((userInput) => ({
            ...userInput,
            name: "",
            email: "",
            telephone: "",
            title: "",
            message: "",
          }));
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          // timer: 5000,
          icon: "error",
          confirmButtonColor: "#338540",
        });
        setLoading(false);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="write-font">
        <h2>Write to us</h2>
      </div>
      <div className="write-font">
        <h4>Send us message and we'll get back to you as soon as possible.</h4>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group form-star">
            <label htmlFor="">
              Name<span>*</span>
            </label>
            <input
              type="text"
              class="form-control form-fill-textbox"
              placeholder="Enter your name here"
              name="name"
              value={userInput.name}
              onChange={handleUserInput}
              required
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group form-star">
            <label htmlFor="">
              Email<span>*</span>
            </label>
            <input
              type="text"
              class="form-control form-fill-textbox"
              placeholder="Enter your email here"
              name="email"
              value={userInput.email}
              onChange={handleUserInput}
              required
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="form-group form-star">
            <label htmlFor="">
              Phone <span>*</span>
            </label>
            <input
              type="text"
              class="form-control form-fill-textbox"
              placeholder="Enter your phone number here"
              name="telephone"
              value={userInput.telephone}
              onChange={handleUserInput}
              required
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group form-star">
            <label htmlFor="">
              Title<span>*</span>
            </label>
            <input
              type="text"
              class="form-control form-fill-textbox"
              placeholder="Enter your no. here"
              name="title"
              value={userInput.title}
              onChange={handleUserInput}
              required
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="form-group form-star">
            <label htmlFor="">
              Message<span>*</span>
            </label>
            <textarea
              name="message"
              id=""
              placeholder="Type your message here"
              class="textarea-size7 form-control"
              required
              value={userInput.message}
              onChange={handleUserInput}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="form-group form-star">
            {!isloading && (
              <button
                type="submit"
                className="btn btn-outline-success send-btn"
              >
                Send
              </button>
            )}
            {isloading && (
              <button className="btn btn-outline-success send-btn" disabled>
                <i className="fas fa-spinner fa-spin mr-2"></i>Sending...
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
export default CustomerContactForm;
