import React, { useState, useEffect } from 'react';
import axios from 'axios';
const UpdateDeals = ({ handleAbout, handleEnd, handleName, handleStart, handleDiscount, handlePercent, card }) => {
  const [userDetails,setUserDetails]=useState([]);
	useEffect(()=>{
		axios.get('/prime.sika/v1/users/merchant/details').then(res=>{
			setUserDetails(res.data.data.user_details);
		  }).catch(err=>{
			console.log(err.response)
		  })
    },[])
  return (
    <div
      className="tab-pane fade"
      id="pills-deals"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="form-group d-flex mt-3 display-sm-block">
        <span>
          <input
            onChange={(e) => handleName(e.target.value)}
            className="form-control amount-textbox mr-3 dt-mb"
            type="text"
            placeholder="DealsName"
          />
        </span>
        <span>
          <input
            onChange={(e) => handleStart(e.target.value)}
            className="form-control date-textbox"
            type="date"
            placeholder="Start Date"
          />
          <input
            onChange={(e) => handleEnd(e.target.value)}
            className="form-control date-textbox1"
            type="date"
            placeholder="End Date"
          />
        </span>
      </div>
      <div className="row mt-5">
        <div className="col-lg-7">
          <div className="row">
            <div className="col-lg-12">
              <a href="/merchants" className="btn btn-success logo-upload-btn">
                <img
                  src="assets/img/gallery-export.png"
                  alt=""
                  className="gallery-img"
                />{" "}
                Upload Your Logo
              </a>
            </div>
            <div className="col-lg-12">
              <div className="radio-group1 mt-4">
                <div className="d-flex">
                  <label
                    htmlFor="myRadio2"
                    className="radio1 d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="myRadio2"
                      className="radio__input mr-1"
                    />
                    <div className="radio__radio1"></div>
                    <h4 className="my-auto">Discount</h4>
                  </label>
                  <input type="text"
                    className="form-control amount-textbox discount-ml"
                    placeholder="5% Discount on every sale"
                    onChange={(e) => handlePercent(e.target.value)}
                  />
                </div>
                <div className="d-flex mt-3">
                  <label
                    htmlFor="myRadio3"
                    className="radio1 d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="radio-phone"
                      id="myRadio3"
                      className="radio__input mr-1"
                    />
                    <div className="radio__radio1"></div>
                    <h4 className="my-auto">Special</h4>
                    <input type="text"
                      className="form-control amount-textbox4 buy-ml"
                      placeholder="Buy one get 2nd half price"
                      onChange={(e) => handleDiscount(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div
            className="logo-preview-btn mt-4"
          >
           <img src={userDetails?.person?.companies[0]?.logo_url} alt="" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <h3>About the Deal</h3>
        </div>
        <div className="col-12 text-color">
          <h4>What is the deal about</h4>
        </div>
        <div className="col-12">
          <textarea
            onChange={(e) => handleAbout(e.target.value)}
            name=""
            id=""
            placeholder="Brief information about the deal"
            className="textarea-size5"
          ></textarea>
        </div>
      </div>
    </div>
  );
};
export default UpdateDeals;
