import { Modal } from "react-bootstrap";

const UpdateProductCategoryModal = ({
  handleUpdateProductCategoryModalClose,
  updateSubCategories,
  handleUpdateCheck,
  saveUpdateChecked,
  showUpdateProductCategoryModal,
  handleUpdateParentCheck,
  updatSelectedParent,
  handleUpdateChildCheck,
  setUpdateFinalChecked,
  updateProductCat,
  deleteUpdateParentCat,
  setUpdateCheckedParent,
  updateSelectCatids,
  setUpdateSelectedParent
}) => {
  const handleSkip = () => {
    var temp = updatSelectedParent;

    var deleteUpdateSelected = deleteUpdateParentCat?.filter((value) => {
      return updateSelectCatids?.find((val) => {
        return value === val;
      });
    });
    deleteUpdateSelected.map((ele) => {
      temp.delete(ele);
    });
    var tempCat = [];
    for (let [key, value] of temp) {
      tempCat.push(key);

      tempCat.push(...value);
    }
    
    setUpdateSelectedParent(temp);
    setUpdateFinalChecked([...tempCat, ...updateProductCat]);
    handleUpdateProductCategoryModalClose();
  };
  return (
    <Modal show={showUpdateProductCategoryModal} onHide={handleSkip}>
      <Modal.Header closeButton>
        {/* <Modal.Title> */}
        <div className="d-flex justify-content-between w-100">
          <div>
            <h4 className="mt-3">Product Sub-categories</h4>
          </div>
        </div>
        {/* </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="product-list-font">
          <h4>
            Your product might also fall under these categories. Please select
            option(s) which best fit your product
          </h4>
        </div>
        <ul className="pl-0 mt-4">
          {updateSubCategories?.sub_categories?.map(
            (list, index, parentCode, childCode) => (
              <li key={[index?.id, parentCode]} className="mb-3">
                <div className="d-flex  check-text-size">
                  <input
                    type="checkbox"
                    id={list?.code}
                    value={list?.id}
                    className="checkbox-design radio_check"
                    onChange={(e) => {
                      handleUpdateCheck(
                        parseInt(e.target.value),
                        e.target.checked
                      );
                      handleUpdateParentCheck(parseInt(list?.id), null);
                    }}
                    checked={
                      updatSelectedParent.has(parseInt(list?.id)) ? true : false
                    }
                  />
                  <h4 className="ml-2">{list?.name}</h4>
                </div>
                <ul>
                  {list?.sub_categories?.map((sublist, i) => (
                    <li key={i}>
                      <div className="d-flex  check-text-size">
                        <input
                          type="checkbox"
                          id={sublist?.code}
                          value={sublist?.id}
                          onChange={(e) => {
                            handleUpdateChildCheck(
                              [parseInt(e.target.value), parseInt(list?.id)],
                              e.target.checked
                            );
                            handleUpdateParentCheck(
                              parseInt(list?.id),
                              parseInt(sublist?.id)
                            );
                          }}
                          checked={
                            updatSelectedParent
                              .get(list?.id)
                              ?.includes(sublist?.id)
                              ? true
                              : false
                          }
                          className="checkbox-design radio_check"
                        />
                        <h4 className="ml-2 sublist-font">{sublist?.name}</h4>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            )
          )}
        </ul>
        <div className="d-flex justify-content-between">
          <div className="">
            <button className="btn btn-danger" onClick={handleSkip}>
              Cancel
            </button>
          </div>
          <div className="">
            <button className="btn btn-success" onClick={saveUpdateChecked}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UpdateProductCategoryModal;
