import axios from "axios";
import { useState, useEffect } from "react";
import Slider from "react-slick";

const UpdateGiftCardGallery = ({ setTemplateUrl, setTemplateSelected, handleCategory}) => {
  const settings = {
    dots: true,
    infinite: true,
    variableWidth: true,
    autoplay: true,
    nextArrow: (
      <img
        src="assets/img/arrow.png"
        alt=""
        className="arrow-position1 slick-next"
      />
    ),
  };

  // This loads all templates
  const [templates, setTemplates] = useState([]);
  const [category, setCategory] = useState("");

  useEffect(() => {
    axios
      .get("/prime.sika/v1/cards/template_categories")
      .then((res) => {
        setTemplates(res.data.data.templates);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="card card-chart1 z-position card-height-9">
          <div className="row">
            <div className="col-12">
              <h3>e-Gift Card Gallery</h3>
            </div>
            <div className="col-12 text-color">
              <h4>
                below are saved e-gift card designs uploaded, to reuse select
                the image and publish
              </h4>
            </div>
          </div>
          <section className="slider variable">
            <Slider {...settings}>
              {templates
                .filter((item) => item?.gallery.length >= 0)
                .map((item) =>
                  item?.gallery?.map((image, index) => (
                    <div 
                    onClick={() => handleCategory({item: item, img: image?.image})}
                    >
                      <label
                        htmlFor={`mycard${index}Radio`}
                        className="radio d-flex align-items-center"
                      >
                        <input
                          type="radio"
                          name="signup"
                          id={`mycard${index}Radio`}
                          className="radio__input1 mr-2"
                        />
                        <div className="radio__radio2">
                          <span className="check-icon">
                            <span className="icon"></span>
                          </span>
                          <img
                            src={image?.image}
                            alt=""
                            className="gallery-position"
                          />
                        </div>
                      </label>
                    </div>
                  ))
                )}
            </Slider>
          </section>
        </div>
      </div>
    </div>
  );
};

export default UpdateGiftCardGallery;
