import Affiliate from "./affiliateComponents/Affiliate";
import AffiliateLoginCredentials from "./affiliateComponents/SetLoginCredentialsForm";
import ThankUPage from "./affiliateComponents/ThankUPage";
import AccountVerificationForm from "./affiliateComponents/AccountVerificationForm";
import { useState } from "react";

const AffiliateSignUp = ({ current, setCurrent }) => {
  const [affiliateScreen, setAffiliateScreen] = useState(1);

  return (
    <div>
      {affiliateScreen === 1 && (
        <Affiliate
          affiliateScreen={affiliateScreen}
          setAffiliateScreen={setAffiliateScreen}
          setCurrent={setCurrent}
          current={current}
        />
      )}
      {affiliateScreen === 2 && (
        <AffiliateLoginCredentials
          affiliateScreen={affiliateScreen}
          setAffiliateScreen={setAffiliateScreen}
        />
      )}
      {affiliateScreen === 3 && (
        <AccountVerificationForm
        setAffiliateScreen={setAffiliateScreen}
        affiliateScreen={affiliateScreen}
        />
      )}
      {affiliateScreen === 4 && (
        <ThankUPage/>
      )}
    </div>
  );
};

export default AffiliateSignUp;
