import { useState, useEffect, createRef } from "react";
import Amounts from "./mainCardComponents/Amounts";
import Category from "./mainCardComponents/Category";
import ImageUpload1 from "./customCardComponents/ImageUpload1";
import Promotion from "./mainCardComponents/Promotion";
import Terms from "./mainCardComponents/Terms";
import swal from "sweetalert";
import axios from "axios";
import CustomCardReview from "./customCardComponents/CustomCardReview";
import CustomSuccess from "./customCardComponents/CustomSuccess";
import Promotions1 from "./customCardComponents/promotion1Components/Promotions1";
import Description from "./mainCardComponents/Description";
const CustomCard = ({ current1, setCurrent1 }) => {
  const [category, setCategory] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [image, setImage] = useState(null);
  const [backimage, setBackImage] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tc, setTc] = useState("");
  const [amount, setAmount] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [pos, setPos] = useState(1);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [amts, setAmts] = useState([]);
  const [amountTextbox, setAmountTextbox] = useState([createRef()]);
  const [show, toggle] = useState(false);
  const [newLogo, setNewLogo] = useState(null);
  const [newBgImage, setNewBgImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [color, setColor] = useState("#0c7dbc");
  const [color1, setColor1] = useState("#fff");
  const [current2, setCurrent2] = useState(1);
  const [visible, setVisible] = useState(true);
  // const [promoState, setPromoState]= useState(card?.is_promotion)

  const [posLeftBottom, setLeftBottom] = useState(["bottom", "left"]);
  const [posRightBottom, setRightBottom] = useState(["bottom", "right"]);
  const [posLeftTop, setLeftTop] = useState(["top", "left"]);
  const [posRightTop, setRightTop] = useState(["top", "right"]);

  const [logoLeftCenter, setLeftCenter] = useState(["center", "left"]);
  const [logoRightCenter, setRightCenter] = useState(["center", "right"]);
  const [logoBottomRight, setBottomRight] = useState(["bottom", "right"]);
  const [logoTopRight, setTopRight] = useState(["top", "right"]);

  var amountPosition = [];
  if (pos === 1) {
    amountPosition = posRightTop;
  } else if (pos === 2) {
    amountPosition = posLeftTop;
  } else if (pos === 3) {
    amountPosition = posRightBottom;
  } else if (pos === 4) {
    amountPosition = posLeftBottom;
  }

  var logoPosition = [];
  if (current2 === 2) {
    logoPosition = logoLeftCenter;
  } else if (current2 === 3) {
    logoPosition = logoRightCenter;
  } else if (current2 === 4) {
    logoPosition = logoBottomRight;
  } else if (current2 === 5) {
    logoPosition = logoTopRight;
  }

  useEffect(() => {
    const data = {
      logo,
      categoryName,
      title,
      tc,
      amts,
    };
    if (
      logo !== null &&
      categoryName !== null &&
      title !== "" &&
      amts.length !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [title, tc, amts, categoryName, logo]);

  const handleValidation = () => {
    if (amts[0] < 25 || amts[1] < 25) {
      swal({
        title: "Error",
        text: "Minimum Amount is GH¢ 25.00",
        icon: "error",
        button: "OK",
      });
    } else {
      setCurrent1(2);
    }
  };
  const handleCatName = (e) => {
    setCategory(e);
    setImage(e.img);
    setCategoryName(e.item.category);
  };

  const company_code = localStorage.getItem("company_code");

  const makeRequest = (data) => {
    var config = {
      method: "post",
      url: `/prime.sika/v2/cards?company_code=${localStorage.getItem(
        "company_code"
      )}`,
      data: data,
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        swal({
          title: "Error",
          text: error.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
      });
  };

  const handleClick = () => {
    switch (reviewData.type) {
      case "giveBack":
        var data = JSON.stringify({
          list_of_amounts: amts,
          description: description,
          logo: newLogo,
          discounted_amount: null,
          percentage_off: null,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title,
          card_background: newBgImage,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color,
            logo_position: logoPosition,
            amount_position: amountPosition,
            type: "custom",
            image_placeholder: "",
            egift_color: color1,
            show_egift_card: visible,
            logo_position_number: current2,
            amount_position_number: pos,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID,
          has_coupon_tag: false,
          coupon_tag: null,
          category: category?.item.category || categoryName,
          default_color: color,
          disable_image_upload: true,
          is_promotion: true,
          promotion_title: null,
          promotion_start: reviewData.giveStart,
          promotion_end: reviewData.giveEnd,
          promo_description: null,
          give_back_campaign_id: "",
          who_created_id: 3,
          // card_style: "",
          owner_id: null,
          promo_type: reviewData.type,
          promo_state: null,
          promo_value: reviewData.giveAmount,
          is_feature: false,
          terms_and_conditions: tc,
          groupings: {
            code: "0f911764b7a1a7a8e25c",
            group_id: 139,
            amounts: [
              {
                card_id: 187,
                amount: "0.0",
              },
            ],
          },
        });
        makeRequest(data);
        break;
      case "deal":
        var dealsData = JSON.stringify({
          list_of_amounts: amts,
          logo: newLogo,
          description: description,
          client_id: 2,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title,
          card_background: newBgImage,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color,
            logo_position: logoPosition,
            amount_position: amountPosition,
            type: "custom",
            image_placeholder: "",
            egift_color: color1,
            show_egift_card: visible,
            logo_position_number: current2,
            amount_position_number: pos,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID,
          has_coupon_tag: false,
          coupon_tag: null,
          category: category?.item.category || categoryName,
          default_color: color,
          disable_image_upload: true,
          is_promotion: true,
          promotion_title: reviewData.dealsName,
          promotion_start: reviewData.dealsStart,
          promotion_end: reviewData.dealsEnd,
          promo_description: reviewData.dealsAbout,
          who_created_id: 3,
          promotion_terms: null,
          // card_style: "",
          owner_id: null,
          promo_type: "promotionDiscount",
          promo_state: null,
          promo_value: reviewData.dealsDiscount || reviewData.dealsPercent,
          is_feature: false,
          terms_and_conditions: tc,
          groupings: {
            group_id: 139,
            amounts: [
              {
                amount: "0.0",
              },
            ],
          },
        });
        makeRequest(dealsData);
        break;
      case "promo":
        var promoData = JSON.stringify({
          id: 9,
          amount: amts.length === 1 ? amts : null,
          list_of_amounts: amount.length > 1 ? amts : null,
          logo: newLogo,
          card_background: newBgImage,
          description: description,
          selected: true,
          default_color: color,
          card_category_id: categoryID,
          disable_image_upload: true,
          amount_position: pos,
          is_promotion: true,
          promotion_type: reviewData.type,
          promo_type: null,
          promo_state: null,
          promotion_title: null,
          title: category?.item?.gallery[0].title || title,
          promotion_start: reviewData.promoStart,
          promotion_end: reviewData.promoEnd,
          promo_value: reviewData.promoAmount,
          promotion_description: null,
          created_at: new Date(),
          updated_at: new Date(),
          category: category?.item.category || categoryName,
          show_amount: true,
          give_back_campaign_id: "",
          card_style: "",
          company_code: company_code,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color,
            logo_position: logoPosition,
            amount_position: amountPosition,
            type: "custom",
            image_placeholder: "",
            egift_color: color1,
            show_egift_card: true,
          },
          state: "published",
        });
        makeRequest(promoData);
        break;
      default:
        var defaultData = JSON.stringify({
          list_of_amounts: amts,
          logo: newLogo,
          discounted_amount: null,
          percentage_off: null,
          expiry_date: null,
          title: category?.item?.gallery[0].title || title,
          description: description,
          card_background: newBgImage,
          web_design_props: {
            font_weight: "normal",
            show_amount: true,
            default_color: color,
            logo_position: logoPosition,
            amount_position: amountPosition,
            type: "custom",
            image_placeholder: "",
            egift_color: color1,
            show_egift_card: visible,
            logo_position_number: current2,
            amount_position_number: pos,
          },
          created_at: new Date(),
          state: "published",
          card_category_id: categoryID,
          has_coupon_tag: false,
          coupon_tag: null,
          category: category?.item.category || categoryName,
          default_color: color,
          disable_image_upload: true,
          is_promotion: false,
          promotion_title: null,
          promotion_start: null,
          promo_description: null,
          promotion_terms: null,

          owner_id: null,
          promo_type: null,
          promo_state: null,
          promo_value: null,
          is_feature: false,
          terms_and_conditions: tc,
          groupings: {
            amounts: [
              {
                amount: "0.0",
              },
            ],
          },
        });
        makeRequest(defaultData);
        break;
    }
  };

  const text = () => {
    const ref = createRef();
    setAmountTextbox([...amountTextbox, ref]);
  };

  const spliceText = (i) => {
    const arr = [...amountTextbox];
    const temp = amts;

    arr.splice(i, 1);
    temp.splice(i, 1);
    // arr.forEach(ele => {
    //   ele.current.value = temp

    // });

    setAmountTextbox([...arr]);
    setAmts([...temp]);
    //done
  };

  useEffect(() => {}, [amts]);

  const handleAmts = (e) => {
    setAmts(e);
  };
  
  return (
    <>
      <div
        className="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        {current1 === 1 && (
          <form action="">
            <ImageUpload1
              handleNewLogo={(e) => setNewLogo(e)}
              handleBgImage={(e) => setNewBgImage(e)}
              handleVisible={(e) => setVisible(e)}
              visible={visible}
              handlePos={(e) => setPos(e)}
              title={category?.item?.gallery[0].title || title}
              handleTitle={(e) => setTitle(e)}
              handleColor={(e) => setColor(e)}
              handleColor1={(e) => setColor1(e)}
              handleLogo={(e) => setLogo(e)}
              setBackgroundImage={(e) => setBackgroundImage(e)}
              amount={category?.item.gallery[0].amount || amount}
              color={color}
              color1={color1}
              logo={logo}
              pos={pos}
              backgroundImage={backgroundImage}
              current2={current2}
              setCurrent2={setCurrent2}
            />
            {amountTextbox ? (
              <Amounts
                handleTextbox={() => text()}
                spliceText={(i) => spliceText(i)}
                handleAmts={(e) => handleAmts(e)}
                amts={amts}
                handleAmount={(e) => setAmount(e)}
                amountTextbox={amountTextbox}
                show={show}
              />
            ) : null}
            <Category
              handleCat={(e) => setCategoryName(e.name)}
              handleCatID={(e) => setCategoryID(e.id)}
              cat={categoryName}
            />
            <Promotions1 handleReviewData={(e) => setReviewData(e)} />
            <Terms terms={tc} handleText={(e) => setTc(e)} />
            <Description
              description={description}
              handleText={(e) => setDescription(e)}
            />
            <div className="row">
              <div className="col-12">
                <button
                  disabled={disabled}
                  type="button"
                  className="btn btn-success btn-block review-btn mt-2"
                  onClick={() => handleValidation()}
                >
                  Review
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      {current1 === 2 && (
        <CustomCardReview
          handleVisible={(e) => setVisible(e)}
          visible={visible}
          type={reviewData.type}
          current1={current1}
          current2={current2}
          setCurrent2={setCurrent2}
          setCurrent1={setCurrent1}
          category={categoryName}
          terms={tc}
          handlePublish={handleClick}
          pos={pos}
          amts={amts}
          handlePos={(e) => setPos(e)}
          title={category?.item?.gallery[0].title || title}
          handleTitle={(e) => setTitle(e)}
          handleColor={(e) => setColor(e)}
          handleColor1={(e) => setColor1(e)}
          handleLogo={(e) => setLogo(e)}
          color={color}
          color1={color1}
          logo={logo}
          backgroundImage={backgroundImage}
          description={description}
        />
      )}
      {current1 === 3 && (
        <CustomSuccess
          setCurrent1={setCurrent1}
          current2={current2}
          color={color}
          color1={color1}
          pos={pos}
          amts={amts}
          logo={logo}
          visible={visible}
        />
      )}
    </>
  );
};

export default CustomCard;
